<div #screen
class="container-fluid h-100"
(window:resize)="onResize($event)">
<div id="entity-errors"
   class="row m-1">
   <div class="col">
      <facnm-errors></facnm-errors>
   </div>
</div>
<div id="screen-modules"
   class="row">
   <div class="col">
      <div className="modules">
         <table class="table">
            <thead>
               <tr>
                  <td class="col-1 text-center"><strong>Module Id</strong></td>
                  <td class="col-4"><strong>Module Name</strong></td>
                  <td class="col-4"><strong>Enabled</strong></td>
                  <td class="col-3"></td>
               </tr>
            </thead>
            <tbody [style.height]="formHeight">
               <tr *ngFor="let module of modules">
                  <td class="col-1 text-center align-middle">
                     <span class="module-id">
                     {{ module.ModuleID }}
                     </span>
                  </td>
                  <td class="col-4 align-middle">
                     {{ module.ModuleName }}
                  </td>
                  <td class="col-4 align-middle">
                     <ng-container>
                        <dx-check-box [(value)]="module.Enabled"
                        (onValueChanged)="onChangeModule(module)"></dx-check-box>   
                     </ng-container>
                  </td>
                  <td class="col-3 align-middle">
                     <button type="button" class="btn-icon-action"
                     (click)="saveModule(module)"
                     [disabled]="!module.modified">
                     <img src="assets/Content/Images/Icons/save.png"
                        class="edit-button-image"
                        [ngClass]="{'disabled': !module.modified}" />
                     </button>
                     <button type="button" class="btn-icon-action"
                     (click)="resetModule(module)"
                     [disabled]="!module.modified">
                     <img src="assets/Content/Images/Icons/cancel.png"
                        class="edit-button-image"
                        [ngClass]="{'disabled': !module.modified}" />
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
</div>
<facnm-notifications [entityName]="entityName"></facnm-notifications>
<facnm-loading-screen-indicator></facnm-loading-screen-indicator>