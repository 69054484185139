import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ConfigurationGroup, ConfigurationGroupInterface } from './configuration-group.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationGroupService implements ConfigurationGroupInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  private listRetrieved: BehaviorSubject<ConfigurationGroup[]> = new BehaviorSubject<ConfigurationGroup[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  notifyRetrieved(data: ConfigurationGroup[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<ConfigurationGroup>) => Observable<any>;

  create(data: Entity<ConfigurationGroup>): Observable<any> {
    return this._http.put<ConfigurationGroup>(
      e.endpoint.fleet.configurationGroup,
      data.value)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  getList(): Observable<ConfigurationGroup[]> {
    return this._http.get<ConfigurationGroup[]>(e.endpoint.fleet.configurationGroup)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    return this._http.delete(e.endpoint.fleet.configurationGroup + "/" + param.Id, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(param) {
    return this._http.get<ConfigurationGroup>(e.endpoint.fleet.configurationGroup + '/' + param.Id)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

}
