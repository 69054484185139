import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity, Enum } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { SharedService } from '@app/_shared/shared.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdditionalFields, AdditionalFieldsInterface, AdditionalFieldViewLocation, FieldLocationEng } from './additional-fields.model';
import { AdditionalFieldsService } from './additional-fields.service';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-additional-fields',
  templateUrl: './additional-fields.component.html',
  styleUrls: ['./additional-fields.component.scss'],
  providers: [
    {
      provide: AdditionalFieldsInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new AdditionalFieldsService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class AdditionalFieldsComponent implements OnInit {

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private dataGridCompany: DxDataGridComponent;
  @ViewChild('dataGridCompany', { static: false }) set content(content: DxDataGridComponent) {
    if (content) {
      this.dataGridCompany = content;
    }
  }

  private dataGridTimeLog: DxDataGridComponent;
  @ViewChild('dataGridTimeLog', { static: false }) set timeLogContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridTimeLog = content;
    }
  }

  private dataGridLoadLocation: DxDataGridComponent;
  @ViewChild('dataGridLoadLocation', { static: false }) set loadLocationContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridLoadLocation = content;
    }
  }

  private unsubscribe$ = new Subject<void>();

  gridData: ArrayStore;
  gridDataCompany: ArrayStore;
  gridDataTimeLog: ArrayStore;
  gridDataLoadLocation: ArrayStore;
  entityData: Entity<AdditionalFields>;
  newEntityData: AdditionalFields;
  selectedVisibilityRow: any;
  selectedFieldLocation: any;
  GICollapse = false;
  ResCollapse = false;
  VCollapse = false;
  isFormVisibilityVisible = false;
  dataTypeList = [];
  fieldLocationsList = []
  viewModeList = [];
  viewModeListAll = [];
  companyList = [];
  timeLogTypeList = [];
  loadLocationTypes = [];
  isAddForm = 0;
  collapseColor = 'green';
  entityName = 'Additional Fields';

  FieldLocationEng = FieldLocationEng;

  constructor(
    private AdditionalFields$: AdditionalFieldsInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private shared$: SharedService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.actions();
    this.getList();
    this.app$.setScreenTitle(this.entityName);
    forkJoin({
      viewModeList: this.AdditionalFields$.getViewModeTypes(),
      dataTypeList: this.AdditionalFields$.getDataTypeList(),
      fieldLocationsList: this.AdditionalFields$.getFieldLocations(),
      companyList: this.shared$.getCompanyList(),
      timeLogTypeList: this.AdditionalFields$.getTimeLogTypeList(),
      loadLocationTypes: this.AdditionalFields$.getLoadLocationTypes()
    }).subscribe(
      res => {
        this.dataTypeList = res['dataTypeList'];
        this.fieldLocationsList = res['fieldLocationsList'];
        let viewModeList = res['viewModeList'].filter(d => d.id != 0);
        this.viewModeListAll = [...viewModeList];
        this.viewModeList = [...viewModeList];
        this.companyList = res['companyList'];
        this.timeLogTypeList = res['timeLogTypeList'];
        this.loadLocationTypes = res['loadLocationTypes'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.AdditionalFields$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ['FieldType', 'FieldLocation']
        });

        if (data.length == 0) {
          this.dataGrid.noDataText = 'No Product Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.AdditionalFields$.getList().subscribe(listWithCreated => {
      this.AdditionalFields$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }


  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = { ...this.newEntityData };
    this.entityData.value.Visibility = [];
    this.entityData.created = true;
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
    this.isFormVisibilityVisible = false;
    this.removeTemporaryVisibilities();
    this.dataGridIntialization();
    this.refreshViewModes();
  }

  private deleteConfirm() {
    let param = { FieldType: this.entityData.value.FieldType, FieldLocation: this.entityData.value.FieldLocation }
    this.AdditionalFields$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    this.entityData.value.Visibility = this.entityData.value.Visibility.filter((afv) => {
      if (afv.OsrChecked) {
        afv.Osr = Object.values(afv.OsrChecked).filter(v => v >= 0);
      }
      return afv.IsVisible == true;
    });

    this.AdditionalFields$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  onVisibilitySelectionChange(selectedRowsInfo) {
    var row = selectedRowsInfo.selectedRowsData[0];
    if (row != null) {
      this.selectedVisibilityRow = row;
      this.selectedFieldLocation = this.entityData.value.FieldLocation;
      this.isFormVisibilityVisible = true;
    }
  }

  removeTemporaryVisibilities() {
    this.companyList.forEach((v) => {
      v.$visibility = { OsrChecked: {} };
    });
    this.timeLogTypeList.forEach((v) => {
      v.$visibility = { OsrChecked: {} };
    });
    this.loadLocationTypes.forEach((v) => {
      v.$visibility = { OsrChecked: {} };
    });

  }

  onShowFieldChange() {
    var afVisibility;
    switch (this.entityData.value.FieldLocation) {

      case FieldLocationEng.LoadLocation:
        afVisibility = this.entityData.value.Visibility.filter((afv) => {
          return afv.LoadLocationType == this.selectedVisibilityRow.LoadLocationType;
        });
        if (!afVisibility.length) {
          this.selectedVisibilityRow.$visibility.LoadLocationType = this.selectedVisibilityRow.LoadLocationType;
        }
        this.dataGridLoadLocation.instance.refresh();
        break;

      case FieldLocationEng.TimeLog: 
        afVisibility = this.entityData.value.Visibility.filter((afv) => {
          return this.selectedVisibilityRow.TimeLogType === afv.TimeLogType && this.selectedVisibilityRow.TimeLogId == afv.TimeLogId
        });
        if (!afVisibility.length) {
          this.selectedVisibilityRow.$visibility.TimeLogId = this.selectedVisibilityRow.TimeLogId;
          this.selectedVisibilityRow.$visibility.TimeLogType = this.selectedVisibilityRow.TimeLogType;
        }
        this.dataGridTimeLog.instance.refresh();
        break;

      case FieldLocationEng.OrderDetail:

        afVisibility = this.entityData.value.Visibility.filter((afv) => {
          return afv.CompanyId == this.selectedVisibilityRow.Id;
        });
        if (!afVisibility.length) {
          this.selectedVisibilityRow.$visibility.CompanyId = this.selectedVisibilityRow.Id;
        }
        this.selectedVisibilityRow.$visibility.IsVisible = Object.values(this.selectedVisibilityRow.$visibility.OsrChecked).filter(v => v >= 0).length > 0;

        this.dataGridCompany.instance.refresh();
        break;

      default:
        afVisibility = this.entityData.value.Visibility.filter((afv) => {
          return afv.CompanyId == this.selectedVisibilityRow.Id;
        });
        if (!afVisibility.length) {
          this.selectedVisibilityRow.$visibility.CompanyId = this.selectedVisibilityRow.Id;
        }

        this.dataGridCompany.instance.refresh();
    }

    if (!afVisibility.length) {
      this.entityData.value.Visibility.push(this.selectedVisibilityRow.$visibility);
    } else {
      this.entityData.value.Visibility.forEach((s, index) => {
        if (this.checkUpdateCondition(s)) {
          this.entityData.value.Visibility[index] = this.selectedVisibilityRow.$visibility
        }
      });
    }
  }
 
  checkUpdateCondition(data:any) {
    let res = false;
    switch (this.entityData.value.FieldLocation) {

      case FieldLocationEng.LoadLocation:
        res = data.LoadLocationType == this.selectedVisibilityRow.LoadLocationType
        break;

      case FieldLocationEng.TimeLog:
        res = data.TimeLogId == this.selectedVisibilityRow.TimeLogId &&  data.TimeLogType == this.selectedVisibilityRow.TimeLogType
        break;

      default:
        res = data.CompanyId == this.selectedVisibilityRow.Id
        break;
    }
    return res;
  }

  restoreVisibilities(visibilities, fieldLocationId) {
    switch (fieldLocationId) {

      case FieldLocationEng.TimeLog:

        visibilities.forEach((visibility, key) => {
          if (visibility.TimeLogType > 0) {
            visibility.IsVisible = true;
            var timeLogType = this.timeLogTypeList.filter((t) => t.TimeLogType === visibility.TimeLogType && t.TimeLogId == visibility.TimeLogId);
            if (timeLogType.length == 1) {
              timeLogType[0].$visibility = visibility;
              timeLogType[0].$visibilityKey = key;
            }
          }
        });
        break;

      case FieldLocationEng.LoadLocation:

        visibilities.forEach((visibility, key) => {
          if (visibility.LoadLocationType > 0) {
            visibility.IsVisible = true;
            var loadLocationType = this.loadLocationTypes.filter((l) => l.LoadLocationType === visibility.LoadLocationType);
            if (loadLocationType.length == 1) {
              loadLocationType[0].$visibility = visibility;
              loadLocationType[0].$visibilityKey = key;
            }
          }
        });
        break;

      case FieldLocationEng.OrderDetail:
        visibilities.forEach((visibility, key) => {
          var osrChecked = {};
          if (visibility.CompanyId > 0) {
            visibility.IsVisible = true;
            var company = this.companyList.filter((c) => c.Id === visibility.CompanyId);
            if (company.length == 1) {
              visibility.Osr.forEach((osr, key) => {
                switch (osr) {
                  case 0: osrChecked['0'] = 0;
                    break;
                  case 1: osrChecked['1'] = 1;
                    break;
                  case 2: osrChecked['2'] = 2;
                    break;
                }
              });
              company[0].$visibility = visibility;
              company[0].$visibilityKey = key;
            }
          }
          visibility.OsrChecked = osrChecked;

        });
        break;

      default:

        visibilities.forEach((visibility, key) => {
          if (visibility.CompanyId > 0) {
            visibility.IsVisible = true;
            var company = this.companyList.filter((c) => c.Id === visibility.CompanyId);
            if (company.length == 1) {
              company[0].$visibility = visibility;
              company[0].$visibilityKey = key;
            }
          }
        });
    }
  }

  onFieldLocationChange() {
    this.isFormVisibilityVisible = false;
    if (this.selectedFieldLocation) {
      this.clearVisibilitySelection();
    }
    this.refreshViewModes();
    this.dataGridIntialization();
  }

  clearVisibilitySelection() {
    this.selectedVisibilityRow = null;
    switch (this.selectedFieldLocation) {
      case FieldLocationEng.LoadLocation:
        this.dataGridLoadLocation?.instance.deselectAll();
        break;
      case FieldLocationEng.TimeLog:
        this.dataGridTimeLog?.instance.deselectAll();
        break;
      default:
        this.dataGridCompany?.instance.deselectAll();
        break;
    }
  }

  refreshViewModes() {

    if (this.isAddForm) this.entityData.value.ViewMode = [];

    switch (this.entityData.value.FieldLocation) {
      case FieldLocationEng.LoadingDetail:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          t.id == AdditionalFieldViewLocation.LoadingProductCompartment);
        if (this.isAddForm) this.entityData.value.ViewMode = [this.viewModeList[0].id];
        break;

      case FieldLocationEng.LoadingDetailPerProduct:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          t.id == AdditionalFieldViewLocation.LoadingProductDetail);
        if (this.isAddForm) this.entityData.value.ViewMode = [this.viewModeList[0].id];
        break;

      case FieldLocationEng.OrderDetail:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          t.id == AdditionalFieldViewLocation.OrderProductDetail);
        if (this.isAddForm) this.entityData.value.ViewMode = [this.viewModeList[0].id];
        break;

      case FieldLocationEng.LoadLocation:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          t.id == AdditionalFieldViewLocation.LoadingLoadLocationProductCompartment);
        if (this.isAddForm) this.entityData.value.ViewMode = [this.viewModeList[0].id];
        break;

      case FieldLocationEng.Loading:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          [
            AdditionalFieldViewLocation.LoadingStart,
            AdditionalFieldViewLocation.LoadingProductsDone
          ].includes(t.id));
        break;

      case FieldLocationEng.TimeLog:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          t.id == AdditionalFieldViewLocation.TimeLog);
        if (this.isAddForm) this.entityData.value.ViewMode = [this.viewModeList[0].id];
        break;

      case FieldLocationEng.Order:
        this.viewModeList = this.viewModeListAll.filter((t: Enum) =>
          [
            AdditionalFieldViewLocation.OrderStart,
            AdditionalFieldViewLocation.OrderProductsDone,
            AdditionalFieldViewLocation.OrderAfterSignature,
            AdditionalFieldViewLocation.OrderPrintSaleDone,
            AdditionalFieldViewLocation.OrderRegisterReceiveScreen
          ].includes(t.id));
        break;

      default:
        this.viewModeList = cloneDeep(this.viewModeListAll);
        break;
    }
  }

  dataGridIntialization() {
    switch (this.entityData.value.FieldLocation) {
      case FieldLocationEng.LoadLocation:
        this.gridDataLoadLocation = new ArrayStore({
          data: this.loadLocationTypes,
        });
        break;

      case FieldLocationEng.TimeLog:
        this.gridDataTimeLog = new ArrayStore({
          data: this.timeLogTypeList,
        });
        break;

      default:
        this.gridDataCompany = new ArrayStore({
          data: this.companyList,
          key: ['Id']
        });
        break;
    }
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  onCellPreparedVisibility(e: any) {
    if (e.rowType === "data" && e.column.dataField === "Visibility") {
      if (e.data.$visibility.IsVisible)
        e.cellElement.innerHTML = '<img src="assets/Content/Images/Icons/shown_field.png"  />';
      else
        e.cellElement.innerHTML = '<img src="assets/Content/Images/Icons/hidden_field.png"  />';
    }
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.isFormVisibilityVisible = false;
    this.AdditionalFields$.getEntity(key).subscribe((res: any) => {
      this.clearVisibilitySelection();
      this.removeTemporaryVisibilities();
      this.restoreVisibilities(res.Visibility, res.FieldLocation);
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
      this.dataGridIntialization();
      this.refreshViewModes();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }


  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData){
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
      
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }


  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  isNumberOnly(event) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}


