import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export class Modules {
    ModuleID: string;
    ModuleName: string;
    Enabled: boolean;
    modified: boolean;
    invalid: boolean;
}

@Injectable()
export abstract class ModulesInterface  {
    save: (data: Modules) => Observable<any>;
    getList: () => Observable<Modules[]>;
}