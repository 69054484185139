import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { ImportConfigurationInterface } from './import-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ImportConfigurationService implements ImportConfigurationInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  uploadConfigurationSet(data): Observable<any> {
    return this._http.post<any>(
      e.endpoint.fleet.configurationset + "/upload",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  importConfigurationSet(data): Observable<any> {
    return this._http.post<any>(
      e.endpoint.fleet.configurationset + "/import",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }
}
