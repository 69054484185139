import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class LoadPoint {
  Id: number;
  Number: string;
  Name1: string;
  Name2: string;
  Name3: string;
  CompanyId: number;
  Barcode: string;
  Type: string;
  Address: string;
  City: string;
  Postcode: string;
  State: string;
  CountryId: number;
  Latitude: number;
  Longitude: number;
  ContactName: any;
  ContactPhone: number;
  LoadLocationGroup: number;
  AllowRegisterDataChange: boolean;
  LoadPointTanks: any; 
}

export class LoadPointTank{
    Id: number;
    LoadLocationId: number;
    TankId: string;
    ProductId: number;
    ProductName: string;
    Barcode: string;
    ChangeDate: Date;
    Deleted: boolean;
}

export class Product {

  Id: number;
  Number: string;
  Name: string;
  ValidDate: Date;
  Barcode: string;
  iButtonId: string;
  Language: string;
  CanbusDriverId: string;
  Password: string;
  Deleted: boolean;
  CompanyId: number;
  PriceChange: number;
  SalesTaxId: number;
  PricePer: any;
  ProductPrice: number;
  MaxDiscount: number;
  ProductWeight: number;
  IsEmptyProduct: boolean;

}

@Injectable()
export abstract class LoadPointInterface implements EntityInterface<LoadPoint> {
  create: (data: Entity<LoadPoint>) => Observable<any>;
  save: (data: Entity<LoadPoint>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<LoadPoint[]>;
  getEntity: (key: any) => Observable<LoadPoint>;
  ListRetrieved: Observable<LoadPoint[]>;
  notifyRetrieved: (data: LoadPoint[]) => void;

  getProductList: (arg) => Observable<Product[]>;
  getloadLocationGroup:()=> Observable<Enum[]>;
  getCountryList:() => Observable<Enum[]>;
  getLoadPointDetails:(id) => Observable<any>;


}