<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
    <div id="user-management" class="row h-100">
        <div class="col">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="userData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onSelectionChanged)="onSelectionChanged($event)" (onRowClick)="onRowClick($event)"
                    (onCellPrepared)="onCellPrepared($event)" [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column caption="User name" dataField="UserName" dataType="string">
                    </dxi-column>
                    <dxi-column caption="Company" dataField="CompanyName" dataType="string">
                    </dxi-column>
                    <dxi-column caption="Fleet" dataField="Fleet" dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true" [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="user && user.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class="container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="collapsible-header-container top-buffer" (click)="GSCollapse = !GSCollapse">
                            <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                <div class="collapsible-header-icon" [ngClass]="collapseClass(GSCollapse)"></div>
                                <div class="text-center">
                                    <span
                                        class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                        General Settings
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="GSCollapse" class="childs" [ngClass]="{'showChild': GSCollapse}">
                                <div class="col-lg-12">
                                    <div class="col-lg-6">
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="UserName" class="col-form-label">User Name</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <input [(ngModel)]="user.value.UserName" type="text"
                                                        class="form-custom-control control-look-input" name="UserName"
                                                        #UserName="ngModel" [readonly]="user.value.Id != 0"
                                                        maxlength="50" required
                                                        [ngClass]="{'modelstate-invalid': hasError('UserName')}">
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('UserName')">
                                                        <small class="error" *ngIf="checkError('UserName','required')">
                                                            User Name is required
                                                        </small>
                                                        <small class="error" *ngIf="checkError('UserName','maxlength')">
                                                            User Name cannot be longer than 50 characters
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="FirstName" class="col-form-label">First Name</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <input [(ngModel)]="user.value.FirstName" type="text"
                                                        class="form-custom-control control-look-input" name="FirstName"
                                                        #FirstName="ngModel" [readonly]="user.value.Id != 0"
                                                        maxlength="20" required
                                                        [ngClass]="{'modelstate-invalid': hasError('FirstName')}">
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('FirstName')">
                                                        <small class="error" *ngIf="checkError('FirstName','required')">
                                                            First Name is required
                                                        </small>
                                                        <small class="error"
                                                            *ngIf="checkError('FirstName','maxlength')">
                                                            First Name cannot be longer than 20 characters
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="LastName" class="col-form-label">Last Name</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <input [(ngModel)]="user.value.LastName" type="text"
                                                        class="form-custom-control control-look-input" name="LastName"
                                                        #LastName="ngModel" [readonly]="user.value.Id != 0"
                                                        maxlength="20" required
                                                        [ngClass]="{'modelstate-invalid': hasError('LastName')}">
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('LastName')">
                                                        <small class="error" *ngIf="checkError('LastName','required')">
                                                            Last Name is required
                                                        </small>
                                                        <small class="error" *ngIf="checkError('LastName','maxlength')">
                                                            Last Name cannot be longer than 20 characters
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="EmailAddress" class="col-form-label">Email</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <input [(ngModel)]="user.value.EmailAddress" type="text"
                                                        class="form-custom-control control-look-input"
                                                        name="EmailAddress" #EmailAddress="ngModel" maxlength="50">
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('EmailAddress')">
                                                        <small class="error"
                                                            *ngIf="checkError('EmailAddress','maxlength')">
                                                            Email cannot be longer than 50 characters
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="Password" class="col-form-label">Password</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <input [(ngModel)]="user.value.Password" type="password"
                                                        class="form-custom-control control-look-input" name="Password"
                                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{1,20}$"
                                                        #Password="ngModel" minlength="7" maxlength="20" [required] ="user.value.Id == 0"
                                                        [ngClass]="{'modelstate-invalid': hasError('Password')}">
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('Password')">
                                                        <small class="error" *ngIf="checkError('Password','required')">
                                                            Password is required
                                                        </small>
                                                        <small class="error" *ngIf="checkError('Password','minlength')">
                                                            Password must be atleast 7 characters
                                                        </small>
                                                        <small class="error" *ngIf="checkError('Password','maxlength')">
                                                            Password cannot be longer than 20 characters
                                                        </small>
                                                        <small class="error" *ngIf="checkError('Password','pattern')">
                                                            Password must have at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*#?&)
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="FleetId" class="col-form-label">Fleet </label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <pdi-dropdown [full]="true" id="fleetId" name="FleetId"
                                                        [(ngModel)]="user.value.FleetId" #FleetId="ngModel">
                                                        <pdi-dropdown-option *ngFor="let fleet of fleetDropDown"
                                                            [(key)]="fleet.Id">{{fleet.Name}}</pdi-dropdown-option>
                                                    </pdi-dropdown>
                                                    <div class="control-look"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                    <label for="CompanyId" class="col-form-label">Company</label>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <pdi-dropdown [full]="true" name="CompanyId"
                                                        [(ngModel)]="user.value.CompanyId" #CompanyId="ngModel"
                                                        required [ngClass]="{'modelstate-invalid': hasError('CompanyId')}">
                                                        <pdi-dropdown-option *ngFor="let company of companyDropDown"
                                                            [(key)]="company.Id">{{company.name}}</pdi-dropdown-option>
                                                    </pdi-dropdown>
                                                    <div class="control-look"></div>
                                                    <div class="error-message" *ngIf="hasError('CompanyId')">
                                                        <small class="error" *ngIf="checkError('CompanyId','required')">
                                                            Please select a Company.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label"
                                                    style="text-align: right;">
                                                    <input type="checkbox" name="IsLocked"
                                                        [(ngModel)]="user.value.IsLocked" style="margin-top: 8px;" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <label for="IsLocked" class="col-form-label">Lock this user</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="LastUserActivity" class="col-form-label">Last Activity:
                                                </label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="text-align: center;"
                                                *ngIf="user.value.Id != 0">
                                                {{user.value.LastUserActivity | date:'dd-MM-yyyy HH:MM:ss a'}}
                                                <div class="control-look" *ngIf="user.value.Id != 0" required></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="container">
                                            <div class="row form-group">
                                                <div class="col-4 pl-0">
                                                    <label for="UserRoleIds" class="col-form-label">Roles</label>
                                                </div>
                                                <dx-tag-box name="UserRoleIds" [(ngModel)]="user.value.UserRoleIds"
                                                    [items]="roleList" valueExpr="Id" [value]="user.value.UserRoleIds"
                                                    displayExpr="RoleName" [hideSelectedItems]="true"
                                                    [searchEnabled]="true">
                                                </dx-tag-box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container">
                                    <div class="row form-group" *ngIf="user.value.Id == 0">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="SecurityQuestion" class="col-form-label">Security
                                                Question</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8">
                                            <input [(ngModel)]="user.value.SecurityQuestion" type="text"
                                                class="form-custom-control control-look-input" name="SecurityQuestion"
                                                #SecurityQuestion="ngModel" required [ngClass]="{'modelstate-invalid': hasError('SecurityQuestion')}">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('SecurityQuestion')">
                                                <small class="error" *ngIf="checkError('SecurityQuestion','required')">
                                                    Security Question is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="user.value.Id == 0">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="SecurityAnswer" class="col-form-label">Security Answer</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8">
                                            <input [(ngModel)]="user.value.SecurityAnswer" type="text"
                                                class="form-custom-control control-look-input" name="SecurityAnswer"
                                                #SecurityAnswer="ngModel" required [ngClass]="{'modelstate-invalid': hasError('SecurityAnswer')}">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('SecurityAnswer')">
                                                <small class="error" *ngIf="checkError('SecurityAnswer','required')">
                                                    Security Answer is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <facnm-action-buttons [modelEntity]="user" [hideDelete]="true" [entityName]="entityName"></facnm-action-buttons>
        </div>
    </div>
</div>
<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>