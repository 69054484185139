import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class Product {

  Id: number;
  Number: string;
  Name: string;
  ValidDate: Date;
  Barcode: string;
  iButtonId: string;
  Language: string;
  CanbusDriverId: string;
  Password: string;
  Deleted: boolean;
  CompanyId: number;
  PriceChange: number;
  SalesTaxId: number;
  PricePer: any;
  ProductPrice: number;
  MaxDiscount: number;
  ProductWeight: number;
  IsEmptyProduct: boolean;
  QuantityUnit:string;
  ProductTypeId: number;
  DangerousGoodsGroupId: number;
  ReturnedEmptyProductId: number;
  AdditionalProductId: number;
  FollowUpProductId: number;
  TransportProductId: number;

}

@Injectable()
export abstract class ProductInterface implements EntityInterface<Product> {
  create: (data: Entity<Product>) => Observable<any>;
  save: (data: Entity<Product>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: (data: any) => Observable<Product[]>;
  getEntity: (key: any) => Observable<Product>;
  ListRetrieved: Observable<Product[]>;
  notifyRetrieved: (data: Product[]) => void;

  getProductCategoryList: () => Observable<Enum[]>;
  getPriceChangeableDropdown: () => Observable<Enum[]>;
  getSalesTaxDropdown: () => Observable<Enum[]>;
  getQuantityUnitDropdown: () => Observable<Enum[]>;
  getProductTypeDropdown: () => Observable<Enum[]>;
  getDangerousGoodsDropdown: () => Observable<Enum[]>;
  getProductDropdown: () => Observable<Enum[]>;
  getNewProduct: () => Observable<Product>;
  saveProductType: (data: any) => Observable<any>;
  getProductCategoryShapes: () => Observable<Enum[]>;
  saveProductCategory: (data: any) => Observable<any>;
  isProductCategoryDeletable: (data: any) => Observable<any>;
  deleteProductCategory: (data: any) => Observable<any>;
}