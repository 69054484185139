<div class="gridDiv">

    <dx-data-grid #dataGridProducts
                  [dataSource]="productStore"
                  (onInitialized)="onProductsInitialized($event)"
                  (onContentReady)="onProductsContentReady($event)"
                  (onRowClick)="onProductsRowClick($event)"
                  (onSelectionChanged)="onProductsSelectionChanged($event)"
                  [allowColumnResizing]="true"
                  [(selectedRowKeys)]="ProductsId">

        <dxi-column dataField="ProductNumber"
                    [minWidth]="100"></dxi-column>
        <dxi-column dataField="ProductName"
                    [width]="'65%'"></dxi-column>


        <dxo-selection [mode]="selectionMode"
                       [allowSelectAll]="false"> </dxo-selection>

        <dxo-filter-row [visible]="true">
        </dxo-filter-row>

        <dxo-scrolling showScrollbar="always"
                       useNative="true">
        </dxo-scrolling>

        <dxo-sorting mode="single"> </dxo-sorting>

        <dxo-paging [pageSize]="5"> </dxo-paging>
        <dxo-pager [showNavigationButtons]="true">
        </dxo-pager>

    </dx-data-grid>

</div>