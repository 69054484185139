import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentTermInterface, PaymentTerm } from './payment-term.model';
import { PaymentTermService } from './payment-term.service';

@Component({
  selector: 'facnm-payment-term',
  templateUrl: './payment-term.component.html',
  styleUrls: ['./payment-term.component.scss'],
  providers: [
    {
      provide: PaymentTermInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new PaymentTermService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class PaymentTermComponent implements OnInit {

  constructor(
    private paymentTerm$: PaymentTermInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  isAddForm = 0;
  entityName = 'Payment Terms';
  addEntityName = 'Payment Term';
  entityData: Entity<PaymentTerm>;
  newEntityData: PaymentTerm;
  newEntity: any;
  companyList: any[];
  paymentTermsOptionList: any[];
  selectedCompany: 0;
  errorList = [];
  originalId = -1;

  ngOnInit(): void {
    this.actions();
    this.app$.setScreenTitle(this.entityName);
    forkJoin({
      newEntity: this.paymentTerm$.getNewEntity(),
      companyList: this.paymentTerm$.getCompanyList(),
      paymentTermsOptionList: this.paymentTerm$.getPaymentTermOptionList(),
    }).subscribe(
      res => {
        this.newEntityData = res['newEntity'];
        this.companyList = res['companyList'];
        this.paymentTermsOptionList = res['paymentTermsOptionList'];
        if (this.companyList.length > 0) {
          this.selectedCompany = this.companyList[0].Id;
        }
        this.getList();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.paymentTerm$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ["Id", "CompanyId"]
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.app$.setScreenTitle(this.entityName);
    this.entityData = new Entity();
    this.entityData.key = undefined;
    if (this.selectedCompany != null && this.selectedCompany != undefined && this.selectedCompany > 0) {
      this.paymentTerm$.getList(this.selectedCompany).subscribe(listWithCreated => {
        this.paymentTerm$.notifyRetrieved(listWithCreated);
        this.app$.notifyScreenReady();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    else {
      this.addNewEntity();
    }
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  validateForm() {
    this.clearErrors();
    var vallidateValue = false;
    if (this.entityData.value.DirectDebit && !this.entityData.value.Invoice) {
      this.errorList.push("'Payment by bank transfer' must be used with 'Invoice' only.");
      vallidateValue = true;
    }
    if (this.entityData.value.Prepayment && !this.entityData.value.Invoice) {
      this.errorList.push("'Prepayment' must be used with 'Invoice' only.");
      vallidateValue = true;
    }
    if (this.entityData.value.DeliveryNoteWithPrices && !this.entityData.value.DeliveryNote) {
      this.errorList.push("'With price' must be used with 'Delivery Note' only.");
      vallidateValue = true;
    }
    if (this.entityData.value.OmitAddress && (!this.entityData.value.DeliveryNote && !this.entityData.value.Invoice)) {
      this.errorList.push("'Omit delivery address' must be used with 'Invoice' or 'Delivery Note' only.");
      vallidateValue = true;
    }
    return vallidateValue;
  }

  clearErrors() {
    this.errorList = [];
  }

  hasErrorList() {
    if (this.errorList != undefined) {
      return (this.errorList.length > 0) ? true : false;
    }
    return false;
  }

  create(isSave: boolean) {
    if (!this.validateForm()) {
      this.handleFormSubmit();
      this.entityForm.onSubmit(undefined);
      if (this.entityForm.form.invalid) {
        setTimeout(() => { this.app$.notifyScreenReady(); })
        return;
      }
      if (this.originalId == undefined || this.originalId == null)
        this.originalId = this.entityData.value.Id;
      this.entityData.value.CompanyId = this.selectedCompany;
      let param = {
        data: this.entityData,
        originalId: this.originalId,
      };
      this.paymentTerm$.create(param).subscribe(
        (okCreate) => {
          this.app$.setScreenTitle(this.entityName);
          isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
          this.deSelectEntity();
          this.getList();
          this.refreshGrid();
          this.app$.notifyScreenReady();
        },
        (errorCreate) => {
          this.errors$.handleServerErrors(errorCreate);
        }
      );
    }
    setTimeout(() => { this.app$.notifyScreenReady(); })
    return;
  }

  private deleteConfirm() {
    let param = {
      Id: this.entityData.value.Id,
      CompanyId: this.selectedCompany,
    };
    this.paymentTerm$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.addEntityName);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.isAddForm = 1;
    this.changeDetectorRef.detectChanges();
    this.clearErrors();
  }

  onContentReadyGroup(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Payment Terms Found";
  }

  private selectEntity(key: any) {
    this.clearErrors();
    this.app$.notifyScreenBusy();
    this.originalId = key.Id;
    this.paymentTerm$.getEntity(key).subscribe((res: any) => {
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.app$.notifyScreenReady();
      this.isAddForm = 0;
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
    this.clearErrors();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - 75);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
