import { ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StrappingChart, StrappingChartInterface } from './strapping-chart.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { StrappingChartService } from './strapping-chart.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { AppService } from '@app/app.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgForm } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import ArrayStore from 'devextreme/data/array_store';
import { Entity } from '@app/app.model';
import { cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { displayZeroValueText, dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-strapping-chart',
  templateUrl: './strapping-chart.component.html',
  styleUrls: ['./strapping-chart.component.scss'],
  providers: [
    {
      provide: StrappingChartInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new StrappingChartService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class StrappingChartComponent implements OnInit {
  showError: boolean;

  constructor(
    private strappingChart$: StrappingChartInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private dataGridStrappingChart: DxDataGridComponent;
  @ViewChild('dataGridStrappingChart', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridStrappingChart = content;
    }
  }

  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  isAddForm = 0;
  entityName = 'Strapping Chart Profile';
  entityNameAdd = 'Chart Profile';
  entityData: Entity<StrappingChart>;
  newEntityData: StrappingChart;
  newEntity: any;
  shapes: any;
  orientations: any;
  collapseColor = 'green';
  GICollapse = false;
  incrementDuplicateErr = false;
  isEmptyErr = false;
  hasPermission = false;

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.app$.loginUserInfo.pipe(takeUntil(this.unsubscribe$)).subscribe((user: any) => {
      if (!user.IsStrappingChartEnabled) {
        this.app$.notifyScreenReady();
        return;
      }
      this.hasPermission =true;
      this.actions();
      this.getList();
      forkJoin({
        newEntity: this.strappingChart$.getNewEntity(),
        orientation: this.strappingChart$.getOrientation(),
        shape: this.strappingChart$.getShape()
      }).subscribe(
        res => {
          this.newEntityData = res['newEntity'];
          this.orientations = res['orientation'];
          this.shapes = res['shape'];
        },
        (errorNetwork) => {
          this.errors$.handleServerErrors(errorNetwork);
        }
      );
    })
  }
  actions() {
    this.strappingChart$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ["Id"]
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.strappingChart$.getList().subscribe(listWithCreated => {
      this.strappingChart$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    let hasDuplicates = false;
    let isEmpty = false;
    let noIncrement = false;
    this.incrementDuplicateErr = false;
    this.isEmptyErr = false;
    this.showError = true;

    if (this.entityData.value.StrappingChartProfileIncrements.length > 0) {
      hasDuplicates = this.entityData.value.StrappingChartProfileIncrements.some((element, index, self) => {
        return self.findIndex((item) => item.Increment === element.Increment) !== index;
      })
      isEmpty = this.entityData.value.StrappingChartProfileIncrements.filter((a) => a.Increment == null || a.IncrementVolume == null).length > 0;
    } else {
      noIncrement = true;
    }

    if (this.entityForm.form.invalid || hasDuplicates || isEmpty || noIncrement) {
      if (hasDuplicates) this.incrementDuplicateErr = true;
      if (isEmpty) this.isEmptyErr = true;
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.strappingChart$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = {
      Id: this.entityData.value.Id,
    };
    this.strappingChart$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityNameAdd);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);;
    this.entityData.created = true;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
    this.isAddForm = 1;
    this.showError = false;

  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Data Found";
  }

  private selectEntity(key: any) {
    this.showError = false;
    this.app$.notifyScreenBusy();
    this.strappingChart$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
      this.isAddForm = 0;
      this.collapseColor = 'green';
      if(this.entityData.value.Orientation == 0){
        displayZeroValueText(this.orientations,'Orientation','id','name');
      }
      if(this.entityData.value.Shape == 0){
        displayZeroValueText(this.shapes,'Shape','id','name');
      }
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }


  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }

    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  onContentReadyStrappingChart(e: any) {
    this.dataGridStrappingChart.noDataText = this.dataGridStrappingChart.instance.totalCount() ? '' : "No Data";
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
