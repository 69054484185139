import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class PaymentTermList {
  Id: number;
  CompanyId: number;
  Name: string;
}

export class PaymentTerm {
  Cash: boolean;
  CashCard: boolean;
  Cheque: boolean;
  CompanyId: 0
  CreditCard: boolean;
  DeliveryNote: boolean;
  DeliveryNoteWithPrices: boolean;
  DirectDebit: boolean;
  Id: number;
  Invoice: boolean;
  OmitAddress: boolean;
  Option: number;
  OptionEnabled: boolean;
  Prepayment: boolean;
}

@Injectable()
export abstract class PaymentTermInterface implements EntityInterface<PaymentTerm> {
  create: (data: any) => Observable<any>;
  save: (data: Entity<PaymentTerm>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: (CompanyId: number) => Observable<any[]>;
  getEntity: (key: any) => Observable<any>;
  ListRetrieved: Observable<any[]>;
  notifyRetrieved: (data: any[]) => void;
  getNewEntity: () => Observable<PaymentTerm>;
  getCompanyList: () => Observable<any>;
  getPaymentTermOptionList: () => Observable<any>;

}