import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'facnm-product-assignment',
  templateUrl: './product-assignment.component.html',
  styleUrls: ['./product-assignment.component.scss']
})
export class ProductAssignmentComponent implements OnInit {

  constructor() { }
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  private dataGridAssignedProducts: DxDataGridComponent;
  @ViewChild('dataGridAssignedProducts', { static: false }) set assignedProducts(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAssignedProducts = content;
    }
  }

  private dataGridUnassignedProducts: DxDataGridComponent;
  @ViewChild('dataGridUnassignedProducts', { static: false }) set unassignedProducts(content: DxDataGridComponent) {
    if (content) {
      this.dataGridUnassignedProducts = content;
    }
  }
  @Input() AssignedProducts = [];
  @Input() UnAssignedProducts = [];
  @Input() isAddForm = 0;
  assignedProductSelected = [];
  unassignedProductSelected = [];
  productColumnConfig: any;
  allMode: string;
  checkBoxesMode: string;

  ngOnInit(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'onClick';
    this.productColumnConfig = [
      { caption: 'Id', dataField: 'Id', visible: false, dataType: 'string' },
      { alignment: 'center', caption: 'Product Number', dataField: 'ProductNumber', cssClass: 'ProductNumber', dataType: 'string' },
      { alignment: 'center', caption: 'Product Name', dataField: 'ShortProductName', cssClass: 'ShortProductName', dataType: 'string' },
    ];
  }
  onContentReadyAssigned(e) {
    this.dataGridAssignedProducts.noDataText = this.dataGridAssignedProducts.instance.totalCount() ? '' : "No data";
  }
  onContentReadyUnAssigned(e) {
    this.dataGridUnassignedProducts.noDataText = this.dataGridUnassignedProducts.instance.totalCount() ? '' : "No data";
  }
  assignProducts() {
    let selectedRows = this.unassignedProductSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.AssignedProducts.filter(a => a.Id == row.Id);
      this.UnAssignedProducts.splice(this.UnAssignedProducts.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.AssignedProducts.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  unassignProducts() {
    let selectedRows = this.assignedProductSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.UnAssignedProducts.filter(a => a.Id == row.Id);
      this.AssignedProducts.splice(this.AssignedProducts.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.UnAssignedProducts.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  clearAndRefreshGrid() {
    if (this.dataGridUnassignedProducts && this.dataGridAssignedProducts) {
      this.dataGridUnassignedProducts.instance.clearSelection();
      this.dataGridUnassignedProducts.instance.refresh();
      this.dataGridAssignedProducts.instance.clearSelection();
      this.dataGridAssignedProducts.instance.refresh();
      this.setNoDataText();
    }
  }
  setNoDataText() {
    this.dataGridUnassignedProducts.noDataText = this.UnAssignedProducts?.length ? '' : "No data";
    this.dataGridAssignedProducts.noDataText = this.AssignedProducts?.length ? '' : "No data";
  }
}
