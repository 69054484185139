<div class="pdi-login">
  <div class="pdi-login-card pdi-centered">
    <div class="pdi-login-logo-dark">
    </div>
    <div class="simple-separator"></div>
    <pdi-page-notification class="mb-0 left-align" [canDismiss]="false" *ngIf="error" [text]="error"
      type="alert"></pdi-page-notification>
    <form [formGroup]="loginForm" #ngForm="ngForm" (ngSubmit)="onSubmit(ngForm)" class="pdi-login-form" novalidate>
      <label class="pdi-login__label">Content Manager</label>
      <pdi-input [darkMode]="true" formControlName="username" label="User Name" placeholder="User Name" required
        [full]="true" [customErrorMessages]="{ 'customError': 'User Name is required'}"></pdi-input>
      <pdi-input [darkMode]="true" formControlName="password" label="Password" placeholder="Password" required
        [full]="true" type="password" [customErrorMessages]="{ 'customError': 'Password is required'}"></pdi-input>
      <pdi-input [darkMode]="true" label="Company" placeholder="Company" formControlName="company" required
        [full]="true" [customErrorMessages]="{ 'customError': 'Company is required'}"></pdi-input>
      <pdi-button buttonId="submit" [darkMode]="true" size="primary" buttonType="submit" [text]="'Login'"></pdi-button>
      <div class="text-center">
        <div class ="or-separator">------------------------------------------ OR
          ------------------------------------------</div>
      </div>
      <pdi-button buttonId="sso" [isDisabled]="!isSSOUrlLoaded()" buttonType="button" [isSecondary]="true" (click)="submitSSOLogin()"
        [text]="'Single Sign-On'"></pdi-button>
    </form>
  </div>
</div>