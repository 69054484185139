import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '@app/app.service';
import { clientVersion } from '../version';

@Component({
  selector: 'facnm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ApiFileVersion: string;
  DbVersion: any;
  RequiredDbVersion: string;
  ApiFileInfoVersion: string
  currentYear: number;
  BuildVersionFull = clientVersion.BuildVersionFull;

  constructor(private app$: AppService) { }

  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    this.app$.APIVersionDetails.subscribe(
      (res: any) => {
        this.ApiFileVersion = res.connectionFailed ? res.ApiFileVersion : 'API v.' + res.ApiFileVersion;
        this.DbVersion = res.DbVersion;
        this.RequiredDbVersion = res.RequiredDbVersion;
        this.ApiFileInfoVersion = res.ApiFileInfoVersion;
      });
  }
}
