<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
    <div id="entity-management" class="row h-100">
        <div class="col-md-6">
            <div id="entity-list" class="h-100">
                <dx-data-grid class="no-data-min-height" #dataGrid [dataSource]="customDataSource" noDataText=""
                    (onContentReady)="onContentReadyGrid($event)" (onInitialized)="onInitialized($event)"
                    (onCellPrepared)="onCellPrepared($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="100" caption="Customer No" dataField="Number"
                        dataType="string"></dxi-column>
                    <dxi-column cssClass="white-space-normal" alignment='left' caption="Customer Name"
                        dataField="FullName" dataType="string" width="45%"></dxi-column>
                    <dxi-column cssClass="white-space-normal" alignment='left' caption="Customer Address"
                        dataField="Address" dataType="string" width="45%"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="none"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-6 pr-0 pl-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Number" class="col-form-label">Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="entityData.value.Number"
                                                [ngClass]="{'modelstate-invalid': hasError('Number')}" type="text"
                                                class="form-custom-control control-look-input" name="Number" required
                                                maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Number')">
                                                <small class="error" *ngIf="checkError('Number','required')">
                                                    Number is required
                                                </small>
                                                <small class="error" *ngIf="checkError('Number','maxlength')">
                                                    Number cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name1" class="col-form-label">Name 1.</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="Name1"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Name1')}"
                                                [(ngModel)]="entityData.value.Name1" required maxlength="250" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Name1')">
                                                <small class="error" *ngIf="checkError('Name1','required')">
                                                    Name 1 is required
                                                </small>
                                                <small class="error" *ngIf="checkError('Name1','maxlength')">
                                                    Name 1 cannot be longer than 250 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name2" class="col-form-label">Name 2.</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="Name2"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Name2')}"
                                                [(ngModel)]="entityData.value.Name2" maxlength="250" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Name2')">
                                                <small class="error" *ngIf="checkError('Name2','maxlength')">
                                                    Name 2 cannot be longer than 250 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name3" class="col-form-label">Name 3.</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="Name3"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Name3')}"
                                                [(ngModel)]="entityData.value.Name3" />
                                            <div class="control-look"></div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="BillQuantityType" class="col-form-label">Bill Quantity
                                                Type</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown name="BillQuantityType" [full]="true"
                                                [(ngModel)]="entityData.value.BillQuantityType">
                                                <pdi-dropdown-option *ngFor="let bqt of billQuantityTypeList" [key]="bqt.Id">
                                                    {{bqt.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="CompanyId" class="col-form-label">Company</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown name="CompanyId" [full]="true"
                                                [ngClass]="{'modelstate-invalid': hasError('CompanyId')}"
                                                (valueSelected)="onCompanyChange()" [(ngModel)]="entityData.value.CompanyId" required>
                                                <pdi-dropdown-option *ngFor="let company of nonPrintCompanies"
                                                    [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('CompanyId')">
                                                <small class="error" *ngIf="checkError('CompanyId','required')">
                                                    Please select a company
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="SalesTaxIdentifier" class="col-form-label">Sales Tax ID</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="SalesTaxIdentifier" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.SalesTaxIdentifier" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="IsTaxExempt" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.IsTaxExempt" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label for="IsTaxExempt" class="col-form-label">Tax-exempt</label>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="TaxGroup" class="col-form-label">Tax Group</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="TaxGroup" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.TaxGroup" maxlength="20" />
                                            <div class="control-look"></div>
                                        </div>
                                        <div class="error-message" *ngIf="hasError('TaxGroup')">
                                            <small class="error" *ngIf="checkError('TaxGroup','maxlength')">
                                                Tax Group cannot be longer than 20 characters
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Barcode" class="col-form-label">Barcode</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="Barcode" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Barcode" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Position" class="col-form-label">Customer Type</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" *ngIf="!customerTypeBillingOrDeliverLoading"
                                                [isDisabled]=" entityData.value.DeliveryCustomers && entityData.value.DeliveryCustomers.length"
                                                name="Position" (valueSelected)="onCustomerTypeChange()" id="customerTypeBillingOrDeliver"
                                                [ngClass]="{'modelstate-invalid': hasError('Position')}"
                                                [(ngModel)]="entityData.value.Position" required>
                                                <pdi-dropdown-option [disabled]="true" selected key=""></pdi-dropdown-option>
                                                <pdi-dropdown-option
                                                    *ngFor="let type of entityData.value.CustomerTypeBillingOrDeliver"
                                                    [key]="type.Id">
                                                    {{type.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Position')">
                                                <small class="error" *ngIf="checkError('Position','required')">
                                                    Please select a customer type
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group"
                                        *ngIf="entityData.value.Position != 1 && entityData.value.Position != -1">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <a pdiLink pdiLinkLabel="Billing Customer" (click)="viewBillingCustomerModal()"></a>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="billingCustomer"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.DefaultCustomerName"
                                                readonly="readonly" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-12">
                                    <div class="row form-group"
                                        *ngIf="entityData.value.Position == 1 && entityData.value.DeliveryCustomers.length > 0">
                                        <div class="col-lg-2 hidden-md hidden-sm hidden-xs "></div>
                                        <div class="pl-0 pr-0 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                            <div class="dataGridDiv" style="min-height:230px">
                                                <div class="deliveryCustomer">Delivery Customers</div>
                                                <dx-data-grid #gridDeliveryCustomer
                                                    [dataSource]="entityData.value.DeliveryCustomers" noDataText=""
                                                    [allowColumnResizing]="true" [columnAutoWidth]="true">
                                                    <dxi-column alignment='center' caption="Number" dataField="Number"
                                                        dataType="string"></dxi-column>
                                                    <dxi-column cssClass="white-space-normal" alignment='left'
                                                        caption="Name" dataField="FullName"
                                                        dataType="string"></dxi-column>
                                                    <dxo-selection mode="single"> </dxo-selection>
                                                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                                    <dxo-scrolling mode="standard" showScrollbar="onHover">
                                                    </dxo-scrolling>
                                                    <dxo-sorting mode="single"> </dxo-sorting>
                                                    <dxo-paging [pageSize]="10"> </dxo-paging>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 hidden-md hidden-sm hidden-xs "></div>
                                    </div>
                                </div>

                            </div>

                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="ADCollapse = !ADCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(ADCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Address</span>
                                    </div>
                                </div>
                            </div>
                            <div id="ADCollapse" class="childs" [ngClass]="{'showChild': !ADCollapse}">
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label for="Address" class="col-form-label">Address</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">

                                            <input type="text" name="Address"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Address')}"
                                                [(ngModel)]="entityData.value.Address" required maxlength="250"
                                                placeholder="Address" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Address')">
                                                <small class="error" *ngIf="checkError('Address','required')">
                                                    Address is required
                                                </small>
                                                <small class="error" *ngIf="checkError('Address','maxlength')">
                                                    Address cannot be longer than 250 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="PostcodeString" class="col-form-label">Zip/Postal Code</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="PostcodeString"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.PostcodeString" placeholder="Postal Code"
                                                [ngClass]="{'modelstate-invalid': hasError('PostcodeString')}"
                                                required />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('PostcodeString')">
                                                <small class="error" *ngIf="checkError('PostcodeString','required')">
                                                    Post Code is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="City" class="col-form-label">City/Place</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="City"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.City" placeholder="City"
                                                [ngClass]="{'modelstate-invalid': hasError('City')}" required
                                                maxlength="250" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('City')">
                                                <small class="error" *ngIf="checkError('City','required')">
                                                    City is required
                                                </small>
                                                <small class="error" *ngIf="checkError('City','maxlength')">
                                                    City cannot be longer than 250 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="CountryId" class="col-form-label">Country</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown name="Country" [full]="true" placeholder="Unknown" id="countryId"
                                                [(ngModel)]="entityData.value.CountryId">
                                                <pdi-dropdown-option *ngFor="let country of countries" [key]="country.Id">
                                                    {{country.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="District" class="col-form-label">District</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.District" name="=District"
                                                placeholder="District" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="State" class="col-form-label">State</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.State" name="State" placeholder="State" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Latitude" class="col-form-label">Latitude</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Latitude" name="Latitude"
                                                placeholder="Latitude" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Longitude" class="col-form-label">Longitude</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Longitude" name="Longitude"
                                                placeholder="Longitude" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="DirectionsInfo" class="col-form-label">Direction Details</label>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <textarea name="DirectionsInfo" class="form-control" rows="3"
                                                [(ngModel)]="entityData.value.DirectionsInfo"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="CICollapse = !CICollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(CICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Contact
                                            Information</span>
                                    </div>
                                </div>
                            </div>
                            <div id="CICollapse" class="childs" [ngClass]="{'showChild': !CICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label pr-0">
                                            <label for="ContactName" class="col-form-label">Contact Person</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="ContactName" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ContactName" placeholder="Person Name" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ContactPhone" class="col-form-label">Telephone</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="ContactPhone" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ContactPhone"
                                                placeholder="Phone Number" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="AutoEmailOptions" class="col-form-label">Auto Email
                                                Options</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown name="AutoEmailOptions" [full]="true" id="autoEmailOptions"
                                                [(ngModel)]="entityData.value.AutoEmailOptions">
                                                <pdi-dropdown-option *ngFor="let aeo of autoEmailOptionsList" [key]="aeo.Id">
                                                    {{aeo.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ContactMobile" class="col-form-label">Mobile</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="ContactMobile" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ContactMobile"
                                                placeholder="Mobile Number" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ContactEmail" class="col-form-label">Email</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="ContactEmail"
                                                [ngClass]="{'modelstate-invalid': hasError('ContactEmail')}"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ContactEmail" placeholder="Email"
                                                pattern="[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\s*)(?:\s*[,;]\s*[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*(?:\s*)$" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ContactEmail')">
                                                <small class="error" *ngIf="checkError('ContactEmail','pattern')">
                                                    Email is not valid
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="DeliveryAuthRequired" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.DeliveryAuthRequired" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                            <label for="DeliveryAuthRequired" class="col-form-label">Require Delivery
                                                Authorisation</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="SICollapse = !SICollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(SICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Sale
                                            Information</span>
                                    </div>
                                </div>
                            </div>


                            <div id="SICollapse" class="childs" [ngClass]="{'showChild': !SICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label pr-0">
                                            Payment Terms
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" *ngIf="!paymentTermsLoading">
                                            <pdi-dropdown name="PaymentTermsId" [full]="true" id="paymentTermsId"
                                                [(ngModel)]="entityData.value.PaymentTermsId">
                                                <pdi-dropdown-option *ngFor="let paymentTerm of paymentTerms"
                                                    [key]="paymentTerm.Id">
                                                    {{paymentTerm.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Days of Payment
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="PaymentDays" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.PaymentDays" name="focusPaymentDays"
                                                placeholder="Number of Days" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">

                                            <label for="SignatureSettings" class="col-form-label">Signature
                                                Settings</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-tag-box name="SignatureSettings"
                                                [(ngModel)]="entityData.value.SignatureSettings"
                                                [items]="signatureSettingsOptions" valueExpr="id"
                                                [value]="entityData.value.SignatureSettings" displayExpr="name"
                                                [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>
                                        </div>
                                    </div>

                                    <div class="row form-group form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="PoNumberMandatory" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.PoNumberMandatory" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label pl-0"
                                            style="padding-top: 7px;">
                                            Purchase Order Number Mandatory
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Max Discount
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="MaxDiscount" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.MaxDiscount" name="focusMaxDiscount"
                                                placeholder="Maximum Discount" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Cash Discount
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="number" name="CashDiscount"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.CashDiscount" name="focusCashDiscount"
                                                placeholder="Cash Discount"
                                                [ngClass]="{'modelstate-invalid': hasError('CashDiscount')}" />
                                            <div class="control-look">
                                            </div>
                                            <div class="error-message" *ngIf="hasError('CashDiscount')">
                                                <small class="error">
                                                    Cash Discount is not a valid number
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Price Group
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown name="PriceGroupId" [full]="true" placeholder="None"
                                                [(ngModel)]="entityData.value.PriceGroupId"
                                                (valueSelected)="onPriceGroupChange()">
                                                <pdi-dropdown-option *ngFor="let specialPriceGroup of specialPriceGroups"
                                                    [key]="specialPriceGroup.Id">
                                                    {{specialPriceGroup.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-1 hidden-md hidden-sm hidden-xs "></div>
                                        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                            <div class="dataGridDiv" style="min-height:230px">
                                                <div class="price-title">Customer Specific Price</div>
                                                <dx-data-grid #dataGridSpcificPrice class="no-data-min-height-sp"
                                                    (onRowInserted)="onRowInsertedSpecificPrice($event)"
                                                    (onContentReady)="onContentReadySpecificPrice($event)"
                                                    [dataSource]="entityData.value.SpecialPriceProducts" noDataText=""
                                                    (onInitialized)="onInitialized($event)" [allowColumnResizing]="true"
                                                    [columnAutoWidth]="true">
                                                    <dxi-column dataField="ProductId" caption="Product"
                                                       width="40%" [lookup]="{ dataSource: productList, valueExpr: 'Id', displayExpr: 'ProductName'}"></dxi-column>
                                                    <dxi-column width="110" alignment='center' caption="Special Price"
                                                        dataField="Price" dataType="number"></dxi-column>
                                                    <dxi-column width="90" alignment='center' caption="Discount"
                                                        dataField="Discount" dataType="number"></dxi-column>
                                                    <dxi-column width="40" alignment='center' caption=""
                                                        cellTemplate="priceTagCellTemplate">
                                                    </dxi-column>
                                                    <div *dxTemplate="let d of 'priceTagCellTemplate'">
                                                        <div
                                                            *ngIf="d.data.ProductId && getGroupSpecialPrices(d.data.ProductId).length > 0">
                                                            <img class="price-tag-active"
                                                                id="popoverButton{{d.data.ProductId}}"
                                                                (click)="togglePopover(d.data.ProductId)"
                                                                src="assets/Content/Images/Icons/pricegroup-info-disable.png" />
                                                            <dx-popover *ngIf="popoverVisibility[d.data.ProductId]"
                                                                target="#popoverButton{{d.data.ProductId}}"
                                                                [visible]="true" position="top" [width]="270"
                                                                title="Price group information" [showTitle]="true"
                                                                [showCloseButton]="true">
                                                                <div class="popover-content">
                                                                    <table class="table table-bordered"
                                                                        style="font-size:12px;">
                                                                        <thead>
                                                                            <th>Minimum Qty</th>
                                                                            <th>Price</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let row of getGroupSpecialPrices(d.data.ProductId)">
                                                                                <td>{{row.min}}</td>
                                                                                <td>{{row.price}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </dx-popover>
                                                        </div>
                                                        <div
                                                            *ngIf="!d.data.ProductId || (d.data.ProductId && getGroupSpecialPrices(d.data.ProductId).length == 0) ">
                                                            <img class="price-tag-inactive"
                                                                src="assets/Content/Images/Icons/pricegroup-info-disable.png" />
                                                        </div>

                                                    </div>


                                                    <dxo-selection mode="single"> </dxo-selection>
                                                    <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                                    <dxo-scrolling mode="standard" showScrollbar="onHover">
                                                    </dxo-scrolling>
                                                    <dxo-sorting mode="single"> </dxo-sorting>
                                                    <dxo-paging [pageSize]="10"> </dxo-paging>
                                                    <dxo-editing [allowUpdating]="false" [allowAdding]="true"
                                                        [allowDeleting]="true" mode="row" [texts]="{ addRow: '' }">
                                                    </dxo-editing>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 hidden-md hidden-sm hidden-xs "></div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="UPCollapse = !UPCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(UPCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Unload
                                            Points</span>
                                    </div>
                                </div>
                            </div>


                            <div id="UPCollapse" class="childs" [ngClass]="{'showChild': !UPCollapse}">

                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <div class="dataGridDiv" style="min-height:230px">
                                                <div class="datagrid-header-title">
                                                    <span>Unload Points</span>
                                                    <div class="dx-edit-button add-unloadpoint" (click)="addNewUnloadPoint()">
                                                        <i class="pdi-icon-Add"></i>
                                                    </div>
                                                </div>
                                                <dx-data-grid #dataGridUnloadPoint Class="no-data-min-height"
                                                    (onRowRemoving)="unloadPointRowRemoving($event)"
                                                    (onContentReady)="onContentReadyUnloadPoint($event)"
                                                    (onSelectionChanged)="onChangeUnloadPoint($event)"
                                                    [dataSource]="entityData.value.UnloadPoints" noDataText=""
                                                    keyExpr="Id">
                                                    <dxi-column alignment='center'  caption="Unload Point"
                                                        dataField="Id" dataType="number">
                                                    </dxi-column>
                                                    <dxi-column  alignment='left' caption="Tank Number"
                                                        dataField="TankNumber" dataType="string"></dxi-column>
                                                    <dxo-selection mode="single"> </dxo-selection>
                                                    <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                                    <dxo-scrolling [useNative]="false" [scrollByContent]="true"
                                                        [scrollByThumb]="true" showScrollbar="onHover"> >
                                                    </dxo-scrolling>
                                                    <dxo-sorting mode="single"> </dxo-sorting>
                                                    <dxo-paging [pageSize]="10"> </dxo-paging>
                                                    <dxo-editing [allowUpdating]="false" [allowAdding]="false"
                                                        [allowDeleting]="true" mode="row">
                                                    </dxo-editing>
                                                </dx-data-grid>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div *ngIf="isUnloadPointSelected">
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="UnloadPointId" class="col-form-label">Number</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input name="UnloadPointId" type="text" readonly="readonly"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Id" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                <input name="IsIdenticalToCustomer" type="checkbox"
                                                    class="checkbox-inline" [(ngModel)]="isIdenticalToCustomer" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <label for="IsIdenticalToCustomer" class="col-form-label">Name and
                                                    Address are identical with the customer's Name and Address</label>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="UnloadPointName1" class="col-form-label">Name 1.</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input name="UnloadPointName1" type="text"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Name1" placeholder="Name 1." />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="UnloadPointName2" class="col-form-label">Name 2.</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Name2" name="UnloadPointName2"
                                                    placeholder="Name 2." />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Address" class="col-form-label">Address</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Address" name="Address"
                                                    placeholder="Address" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="PostalCode" class="col-form-label">Zip/Postal
                                                    Code</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Postcode" name="PostalCode"
                                                    placeholder="Postal Code" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="City" class="col-form-label">City/Place</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.City" name="City" placeholder="City" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="District" class="col-form-label">District</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.District" name="District"
                                                    placeholder="District" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="!isIdenticalToCustomer">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="State" class="col-form-label">State</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.State" name="State" placeholder="State" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Latitude" class="col-form-label">Latitude</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Latitude" name="Latitude"
                                                    placeholder="Latitude" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Longitude" class="col-form-label">Longitude</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Longitude" name="Longitude"
                                                    placeholder="Longitude" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="MaxDistance" class="col-form-label">Max.
                                                    Distance</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.MaximumDistance" name="MaxDistance"
                                                    placeholder="Maximum Distance" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <a pdiLink pdiLinkLabel="Product" (click)="viewProductModal()"></a>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" name="product"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPointProductName" readonly="readonly" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Barcode" class="col-form-label">Barcode</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.Barcode" name="Barcode"
                                                    placeholder="Barcode" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TankNo" class="col-form-label">Tank No.</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TankNumber" name="TankNo"
                                                    placeholder="Tank Number" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TankSize" class="col-form-label">Tank Size</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TankSize" name="TankSize"
                                                    placeholder="Tank Size" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Filling" class="col-form-label">Filling Limit</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TankFillingLimit" name="Filling"
                                                    placeholder="Filling Limit" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="user.isStrappingChartEnabled">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="ChartProfile" class="col-form-label">Strapping Chart
                                                    ID/Desc</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <dx-select-box name="ChartProfile"
                                                    [(ngModel)]="unloadPoint.UllageChartProfileID" [dataSource]="strappingChartProfileDropdown"
                                                    [displayExpr]="strappingChartDisplayExpr" valueExpr="Id" [searchExpr]="['ProfileId','ProfileDescription']"
                                                    [searchMode]="dxSelectOptions.searchMode"
                                                    [searchTimeout]="dxSelectOptions.searchTimeout"
                                                    [minSearchLength]="dxSelectOptions.minSearchLength"
                                                    [searchEnabled]="true" placeholder="">
                                                </dx-select-box>
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="DipRequired" class="col-form-label">Dipping
                                                    Required</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <pdi-dropdown id="dipRequired" placeholder="Default" name="DippingReqired" [full]="true"
                                                    [(ngModel)]="unloadPoint.DipRequired">
                                                    <pdi-dropdown-option *ngFor="let dippingType of dippingTypes"
                                                        [key]="dippingType.id">
                                                        {{dippingType.name }}
                                                    </pdi-dropdown-option>
                                                </pdi-dropdown>
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TankCounter" class="col-form-label">Tank Counter No.</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TankMeterNumber" name="TankCounter"
                                                    placeholder="Counter Number" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                <input name="ProtectiveCapQuery" type="checkbox" class="checkbox-inline"
                                                    [(ngModel)]="unloadPoint.ProtectiveCapQuery" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                <label for="ProtectiveCapQuery" class="col-form-label">Protective
                                                    Cap</label>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TankInformation" class="col-form-label">Tank
                                                    Information</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <textarea name="TankInformation" rows="3" class="form-control"
                                                    [(ngModel)]="unloadPoint.TankInformation"> </textarea>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TaxGroup2" class="col-form-label">Tax Group</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" maxlength="20"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TaxGroup" name="TaxGroup2"
                                                    placeholder="Tax Group" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="TaxId" class="col-form-label">Tax Id</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" maxlength="20"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="unloadPoint.TaxId" name="TaxId" placeholder="Tax Id" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-8 control-label">
                                                <strong>Last Delivery Location</strong>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="LocationName" class="col-form-label">Name</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    name="LocationName" [(ngModel)]="lastDeliveryLocation.Name"
                                                    readonly="readonly" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="LocationNumber" class="col-form-label">Number</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" class="form-custom-control control-look-input"
                                                    name="LocationNumber" [(ngModel)]="lastDeliveryLocation.Number"
                                                    readonly="readonly" />
                                                <div class="control-look">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="OTCollapse = !OTCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(OTCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Other</span>
                                    </div>
                                </div>
                            </div>


                            <div id="OTCollapse" class="childs" [ngClass]="{'showChild': !OTCollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Language
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-select-box name="Language"
                                                [(ngModel)]="entityData.value.Language" [dataSource]="languages"
                                                displayExpr="Name" valueExpr="Code" [searchExpr]="['Code','Name']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder=""itemTemplate="item">
                                                <div *dxTemplate="let data of 'item'">
                                                    <div>{{data.Name}}</div>
                                                    <div>{{data.Code}}</div>
                                                </div>
                                            </dx-select-box>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Print Company
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true" [(ngModel)]="entityData.value.PrintCompanyId" name="PrintCompanyId">
                                                <pdi-dropdown-option *ngFor="let company of companies" [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Print Info
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.PrintInfo" name="PrintInfo" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Print Options
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-tag-box name="printOptions" [(ngModel)]="entityData.value.PrintOptions"
                                                [items]="printOptions" valueExpr="id"
                                                [value]="entityData.value.PrintOptions" displayExpr="name"
                                                [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>

                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Product Options
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-tag-box name="productOptions"
                                                [(ngModel)]="entityData.value.ProductOptions" [items]="productOptions"
                                                valueExpr="id" [value]="entityData.value.ProductOptions"
                                                displayExpr="name" [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Customer Type</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true" *ngIf="!customerTypeLoading"
                                                [(ngModel)]="entityData.value.TypeId" id="customerTypeId" name="CustomerType2">
                                                <pdi-dropdown-option *ngFor="let customerType of customerTypes"
                                                    [key]="customerType.Id">
                                                    {{customerType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <button type="button" data-toggle="modal" class="pdi-add-button"
                                                (click)="showCustomerTypeModal = true">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Ext. Nr. 1</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="extNr1"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.extNr1" placeholder="extNr1"
                                                [ngClass]="{'modelstate-invalid': hasError('extNr1')}" maxlength="50" />
                                            <div class="control-look"></div>

                                            <div class="error-message" *ngIf="hasError('extNr1')">
                                                <small class="error" *ngIf="checkError('extNr1','maxlength')">
                                                    Ext. Nr. 1 cannot be longer than 50 characters
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Ext. Nr. 2</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="extNr2"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.extNr2" placeholder="extNr2"
                                                [ngClass]="{'modelstate-invalid': hasError('extNr2')}" maxlength="50" />
                                            <div class="control-look"></div>

                                            <div class="error-message" *ngIf="hasError('extNr2')">
                                                <small class="error" *ngIf="checkError('extNr2','maxlength')">
                                                    Ext. Nr. 2 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Ext. Nr. 3</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="extNr3"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.extNr3" placeholder="extNr3"
                                                [ngClass]="{'modelstate-invalid': hasError('extNr3')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('extNr2')">
                                                <small class="error" *ngIf="checkError('extNr2','maxlength')">
                                                    Ext. Nr. 3 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="OnHold" type="checkbox" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.OnHold" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                            <label for="OnHold" class="col-form-label">Customer On Hold (Block
                                                Deliveries)</label>
                                        </div>
                                    </div>

                                    <div class="form-group form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="PhotoMandatory" type="checkbox" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.PhotoMandatory" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                            <label for="PhotoMandatory" class="col-form-label">Require Delivery
                                                Photo</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label class="col-form-label">Additional information about the
                                                customer</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <textarea rows="3" name="AdditionalInfo" class="form-control"
                                                [(ngModel)]="entityData.value.AdditionalInfo"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label class="col-form-label">Popup info</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <textarea rows="3" name="PopupInfo" class="form-control"
                                                [(ngModel)]="entityData.value.PopupInfo"></textarea>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label class="col-form-label">End of Delivery Popup info</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <textarea rows="3" name="EodPopupInfo" class="form-control" maxlength="1000"
                                                [(ngModel)]="entityData.value.EodPopupInfo"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameNotification" [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<dx-popup title="Warning" [(visible)]="deleteWarning" hideOnOutsideClick="true" [dragEnabled]="false"
    shadingColor="shadingColor" maxHeight="200" maxWidth="400">
    <div>
        <div>
            <div> Cannot delete Unload Point, Atleast one Unload Point is required per customer.</div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="deleteWarning = false" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">OK</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>


<facnm-billing-customer-popup *ngIf="showBillingCustomerModal" (popupCancel)="billingCustomerSelectionCancel()"
    (popupOk)="billingCustomerSelectionOk($event)">
</facnm-billing-customer-popup>

<facnm-product-popup *ngIf="showProductModal" (popupCancel)="productSelectionCancel()"
    (popupOk)="productSelectionOk($event)">
</facnm-product-popup>

<dx-popup title="New Customer Type" [(visible)]="showCustomerTypeModal" [dragEnabled]="false" [width]="450"
    height="auto" (onHidden)="closeCustomerType()">
    <div id="entity-detail" class="modal-control">
        <div class="p-4" id="entity-form" style="font-size:14px">

            <pdi-page-notification *ngIf="modalError" type="alert" text="<strong> Error! </strong> {{modalError}}">
            </pdi-page-notification>
            <div class="row form-group">
                <div class="col-md-4 control-label">
                    <label class="col-form-label"> Number </label>
                </div>
                <div class="col-md-8">
                    <input type="number" class="form-custom-control control-look-input" [(ngModel)]="customerType.Id"
                        focus-on="focusctNumber" placeholder="Number" />
                    <div class="control-look"></div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4 control-label">
                    <label class="col-form-label">Name</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-custom-control control-look-input" [(ngModel)]="customerType.Name"
                        focus-on="focusctName" placeholder="Name" />
                    <div class="control-look"></div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-md-12 d-flex" style="justify-content: space-around;">
                    <button [disabled]="saveTypeProgress" type="button" class="edit-button-green"
                        (click)=" addCustomerType()">
                        <img src="assets/Content/Images/Icons/save.png"
                            class="edit-button-image edit-button-image-greeedit-button-green mr-1" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs  h-100">Save </span>
                    </button>
                    <button type="button" class="edit-button-green" (click)="closeCustomerType()">
                        <img src="assets/Content/Images/Icons/cancel.png"
                            class="edit-button-image mr-1" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs h-100">Close</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</dx-popup>



<facnm-delete-dialog [entityName]="entityNameNotification"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>