export var dxDataGridHeightAdjustment = 25;

export function IsUndefinedOrNull(val) {
    return val === undefined || val === null;
}

export function isNumberOnly(event:any) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
}

export function checkPattern(type: any, val: any) {
    if (val && type) {
        let patternObj = {
            'int': /^[-+]?[0-9]*$/,
            'float': /^[-+]?[0-9]*\.?[0-9]+$/
        }
        return patternObj[type]?.test(val);
    }
    return true;
}

export function areArraysEqual(arr1: any[], arr2: any[]): boolean {
    return  arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
}

export function getConfigLables(configId: any) {
    let data = {}
    switch (configId) {
        case 282:
            data = {
                addModalTitle: "Add New Register",
                addButtonLabel: "Add Register",
                listLabel: "Register "
            }
            break;
        case 408:
            data = {
                addModalTitle: "Emissions Factors",
                addButtonLabel: "Add Factor Group",
                listLabel: "Group "
            }
            break;

        default:
            data = {
                addModalTitle: "Add",
                addButtonLabel: "Add ",
                listLabel: "List"
            }
            break;
    }
    return data;
}

export function displayZeroValueText(list, elementId, key, text) {
    if(list)
    {
        var displayText = list.filter(a => a[key] == 0).map(a => a[text]);
        if (displayText) {
            setTimeout(() => {
                if (document.getElementById(elementId))
                    document.getElementById(elementId).firstElementChild.querySelector('input').value = displayText;
            });
        }
    }
}
export function refreshElement(component,variable)
{
    component[variable] = true;
    setTimeout(()=>{
        component[variable] = false;  
    })
}

export function makePDIDropDownOptions(arr, idKey, lableKey) {
    if (!arr) return [];
    return arr?.map((obj: any) => {
        return {
            key: obj[idKey],
            label: obj[lableKey],
        };
    });
}

export class dxSelectOptions{
    searchMode = 'contains';
    searchTimeout = 200;
    minSearchLength = 0;
}