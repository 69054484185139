import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class ConfigurationGroup {
  Id: Number;
  Name:String;
  AssignedVehicles: any;
  UnassignedVehicles: any;
}

@Injectable()
export abstract class ConfigurationGroupInterface implements EntityInterface<ConfigurationGroup> {
  create: (data: Entity<ConfigurationGroup>) => Observable<any>;
  save: (data: Entity<ConfigurationGroup>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<ConfigurationGroup[]>;
  getEntity: (key: any) => Observable<ConfigurationGroup>;
  ListRetrieved: Observable<ConfigurationGroup[]>;
  notifyRetrieved: (data: ConfigurationGroup[]) => void;
}