<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col-md-6">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReadyGroup($event)" #dataGrid
                    [dataSource]="gridData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onRowClick)="onRowClick($event)" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='left' caption="Bank Name" dataField="BankName"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Bank Number" dataField="BankNumber"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='left' caption="Account Number" dataField="AccountNumber"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Owner" dataField="Owner"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-6">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            
                           <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                          <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="BankName" class="col-form-label">Bank Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.BankName"
                                        [ngClass]="{'modelstate-invalid': hasError('BankName')}" type="text"
                                        class="form-custom-control control-look-input" maxlength="50" required
                                        name="BankName">
                                    <div class="control-look"></div>
                                    <div class="error-message" *ngIf="hasError('BankName')">
                                        <small class="error" *ngIf="checkError('BankName','required')">
                                            Bank Name is required
                                        </small>
                                    </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="BankNumber" class="col-form-label">Bank Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.BankNumber"
                                        [ngClass]="{'modelstate-invalid': hasError('BankNumber')}" type="text" (keypress)="isNumberOnly($event)"
                                            class="form-custom-control control-look-input" required maxlength="25" 
                                            name="BankNumber">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('BankNumber')">
                                            <small class="error" *ngIf="checkError('BankNumber','required')">
                                                Bank Number is required
                                            </small>
                                            <small class="error" *ngIf="checkError('BankNumber','maxlength')">
                                                Bank Number cannot be longer than 25 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                    <label for="IBAN" class="col-form-label">IBAN</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.IBAN" type="text"
                                        class="form-custom-control control-look-input" maxlength="50"
                                        name="IBAN">
                                    <div class="control-look"></div>
                                    <div class="error-message" *ngIf="hasError('IBAN')">
                                        <small class="error" *ngIf="checkError('IBAN','maxlength')">
                                            IBAN cannot be longer than 50 characters
                                        </small>
                                    </div>
                                    </div>
                                </div>
                             </div>
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="AccountNumber" class="col-form-label">Account Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.AccountNumber"
                                        [ngClass]="{'modelstate-invalid': hasError('AccountNumber')}" type="text" (keypress)="isNumberOnly($event)"
                                            class="form-custom-control control-look-input" required maxlength="25" 
                                            name="AccountNumber">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('AccountNumber')">
                                            <small class="error" *ngIf="checkError('AccountNumber','required')">
                                                Account Number is required
                                            </small>
                                            <small class="error" *ngIf="checkError('AccountNumber','maxlength')">
                                                Account Number cannot be longer than 25 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Owner" class="col-form-label">Owner</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Owner" 
                                        [ngClass]="{'modelstate-invalid': hasError('Owner')}" type="text"
                                            class="form-custom-control control-look-input" maxlength="50" required 
                                            name="Owner">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Owner')">
                                            <small class="error" *ngIf="checkError('Owner','required')">
                                                Owner is required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="BIC" class="col-form-label">BIC</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.BIC" type="text"
                                            class="form-custom-control control-look-input" maxlength="50"
                                            name="BIC">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('BIC')">
                                            <small class="error" *ngIf="checkError('BIC','maxlength')">
                                                BIC cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style="clear:both;"></div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName"
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>