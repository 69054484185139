import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DataArea, CustomerDataAreaInterface } from './customer-data-area.model';
import { CustomerDataAreaService } from './customer-data-area.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '@app/_shared/shared.service';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-customer-data-area',
  templateUrl: './customer-data-area.component.html',
  styleUrls: ['./customer-data-area.component.scss'],
  providers: [
    {
      provide: CustomerDataAreaInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new CustomerDataAreaService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class CustomerDataAreaComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  entityName = 'Data Area';
  gridDataSource: any;
  entityData: Entity<DataArea>;
  collapseColor = 'green';
  isAddForm = 0;
  newEntityData: DataArea;
  entityDataDeleteConfirm = false;
  entityDataDeleteConfirmMsg = "Data area will be removed for both products and customers. Are you sure you want to delete?";
  CSCollapse = false;
  countries: any;
  customerTypes: any;

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private dataGridCustomerSettings: DxDataGridComponent;
  @ViewChild('dataGridCustomerSettings', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridCustomerSettings = content;
    }
  }

  constructor(
    private dataArea$: CustomerDataAreaInterface,
    private app$: AppService,
    private errors$: ErrorsService,
    private actions$: ActionsService,
    private shared$: SharedService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.actions();
    forkJoin({
      dataAreaList: this.dataArea$.getList(0),
      newEntity: this.dataArea$.getNewEntity(),
      countries: this.shared$.getCountryList(),
      customerTypes: this.shared$.getCustomerTypeList(),
    }).subscribe(
      res => {
        this.dataArea$.notifyRetrieved(res['dataAreaList']);
        this.newEntityData = res['newEntity'];
        this.countries = res['countries'];
        this.customerTypes = res['customerTypes'];
        this.app$.notifyScreenReady();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.dataArea$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridDataSource = new ArrayStore({
          data: data,
          key: ['Id']
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.dataArea$.getList(0).subscribe(listWithCreated => {
      this.dataArea$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.dataArea$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  deleteConfirm() {
    let param = { Id: this.entityData.value.Id };
    this.dataArea$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Data Areas Found";
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.dataArea$.getEntity(key).subscribe((res: any) => {
      this.entityDataDeleteConfirm = true;
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
    this.isAddForm = 1;
  }

  onContentReadyCustomerSettings(e: any) {
    this.dataGridCustomerSettings.noDataText = this.dataGridCustomerSettings.instance.totalCount() ? '' : "No Data";
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
