<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col-md-3 pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReadyGroup($event)" [wordWrapEnabled]="true" #dataGrid [dataSource]="gridData" noDataText=""
                    (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="150px" caption="Group ID" dataField="Id"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='left' caption="Group Name" dataField="Name" dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Group ID</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Id" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="groupId">
                                        <div class="control-look"></div>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Group Name</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Name"
                                            [ngClass]="{'modelstate-invalid': hasError('Name')}" type="text"
                                            class="form-custom-control control-look-input" name="Name" required
                                            maxlength="100">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Name')">
                                            <small class="error" *ngIf="checkError('Name','required')">
                                                Configuration Group Name is required
                                            </small>
                                            <small class="error" *ngIf="checkError('Name','maxlength')">
                                                Configuration Group Name cannot be longer than 100 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="VCollapse = !VCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(VCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Vehicles
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="VCollapse" class="childs" [ngClass]="{'showChild': !VCollapse}">
                                <div class="col-lg-6">
                                    <div style="height: 470px;" [ngClass]="{
                                        editGridDiv: !entityData.value.IsNew, 
                                        newGridDiv: entityData.value.IsNew
                                    }">
                                        <dx-data-grid  (onContentReady)="onContentReadyAssigned($event)"
                                            #dataGridAssignedVehicles noDataText=""
                                            [dataSource]="entityData.value.AssignedVehicles"
                                            [allowColumnResizing]="false" [columnAutoWidth]="false"
                                            [(selectedRowKeys)]="assignedVehicleSelected"
                                            [columns]="vehicleColumnConfig">
                                            <dxo-selection [selectAllMode]="allMode"
                                                [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
                                            <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                        </dx-data-grid>
                                    </div>
                                </div>
                                <div class="col-lg-1 pl-0">
                                    <div class="left-arrow text-center">
                                        <a *ngIf="!unassignedVehicleSelected.length">
                                            <img class="arrow-move" src="assets/Content/Images/Icons/arrow_left.svg">
                                        </a>
                                        <div *ngIf="unassignedVehicleSelected.length">
                                            <a (click)="assignVehicles()">
                                                <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_left_active.svg">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="right-arrow text-center">
                                        <a *ngIf="!assignedVehicleSelected.length">
                                            <img class="arrow-move" src="assets/Content/Images/Icons/arrow_right.svg">
                                        </a>
                                        <a (click)="unassignVehicles()" *ngIf="assignedVehicleSelected.length">
                                            <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_right_active.svg">
                                        </a>
                                    </div>

                                </div>
                                <div class="col-lg-5 pl-0">
                                    <div class="gridDiv" style="height: 470px;">
                                        <dx-data-grid #dataGridUnassignedVehicles
                                            (onContentReady)="onContentReadyUnAssigned($event)" noDataText=""
                                            [dataSource]="entityData.value.UnassignedVehicles"
                                            [allowColumnResizing]="false" [columnAutoWidth]="false"
                                            [(selectedRowKeys)]="unassignedVehicleSelected"
                                            [columns]="vehicleColumnConfig">
                                            <dxo-selection [selectAllMode]="allMode"
                                                [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
                                            <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                        </dx-data-grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameNotification"
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityNameNotification"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityNameNotification"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>