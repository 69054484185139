import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class vehicle {
  Id: number;
  BuildType:number;
  Color: any;
  ColorHash: any;
  DeviceId: number;
  DeviceName: string;
  IsTruck: string;
  LicensePlate: string;
  VehicleNumber: string;
  VehicleTypeId: number;
  VehicleTypeImageUrl: string;
  IsTrailerGpsBox: boolean
  deviceDetailsVM: any;
  CompartmentSet: any;
  TypeId: any;
  CarrierId: any;
  State: any;
}

export class deviceDetailsVM{
    Id: number;
    Name: string;
    Type: string;
    Config: string;
    IMEI: string;
    GPSboxIMEI: string;
    PhoneNumber: string;
    BoxConfigId: string;
    UseIgnitionForBreakCalc: boolean;
    CompanyId: number;
    State:number;
    DataAreaId:number;
    TimezoneName:string;
    RangeVMs: any[];
    CompanyName: string;
}

@Injectable()
export abstract class vehicleInterface implements EntityInterface<vehicle> {
  create: (data: Entity<vehicle>) => Observable<any>;
  save: (data: Entity<vehicle>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<vehicle[]>;
  getEntity: (key: any) => Observable<vehicle>;
  ListRetrieved: Observable<vehicle[]>;
  notifyRetrieved: (data: vehicle[]) => void;
  getVehicleBoxConfig: () => Observable<any[]>;
  getVehicleBuildTypes: () => Observable<any[]>;
  getVehicleClasses: () => Observable<any[]>;
  getNewVehicle: () => Observable<any[]>;
  getCompanyList: () => Observable<any[]>;
  getNonPrintCompanyList: () => Observable<any[]>;
  getCompartmentSetList: () => Observable<any[]>;
  getLoadPointList: ()=> Observable<any[]>;
  getDeviceTypeList: ()=> Observable<any[]>;
  getDeviceConfigList: ()=> Observable<any[]>;
  getDeviceStateList: ()=> Observable<any[]>;
  getDataAreaList: ()=> Observable<any[]>;
  getTimeZones: ()=> Observable<any[]>;
  getTimeZoneValues: ()=> Observable<any[]>;
  getTrailerStates: ()=> Observable<any[]>;
  getVehicleTypes: ()=> Observable<any[]>;
  getSpecialActions: ()=> Observable<any[]>;
  getVehicleDetails: (param) => Observable<any[]>;
  getFilteredVehicleList:(param) => Observable<vehicle[]>;
  saveSpecialAction:(vehiclelist, specialaction) => Observable<any[]>;
  deleteVehicleType:(param) => Observable<any>;
  saveVehicleTypes:(vehicleTypes)=>Observable<any[]>;
  isCompartmentSetDeletable:(param) => Observable<any[]>;
  deleteCompartmentSet:(param) => Observable<any>;
  deleteCompartment:(Id, setId) => Observable<any>;
  saveCompartmentSets:(param) => Observable<any[]>;
  saveMultipleVehicles:(vehicle, vehicleList) => Observable<any[]>; 
}