import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import ArrayStore from 'devextreme/data/array_store';
import { ProductService } from '@products/products.service';
import { Product } from '../products.model';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'facnm-products-grid',
    templateUrl: './products-grid.component.html',
    styleUrls: ['./products-grid.component.scss']
})
export class ProductsGridComponent implements OnInit {

    productStore: ArrayStore;
    productList: Product[];
    currentProductId: number;

    @ViewChild('dataGridProducts', { static: true }) private dataGridProducts: DxDataGridComponent;

    @Input() selectionMode: string;

    @Input() ProductId: number;
    @Output() ProductIdChange = new EventEmitter<number>();

    @Input() ProductsId: number[] = [];
    @Output() ProductsIdChange = new EventEmitter<number[]>();

    selectionChangedRaised: boolean;

    constructor(private product$: ProductService) {

        this.product$.ListRetrieved.subscribe(data => {

            this.productList = data;
            this.productStore = new ArrayStore({
                data: this.productList,
                key: 'Id'
            });
        });
    }


    ngOnInit() {

    }


    onProductsInitialized($event) {

        if (!this.selectionMode)
            this.selectionMode = 'single';
    }


    onProductsContentReady($event) {

        if (!this.currentProductId) {

            this.currentProductId = this.ProductId;
            setTimeout(() => {
                if (this.currentProductId)
                    $event.component.selectRows(this.currentProductId, false);
            });
            if (this.currentProductId)
                $event.component.navigateToRow([this.currentProductId]);
        }
    }


    onProductsRowClick($event) {

        if (this.selectionMode == 'single') {

            this.ProductId = $event.key;
            this.ProductIdChange.emit(this.ProductId);
        }

        else if (!this.selectionChangedRaised) {

            var dataGrid = $event.component;
            var keys = dataGrid.getSelectedRowKeys();
            dataGrid.deselectRows(keys);
        }

        this.selectionChangedRaised = false;
    }


    onProductsSelectionChanged($event) {

        if (this.selectionMode == 'multiple') {

            this.ProductsIdChange.emit(this.ProductsId);
        }

        this.selectionChangedRaised = true;
    }


    refreshGrid() {

        this.currentProductId = undefined;

        this.productStore = new ArrayStore({
            data: this.productList,
            key: 'Id'
        });
    }

    clearSelection(): any {

        this.dataGridProducts.instance.clearSelection();
    }
}
