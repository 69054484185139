<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">

    <div id="product-transformation"
         class="row h-100">

        <div class="col">

            <div id="entity-list"
                 class="h-100">

                <dx-data-grid #dataGrid
                              [dataSource]="productTransformationStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                             <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column dataField="ProductFromName"></dxi-column>
                    <dxi-column dataField="ProductToName"></dxi-column>
                    <dxi-column dataField="Type">
                        <dxo-lookup [dataSource]="productTransformationTypeList"
                                    valueExpr="id"
                                    displayExpr="name">
                        </dxo-lookup>
                    </dxi-column>


                    <dxo-selection mode="single"> </dxo-selection>

                    <dxo-filter-row [visible]="true"> </dxo-filter-row>

                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                    <dxo-sorting mode="single"> </dxo-sorting>

                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>

                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="product-transformation.dataGrid"></dxo-state-storing>

                </dx-data-grid>

            </div>

        </div>


        <div id="entity-detail"
             class="col pr-0">

            <div *ngIf="productTransformation && productTransformation.key">


                <form id="entity-form"
                      #productTransformationForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">

                        <div id="entity-errors"
                             class="row">
                            <div class="col">

                                <facnm-errors></facnm-errors>

                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-md-12">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Type"
                                                   class="col-form-label">Type</label>
                                        </div>
                                        <div class="col-8">

                                            <pdi-dropdown #Type="ngModel" [full]="true" [(ngModel)]="productTransformation.value.Type"
                                                [ngClass]="{'modelstate-invalid': !Type.dirty && isInvalid('Type')}" name="Type">
                                                <pdi-dropdown-option *ngFor="let t of productTransformationTypeList" [key]="t.id">
                                                    {{t.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>


                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!Type.dirty && isInvalid('Type')">
                                                <small class="error">
                                                    {{getError('Type')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Surcharge"
                                                   class="col-form-label">Surcharge</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="productTransformation.value.Surcharge"
                                                   [ngClass]="{'modelstate-invalid': !Surcharge.dirty && isInvalid('Surcharge')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="Surcharge"
                                                   #Surcharge="ngModel">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!Surcharge.dirty && isInvalid('Surcharge')">
                                                <small class="error">
                                                    {{getError('Surcharge')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Flag"
                                                   class="col-form-label">Flag</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="productTransformation.value.Flag"
                                                   (keypress)="allowOnlyNumbers(event)"
                                                   #Flag="ngModel"
                                                   [ngClass]="{'modelstate-invalid': !Flag.dirty && isInvalid('Flag')}"
                                                   type="number"
                                                   class="form-custom-control control-look-input"
                                                   name="Flag"
                                                   placeholder="Flag">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!Flag.dirty && isInvalid('Flag')">
                                                <small class="error">
                                                    {{getError('Flag')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-md-12">


                                <div class="container">


                                    <div class="row form-group">
                                        <div class="col-12">
                                            <label class="col-form-label">From
                                                Product <i *ngIf="!productTransformation.value.ProductFromId && isInvalid('ProductFromId') || (ServerErrors && productTransformation.value.ProductFromId == productTransformation.value.ProductToId)"
                                                   class="fa fa-exclamation-circle modelstate-invalid"></i></label>
                                        </div>
                                        <div class="col-12">


                                            <facnm-products-grid #productsFrom
                                                                 [(ProductId)]="productTransformation.value.ProductFromId"></facnm-products-grid>


                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12">


                                <div class="container">


                                    <div class="row form-group">
                                        <div class="col-12">
                                            <label class="col-form-label">To Product <i *ngIf="!productTransformation.value.ProductToId && isInvalid('ProductToId') || (ServerErrors && productTransformation.value.ProductFromId == productTransformation.value.ProductToId)"
                                                   class="fa fa-exclamation-circle modelstate-invalid"></i>
                                            </label>
                                        </div>
                                        <div class="col-12">


                                            <facnm-products-grid #productsTo
                                                                 [(ProductId)]="productTransformation.value.ProductToId"></facnm-products-grid>


                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="productTransformation"></facnm-action-buttons>

        </div>

    </div>

</div>



<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>