<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col-md-3 pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReadyGroup($event)" #dataGrid [dataSource]="gridData"
                    noDataText="" (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="150px" caption="Group No" dataField="Id"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='left' caption="Group Name" dataField="Name"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="col-md-12">

                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                    <label for="Title" class="col-form-label">Group Number</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Id" type="text" class="form-custom-control control-look-input"
                                            readonly="readonly" name="groupId">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Group Name </label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Name"
                                            [ngClass]="{'modelstate-invalid': hasError('Name')}" type="text"
                                            class="form-custom-control control-look-input" name="Name" required
                                            maxlength="50">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Name')">
                                            <small class="error" *ngIf="checkError('Name','required')">
                                                Product Group Name is required
                                            </small>
                                            <small class="error" *ngIf="checkError('Name','maxlength')">
                                                Product Group Name cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            
                            <div class="collapsible-header-container top-buffer" (click)="PCollapse = !PCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(PCollapse)"></div>
                                    <div class="text-center">
                                        <span class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Products</span>
                                    </div>
                                </div>
                            </div>
                            <div id="PCollapse" class="childs" [ngClass]="{'showChild': !PCollapse}">
                                <div class="error-message mb-4"
                                    *ngIf="this.entityForm?.submitted && entityData.value.AssignedProducts.length == 0 ">
                                    <small class="error">
                                        Please add atleast one product in Products Grid
                                    </small>
                                </div>
                                <div class="error-message mb-4" *ngIf="this.productGridErrorMsg.length >1">
                                    <small class="error">
                                        {{productGridErrorMsg}}
                                    </small>
                                </div>
                            <div class="col-lg-12">
                                
                            <div class="row form-group">
                                <div class="col-lg-1 hidden-md hidden-sm hidden-xs "></div>
                                
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="dataGridDiv" style="min-height:300px">
                                        <div class="product-title">Products</div>
                                        <dx-data-grid #dataGridProduct [dataSource]="entityData.value.AssignedProducts" class="no-data-min-height-sp" (onRowInserted)="onRowInsertedProduct($event)"
                                            (onContentReady)="onContentReadyProduct($event)" noDataText="" (onInitialized)="onInitialized($event)"
                                            [allowColumnResizing]="true" [columnAutoWidth]="true" (onRowInserting) ="onProductGridRowInserting($event)" (onRowUpdating)="onProductGridRowUpdating($event)">                                            
                                            <dxi-column alignment='center' caption="Product Name on Register" dataField="ProductNameOnDevice"
                                                dataType="string" width="25%"></dxi-column>
                                            <dxi-column  alignment='center' caption="Product ID on Register" dataField="ProductNameNumber"
                                                dataType="string" width="25%"></dxi-column>
                                            <dxi-column alignment='center' caption="Product Unit" dataField="UnitOfProduct"
                                                dataType="string" width="15%"></dxi-column>
                                            <dxi-column  alignment='center' caption="Product Number - Name" dataField="ProductId"
                                                dataType="number"  [lookup]="{ dataSource: productList, valueExpr: 'Id', displayExpr: 'ProductName'}" width="25%" ></dxi-column>
                                            <dxo-selection mode="single"> </dxo-selection>
                                            <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover">
                                            </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                            <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="row"
                                                [texts]="{ addRow: '' }">
                                            </dxo-editing>
                                        </dx-data-grid>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-1 hidden-md hidden-sm hidden-xs "></div>
                            </div>
                            </div>
                            </div>
                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="VCollapse = !VCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(VCollapse)"></div>
                                    <div class="text-center">
                                        <span class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Vehicles</span>
                                    </div>
                                </div>
                            </div>
                            <div id="VCollapse" class="childs" [ngClass]="{'showChild': !VCollapse}">
                                <div class="error-message mb-4" *ngIf="this.entityForm?.submitted && entityData.value.AssignedVehicles.length == 0 ">
                                    <small class="error">
                                    Please add atleast one Vehicle in Vehicles Grid
                                    </small>
                                </div>
                                <div class="col-lg-12"> 
                                    <facnm-vehicle-assignment [AssignedVehicles]="entityData.value.AssignedVehicles" 
                                     [UnAssignedVehicles]="entityData.value.UnAssignedVehicles" [isAddForm] ="isAddForm"  >
                                    </facnm-vehicle-assignment>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" 
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName" ></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>