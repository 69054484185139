import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceGroup,DeviceGroupInterface } from './device-group.module';
import { DeviceGroupService } from './device-group.service';
import { IsUndefinedOrNull, dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-device-group',
  templateUrl: './device-group.component.html',
  styleUrls: ['./device-group.component.scss'],
providers: [
  {
    provide: DeviceGroupInterface,
    useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
      return new DeviceGroupService(httpClient, errors$);
    },
    deps: [HttpClient, ErrorsService]
  }
  ]
})
export class DeviceGroupComponent implements OnInit {
  constructor(
    private deviceGroup$: DeviceGroupInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  private dataGridProduct: DxDataGridComponent;
  @ViewChild('dataGridProduct', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridProduct = content;
    }
  }
  @ViewChild('entityForm') entityForm: NgForm;

  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  isAddForm = 0;
  entityName = 'Device Product Group';
  entityData: Entity<DeviceGroup>;
  productList: any;
  newEntityData: DeviceGroup;
  newEntity: any;
  PCollapse = false;
  VCollapse = false;  
  collapseColor = 'green';
  productGridErrorMsg = '';

  ngOnInit(): void {
    this.actions();
    this.getList();
    this.app$.setScreenTitle(this.entityName);
    forkJoin({
      productList: this.deviceGroup$.getProductList(0),
      newEntityData: this.deviceGroup$.getNewEntity()
    }).subscribe(
      res => {
        this.newEntityData = res['newEntityData'];
        this.productList = res['productList'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.deviceGroup$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ["Id"]
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.deviceGroup$.getList().subscribe(listWithCreated => {
      this.deviceGroup$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();  
    this.entityForm.onSubmit(undefined);
    if ( (this.entityForm.form.invalid)  || (this.entityData.value.AssignedProducts.length < 1) || (this.entityData.value.AssignedVehicles.length < 1) ) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.deviceGroup$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = {
      Id: this.entityData.value.Id,
    };
    this.deviceGroup$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.value.Id = 0;
    this.entityData.value.Name = "";
    this.entityData.value.AssignedProducts = [];
    this.entityData.value.AssignedVehicles = [];
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
  }

  onContentReadyGroup(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Product Groups Found";
  }

  private selectEntity(key: any) {
      this.deviceGroup$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.refreshProductGrid();
      this.isAddForm=0;
      this.collapseColor = 'green';
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));    
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();  
    if (IsUndefinedOrNull(this.entityData.value.AssignedProducts)) {
      this.entityData.value.AssignedProducts = [];
    }
    if (IsUndefinedOrNull(this.entityData.value.AssignedVehicles)) {
      this.entityData.value.AssignedVehicles = [];      
    }
    if (IsUndefinedOrNull(this.entityData.value.UnAssignedVehicles)) {
      this.entityData.value.UnAssignedVehicles = [];
    }
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }
  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }

     if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
        this.deSelectEntity();
      }
      else {
        this.selectEntity($clikedRow.key);
      } 
  }

    collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }
  getProductList(productID) {
    this.deviceGroup$.getProductList(productID).subscribe((data) => {
      this.productList = data;
      this.refreshProductGrid();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshProductGrid() {
    if (this.dataGridProduct) {
      this.dataGridProduct.instance.refresh();
      this.productGridErrorMsg = ""
    }
  }

 onRowInsertedProduct(event: any) {
    event.data.Changed = true;
  }

  onContentReadyProduct(e: any) {
    this.dataGridProduct.noDataText = this.dataGridProduct.instance.totalCount() ? '' : "No Products Found";
  }
onProductGridRowInserting(e) {
  this.productGridErrorMsg = "";
  if (!IsUndefinedOrNull(e.data)) {
    if (IsUndefinedOrNull(e.data.ProductNameOnDevice) || IsUndefinedOrNull(e.data.ProductNameOnDevice) ||
        IsUndefinedOrNull(e.data.UnitOfProduct) || IsUndefinedOrNull(e.data.ProductId)) {
        this.productGridErrorMsg = "Please enter all values for new record.";
        e.cancel = true;              
        return;
    }
    else{
      this.productGridErrorMsg = "";
    }
  }
  else {
    this.productGridErrorMsg = "Please enter all values for new record.";
    e.cancel = true;
    return;
  }
}

checkProductGridProperties(obj) {
  for (var key in obj) {
      if (obj[key] == null || obj[key] == "")
          return true;
  }
  return false;
}
onProductGridRowUpdating(e) {
  this.productGridErrorMsg = "";
  if (!IsUndefinedOrNull(e)) { 
    var checkList = this.checkProductGridProperties(e.newData);
    if (checkList) {
        this.productGridErrorMsg  = "Please enter all values to update record.";
        e.cancel = true;                    
        return;
    }
    else {
      this.productGridErrorMsg = "";
    }
  }
  else {
    this.productGridErrorMsg = "Please enter all values to update record.";
    e.cancel = true;
    return;
  }
}

formHeight: string;
@ViewChild('screen', { static: true }) screenElement: ElementRef;
private resizeScreenContent() {
  let h = this.screenElement.nativeElement.clientHeight;
  this.formHeight = h - 80 + 'px';
  this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
}

ngOnDestroy(): void {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

}

 
