import { Component, OnInit, ViewChild } from '@angular/core';
import { SafetyCheckInterface } from './safety-check.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { SafetyCheckService } from './safety-check.service';
import { AppService } from '@app/app.service';
import { CompanyService } from '@settings/company/company.service';
import { forkJoin } from 'rxjs';
import { SharedService } from '@app/_shared/shared.service';
import { ProductService } from '@products/product/product.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { cloneDeep } from 'lodash';
import ArrayStore from 'devextreme/data/array_store';
import { IsUndefinedOrNull, dxSelectOptions } from '@app/common_helper';
import { ActionsService } from '@app/_shared/actions/actions.service';

@Component({
  selector: 'facnm-safety-check',
  templateUrl: './safety-check.component.html',
  styleUrls: ['./safety-check.component.scss'],
  providers: [
    {
      provide: SafetyCheckInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new SafetyCheckService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class SafetyCheckComponent implements OnInit {
  entityName = "Safety Checks";
  mandatoryGlobalImg = "Content/Images/Icons/mandatory-global-24-black.png";
  notvisibleImg = "Content/Images/Icons/notvisible-24-black.png";
  optionalImg = "Content/Images/Icons/optional-24-yellow.png";
  mandatoryImg = "Content/Images/Icons/mandatory-24-red.png";
  inActiveOpacity = 0.4;
  safetyCheckCompanies: any;
  safetyCheckCompanyId: any;
  safetyCheckGroupId: any;
  safetyCheckTextGroups: any;
  safetyMandatoryState: any;
  safetyGlobalMandatoryState: any;
  customerTypes: any;
  customerTypeId: any;
  vehicleTypes: any;
  vehicleTypeId: any;
  dataAreas: any;
  dataAreaId: any;
  devices: any;
  vehicleId: any;
  productTypes: any;
  productTypeId: any;
  selectedSafetyCheckOthers: any;
  resetsafetyCheck: any;
  selectedSafetyCheckVehicleInspection: any;
  isSafetyCheckVehicleInspectionFormVisible: boolean;
  isSafetyCheckOthersFormVisible: boolean;
  isSafetyCheckCustomerFormVisible: boolean;
  isSafetyCheckLoginLogoutFormVisible: boolean;
  isSafetyCheckProductFormVisible: boolean;
  isAddNewTextVisible: boolean;
  isDeleteVisible: boolean;
  newDataOthers: any;
  newDataVehicle: any;
  newDataCustomer: any;
  newDataLogin: any;
  newDataLogout: any;
  newDataProduct: any;
  formTop: string;
  selectedSafetyCheckCustomer: any;
  selectedSafetyCheckProduct: any;
  selectedSafetyCheckLoginLogout: any;
  intialLoad = {};
  safetyChecksDevice: ArrayStore;
  safetyChecksCustomer: ArrayStore;
  safetyChecksLoginLogout:  ArrayStore;
  safetyChecksProduct: ArrayStore;
  safetyChecksVehicle: ArrayStore;
  safetyChecksForOthers: ArrayStore;
  isFormVisible = false;
  selectedKeys = {};
  requiredErr = {};
  maxLengthErr = {};
  formSubmitted: boolean;
  showDeleteConfirm: boolean;
  animation: any;
  shadingColor: any;
  collapseColor = "green";
  saveDisabled: boolean;
  devicesColumn: any;
  dxSelectOptions = new dxSelectOptions();

  private gridSafetyCheckCustomer: DxDataGridComponent;
  @ViewChild('gridSafetyCheckCustomer', { static: false }) set grid1(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckCustomer = content;
    }
  }
  private gridSafetyCheckLoginLogout: DxDataGridComponent;
  @ViewChild('gridSafetyCheckLoginLogout', { static: false }) set grid11(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckLoginLogout = content;
    }
  }
  private gridSafetyCheckProduct: DxDataGridComponent;
  @ViewChild('gridSafetyCheckProduct', { static: false }) set grid2(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckProduct = content;
    }
  }

  private gridSafetyCheckVehicleInspection: DxDataGridComponent;
  @ViewChild('gridSafetyCheckVehicleInspection', { static: false }) set grid3(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckVehicleInspection = content;
    }
  }

  private gridSafetyCheckOthers: DxDataGridComponent;
  @ViewChild('gridSafetyCheckOthers', { static: false }) set grid4(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckOthers = content;
    }
  }
  private gridSafetyCheckDevices: DxDataGridComponent;
  @ViewChild('gridSafetyCheckDevices', { static: false }) set grid5(content: DxDataGridComponent) {
    if (content) {
      this.gridSafetyCheckDevices = content;
    }
  }

  constructor(
    private safetyCheck$: SafetyCheckInterface,
    private errors$: ErrorsService,
    private company$: CompanyService,
    private app$: AppService,
    private shared$: SharedService,
    private products$: ProductService,
    private actions$: ActionsService
  ) { }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.animation = this.app$.getAnimationSettings();
    this.shadingColor = this.app$.getShadingColor();
    forkJoin({
      companyList: this.company$.getCompanyDropDown(0),
      safetyCheckTextGroups: this.safetyCheck$.getSafetyCheckTextGroups(),
      safetyGlobalMandatoryStates: this.safetyCheck$.getSafetyGlobalMandatoryStates(),
      safetyMandatoryStates: this.safetyCheck$.getSafetyMandatoryStates()
    }).subscribe(
      res => {
        this.app$.notifyScreenReady();
        this.safetyCheckCompanies = res['companyList'];
        this.safetyCheckTextGroups = res['safetyCheckTextGroups'];
        this.safetyGlobalMandatoryState = res['safetyGlobalMandatoryStates'];
        this.safetyMandatoryState = res['safetyMandatoryStates'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );

    this.devicesColumn = [
      {
        caption: 'Device Id - Name', dataField: 'Id', cssClass: 'DeviceId', dataType: 'string', alignment: 'center',
        cellTemplate: function (container, options) {
          var devicedetails = "";
          if (options.data.Id != 0) devicedetails = devicedetails + options.data.Id + " - ";
          if (options.data.Name != null) devicedetails = devicedetails + options.data.Name;
          $('<span />').text(devicedetails).append("</br>").appendTo(container);
        },
        calculateCellValue: function (data) {
          return data.Id + " - " + data.Name;
        },
        calculateFilterExpression: function (filterValue, selectedFilterOperation) {
          return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
      },
      {
        alignment: 'center', caption: 'License Plate', dataField: 'LicensePlate', cssClass: 'LicensePlate', dataType: 'string',
        cellTemplate: function (container, options) {
          $('<span />')
            .text(options.data.LicensePlate)
            .appendTo(container);
        }
      }
    ];
  }

  resetFilterOptions()  {
    this.cancelForm();
    this.isAddNewTextVisible = false;
    this.safetyCheckCompanyId = null;
    this.safetyCheckGroupId = null;
    this.vehicleTypeId = null;
    this.dataAreaId = null;
    this.vehicleId = null;
    this.devices = null;
    this.dataAreas = null;
    this.vehicleTypes = null;    
};


  companyChanged() {
    let arg = {
      companyId: this.safetyCheckCompanyId
      }
    if (this.safetyCheckGroupId != null && (this.safetyCheckGroupId == 1 || this.safetyCheckGroupId == 11)) {
      this.vehicleId =null;
      this.vehicleTypeId = null;
      this.dataAreaId = null;
      this.safetyCheck$.getDevicesList(arg).subscribe(data=> {
          this.devices = cloneDeep(data)
          this.safetyChecksDevice = data;
          this.gridSafetyCheckDevices.instance.refresh();
        }), (errorGetList) => this.errors$.handleServerErrors(errorGetList);
    }
    this.cancelForm();
    this.getSafetyChecks();
  }

  vehicleTypeDataAreaChanged() {
    if (this.vehicleTypeId != null) {
      if (this.dataAreaId != null && this.dataAreaId != 0) {
          this.safetyChecksDevice = this.devices.filter(a => a.VehicleTypeId == this.vehicleTypeId && a.DataAreaId == this.dataAreaId);
      }
      else {
          this.safetyChecksDevice = this.devices.filter(a => a.VehicleTypeId == this.vehicleTypeId);
      }
      this.gridSafetyCheckDevices.instance.refresh();
      this.deSelectEntityDevice();
    }
    this.getSafetyChecks();
  }

  onRowClickDevice(clickedRow: any) {

    if (!this.selectedKeys[this.safetyCheckGroupId]) {
      this.selectedKeys[this.safetyCheckGroupId] = null;
    }
    if (JSON.stringify(this.selectedKeys[this.safetyCheckGroupId]) === JSON.stringify(clickedRow.key)) {
      this.deSelectEntityDevice();
    }
    else {
      this.selectEntityDevice(clickedRow.key)
    }
  }
  selectEntityDevice(key: any){
    this.vehicleId = key.Id;
    this.getSafetyChecks();
  }
  deSelectEntityDevice(){
    this.vehicleId =null;
    this.gridSafetyCheckDevices.instance.deselectAll();
  }

  onRowClick(clickedRow: any) {
    if (!this.selectedKeys[this.safetyCheckGroupId]) {
      this.selectedKeys[this.safetyCheckGroupId] = null;
    }
    if (JSON.stringify(this.selectedKeys[this.safetyCheckGroupId]) === JSON.stringify(clickedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity(clickedRow.key)
    }
  }


  deSelectEntity() {
    this.resetsafetyCheck = null;
    this.isFormVisible = false;
    this.isDeleteVisible = false;
    if(this.safetyCheckGroupId)
    {
      this.isAddNewTextVisible = true;
    }
    else
    {
      this.isAddNewTextVisible = false;
    }
    this.selectedKeys[this.safetyCheckGroupId] = null;
    this.clearValidation();

    switch (this.safetyCheckGroupId) {
      case 1:
        this.gridSafetyCheckLoginLogout.instance.deselectAll();
        this.selectedSafetyCheckLoginLogout = null;
        this.isSafetyCheckLoginLogoutFormVisible = false;
        break;

      case 11:
        this.gridSafetyCheckLoginLogout.instance.deselectAll();
        this.selectedSafetyCheckLoginLogout = null;
        this.isSafetyCheckLoginLogoutFormVisible = false;
        break;

      case 2:
        this.gridSafetyCheckCustomer.instance.deselectAll();
        this.selectedSafetyCheckCustomer = null;
        this.isSafetyCheckCustomerFormVisible = false;
        break;

      case 5:
      case 13:
        this.gridSafetyCheckProduct.instance.deselectAll();
        this.selectedSafetyCheckProduct = null;
        this.isSafetyCheckProductFormVisible = false;
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        this.gridSafetyCheckOthers.instance.deselectAll();
        this.selectedSafetyCheckOthers = null;
        this.isSafetyCheckOthersFormVisible = false;
        break;

      case 12:
        this.gridSafetyCheckVehicleInspection.instance.deselectAll();
        this.selectedSafetyCheckVehicleInspection = null;
        this.isSafetyCheckVehicleInspectionFormVisible = false;
        break;

      default:
        break;
    }
  }

  selectEntity(key: any) {
    this.selectedKeys[this.safetyCheckGroupId] = key;
    this.isFormVisible = true;
    this.isDeleteVisible = true;
    this.isAddNewTextVisible = false;
    this.collapseColor = "green";
    this.clearValidation();

    switch (this.safetyCheckGroupId) {
      case 1:
        this.safetyChecksLoginLogout.byKey(key)
          .then(pt => {
            pt.VehicleTypeId = (IsUndefinedOrNull(this.vehicleTypeId) == true) ? null : this.vehicleTypeId;
            pt.DataAreaId = (IsUndefinedOrNull(this.dataAreaId) == true) ? null : this.dataAreaId;
            pt.VehicleId = (IsUndefinedOrNull(this.vehicleId) == true) ? null : this.vehicleId;
            this.selectedSafetyCheckLoginLogout = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckLoginLogoutFormVisible = true;
          });
          break;

      case 11:
        this.safetyChecksLoginLogout.byKey(key)
          .then(pt => {
            pt.VehicleTypeId = (IsUndefinedOrNull(this.vehicleTypeId) == true) ? null : this.vehicleTypeId;
            pt.DataAreaId = (IsUndefinedOrNull(this.dataAreaId) == true) ? null : this.dataAreaId;
            pt.VehicleId = (IsUndefinedOrNull(this.vehicleId) == true) ? null : this.vehicleId;
            this.selectedSafetyCheckLoginLogout = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckLoginLogoutFormVisible = true;
          });
          break;

      case 2:
        this.safetyChecksCustomer.byKey(key)
          .then(pt => {
            pt.CustomerTypeId = (IsUndefinedOrNull(this.customerTypeId) == true) ? null : this.customerTypeId;
            this.selectedSafetyCheckCustomer = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckCustomerFormVisible = true;
          });
        break;

      case 5:
      case 13:
        this.safetyChecksProduct.byKey(key)
          .then(pt => {
            pt.ProductTypeId = (IsUndefinedOrNull(this.productTypeId) == true) ? null : this.productTypeId;
            this.selectedSafetyCheckProduct = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckProductFormVisible = true;
          });
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        this.safetyChecksForOthers.byKey(key)
          .then(pt => {
            this.selectedSafetyCheckOthers = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckOthersFormVisible = true;
          });
        break;

      case 12:
        this.safetyChecksVehicle.byKey(key)
          .then(pt => {
            pt.VehicleTypeId = (IsUndefinedOrNull(this.vehicleTypeId) == true) ? null : this.vehicleTypeId;
            this.selectedSafetyCheckVehicleInspection = cloneDeep(pt);
            this.resetsafetyCheck = cloneDeep(pt);
            this.isSafetyCheckVehicleInspectionFormVisible = true;
          });
        break;

      default:
        break;
    }
  }

  setGridHeight(grid: any) {
    setTimeout(() => {
      grid.instance.option('height', 350);
      grid.instance.refresh();
    }, 1000);
  }

  onContentReadyCustomer() {
    if (this.gridSafetyCheckCustomer) {
      if (this.intialLoad[this.safetyCheckGroupId])
        this.setGridHeight(this.gridSafetyCheckCustomer);
      this.intialLoad[this.safetyCheckGroupId] = false;
      this.gridSafetyCheckCustomer.noDataText = this.gridSafetyCheckCustomer.instance.totalCount() ? "" : "No Customer Safety Check Texts Found";
    }
  }
  onContentReadyDevice() {
    if (this.gridSafetyCheckDevices) {
      this.intialLoad[this.safetyCheckGroupId] = false;
      this.gridSafetyCheckDevices.noDataText = this.gridSafetyCheckDevices.instance.totalCount() ? "" : "No Devices Found";
    }
  }

  onContentReadyLoginLogout() {
    if (this.gridSafetyCheckLoginLogout) {
      if (this.intialLoad[this.safetyCheckGroupId])
        this.setGridHeight(this.gridSafetyCheckLoginLogout);
      this.intialLoad[this.safetyCheckGroupId] = false;
      let dataText = this.safetyCheckGroupId == 1 ? "No Login Safety Check Texts Found" : "No Logout Safety Check Texts Found";
      this.gridSafetyCheckLoginLogout.noDataText = this.gridSafetyCheckLoginLogout.instance.totalCount() ? "" : dataText;
      if (this.selectedKeys[this.safetyCheckGroupId] && this.gridSafetyCheckLoginLogout.instance.getSelectedRowKeys().length == 0) {
        this.gridSafetyCheckLoginLogout.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
      }
    }
  }

  onContentReadyProduct() {
    if (this.gridSafetyCheckProduct) {
      if (this.intialLoad[this.safetyCheckGroupId])
        this.setGridHeight(this.gridSafetyCheckProduct);
      this.intialLoad[this.safetyCheckGroupId] = false;
      this.gridSafetyCheckProduct.noDataText = this.gridSafetyCheckProduct.instance.totalCount() ? "" : "No Product Safety Check Texts Found";
      if (this.selectedKeys[this.safetyCheckGroupId] && this.gridSafetyCheckProduct.instance.getSelectedRowKeys().length == 0) {
        this.gridSafetyCheckProduct.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
      }
    }
  }

  onContentReadyVehicle() {
    if (this.gridSafetyCheckVehicleInspection) {
      if (this.intialLoad[this.safetyCheckGroupId])
        this.setGridHeight(this.gridSafetyCheckVehicleInspection);
      this.intialLoad[this.safetyCheckGroupId] = false;
      this.gridSafetyCheckVehicleInspection.noDataText = this.gridSafetyCheckVehicleInspection.instance.totalCount() ? "" : "No Vehicle Inspection Safety Check Texts Found";
    }
  }

  onContentReadyOthers() {
    if (this.gridSafetyCheckOthers) {
      if (this.intialLoad[this.safetyCheckGroupId])
        this.setGridHeight(this.gridSafetyCheckOthers);
      this.intialLoad[this.safetyCheckGroupId] = false;
      this.gridSafetyCheckOthers.noDataText = this.gridSafetyCheckOthers.instance.totalCount() ? "" : "No Safety Check Texts Found for the Group";
      if (this.selectedKeys[this.safetyCheckGroupId] && this.gridSafetyCheckOthers.instance.getSelectedRowKeys().length == 0) {
        this.gridSafetyCheckOthers.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
      }
    }
  }

  cellPreparedCustomer(e: any) {
    if (
      (e.rowType === "header" && e.column.dataField == "MandatoryForCustomerTypeImage" && !this.customerTypeId) ||
      (e.rowType === "data" && e.column.dataField != "MandatoryGlobalImage" && (!this.customerTypeId || e.data.MandatoryForCustomerType == 0))
    ) {
      e.cellElement.style.opacity = this.inActiveOpacity;
    }
  }

  cellPreparedLoginLogout(e: any) {
    if (
      (e.rowType === "header" && e.column.dataField == "MandatoryForVehicleTypeImage" && !this.vehicleTypeId) ||
      (e.rowType === "header" && e.column.dataField == "MandatoryForDataAreaImage" && this.dataAreaId == null) ||
      (e.rowType === "header" && e.column.dataField == "MandatoryForVehicleImage" && !this.vehicleId) ||
      (e.rowType === "data" && e.column.dataField != "MandatoryGlobalImage" && e.data.MandatoryForFleet == 0 &&
      (!this.vehicleTypeId || e.data.MandatoryForVehicleType == 0) &&
      (!this.dataAreaId || e.data.MandatoryForDataArea ==0) &&
      (!this.vehicleId || e.data.MandatoryForVehicle == 0))
    ) {
      e.cellElement.style.opacity = this.inActiveOpacity;
    }
  }

  cellPreparedProduct(e: any) {
    if (
      (e.rowType === "header" && e.column.dataField == "MandatoryForProductTypeImage" && this.productTypeId == undefined) ||
      (e.rowType === "data" && e.column.dataField != "MandatoryGlobalImage" && (this.productTypeId == undefined || e.data.MandatoryForProductType == 0))
    ) {
      e.cellElement.style.opacity = this.inActiveOpacity;
    }
  }

  cellPreparedVehicle(e: any) {
    if (
      (e.rowType === "header" && e.column.dataField === "MandatoryForVehicleTypeImage" && this.vehicleTypeId === undefined) ||
      (e.rowType === "data" && e.column.dataField !== "MandatoryGlobalImage" && (this.vehicleTypeId === undefined || e.data.MandatoryForVehicleType == 0))
    ) {
      e.cellElement.style.opacity = this.inActiveOpacity;
    }
  }

  cellPreparedOthers(e: any) {
    if (e.rowType === "data" && e.column.dataField !== "MandatoryGlobalImage" && e.data.MandatoryForOthers == 0) {
      e.cellElement.style.opacity = this.inActiveOpacity;
    }
  }

  safetyCheckGroupChanged() {
    let groupId = this.safetyCheckGroupId;
    this.intialLoad[this.safetyCheckGroupId] = true;
    this.isAddNewTextVisible = true;
    this.isSafetyCheckLoginLogoutFormVisible = false;
    this.isSafetyCheckCustomerFormVisible = false;
    this.isSafetyCheckProductFormVisible = false;
    this.isSafetyCheckOthersFormVisible = false;
    this.isSafetyCheckVehicleInspectionFormVisible = false;
    this.isFormVisible = false;
    this.selectedSafetyCheckLoginLogout = null;
    this.selectedSafetyCheckCustomer = null;
    this.selectedSafetyCheckProduct = null;
    this.selectedSafetyCheckOthers = null;
    this.selectedSafetyCheckVehicleInspection = null;

    if (groupId == 1 || groupId == 11 || groupId == 12) {
      this.vehicleTypeId = null;
      this.dataAreaId = null;
      this.vehicleId = null;
      this.productTypeId = null;
      this.customerTypeId = null;
      if((groupId == 1 || groupId == 11) && this.gridSafetyCheckDevices){
        this.safetyChecksDevice = cloneDeep(this.devices);
        this.gridSafetyCheckDevices.instance.deselectAll();
      }
      if (!this.vehicleTypes) {
        this.shared$.getVehicleTypes(0).subscribe(res => {
          this.vehicleTypes = res;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
      if (!this.dataAreas) {
        this.safetyCheck$.getDataAreaList().subscribe(res => {
          this.dataAreas = res;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
      if (!this.devices && (groupId == 1 || groupId == 11)){
        let arg = {
          companyId: this.safetyCheckCompanyId
        }
        this.safetyCheck$.getDevicesList(arg).subscribe(res => {
          this.devices = res;
          this.safetyChecksDevice = cloneDeep(res);
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (groupId == 2) {
      this.vehicleTypeId = null;
      this.dataAreaId = null;
      this.vehicleId = null;
      this.productTypeId = null;

      if (!this.customerTypes) {
        this.shared$.getCustomerTypeList().subscribe(res => {
          this.customerTypes = res.filter(a => a.Id != 1);
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (groupId == 5 || groupId == 13) {
      this.vehicleTypeId = null;
      this.dataAreaId = null;
      this.vehicleId = null;
      this.customerTypeId = null;
      if (!this.productTypes) {
        this.products$.getProductTypeDropdown().subscribe(res => {
          this.productTypes = res;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (groupId == 7 || groupId == 8 || groupId == 9 || groupId == 10) {
      this.vehicleTypeId = null;
      this.dataAreaId = null;
      this.vehicleId = null;
      this.customerTypeId = null;
      this.productTypeId = null;
    }

    if (this.safetyChecksProduct && [5, 13].indexOf(this.safetyCheckGroupId) < 0) {
      this.safetyChecksProduct.clear();
    }

    if (this.safetyChecksForOthers && [7, 8, 9, 10].indexOf(this.safetyCheckGroupId) < 0) {
      this.safetyChecksForOthers.clear();
    }

    this.getSafetyChecks();
  }

  getSafetyChecks() {
    if (this.safetyCheckGroupId == 1) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        vehicleTypeId: this.vehicleTypeId,
        dataAreaId: this.dataAreaId,
        vehicleId: this.vehicleId
      }
      this.gridSafetyCheckLoginLogout?.instance.beginCustomLoading('');
      this.safetyCheck$.getSafetyCheckForLogin(arg).subscribe(res => {
        this.updateLoginLogoutGrid(res);
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    if (this.safetyCheckGroupId == 11) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        vehicleTypeId: this.vehicleTypeId,
        dataAreaId: this.dataAreaId,
        vehicleId: this.vehicleId
      }
      this.gridSafetyCheckLoginLogout?.instance.beginCustomLoading('');
      this.safetyCheck$.getSafetyCheckForLogout(arg).subscribe(res => {
        this.updateLoginLogoutGrid(res);
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    else if (this.safetyCheckGroupId == 12) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        vehicleTypeId: this.vehicleTypeId,
      }
      this.gridSafetyCheckVehicleInspection?.instance.beginCustomLoading('');
      this.safetyCheck$.getSafetyCheckForVehicleInspection(arg).subscribe(res => {
        this.safetyChecksVehicle = new ArrayStore({
          data: res,
          key: ['Id']
        });
        this.gridSafetyCheckVehicleInspection?.instance.endCustomLoading();
        this.gridSafetyCheckVehicleInspection.instance.repaint();
        if (this.selectedKeys[this.safetyCheckGroupId]) {
          this.selectEntity(this.selectedKeys[this.safetyCheckGroupId]);
          this.gridSafetyCheckVehicleInspection.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
        }

      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    else if (this.safetyCheckGroupId == 2) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        customerTypeId: this.customerTypeId,
      }
      this.gridSafetyCheckCustomer?.instance.beginCustomLoading('');
      this.safetyCheck$.getSafetyCheckForCustomer(arg).subscribe(res => {
        this.safetyChecksCustomer = new ArrayStore({
          data: res,
          key: ['Id']
        });
        this.gridSafetyCheckCustomer?.instance.endCustomLoading();
        this.gridSafetyCheckCustomer.instance.repaint();
        if (this.selectedKeys[this.safetyCheckGroupId]) {
          this.selectEntity(this.selectedKeys[this.safetyCheckGroupId]);
          this.gridSafetyCheckCustomer.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
        }
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    else if (this.safetyCheckGroupId == 5) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        productTypeId: this.productTypeId,
      }
      this.safetyCheck$.getSafetyCheckForProduct(arg).subscribe(res => {
        this.updateProductGrid(res);
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

    }
    else if (this.safetyCheckGroupId == 13) {
      let arg = {
        companyId: this.safetyCheckCompanyId,
        productTypeId: this.productTypeId,
      }
      this.safetyCheck$.getSafetyCheckForLoadingProduct(arg).subscribe(res => {
        this.updateProductGrid(res);
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
    else if (this.safetyCheckGroupId == 7 || this.safetyCheckGroupId == 8
      || this.safetyCheckGroupId == 9 || this.safetyCheckGroupId == 10) {

      let arg = {
        companyId: this.safetyCheckCompanyId,
        groupTypeId: this.safetyCheckGroupId,
      }
      this.safetyCheck$.getSafetyCheckForOthers(arg).subscribe(res => {
        this.safetyChecksForOthers = new ArrayStore({
          data: res,
          key: ['Id']
        });
        if (this.selectedKeys[this.safetyCheckGroupId]) {
          this.selectEntity(this.selectedKeys[this.safetyCheckGroupId]);
          this.gridSafetyCheckOthers.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
        }
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
  }
  updateLoginLogoutGrid(res: any) {
    this.safetyChecksLoginLogout = new ArrayStore({
      data: res,
      key: ['Id']
    });
    this.gridSafetyCheckLoginLogout?.instance.endCustomLoading();
    setTimeout(() => {
      this.gridSafetyCheckLoginLogout.instance.repaint();
      if (this.selectedKeys[this.safetyCheckGroupId]) {
        this.selectEntity(this.selectedKeys[this.safetyCheckGroupId]);
        this.gridSafetyCheckLoginLogout.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
      }
    }, 1000);
  }
  updateProductGrid(res: any) {
    this.safetyChecksProduct = new ArrayStore({
      data: res,
      key: ['Id']
    });
    this.gridSafetyCheckProduct?.instance.repaint();
    if (this.selectedKeys[this.safetyCheckGroupId]) {
      this.selectEntity(this.selectedKeys[this.safetyCheckGroupId]);
      this.gridSafetyCheckProduct.instance.selectRows(this.selectedKeys[this.safetyCheckGroupId], true);
    }
  }

  addNewSafetyCheck() {
    if (this.safetyCheckGroupId == 1) {
      if (this.newDataLogin) {
        this.selectedSafetyCheckLoginLogout = cloneDeep(this.newDataLogin);
        this.resetsafetyCheck = cloneDeep(this.newDataLogin);
        this.isSafetyCheckLoginLogoutFormVisible = true;
      } else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          vehicleTypeId: this.vehicleTypeId,
          dataAreaId: this.dataAreaId,
          vehicleId: this.vehicleId
        }
        this.safetyCheck$.getNewSafetyCheckForLogin(arg).subscribe(res => {
          this.selectedSafetyCheckLoginLogout = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataLogin = cloneDeep(res);
          this.isSafetyCheckLoginLogoutFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    if (this.safetyCheckGroupId == 11) {
      if (this.newDataLogout) {
        this.selectedSafetyCheckLoginLogout = cloneDeep(this.newDataLogout);
        this.resetsafetyCheck = cloneDeep(this.newDataLogout);
        this.isSafetyCheckLoginLogoutFormVisible = true;
      } else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          vehicleTypeId: this.vehicleTypeId,
          dataAreaId: this.dataAreaId,
          vehicleId: this.vehicleId
        }
        this.safetyCheck$.getNewSafetyCheckForLogout(arg).subscribe(res => {
          this.selectedSafetyCheckLoginLogout = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataLogout = cloneDeep(res);
          this.isSafetyCheckLoginLogoutFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (this.safetyCheckGroupId == 12) {
      if (this.newDataVehicle) {
        this.selectedSafetyCheckVehicleInspection = cloneDeep(this.newDataVehicle);
        this.resetsafetyCheck = cloneDeep(this.newDataVehicle);
        this.isSafetyCheckVehicleInspectionFormVisible = true;
      } else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          vehicleTypeId: this.vehicleTypeId,
        }
        this.safetyCheck$.getNewSafetyCheckForVehicleInspection(arg).subscribe(res => {
          this.selectedSafetyCheckVehicleInspection = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataVehicle = cloneDeep(res);
          this.isSafetyCheckVehicleInspectionFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (this.safetyCheckGroupId == 2) {
      if (this.newDataCustomer) {
        this.selectedSafetyCheckCustomer = cloneDeep(this.newDataCustomer);
        this.resetsafetyCheck = cloneDeep(this.newDataCustomer);
        this.isSafetyCheckCustomerFormVisible = true;
      } else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          customerTypeId: this.customerTypeId,
        }
        this.safetyCheck$.getNewSafetyCheckForCustomer(arg).subscribe(res => {
          this.selectedSafetyCheckCustomer = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataCustomer = cloneDeep(res);
          this.isSafetyCheckCustomerFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (this.safetyCheckGroupId == 5 || this.safetyCheckGroupId == 13) {
      if (this.newDataProduct) {
        this.selectedSafetyCheckProduct = cloneDeep(this.newDataProduct);
        this.resetsafetyCheck = cloneDeep(this.newDataProduct);
        this.isSafetyCheckProductFormVisible = true;
      } else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          productTypeId: this.productTypeId,
        }
        this.safetyCheck$.getNewSafetyCheckForProduct(arg).subscribe(res => {
          this.selectedSafetyCheckProduct = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataProduct = cloneDeep(res);
          this.isSafetyCheckProductFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    else if (this.safetyCheckGroupId == 7 || this.safetyCheckGroupId == 8
      || this.safetyCheckGroupId == 9 || this.safetyCheckGroupId == 10) {
      if (this.newDataOthers) {
        this.selectedSafetyCheckOthers = cloneDeep(this.newDataOthers);
        this.resetsafetyCheck = cloneDeep(this.newDataOthers);
        this.isSafetyCheckOthersFormVisible = true;
      }
      else {
        let arg = {
          companyId: this.safetyCheckCompanyId,
          groupTypeId: this.safetyCheckGroupId,
        }
        this.safetyCheck$.getNewSafetyCheckForOthers(arg).subscribe(res => {
          this.selectedSafetyCheckOthers = res;
          this.resetsafetyCheck = cloneDeep(res);
          this.newDataOthers = cloneDeep(res);
          this.isSafetyCheckOthersFormVisible = true;
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      }
    }
    this.isAddNewTextVisible = false;
    this.isFormVisible = true;
    this.isDeleteVisible = false;
    this.collapseColor = "yellow";
  }

  cancelForm() {
    this.deSelectEntity();
  }

  updateGlobalMandatory(globalMandatoryState: any, type: any) {
    let mandatoryGlobal = 0;
    let mandatoryGlobalImg = this.notvisibleImg;
    if (globalMandatoryState == 0) {
      mandatoryGlobal = 1;
      mandatoryGlobalImg = this.mandatoryGlobalImg;
    }
    if (type == "loginlogout") {
      this.selectedSafetyCheckLoginLogout.MandatoryGlobal = mandatoryGlobal;
      this.selectedSafetyCheckLoginLogout.MandatoryGlobalImage = mandatoryGlobalImg;    }
    else if (type == "vehicleinspection") {
      this.selectedSafetyCheckVehicleInspection.MandatoryGlobal = mandatoryGlobal;
      this.selectedSafetyCheckVehicleInspection.MandatoryGlobalImage = mandatoryGlobalImg;
    }
    else if (type == "customer") {
      this.selectedSafetyCheckCustomer.MandatoryGlobal = mandatoryGlobal;
      this.selectedSafetyCheckCustomer.MandatoryGlobalImage = mandatoryGlobalImg;
    }
    else if (type == "product") {
      this.selectedSafetyCheckProduct.MandatoryGlobal = mandatoryGlobal;
      this.selectedSafetyCheckProduct.MandatoryGlobalImage = mandatoryGlobalImg;
    }
    else if (type == "others") {
      this.selectedSafetyCheckOthers.MandatoryGlobal = mandatoryGlobal;
      this.selectedSafetyCheckOthers.MandatoryGlobalImage = mandatoryGlobalImg;
    }
  }

  getNextState(state: any) {
    if (state == 0) return 1;
    else if (state == 1) return 2;
    else if (state == 2) return 0;
  }

  getImageUrl(state: any) {
    if (state == 0) return this.optionalImg;
    else if (state == 1) return this.mandatoryImg;
    else if (state == 2) return this.notvisibleImg;
  }

  updateMandatoryState(mandatoryState: any, filterType: any) {
    if (filterType == "fleet") {
      if (this.safetyCheckGroupId == 1 || this.safetyCheckGroupId == 11) {
        this.selectedSafetyCheckLoginLogout.MandatoryForFleet = this.getNextState(mandatoryState);
        this.selectedSafetyCheckLoginLogout.MandatoryForFleetImage = this.getImageUrl(mandatoryState);
      }
      else if (this.safetyCheckGroupId == 12) {
        this.selectedSafetyCheckVehicleInspection.MandatoryForFleet = this.getNextState(mandatoryState);
        this.selectedSafetyCheckVehicleInspection.MandatoryForFleetImage = this.getImageUrl(mandatoryState);
      }
    }
    else if (filterType == "vehicletype") {
      if (this.safetyCheckGroupId == 1 || this.safetyCheckGroupId == 11) {
        this.selectedSafetyCheckLoginLogout.MandatoryForVehicleType = this.getNextState(mandatoryState);
        this.selectedSafetyCheckLoginLogout.MandatoryForVehicleTypeImage = this.getImageUrl(mandatoryState);
      }
      else if (this.safetyCheckGroupId == 12) {
        this.selectedSafetyCheckVehicleInspection.MandatoryForVehicleType = this.getNextState(mandatoryState);
        this.selectedSafetyCheckVehicleInspection.MandatoryForVehicleTypeImage = this.getImageUrl(mandatoryState);
      }
    }
    else if (filterType == "dataarea") {
      if (this.safetyCheckGroupId == 1 || this.safetyCheckGroupId == 11) {
        this.selectedSafetyCheckLoginLogout.MandatoryForDataArea = this.getNextState(mandatoryState);
        this.selectedSafetyCheckLoginLogout.MandatoryForDataAreaImage = this.getImageUrl(mandatoryState);
      }
      else if (this.safetyCheckGroupId == 12) {
        this.selectedSafetyCheckVehicleInspection.MandatoryForDataArea = this.getNextState(mandatoryState);
        this.selectedSafetyCheckVehicleInspection.MandatoryForDataAreaImage = this.getImageUrl(mandatoryState);
      }
    }
    else if (filterType == "vehicle") {
      if (this.safetyCheckGroupId == 1 || this.safetyCheckGroupId == 11) {
        this.selectedSafetyCheckLoginLogout.MandatoryForVehicle = this.getNextState(mandatoryState);
        this.selectedSafetyCheckLoginLogout.MandatoryForVehicleImage = this.getImageUrl(mandatoryState);
      }
      else if (this.safetyCheckGroupId == 12) {
        this.selectedSafetyCheckVehicleInspection.MandatoryForVehicle = this.getNextState(mandatoryState);
        this.selectedSafetyCheckVehicleInspection.MandatoryForVehicleImage = this.getImageUrl(mandatoryState);
      }
    }
    else if (filterType == "customertype") {
      this.selectedSafetyCheckCustomer.MandatoryForCustomerType = this.getNextState(mandatoryState);
      this.selectedSafetyCheckCustomer.MandatoryForCustomerTypeImage = this.getImageUrl(mandatoryState);
    }
    else if (filterType == "producttype") {
      this.selectedSafetyCheckProduct.MandatoryForProductType = this.getNextState(mandatoryState);
      this.selectedSafetyCheckProduct.MandatoryForProductTypeImage = this.getImageUrl(mandatoryState);
    }
    else if (filterType == "others") {
      this.selectedSafetyCheckOthers.MandatoryForOthers = this.getNextState(mandatoryState);
      this.selectedSafetyCheckOthers.MandatoryForOthersImage = this.getImageUrl(mandatoryState);
    }
  }

  clearValidation() {
    this.formSubmitted = false;
    this.requiredErr = {};
    this.maxLengthErr = {};
  }

  checkValidation(formData: any) {
    if (this.formSubmitted)
      this.formValidation(formData)
  }

  formValidation(formData: any) {
    this.requiredErr = {};
    this.maxLengthErr = {};

    if (!formData.Text || formData.Text.trim() == "") {
      this.requiredErr[this.safetyCheckGroupId] = true;
    } else if (formData.Text.trim().length > 50) {
      this.maxLengthErr[this.safetyCheckGroupId] = true;
    }
    return this.requiredErr[this.safetyCheckGroupId] || this.maxLengthErr[this.safetyCheckGroupId];
  }

  submitForm() {
    this.formSubmitted = true;
    switch (this.safetyCheckGroupId) {
      case 1:
        if (this.formValidation(this.selectedSafetyCheckLoginLogout))
        return;
      if (this.vehicleTypeId && !this.selectedSafetyCheckLoginLogout.VehicleTypeId) {
        this.selectedSafetyCheckLoginLogout.VehicleTypeId = this.vehicleTypeId;
      }
      if (this.dataAreaId && !this.selectedSafetyCheckLoginLogout.DataAreaId) {
        this.selectedSafetyCheckLoginLogout.DataAreaId = this.dataAreaId;
      }
      if (this.vehicleId && !this.selectedSafetyCheckLoginLogout.VehicleId) {
        this.selectedSafetyCheckLoginLogout.VehicleId = this.vehicleId;
      }

      this.safetyCheck$.saveSafetyCheckForLogin(this.selectedSafetyCheckLoginLogout).subscribe(res => {
        this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for login successfully' });
        this.cancelForm();
        this.getSafetyChecks();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      break;

      case 11:
        if (this.formValidation(this.selectedSafetyCheckLoginLogout))
        return;
      if (this.vehicleTypeId && !this.selectedSafetyCheckLoginLogout.VehicleTypeId) {
        this.selectedSafetyCheckLoginLogout.VehicleTypeId = this.vehicleTypeId;
      }
      if (this.dataAreaId && !this.selectedSafetyCheckLoginLogout.DataAreaId) {
        this.selectedSafetyCheckLoginLogout.DataAreaId = this.dataAreaId;
      }
      if (this.vehicleId && !this.selectedSafetyCheckLoginLogout.VehicleId) {
        this.selectedSafetyCheckLoginLogout.VehicleId = this.vehicleId;
      }

      this.safetyCheck$.saveSafetyCheckForLogout(this.selectedSafetyCheckLoginLogout).subscribe(res => {
        this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for logout successfully' });
        this.cancelForm();
        this.getSafetyChecks();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
      break;

      case 2:

        if (this.formValidation(this.selectedSafetyCheckCustomer))
          return;
        if (this.customerTypeId && !this.selectedSafetyCheckCustomer.CustomerTypeId) {
          this.selectedSafetyCheckCustomer.CustomerTypeId = this.customerTypeId;
        }
        this.safetyCheck$.saveSafetyCheckForCustomer(this.selectedSafetyCheckCustomer).subscribe(res => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for customer successfully' });
          this.cancelForm();
          this.getSafetyChecks();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        break;

      case 5:
        if (this.formValidation(this.selectedSafetyCheckProduct))
          return;
        if (this.productTypeId) {
          this.selectedSafetyCheckProduct.ProductTypeId = this.productTypeId;
        }
        this.safetyCheck$.saveSafetyCheckForProduct(this.selectedSafetyCheckProduct).subscribe(res => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for product successfully' });
          this.cancelForm();
          this.getSafetyChecks();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        break;

      case 13:
        if (this.formValidation(this.selectedSafetyCheckProduct))
          return;
        if (this.productTypeId) {
          this.selectedSafetyCheckProduct.ProductTypeId = this.productTypeId;
        }
        this.safetyCheck$.saveSafetyCheckForLoadingProduct(this.selectedSafetyCheckProduct).subscribe(res => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for loading product successfully' });
          this.cancelForm();
          this.getSafetyChecks();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        if (this.formValidation(this.selectedSafetyCheckOthers))
          return;
        this.safetyCheck$.saveSafetyCheckForOthers(this.selectedSafetyCheckOthers).subscribe(res => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details successfully' });
          this.cancelForm();
          this.getSafetyChecks();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        break;

      case 12:
        if (this.formValidation(this.selectedSafetyCheckVehicleInspection))
          return;
        if (this.vehicleTypeId) {
          this.selectedSafetyCheckVehicleInspection.VehicleTypeId = this.vehicleTypeId;
        }
        this.safetyCheck$.saveSafetyCheckForVehicleInspection(this.selectedSafetyCheckVehicleInspection).subscribe(res => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Updated Safety check details for vehicle inspection successfully' });
          this.cancelForm();
          this.getSafetyChecks();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        break;

      default:
        break;
    }
  }

  reset() {
    let message = "";
    let resetData = cloneDeep(this.resetsafetyCheck);
    switch (this.safetyCheckGroupId) {
      case 1:
        this.selectedSafetyCheckLoginLogout = resetData;
        message = 'Reset Safety check details for login successfully';
        break;

      case 11:
        this.selectedSafetyCheckLoginLogout = resetData;
        message = 'Reset Safety check details for logout successfully';
        break;

      case 2:
        this.selectedSafetyCheckCustomer = resetData;
        message = 'Reset Safety check details for customer successfully';
        break;

      case 5:
      case 13:
        this.selectedSafetyCheckProduct = resetData;
        message = 'Reset Safety check details for product successfully';
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        this.selectedSafetyCheckOthers = resetData;
        message = 'Reset Safety check details successfully';
        break;

      case 12:
        this.selectedSafetyCheckVehicleInspection = resetData;
        message = 'Reset Safety check details for vehicle inspection successfully';
        break;

      default:
        break;
    }
    this.actions$.notifyEntityCustomAction({ title: 'Success!', message: message });
    this.checkValidation(resetData);
  }

  deleteCancel() {
    this.showDeleteConfirm = false;
  }

  deleteConfirm() {
    this.showDeleteConfirm = false;
    let arg = {};
    let message = "";
    switch (this.safetyCheckGroupId) {
      case 1:
        arg = {
          companyId: this.selectedSafetyCheckLoginLogout.CompanyId,
          textId: this.selectedSafetyCheckLoginLogout.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details for login successfully";
        break;

      case 11:
        arg = {
          companyId: this.selectedSafetyCheckLoginLogout.CompanyId,
          textId: this.selectedSafetyCheckLoginLogout.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details for logout successfully";
        break;


      case 2:
        arg = {
          companyId: this.selectedSafetyCheckCustomer.CompanyId,
          textId: this.selectedSafetyCheckCustomer.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details for customer successfully"
        break;

      case 5:
      case 13:
        arg = {
          companyId: this.selectedSafetyCheckProduct.CompanyId,
          textId: this.selectedSafetyCheckProduct.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details for product successfully";
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        arg = {
          companyId: this.selectedSafetyCheckOthers.CompanyId,
          textId: this.selectedSafetyCheckOthers.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details successfully";
        break;

      case 12:
        arg = {
          companyId: this.selectedSafetyCheckVehicleInspection.CompanyId,
          textId: this.selectedSafetyCheckVehicleInspection.Id,
          safetyCheckGroupId: this.safetyCheckGroupId
        }
        message = "Deleted Safety check details for vehicle inspection successfully";
        break;

      default:
        break;
    }

    if (this.safetyCheckGroupId) {
      this.app$.notifyScreenBusy();
      this.safetyCheck$.deleteSafetyCheck(arg).subscribe(res => {
        this.actions$.notifyEntityCustomAction({ title: 'Success!', message: message });
        this.cancelForm();
        this.getSafetyChecks();
        this.app$.notifyScreenReady();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }

  }

  disableSaveBtn() {
    let disabled = true;
    switch (this.safetyCheckGroupId) {
      case 1:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckLoginLogout))
        disabled = false;
        break;

      case 11:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckLoginLogout))
        disabled = false;
        break;

      case 2:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckCustomer))
        disabled = false;
        break;

      case 5:
      case 13:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckProduct))
        disabled = false;
        break;

      case 7:
      case 8:
      case 9:
      case 10:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckOthers))
          disabled = false;
        break;

      case 12:
        if (JSON.stringify(this.resetsafetyCheck) != JSON.stringify(this.selectedSafetyCheckVehicleInspection))
          disabled = false;
        break;

      default:
        break;
    }
    this.saveDisabled = disabled;
    return disabled;
  }
}
