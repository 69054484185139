import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DxDataGridModule, DxTooltipModule, DxToastModule, DxLoadPanelModule, DxPopupModule, DxDropDownBoxModule, DxButtonModule } from 'devextreme-angular';
import { ProductsModule } from '@products/products.module';
import { FleetModule } from './fleet/fleet.module';
import { SettingsModule } from './settings/settings.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './_shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginSSOComponent } from './login-sso/login-sso.component';
import { LoadPointComponent } from './load-point/load-point.component';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { CustomersModule } from './customers/customers.module';
import { SafetyCheckComponent } from './safety-check/safety-check.component';
import { PdiInputModule, PdiButtonsModule, PdiFooterModule, PdiNotificationsModule } from '@pdi/platform-angular-template';
import { AppComponent } from './app.component';
import { AppRouting } from './app-routing';
import { AuthInterceptor } from './auth.interceptor';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        FooterComponent,
        HeaderComponent,
        LoginSSOComponent,
        LoadPointComponent,
        SafetyCheckComponent,
    ],

    imports: [
        BrowserModule,
        HttpClientModule,
        FleetModule,
        ProductsModule,
        SettingsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CommonModule,
        DxDataGridModule,
        DxTooltipModule,
        DxLoadPanelModule,
        DxToastModule,
        DxPopupModule,
        DxDropDownBoxModule,
        DxButtonModule,
        NgxSmartModalModule.forRoot(),
        CustomersModule,
        PdiInputModule,
        PdiButtonsModule,
        PdiFooterModule,
        PdiNotificationsModule,
        AppRouting,
    ],
    providers: [NgxSmartModalService,{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {

}
