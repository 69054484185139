<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="factor" class="row h-100">
        <div class="col-md-6 col-sm-6">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReady($event)" #dataGrid [dataSource]="gridDataSource"
                    noDataText="" (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="Quantity Unit" dataField="QuantityUnit" dataType="string">
                        <dxo-lookup [dataSource]="quantityUnitList"></dxo-lookup>
                    </dxi-column>
                    <dxi-column alignment='center' caption="Factor ID" dataField="Id" dataType="string">
                        <dxo-lookup [dataSource]="factorIdList"></dxo-lookup>
                    </dxi-column>
                    <dxi-column alignment='center' caption="Factor Code" dataField="Code" dataType="string">
                        <dxo-lookup [dataSource]="factorCodeList"></dxo-lookup>
                    </dxi-column>
                    <dxi-column alignment='center' caption="Temperature" dataField="Temperature"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='center' caption="Factor" dataField="Value" dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>

        <div id="entity-detail" class="col-md-6 pr-0">
            <div *ngIf="entityData  &&  entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight" style="overflow-x: hidden;">
                    <div class="factor-Container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div>
                                <div class="row form-group">
                                    <div class="col-lg-2 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label class="col-form-label">Quantity Unit </label>
                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3 pl-1">
                                        <pdi-dropdown [full]="true" name="QuantityUnit"
                                            [(ngModel)]="entityData.value.QuantityUnit" *ngIf="!isNewQuantityUnit"
                                            [ngClass]="{'modelstate-invalid': hasError('QuantityUnit')}" required>
                                            <pdi-dropdown-option *ngFor="let quantityunit of quantityUnitList"
                                                [key]="quantityunit">
                                                {{quantityunit}}
                                            </pdi-dropdown-option>
                                        </pdi-dropdown>
                                        <div class="control-look" *ngIf="!isNewQuantityUnit"></div>
                                        <input name="newQuantityUnit" id="txtNewUnit" type="text" maxlength="10"
                                            class="form-custom-control control-look-input" [(ngModel)]="newQuantityUnit"
                                            *ngIf="isNewQuantityUnit" />
                                        <div class="control-look" *ngIf="isNewQuantityUnit"></div>
                                        <div class="error-message" *ngIf="hasError('QuantityUnit')">
                                            <small class="error" *ngIf="checkError('QuantityUnit','required')">
                                                Quantity Unit is required
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-top:4px;">
                                        <button type="button" class="pdi-add-button"
                                            (click)="addNewQuantityUnit()" *ngIf="!isNewQuantityUnit">
                                            <span class="pdi-icon-Add"></span>
                                        </button>
                                        <button type="button" class="edit-button-image-green"
                                            (click)="saveNewQuantityUnit()" *ngIf="isNewQuantityUnit">
                                            <img src="assets/Content/Images/Icons/save.png" />
                                        </button>
                                        <button type="button" class="edit-button-image-green ml-2"
                                            (click)="cancelNewQuantityUnit()" *ngIf="isNewQuantityUnit">
                                            <img src="assets/Content/Images/Icons/cancel.png" />
                                        </button>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-2 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Factor ID</label>
                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3 pl-1">
                                        <input [(ngModel)]="entityData.value.Id" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="Id">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-2 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Factor Code</label>
                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3 pl-1">
                                        <input type="number" name="Code" class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.Code" min="1" max="255"
                                            [ngClass]="{'modelstate-invalid': hasError('Code')}"
                                            [disabled]="!isAddForm" />
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Code')">
                                            <small class="error" *ngIf="checkError('Code','min')">
                                                Factor Code cannot be less than 1
                                            </small>
                                            <small class="error" *ngIf="checkError('Code','max')">
                                                Factor Code cannot be more than 255
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div>
                                <div class="error-message mb-4"
                                    *ngIf="this.entityForm?.submitted && entityData.value.Items.length == 0 ">
                                    <small class="error">
                                        Add minimum one Factor Item!
                                    </small>
                                </div>
                                <div class="error-message mb-4" *ngIf="this.factorGridErrorMsg.length >1">
                                    <small class="error">
                                        {{factorGridErrorMsg}}
                                    </small>
                                </div>
                                <div class="dataGridDiv mt-2" style="min-height:400px">
                                    <div class="factor_items">Factor Items</div>
                                    <dx-data-grid #dataGridFactorItems class="no-data-min-height-sp"
                                        (onInitialized)="onInitialized($event)"
                                        (onContentReady)="onContentReadyFactorItems($event)"
                                        (onRowInserting)="onFactorItemsGridRowInserting($event)"
                                        [dataSource]="entityData.value.Items" noDataText="" [allowColumnResizing]="true"
                                        [columnAutoWidth]="true">
                                        <dxi-column width="30%" alignment='center' caption="Temperature"
                                            dataField="Temperature" dataType="number"></dxi-column>
                                        <dxi-column width="30%" alignment='center' caption="Factor" dataField="Value"
                                            dataType="number"></dxi-column>
                                        <dxo-selection mode="single"> </dxo-selection>
                                        <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                        <dxo-scrolling mode="standard" showScrollbar="onHover">
                                        </dxo-scrolling>
                                        <dxo-sorting mode="single"> </dxo-sorting>
                                        <dxo-paging [pageSize]="10"> </dxo-paging>
                                        <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true"
                                            mode="row" [texts]="{ addRow: '' }">
                                        </dxo-editing>
                                    </dx-data-grid>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameNotification"
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>

    </div>

</div>

<facnm-delete-dialog [entityName]="entityNameNotification"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityNameNotification"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>