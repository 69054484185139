import { Component } from '@angular/core';
import { AppService } from './app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    pageTitle = 'Login';
    constructor(private app$: AppService) { }
    ngOnInit(): void {
        this.app$.PageTitleValue.subscribe((res: any) => {
            setTimeout(() => {
                this.pageTitle = res;
            })
        });
    }
}