import { Component, OnInit, Output,Input, EventEmitter, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'facnm-vehicle-assignment',
  templateUrl: './vehicle-assignment.component.html',
  styleUrls: ['./vehicle-assignment.component.scss']
})
export class VehicleAssignmentComponent implements OnInit {

  constructor() { }
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  private dataGridAssignedVehicles: DxDataGridComponent;
  @ViewChild('dataGridAssignedVehicles', { static: false }) set assignedVehicles(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAssignedVehicles = content;
    }
  }

  private dataGridUnassignedVehicles: DxDataGridComponent;
  @ViewChild('dataGridUnassignedVehicles', { static: false }) set unassignedVehicles(content: DxDataGridComponent) {
    if (content) {
      this.dataGridUnassignedVehicles = content;
    }
  }
  @Input() AssignedVehicles = [];
  @Input() UnAssignedVehicles = [];
  @Input() isAddForm = 0;
  assignedVehicleSelected = [];
  unassignedVehicleSelected = [];
  vehicleColumnConfig: any;
  allMode: string;
  checkBoxesMode: string;

  ngOnInit(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'onClick';
    this.vehicleColumnConfig = [
      { caption: 'Vehicle Id', dataField: 'Id', visible: false, dataType: 'number' },
      { caption: 'Device Id - Name', dataField: 'DeviceId', cssClass: 'DeviceId', dataType: 'string', alignment: 'left',
        cellTemplate: function (container, options) {
          var devicedetails = "";
          if (options.data.DeviceId != 0) devicedetails = devicedetails + options.data.DeviceId + " - ";
          if (options.data.DeviceName != null) devicedetails = devicedetails + options.data.DeviceName;
          $('<span />').text(devicedetails).append("</br>").appendTo(container);
        },
        calculateCellValue: function (data) {
          return data.DeviceId + " - " + data.DeviceName;
        },
        calculateFilterExpression: function (filterValue, selectedFilterOperation) {
          return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
      },
      { alignment: 'center', caption: 'Vehicle No', dataField: 'VehicleNumber', cssClass: 'VehicleNumber', dataType: 'string' },
      { alignment: 'left', caption: 'License Plate', dataField: 'LicensePlate', cssClass: 'LicensePlate', dataType: 'string', width: 150,
        cellTemplate: function (container, options) {
          $('<span />')
            .text(options.data.LicensePlate)
            .appendTo(container);
        }
      }
    ];
  }     
  onContentReadyAssigned(e) {
    this.dataGridAssignedVehicles.noDataText = this.dataGridAssignedVehicles.instance.totalCount() ? '' : "No data";
  }
  onContentReadyUnAssigned(e) {
    this.dataGridUnassignedVehicles.noDataText = this.dataGridUnassignedVehicles.instance.totalCount() ? '' : "No data";
  }
  assignVehicles() {
    let selectedRows = this.unassignedVehicleSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.AssignedVehicles.filter(a => a.DeviceId == row.DeviceId);
      this.UnAssignedVehicles.splice(this.UnAssignedVehicles.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.AssignedVehicles.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  unassignVehicles() {
    let selectedRows = this.assignedVehicleSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.UnAssignedVehicles.filter(a => a.DeviceId == row.DeviceId);
      this.AssignedVehicles.splice(this.AssignedVehicles.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.UnAssignedVehicles.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  clearAndRefreshGrid() {
    if (this.dataGridUnassignedVehicles && this.dataGridAssignedVehicles) {
      this.dataGridUnassignedVehicles.instance.clearSelection();
      this.dataGridUnassignedVehicles.instance.refresh();
      this.dataGridAssignedVehicles.instance.clearSelection();
      this.dataGridAssignedVehicles.instance.refresh();
      this.setNoDataText();
    }
  }
  setNoDataText() {
    this.dataGridUnassignedVehicles.noDataText = this.UnAssignedVehicles?.length ? '' : "No data";
    this.dataGridAssignedVehicles.noDataText = this.AssignedVehicles?.length ? '' : "No data";
  }
}
