import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { ErrorsService } from '@app/_shared/errors/errors.service';

import { Device, Company } from './shared.model';

import { map as mapObject } from 'lodash';
import { Enum } from '@app/app.model';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    private companyListRetrieved: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>(undefined);
    CompanyListRetrieved = this.companyListRetrieved.asObservable();
    getCompanyList(): Observable<Company[]> {

        return this._http.get<Company[]>(e.endpoint.company,)
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyCompanyRetrieved(data: Company[]): void {
        this.companyListRetrieved.next(data);
    }



    private deviceListRetrieved: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>(undefined);
    DeviceListRetrieved = this.deviceListRetrieved.asObservable();

    getDeviceList(companyId?: number): Observable<Device[]> {

        let id = companyId ? companyId : '';
        //NOTE <any> because server device contains vehicle properties too
        return this._http.get<any>(e.endpoint.fleet.devices + '/' + id).pipe(

            map(devices => { //NOTE converts <any> to <Device[]>
                if (devices) {

                    let devicesMapped: Device[] = [];

                    mapObject(devices, (d) => {

                        let device: Device = d;

                        device.Vehicle = { Id: d.VehicleId, LicensePlate: d.LicensePlate, VehicleNumber: d.VehicleNumber };

                        devicesMapped.push(device);
                    });

                    return devicesMapped;
                }
            }),

            catchError(this.errors$.handleNetworkError)
        );
    }


    notifyDeviceRetrieved(data: Device[]): void {
        this.deviceListRetrieved.next(data);
    }


    getSafetyGroups(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.safetycheck + '/safety-groups')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleTypes(lookup = 1): Observable<any> {

        return this._http.get<any>(e.endpoint.fleet.vehicles + '/vehicletypes/'+ lookup)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getCountryList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.masterData + '/countries/0')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getLanguageList(): Observable<any> {
        return this._http.get<any>(e.endpoint.masterData + '/languages/0')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getCustomerTypeList(): Observable<any> {
        return this._http.get<any>(e.endpoint.masterData + '/customertypes/0')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    saveCustomerType(customerType): Observable<any> {
        return this._http.put<any>(
            e.endpoint.masterData + '/customertypes',
            customerType).pipe(catchError(this.errors$.handleNetworkError));
    }

    getCompanies(lookup): Observable<any> {
        return this._http.get<any>(e.endpoint.company + '/dropdown/' + lookup)
        .pipe(catchError(this.errors$.handleNetworkError));
    }



}
