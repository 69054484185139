import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { DeviceMap, DeviceMapInterface } from './device-mapping.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceMappingService  implements DeviceMapInterface  {

 constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  private listRetrieved: BehaviorSubject<DeviceMap[]> = new BehaviorSubject<DeviceMap[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<DeviceMap>) => Observable<any>;

  notifyRetrieved(data: DeviceMap[]): void {
    this.listRetrieved.next(data);
  }

  getList(): Observable<DeviceMap[]> {
    return this._http.get<DeviceMap[]>(e.endpoint.products.productDevice)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<DeviceMap>): Observable<any> {
    return this._http.put<DeviceMap>(
      e.endpoint.products.productDevice,
      data.value)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    return this._http.delete(e.endpoint.products.productDevice + "/" + param.ProductNameNumber + "/" + param.DeviceId + "/" + param.ProductId, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getProductList() {
    return this._http.get<DeviceMap>(e.endpoint.products.product + '/dropdown/0')
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  
  getDeviceList() {
    return this._http.get<DeviceMap>(e.endpoint.device + '/dropdown')
    .pipe(catchError(this.errors$.handleNetworkError));
  }
   getNewEntity() {
    return this._http.get<DeviceMap>(e.endpoint.products.productDevice + '/newDeviceProduct')
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getEntity(param: any) {
    return this._http.get<DeviceMap>(e.endpoint.products.productDevice + "/details/"+ param.ProductNameNumber + "/" + param.DeviceId + "/" + param.ProductId+ '?noCache=' + new Date().getTime())
    .pipe(catchError(this.errors$.handleNetworkError));
  }
}
