import { Component, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { ErrorsService } from '../errors/errors.service';
import { ActionsService } from '../actions/actions.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'facnm-loading-screen-indicator',
    templateUrl: './loading-screen.indicator.html',
    styleUrls: ['./loading-screen.indicator.scss']
})
export class LoadingScreenIndicator implements OnInit {

    private unsubscribe$ = new Subject<void>();

    animation: any;
    shadingColor: any;

    visible: boolean;

    constructor(private app$: AppService, private actions$: ActionsService, private errors$: ErrorsService) {

        this.animation = app$.getAnimationSettings();
        this.shadingColor = app$.getShadingColor();

        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = true);
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = true);
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = true);

        app$.ScreenBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { this.visible = true; this.errors$.clearErrors() });
        app$.ScreenReady.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = false);

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
            if (errors) {
                this.visible = false;
            }
        });

    }

    ngOnInit() {
        if(this.visible === undefined)
        {
            this.visible = true;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
