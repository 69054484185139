<div #screen class="container-fluid h-100">
  <div id="entity-management" class="row h-100">
    <div class="col-md-3 pr-0 h-100 right-panel overflow-y-auto">
      <div class="selectContainer" *ngIf="configurationGroups">
        <pdi-dropdown [full]="true" [(ngModel)]="groupSpecificConfigurationGroupNameId" id="groupSpecificConfigurationGroupNameId"
          name="configurationGroup" (valueSelected)="getConfigurationValues()">
          <pdi-dropdown-option *ngFor="let entity of configurationGroups" [key]="entity.Id">
            {{ entity.Name }}
          </pdi-dropdown-option>
        </pdi-dropdown>
      </div>
    </div>

    <div id="entity-detail" class="col-md-9 pr-3 h-100 overflow-y-auto">
      <div id="entity-errors" class="mt-4">
        <div class="col">
          <facnm-errors></facnm-errors>
        </div>
      </div>
      <form
        name="transPacConfigurationForm"
        id="transPacConfigurationForm"
        ng-submit="submitForm()"
        class="form-horizontal"
        novalidate
      >
    <div class="alert alert-warning"  *ngIf="isServerError" >
    <button type="button" class="close" (click)="closeServerErrors()"><span aria-hidden="true">&times;</span></button>
    <strong>Warning! </strong>
    {{ serverErrorMessage }}
    </div>

        <div
          calss=" col-lg-12 col-md-12 col-sm-12 col-xs-12 contentBoxRight right75"
          *ngIf="configurationValues && configurationValues.length > 0"
        >
          <div class="top-buffer" ng-show="isFormVisible">
            <table class="table">
              <thead>
                <tr>
                  <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                    <strong>Config Id</strong>
                  </td>
                  <td class="col-md-4 col-sm-4 col-xs-4">
                    <strong>Description</strong>
                  </td>
                  <td class="col-md-2 col-sm-2 col-xs-2 text-left">
                    <strong>{{ configValueTitle }}</strong>
                  </td>

                  <td class="col-md-5 col-sm-5 col-xs-5"></td>
                </tr>
              </thead>
              <tr
                *ngFor="
                  let configurationvalue of configurationValues;
                  let i = index
                "
              >
                <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                  <div class="pt-3">
                    <span class="configIdContainer">{{
                      configurationvalue.Id
                    }}</span>
                  </div>
                </td>
                <td class="col-md-4 col-sm-4 col-xs-4">
                  <div class="pt-3">{{ configurationvalue.Name }}</div>
                </td>
                <td
                  class="col-md-3 col-sm-3 col-xs-3 text-left"
                  *ngIf="groupSpecificConfigurationGroupNameId == '0'"
                >
                  <facnm-config-field                    
                    [configType]="1"
                    [configurationValue]="configurationvalue"
                    [disabled]="true"
                  ></facnm-config-field>
                </td>

                <td
                  class="col-md-3 col-sm-3 col-xs-3 text-left"
                  *ngIf="groupSpecificConfigurationGroupNameId != '0'"
                >
                  <facnm-config-field                    
                    [configType]="4"
                    [configurationValue]="configurationvalue"
                    [disabled]="true"
                    [configPageType]="2"                    
                  ></facnm-config-field>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 contentBoxButtons right75"
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer">
            <div class="col-md-5 col-sm-5 col-xs-5 text-center"></div>
            <div class="col-md-2 col-sm-2 col-xs-2 text-center">
              <button id="action-export" type="button" (click)="exportConfigurationValues()">
                <img
                  src="assets/Content/Images/Icons/save.png"
                  class="edit-button-image"
                />
                <span class="edit-button hidden-md hidden-sm hidden-xs"
                  >Export</span
                >
              </button>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-5 text-center"></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<facnm-notifications [entityName]="entityName"></facnm-notifications>
<facnm-loading-screen-indicator></facnm-loading-screen-indicator>
<facnm-loading-entity-indicator></facnm-loading-entity-indicator>


