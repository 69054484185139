import {ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { forkJoin, Subject } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';

import { Enum, Entity } from '@app/app.model';

import { ProductTransformation, ProductTransformationInterface } from './product-transformation.model';
import { ProductTransformationService } from './product-transformation.service';
import { ProductService } from '@products/products.service';
import { ProductsGridComponent } from '@products/products-grid/products-grid.component';

import { AppService } from '@app/app.service';

import cloneDeep from 'lodash/cloneDeep';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { takeUntil } from 'rxjs/operators';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
    selector: 'facnm-product-transformation',
    templateUrl: './product-transformation.component.html',
    styleUrls: ['./product-transformation.component.scss'],
    providers: [
        {
            provide: ProductTransformationInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new ProductTransformationService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class ProductTransformationComponent implements OnInit, AfterViewInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Product Transformation';

    productTransformation: Entity<ProductTransformation>;
    productTransformationStore: ArrayStore;

    @ViewChild("productTransformationForm") productTransformationForm: NgForm;
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

    productTransformationTypeList: Enum[];

    @ViewChild('productsFrom') private productsFrom: ProductsGridComponent;
    @ViewChild('productsTo') private productsTo: ProductsGridComponent;


    constructor(private productTransformation$: ProductTransformationInterface, private actions$: ActionsService, private errors$: ErrorsService, private product$: ProductService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {

        productTransformation$.ListRetrieved.subscribe(data => {

            if (data) {

                this.productTransformationStore = new ArrayStore({
                    data: data,
                    key: ['Flag', 'Type', 'ProductFromId', 'ProductToId']
                });

                if (data.length == 0) {
                    this.dataGrid.noDataText = 'No Product Transformation';
                }
                else {
                    this.dataGrid.noDataText = '';
                }
            }
        });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });

    }


    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(

            this.productTransformation$.getProductTransformationTypeList(),
            this.product$.getList()

        ).subscribe(
            multipleData => {

                this.productTransformationTypeList = multipleData[0];

                this.product$.notifyRetrieved(multipleData[1]);

                this.productTransformation$.getList().subscribe(productTransformationData => {
                    this.productTransformation$.notifyRetrieved(productTransformationData);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList))
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }


    onSelectionChanged($clikedRow) {

        if (!this.productTransformation)
            try {
                let keySaved = JSON.parse(localStorage.getItem('product-transformation.dataGrid')).selectedRowKeys[0];

                if (keySaved) {
                    this.selectProductTransformation(keySaved);
                }
            }
            catch{ }

        else {

            this.refreshProductsGrids();
        }
    }

    onRowClick($clikedRow) {

        this.app$.setScreenTitle(this.entityName);

        if (!this.productTransformation)
            this.productTransformation = new Entity();

        if (JSON.stringify(this.productTransformation.key) === JSON.stringify($clikedRow.key)) {
            this.deselectProductTransformation();
        }
        else {
            this.selectProductTransformation($clikedRow.key);
        }
    }

    allowOnlyNumbers(evt) {

        return this.app$.allowOnlyNumbers(evt);
    }

    private refreshProductsGrids() {

        if (this.productsFrom && this.productsTo) {
            this.productsFrom.refreshGrid();
            this.productsTo.refreshGrid();
        }
    }


    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    private addNewEntity() {

        this.app$.setScreenTitle('New ' + this.entityName);

        this.productTransformation = new Entity();
        this.productTransformation.value.Flag = 0;
        this.productTransformation.created = true;
        this.changeDetectorRef.detectChanges();

        this.refreshProductsGrids();
    }


    private reset() {

        this.productTransformation.restore();

        if (this.productTransformation.created) {
            this.productTransformation.value.Flag = 0;
            this.productsFrom.clearSelection();
            this.productsTo.clearSelection();
        }

        this.refreshProductsGrids();
    }


    private create() {

        this.handleFormSubmit();

        this.productTransformation$.create(this.productTransformation).subscribe(

            (okCreate) => {

                this.app$.setScreenTitle(this.entityName);
                this.actions$.notifyCreated();

                this.deselectProductTransformation();

                this.productTransformation$.getList().subscribe(listWithCreated => {
                    this.productTransformation$.notifyRetrieved(listWithCreated);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }



    private save() {

        this.handleFormSubmit();

        this.productTransformation$.save(this.productTransformation).subscribe(

            (okSave) => {

                this.actions$.notifySaved();

                this.deselectProductTransformation();

                this.productTransformation$.getList().subscribe(listWithCreated => {
                    this.productTransformation$.notifyRetrieved(listWithCreated);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

            },

            (errorSave) => {
                this.errors$.handleServerErrors(errorSave);
            }
        );
    }


    private deleteConfirm() {


        this.productTransformation$.delete(this.productTransformation.key).subscribe(

            (okDelete) => {

                this.actions$.notifyDeleted();

                this.deselectProductTransformation();


                this.productTransformation$.getList().subscribe(listWithCreated => {
                    this.productTransformation$.notifyRetrieved(listWithCreated);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

            },

            (errorDelete) => {
                this.errors$.handleServerErrors(errorDelete);
            }
        );
    }


    private cancel() {

        this.app$.setScreenTitle(this.entityName);

        this.deselectProductTransformation();
    }


    private selectProductTransformation(key: any) {

        this.actions$.notifyEntityBusy();

        this.productTransformation = new Entity();
        this.productTransformation.key = key;

        this.productTransformationStore.byKey(key)
            .then(pt => {

                this.productTransformation.value = cloneDeep(pt);
                this.changeDetectorRef.detectChanges()
                this.app$.notifyScreenReady()
            });
    }


  private deselectProductTransformation() {

        this.dataGrid.instance.deselectRows(this.productTransformation.key);
        this.productTransformation.key = undefined;
    }


    private handleFormSubmit() {
        this.productTransformationForm.form.markAsPristine();
        this.productTransformationForm.form.markAsUntouched();
        this.productTransformationForm.form.updateValueAndValidity();
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    onInitialized(e) {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }

}
