<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div [class]="{'no-permission':!hasPermission}" id="entity-management" class="row h-100">
        <div class="col-md-3 pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReady($event)" [wordWrapEnabled]="true" #dataGrid
                    [dataSource]="gridData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onRowClick)="onRowClick($event)" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="150px" caption="Profile ID" dataField="ProfileId"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Description" dataField="ProfileDescription"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer" ng-show="isFormVisible">
                            <div class="container">
                                <div class="row form-group">
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="ProfileId"class="col-form-label">Profile ID </label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <input type="text" name="ProfileId"
                                            class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.ProfileId"
                                            [ngClass]="{'modelstate-invalid': hasError('ProfileId')}" required
                                            focus-on="profileId" placeholder="Chart Profile ID" maxlength="100">
                                        <div class="control-look" ng-click="clickHalfDiv('profileId')"></div>
                                        <div class="error-message" *ngIf="hasError('ProfileId')">
                                            <small class="error" *ngIf="checkError('ProfileId','required')">
                                                Profile ID is required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Orientation">Orientation </label>
                                    </div>
                                    <div  class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <pdi-dropdown id="Orientation" name="Orientation" [full]="true"
                                            [(ngModel)]="entityData.value.Orientation"
                                            [ngClass]="{'modelstate-invalid': hasError('Orientation')}">
                                            <pdi-dropdown-option *ngFor="let orientation  of orientations"
                                                 [key]="orientation.id">
                                                {{orientation.name}}
                                            </pdi-dropdown-option> 
                                        </pdi-dropdown>
                                        <div class="control-look" ng-click="clickHalfDiv('orientation')"></div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Diameter">Diameter</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <input type="number" name="Diameter"
                                            class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.Diameter" focus-on="diameter"
                                            placeholder="Diameter">
                                        <div class="control-look" ng-click="clickHalfDiv('diameter')"></div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Heighth">Height</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <input type="number" name="Height"
                                            class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.Height" focus-on="height"
                                            placeholder="Height">
                                        <div class="control-look" ng-click="clickHalfDiv('height')"></div>
                                    </div>
                                </div>
                                <div class="row form-group">

                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Description">Description</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <input type="text" name="ProfileDescription"
                                            class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.ProfileDescription"
                                            [ngClass]="{'modelstate-invalid': hasError('ProfileDescription')}" required
                                            focus-on="description" placeholder="Description" maxlength="250">
                                        <div class="control-look" ng-click="clickHalfDiv('Description')"></div>
                                        <div class="error-message" *ngIf="hasError('ProfileDescription')">
                                            <small class="error" *ngIf="checkError('ProfileDescription','required')">
                                                Description is required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Shape">Shape</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <pdi-dropdown id="Shape" name="Shape" [full]="true"
                                            [(ngModel)]="entityData.value.Shape"
                                            [ngClass]="{'modelstate-invalid': hasError('Shape')}">
                                            <pdi-dropdown-option *ngFor="let shape  of shapes" [key]="shape.id">
                                                {{shape.name}}
                                            </pdi-dropdown-option>
                                        </pdi-dropdown>
                                        <div class="control-look" ng-click="clickHalfDiv('shape')"></div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 control-label">
                                        <label for="Length">Length</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <input type="number" name="Length"
                                            class="form-custom-control control-look-input"
                                            [(ngModel)]="entityData.value.Length" focus-on="length"
                                            placeholder="Length">
                                        <div class="control-look" ng-click="clickHalfDiv('length')"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class="col-md-12 collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Increments
                                            and Volume</span>
                                    </div>
                                </div>
                            </div>
                            <div class="error-message mb-4"
                                *ngIf="this.entityForm?.submitted && entityData.value.StrappingChartProfileIncrements.length == 0 ">
                                <small class="error">
                                    Chart Profile should have minimum one Increment and Volume
                                </small>
                            </div>
                            <div id="error_message" class="error-message mb-4" *ngIf="showError && incrementDuplicateErr">
                                <small class="error">
                                    Increment should be unique
                                </small>
                            </div>
                            <div id="error_message" class="error-message mb-4" *ngIf="showError && isEmptyErr">
                                <small class="error">
                                    Increment and volume should not be empty
                                </small>
                            </div>

                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="dataGridDiv" style="min-height:350px">
                                            <div class="strapping-title">Increments and Volume</div>
                                            <dx-data-grid #dataGridStrappingChart class="no-data-min-height-sp"
                                                (onContentReady)="onContentReadyStrappingChart($event)"
                                                [dataSource]="entityData.value.StrappingChartProfileIncrements"
                                                noDataText="" (onInitialized)="onInitialized($event)"
                                                [allowColumnResizing]="true" [columnAutoWidth]="true">
                                                <dxi-column width="40%" alignment='center' caption="Increment"
                                                    dataField="Increment" dataType="number"></dxi-column>
                                                <dxi-column width="40%" alignment='center' caption="Volume"
                                                    dataField="IncrementVolume" dataType="number"></dxi-column>

                                                <dxo-selection mode="single"> </dxo-selection>
                                                <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                                <dxo-scrolling mode="standard" showScrollbar="onHover">
                                                </dxo-scrolling>
                                                <dxo-sorting mode="single"> </dxo-sorting>
                                                <dxo-paging [pageSize]="10"> </dxo-paging>
                                                <dxo-editing [allowUpdating]="true" [allowAdding]="true"
                                                    [allowDeleting]="true" mode="row" [texts]="{ addRow: '' }">
                                                </dxo-editing>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameAdd" [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>