<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid [wordWrapEnabled]="true" #dataGrid [dataSource]="gridData" noDataText=""
                    (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='left' caption="Title" dataField="Title" dataType="string"></dxi-column>
                    <dxi-column alignment='center' caption="Data Type" dataField="DataType" dataType="string">
                        <dxo-lookup [dataSource]="dataTypeList" valueExpr="id" displayExpr="name"></dxo-lookup>
                    </dxi-column>
                    <dxi-column alignment='left' caption="Field Location" dataField="FieldLocation" dataType="string">
                        <dxo-lookup [dataSource]="fieldLocationsList" valueExpr="id" displayExpr="name"></dxo-lookup>
                    </dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Title" class="col-form-label">Title</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="entityData.value.Title"
                                                [ngClass]="{'modelstate-invalid': hasError('Title')}" type="text"
                                                class="form-custom-control control-look-input" name="Title" required
                                                maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Title')">
                                                <small class="error" *ngIf="checkError('Title','required')">
                                                    Title is required
                                                </small>
                                                <small class="error" *ngIf="checkError('Title','maxlength')">
                                                    Title cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="FieldId" class="col-form-label">Field location</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown name="FieldLocation" [full]="true"
                                                [(ngModel)]="entityData.value.FieldLocation"
                                                (valueSelected)="onFieldLocationChange()" [isDisabled]="!isAddForm"
                                                [ngClass]="{'modelstate-invalid': hasError('FieldLocation')}" required>
                                                <pdi-dropdown-option *ngFor="let fieldLocation of fieldLocationsList"
                                                    [key]="fieldLocation.id">
                                                    {{fieldLocation.id - 10 }} - {{fieldLocation.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('FieldLocation')">
                                                <small class="error" *ngIf="checkError('FieldLocation','required')">
                                                    Field location is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="!isAddForm">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="FieldId" class="col-form-label">Field Id</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="FieldId"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.FieldType" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ViewMode" class="col-form-label">View mode</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-tag-box required name="ViewMode" [(ngModel)]="entityData.value.ViewMode"
                                                [items]="viewModeList" valueExpr="id"
                                                [value]="entityData.value.ViewMode" displayExpr="name"
                                                [hideSelectedItems]="true" [searchEnabled]="true"
                                                [ngClass]="{'modelstate-invalid': hasError('ViewMode')}">
                                            </dx-tag-box>
                                            <div class="error-message" *ngIf="hasError('ViewMode')">
                                                <small class="error" *ngIf="checkError('ViewMode','required')">
                                                    View mode is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="IsViewModeReadOnly" class="checkbox-inline"
                                                [(ngModel)]="entityData.value.IsViewModeReadOnly" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label for="IsViewModeReadOnly" class="col-form-label">Read only view
                                                mode</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="FieldId" class="col-form-label">Data type</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown name="DataType" [full]="true" class="control-look-input"
                                                [(ngModel)]="entityData.value.DataType"
                                                [ngClass]="{'modelstate-invalid': hasError('DataType')}" required>
                                                <pdi-dropdown-option *ngFor="let dataType of dataTypeList" [key]="dataType.id">
                                                    {{dataType.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DataType')">
                                                <small class="error" *ngIf="checkError('DataType','required')">
                                                    Data type is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ExportFieldName" class="col-form-label">Export field</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="ExportFieldName"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('ExportFieldName')}"
                                                [(ngModel)]="entityData.value.ExportFieldName" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ExportFieldName')">
                                                <small class="error" *ngIf="checkError('ExportFieldName','maxlength')">
                                                    Export field cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Sort" class="col-form-label">Sort</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="Sort"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Sort')}"
                                                max="255"
                                                min="0"
                                                pattern="^[0-9]*$"
                                                (keypress)="isNumberOnly($event)"
                                                [(ngModel)]="entityData.value.Sort" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Sort')">
                                                <small class="error" *ngIf="checkError('Sort','max')">
                                                    Sort can be up to 255
                                                </small>
                                                <small class="error" *ngIf="checkError('Sort','pattern')">
                                                    Sort is not a valid number
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div *ngIf="entityData.value.DataType && [4,5,13].includes(entityData.value.DataType) == false"
                                class="collapsible-header-container top-buffer" (click)="ResCollapse = !ResCollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(ResCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Restrictions</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="entityData.value.DataType && [4,5,13].includes(entityData.value.DataType) == false"
                                id="ResCollapse" class="childs" [ngClass]="{'showChild': !ResCollapse}">
                                <div *ngIf="[1,2,3,10,11,12,6,9].includes(entityData.value.DataType)">
                                    <div class="col-lg-6">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="MinLength" class="col-form-label">Min. length</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                                <input type="number" name="MinLength"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.MinLength" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="MaxLength" class="col-form-label">Max. length</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                                <input type="number" name="MaxLength"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.MaxLength" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="entityData.value.DataType==2||entityData.value.DataType==11">
                                    <div class="col-lg-6">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Digits" class="col-form-label">Digits</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                                <input type="number" name="Digits"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.DigitsBeforeComma" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="entityData.value.DataType==3||entityData.value.DataType==12">
                                    <div class="col-lg-6">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="DigitsBeforeComma" class="col-form-label">Digits before
                                                    comma</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                                <input type="number" name="DigitsBeforeComma"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.DigitsBeforeComma" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="DigitsAfterComma" class="col-form-label">Digits after
                                                    comma</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                                <input type="number" name="DigitsAfterComma"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.DigitsAfterComma" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="entityData.value.DataType==7||entityData.value.DataType==8">
                                    <div class="col-lg-12">
                                        <div class="form-group row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <label for="Datasource" class="col-form-label">Datasource</label>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <textarea style="border:none !important" name="DataSource"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="entityData.value.DataSource"></textarea>
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="PACollapse = !PACollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(PACollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Visibility
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="PACollapse" class="childs" [ngClass]="{'showChild': !PACollapse}">
                                <div class="col-lg-6">
                                    <div class="visibility" *ngIf="entityData.value.FieldLocation">
                                        <dx-data-grid
                                            *ngIf="entityData.value.FieldLocation && entityData.value.FieldLocation != FieldLocationEng.TimeLog && entityData.value.FieldLocation != FieldLocationEng.LoadLocation"
                                            #dataGridCompany [dataSource]="gridDataCompany" noDataText=""
                                            (onCellPrepared)="onCellPreparedVisibility($event)"
                                            (onSelectionChanged)=onVisibilitySelectionChange($event)
                                            [allowColumnResizing]="false" [columnAutoWidth]="false">
                                            <dxi-column alignment='center' width="50px" caption="Id" dataField="Id"
                                                dataType="string"></dxi-column>
                                            <dxi-column alignment='left' caption="Company Name" dataField="name"
                                                dataType="string"></dxi-column>
                                            <dxi-column alignment='center' [allowFiltering]="false" width="50px"
                                                caption="" dataField="Visibility" dataType="string"></dxi-column>
                                            <dxo-selection mode="single"> </dxo-selection>
                                            <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                        </dx-data-grid>
                                        <dx-data-grid *ngIf="entityData.value.FieldLocation == FieldLocationEng.TimeLog"
                                            #dataGridTimeLog [dataSource]="gridDataTimeLog" noDataText=""
                                            (onCellPrepared)="onCellPreparedVisibility($event)"
                                            (onSelectionChanged)=onVisibilitySelectionChange($event)
                                            [allowColumnResizing]="false" [columnAutoWidth]="false">
                                            <dxi-column alignment='left' caption="Time Log" dataField="TimeLogName"
                                                dataType="string"></dxi-column>
                                            <dxi-column alignment='center' [allowFiltering]="false" width="50px"
                                                caption="" dataField="Visibility" dataType="string"></dxi-column>
                                            <dxo-selection mode="single"> </dxo-selection>
                                            <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                        </dx-data-grid>
                                        <dx-data-grid
                                            *ngIf="entityData.value.FieldLocation == FieldLocationEng.LoadLocation"
                                            #dataGridLoadLocation [dataSource]="gridDataLoadLocation" noDataText=""
                                            (onCellPrepared)="onCellPreparedVisibility($event)"
                                            (onSelectionChanged)=onVisibilitySelectionChange($event)
                                            [allowColumnResizing]="false" [columnAutoWidth]="false">
                                            <dxi-column alignment='center' caption="Type" dataField="LoadLocationType"
                                                dataType="string"></dxi-column>
                                            <dxi-column alignment='center' [allowFiltering]="false" width="50px"
                                                caption="" dataField="Visibility" dataType="string"></dxi-column>
                                            <dxo-selection mode="single"> </dxo-selection>
                                            <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                                            <dxo-sorting mode="single"> </dxo-sorting>
                                            <dxo-paging [pageSize]="10"> </dxo-paging>
                                        </dx-data-grid>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div *ngIf="isFormVisibilityVisible">
                                        <div class="form-group form-group-inline"
                                            *ngIf="entityData.value.FieldLocation !== FieldLocationEng.OrderDetail">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                <input name="IsVisible" type="checkbox" class="checkbox-inline"
                                                    (change)="onShowFieldChange()"
                                                    [(ngModel)]="selectedVisibilityRow.$visibility.IsVisible" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                <label for="IsVisible" class="col-form-label">Show the field</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                N. repetitions
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="number" name="Repetitions"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="selectedVisibilityRow.$visibility.Repetitions"
                                                    (change)="onShowFieldChange()"
                                                    [readonly]="entityData.value.FieldLocation == FieldLocationEng.OrderDetail && !selectedVisibilityRow.$visibility.IsVisible" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="form-group form-group-inline">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                <input name="IsMandatory" type="checkbox" class="checkbox-inline"
                                                    [(ngModel)]="selectedVisibilityRow.$visibility.IsMandatory"
                                                    (change)="onShowFieldChange()"
                                                    [disabled]="!selectedVisibilityRow.$visibility.IsVisible" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                <label for="IsMandatory" class="col-form-label">Is mandatory</label>
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>

                                        <div *ngIf="entityData.value.FieldLocation == FieldLocationEng.OrderDetail">

                                            <div class="form-group form-group-inline">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                    <input name="OsrChecked0" type="checkbox" class="checkbox-inline"
                                                        [(ngModel)]="selectedVisibilityRow.$visibility.OsrChecked[0]"
                                                        (change)="onShowFieldChange()" intValue="true" trueFalseValue
                                                        trueValue="0" falseValue="-1" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                    Show the field for normal orders
                                                </div>
                                            </div>
                                            <div style="clear:both;"></div>

                                            <div class="form-group form-group-inline">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                    <input name="OsrChecked1" type="checkbox" class="checkbox-inline"
                                                        [(ngModel)]="selectedVisibilityRow.$visibility.OsrChecked[1]"
                                                        (change)="onShowFieldChange()" intValue="true" trueFalseValue
                                                        trueValue="1" falseValue="-1" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                    Show the field for OSR orders
                                                </div>
                                            </div>

                                            <div style="clear:both;"></div>

                                            <div class="form-group form-group-inline">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                                    <input name="OsrChecked2" type="checkbox" class="checkbox-inline"
                                                        [(ngModel)]="selectedVisibilityRow.$visibility.OsrChecked[2]"
                                                        (change)="onShowFieldChange()" intValue="true" trueFalseValue
                                                        trueValue="2" falseValue="-1" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                                    Show the field for OSR orders when new equipment was created
                                                </div>
                                            </div>
                                            <div style="clear:both;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog entityName="Additional Field"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>