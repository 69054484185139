import { Injectable } from '@angular/core';
import { throwError, Subject,Observable } from 'rxjs';


import { AppService } from '@app/app.service';
import { ServerError } from '../shared.model';

import { HttpErrorResponse } from '@angular/common/http';

import forIn from 'lodash/forIn';

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {

    constructor(private app$: AppService) { }

    private errorsReceived: Subject<any> = new Subject<any>();
    ServerErrorsReceived = this.errorsReceived.asObservable();

    ModelStateErrors: any;
    ServerErrors: ServerError;
    
    isInvalid(field) {
        return this.ModelStateErrors && Object.keys(this.ModelStateErrors).some((k) => k.indexOf(field) >= 0);
    }

    getError(field) {
        return this.ModelStateErrors && this.ModelStateErrors[Object.keys(this.ModelStateErrors).find((k) => k.indexOf(field) >= 0)][0];
    }

    clearErrors() {

        this.ModelStateErrors = null;
        this.ServerErrors = null;

        this.errorsReceived.next(null);
    }

    handleServerErrors(serverError: ServerError) {

        this.ServerErrors = undefined;
        this.ModelStateErrors = undefined;

        if (serverError.redirectToLogin) {
            this.app$.redirectToLogin();
        }
        else if (serverError.modelState) {

            this.ModelStateErrors = serverError.modelState;
        }
        else {
            this.ServerErrors = serverError;
        }

        this.errorsReceived.next(serverError);
    }

    handleCustomError(error: string){
        let e = new ServerError();
        e.message = error;
        this.errorsReceived.next(e);
        return throwError(e);
    }


    handleNetworkError(httpErrorResponse: HttpErrorResponse) {

        let e = new ServerError();

        if (!httpErrorResponse.status) {
            e.message = "Network error";
            return throwError(e);
        }

        else if (httpErrorResponse.error.ModelState) {

            let modelStateReadable = {};

            forIn(httpErrorResponse.error.ModelState, (v, k) => {

                if (v[0].indexOf('too large or small for an Int32') >= 0) {
                    modelStateReadable[k] = ['Max limit has reached'];
                }

                else if ([
                    'Unexpected token when deserializing object',
                    'Unexpected end when deserializing object'
                ].some(errorUnreadable => { return v[0].indexOf(errorUnreadable) >= 0 }))
                    return false;

                else modelStateReadable[k] = v;
            });

            e.modelState = modelStateReadable;
        }

        else if (typeof httpErrorResponse.error === 'string' && httpErrorResponse.error.indexOf('controller') > 0) {
            e.redirectToLogin = true;
            e.message = 'Your session expired';
            return throwError(e);
        }

        else if (httpErrorResponse.error.size && httpErrorResponse.error.type && httpErrorResponse.error.type == "application/json") {

            return throwError(httpErrorResponse.error as Blob);
        }
        else if (httpErrorResponse.error.error_description) {
            e.message = httpErrorResponse.error.error_description;
        } else if (typeof httpErrorResponse.error === 'string' && httpErrorResponse.name == "HttpErrorResponse") {
            e.message = httpErrorResponse.error;
        }
        else {
            e.message = httpErrorResponse.error.Message;
            e.details = httpErrorResponse.error.ExceptionMessage;
        }
        return throwError(e);
    }

    private alertSubject = new Subject<string>();

    getAlerts(): Observable<string> {
        return this.alertSubject.asObservable();
      }

    error(message: string) {
        this.alertSubject.next(message);
      }

}
