<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
    <div id="entity-management" class="row h-100">
        <div class="col-md-6">
            <div class="pl-0 pr-1 col-md-12 pb-3">
                <div class="pl-0 col-md-6 text-left">
                    <button class="pr-2" (click)="viewFilterOptionsModal()">
                        <img src="assets/Content/Images/Icons/filter_icon.png" />
                        <span>Filter Audit Logs</span>
                    </button>
                </div>
                <div *ngIf="totalCount" id="TotalCount" class="pr-0 col-md-6 text-right">
                    <span style="vertical-align:text-bottom"> Total Rows: </span><span class="mt-3 badge">{{ totalCount
                        }}</span>
                </div>
            </div>
            <div style="clear: both;"></div>
            <div id="entity-list" [style.height]="formHeight">
                <dx-data-grid #dataGrid class="no-data-min-height" [dataSource]="customDataSource" noDataText=""
                    (onContentReady)="onContentReadyGrid($event)" (onInitialized)="onInitialized($event)"
                    (onRowClick)="onRowClick($event)" [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="Audit Log Id" dataField="Id" dataType="number"></dxi-column>
                    <dxi-column alignment='center' caption="Log Date" dataField="LogDate" dataType="date"
                        format="dd/MM/yyyy HH:mm"></dxi-column>
                        <dxi-column alignment='center' caption="Log Type" dataField="LogType"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='center' caption="Table Name" dataField="TableName"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='center' caption="User" dataField="UserName" dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="none"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-6 pr-0 pl-0">
            <div id="entity-errors" class="row">
                <div class="col">
                    <facnm-errors></facnm-errors>
                </div>
            </div>
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div class="top-buffer">

                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="GICollapse" class="childs mt-2" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-md-4">
                                            <label class="col-form-label"> Audit Log Id</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="entityDataId"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.Id" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-4">
                                            <label class="col-form-label"> Log Date</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="LogDate"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.LogDate" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-4 pr-0">
                                            <label class="col-form-label"> Library Version </label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="LibraryVersion"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.LibraryVersion" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-md-4">
                                            <label class="col-form-label"> User Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="UserName"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.UserName" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-4">
                                            <label class="col-form-label"> Table Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="TableName"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.TableName" readonly />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="LVCollapse = !LVCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(LVCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Log Values
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="LVCollapse" class="childs" [ngClass]="{'showChild': !LVCollapse}">

                                <div class="col-lg-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Column Name</th>
                                                <th>Old Value</th>
                                                <th>New Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let key of objectKeys"
                                                [class]="{success: entityData.OldValue[key] !=  entityData.NewValue[key]  }">
                                                <td>{{key}}</td>
                                                <td>{{entityData.OldValue[key]}}</td>
                                                <td>{{entityData.NewValue[key]}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div style="clear:both;"></div>

                        </div>
                    </div>
                </form>
            </div>

            <div class="d-flex justify-content-center mt-4 pt-2" *ngIf="entityData && entityData.key">
                <button (click)="cancelDetails()" id="action-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block cancel-icon">Cancel</span>
                </button>
            </div>

        </div>
    </div>
</div>

<dx-popup title="Filter Options" [(visible)]="showFilterModal" [dragEnabled]="false" maxHeight="420" maxWidth="600">
    <div class="container-fluid filter-options-modal">

        <div class="row form-group">
            <div class="col-md-5 control-label">
                <label class="col-form-label">Table Name</label>
            </div>
            <div class="col-md-7">
                <pdi-dropdown *ngIf="entityList" [full]="true" name="TableName"
                    [(ngModel)]="filterOptions.TableName">
                    <pdi-dropdown-option key=""></pdi-dropdown-option>
                    <pdi-dropdown-option *ngFor="let entity of entityList" [key]="entity.Name"> {{entity.DisplayName}}</pdi-dropdown-option>
                </pdi-dropdown>
                <div class="control-look"></div>
            </div>
        </div>

        <div class="row form-group">
            <div class=" col-md-5 control-label">
                <label class="col-form-label">Log Type</label>
            </div>
            <div class="col-md-7">
                <pdi-dropdown [full]="true" [(ngModel)]="filterOptions.LogType" name="LogType">
                    <pdi-dropdown-option key=""></pdi-dropdown-option>
                    <pdi-dropdown-option key="insert">Insert</pdi-dropdown-option>
                    <pdi-dropdown-option key="update">Update</pdi-dropdown-option>
                    <pdi-dropdown-option key="delete">Delete</pdi-dropdown-option>
                </pdi-dropdown>
                <div class="control-look"></div>
            </div>
        </div>

        <div class="row form-group">
            <div class=" col-md-5 control-label">
                <label class="col-form-label">User Name</label>
            </div>
            <div class="col-md-7">
                <input type="text" id="Username" name="Username" class="form-custom-control control-look-input"
                    [(ngModel)]="filterOptions.Username" />
                <div class="control-look"></div>
            </div>
        </div>

        <div class="row form-group">
            <div class=" col-md-5 control-label">
                <label class="col-form-label">Date From</label>
            </div>
            <div class="col-md-7">
                <pdi-date-picker label="" [(ngModel)]="filterOptions.DateFrom" [format]="dateFormat"
                 required name="DateFrom"></pdi-date-picker>
                 <div class="error-message" *ngIf="filterApplied && !filterOptions.DateFrom && filterOptions.DateTo">
                    <small class="error"> Date From is required </small>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class=" col-md-5 control-label">
                <label class="col-form-label">Date To</label>
            </div>
            <div class="col-md-7">
                <pdi-date-picker label="" [(ngModel)]="filterOptions.DateTo" [format]="dateFormat"
                 name="DateTo"></pdi-date-picker>
            </div>
        </div>

        <div class="text-center fixed-bottom pb-5">
            <div class="row">
                <div class=" col-md-12 text-center">
                    <button id="action-reset" class="mr-5" type="button" (click)="resetfilterOptions()">
                        <img src="assets/Content/Images/Icons/reset.png" class="other-button-image" />
                        <span class="other-button hidden-md hidden-sm hidden-xs">Reset</span>
                    </button>
                    <button id="action-filter" class="ml-5" type="button" (click)="applyFilter()">
                        <img src="assets/Content/Images/Icons/save.png" class="other-button-image" />
                        <span class="other-button hidden-md hidden-sm hidden-xs">Filter</span>
                    </button>
                </div>
            </div>
        </div>

    </div>
</dx-popup>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>