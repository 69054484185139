import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxDataGridModule, DxToastModule, DxLoadPanelModule, DxPopupModule, DxTooltipModule, DxListModule, DxTabsModule, DxScrollViewModule, DxButtonModule, DxCheckBoxModule, DxPopoverModule, DxFileUploaderModule, DxTagBoxModule } from 'devextreme-angular';

import { AutosizeModule } from 'ngx-autosize';

import { FormsModule } from '@angular/forms';
import { AssessmentComponent } from './assessment/assessment.component';
import { TooltipConditionsInfo_Assessment } from './assessment/tooltip-conditions-info';
import { ManageImagesComponent } from './manage-images/manage-images.component';
import { SharedModule } from '@app/_shared/shared.module';
import { DriverManagementComponent } from './driver-management/driver-management.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule,MAT_DATE_LOCALE } from '@angular/material/core';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import { AdditionalFieldsComponent } from './additional-fields/additional-fields.component';
import { ConfigurationGroupComponent } from './configuration-group/configuration-group.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { NgxSmartModalModule,NgxSmartModalService } from 'ngx-smart-modal';
import { TimeLogComponent } from './time-log/time-log.component';
import { TranspacConfigurationComponent } from './transpac-configuration/transpac-configuration.component';
import { ConfigFieldComponent } from './transpac-configuration/config-field/config-field.component';
import { ConfigurationModalComponent } from './transpac-configuration/configuration-modal/configuration-modal.component';
import { ModalConfigFieldComponent } from './transpac-configuration/modal-config-field/modal-config-field.component';
import { ExportConfigurationComponent } from './export-configuration/export-configuration.component';
import { ImportConfigurationComponent } from './import-configuration/import-configuration.component';
import { PdiDatePickerModule,PdiRadioModule} from '@pdi/platform-angular-template';

@NgModule({
    
    declarations: [
        AssessmentComponent,
        TooltipConditionsInfo_Assessment,
        ManageImagesComponent,
        DriverManagementComponent,
        AdditionalFieldsComponent,
        ConfigurationGroupComponent,
        VehicleComponent,
        TimeLogComponent,
        TranspacConfigurationComponent,
        ConfigFieldComponent,
        ConfigurationModalComponent,
        ModalConfigFieldComponent,
        ExportConfigurationComponent,
        ImportConfigurationComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'accent' }
        },NgxSmartModalService
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        DxDataGridModule,
        DxLoadPanelModule,
        DxTooltipModule,
        DxToastModule,
        DxPopupModule,
        DxListModule,
        DxTabsModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxPopoverModule,
        DxFileUploaderModule,
        AutosizeModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        DxTagBoxModule,
        NgxSmartModalModule.forRoot(),
        PdiDatePickerModule,
        PdiRadioModule
    ],
    exports: [MatRadioModule]
})
export class FleetModule { }
