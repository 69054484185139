

export interface Company {

    Id: number;
    name: string;
}



export class Device {

    Id: number;
    Name: string;
    IMEI: string;

    Vehicle: Vehicle;
}


export class Vehicle {

    Id: number;
    VehicleNumber: string;
    LicensePlate: string;
}



export class ServerError {
    modelState: any;
    message: string;
    details: string;
    redirectToLogin: boolean;
}

export class ScreenNotification {
    title: string;
    message: string;
    type?: string;
}
