import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from '@app/app.service';
import { LoginSSOService } from './login-sso.service';
import { PdiLoaderService } from '@pdi/platform-angular-template';

@Component({
  selector: 'facnm-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSSOComponent implements OnInit {
  entityName = "Login with SSO";
  error: string;
  loginSSOForm = new FormGroup({
    company: new FormControl(null, (env) => !env.value ? { customError: true } : null)
  });

  constructor(private app$: AppService, 
    private loginSSOService$: LoginSSOService,
    private el: ElementRef,
    private loadingService: PdiLoaderService
    ) { }
  get company() { return this.loginSSOForm.get('company'); }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.app$.notifyScreenReady();
    this.loginSSOService$.InitializeAuthService();
  }

  ngAfterViewInit(){
    this.el.nativeElement.querySelectorAll('input')[0].setAttribute('id', 'company');
  }
  
  onSubmit(loginSSOForm) {
    this.loginSSOForm.markAllAsTouched();
    if (loginSSOForm.valid) {
      this.loadingService.openGlobalLoader();
      let ssotoken = "";
      this.app$.notifyScreenBusy();
      this.loginSSOService$.authService.authenticate().then(
        (res: any) => {
          ssotoken = res.accessToken;
          if(ssotoken){
            this.loginSSOService$.authentincateSSO(loginSSOForm.value, ssotoken).subscribe(
              (res: any) => {
                // Redirecting to home page after authenticating the user from DB.
                sessionStorage.setItem("Bearer", res.Token);
                this.app$.updateUserInfo({ isAuthenticated: true });
                this.app$.getUserDetails();
                this.app$.getAPIVersion();
                this.app$.redirectTo('home');
                this.loadingService.closeGlobalLoader();
              },
              (err) => {
                this.app$.notifyScreenReady();
                this.error = err.message;
                this.loadingService.closeGlobalLoader();
              }
            );
          } else{
            this.error = 'Invalid Token';
          }
        },
        (err) => {
          this.app$.notifyScreenReady();
          this.error = err.message;
        }
      );
    }
  }

}
