<div [class]="{'input-group':configType!=1 && configPageType == 1}" id="config">
    <div>
        <div *ngIf="configurationValue.ValueOptionsList.length > 0">
            <div [class]="{'disabled-tag':disabled && showGroupCount}" *ngIf="configurationValue.Type == 'flags'">
                <dx-tag-box style="margin-top: 5px;" name="UserRoleIds" [disabled]="disabled"
                    [(ngModel)]="configurationValue[flagsValueKey]" [items]="configurationValue.ValueOptionsList"
                    valueExpr="Id" [value]="configurationValue[flagsValueKey]" displayExpr="Name"
                    [hideSelectedItems]="true" [searchEnabled]="true" (onValueChanged)="isChanged()">
                </dx-tag-box>
            </div>
            <pdi-dropdown [full]="true" [isDisabled]="disabled" id="{{inputAccessKey}}"
                [(ngModel)]="configurationValue[accessKey]" *ngIf="configurationValue.Type == 'int' "
                (valueSelected)="isChanged()">
                <pdi-dropdown-option *ngFor="let optionvalue of configurationValue.ValueOptionsList"
                    [key]="optionvalue.IdString">
                    {{optionvalue.Name}}</pdi-dropdown-option>
            </pdi-dropdown>
        </div>
        <div *ngIf="configurationValue.ValueOptionsList.length == 0">
            <input type="checkbox" [disabled]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control checkbox-inline" [(ngModel)]="configurationValue[booleanValueKey]"
                (change)="setBoolValue()" *ngIf="configurationValue.Type == 'bool' " />

            <input type="text" [readonly]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control" [(ngModel)]="configurationValue[accessKey]" (input)="isChanged()"
                *ngIf="configurationValue.Type == 'byte' " />

            <input type="color" [readonly]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control" [(ngModel)]="configurationValue[accessKey]" (input)="isChanged()"
                *ngIf="configurationValue.Type == 'color' " />

            <input type="text" [readonly]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control" [(ngModel)]="configurationValue[accessKey]" (input)="isChanged()"
                *ngIf="configurationValue.Type == 'dastr' " />

            <input type="text" [readonly]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control"
                [ngClass]="{'error-alert': !disabled && configurationValue.IsSubmitted && configurationValue.invalid}"
                [(ngModel)]="configurationValue[accessKey]" *ngIf="configurationValue.Type == 'float'"
                (input)="checkPattern()" />

            <input type="text" [readonly]="disabled" id="{{inputAccessKey}}" name="{{inputAccessKey}}"
                class="form-control"
                [ngClass]="{'error-alert':!disabled && configurationValue.IsSubmitted && configurationValue.invalid}"
                [(ngModel)]="configurationValue[accessKey]" (input)="checkPattern()"
                *ngIf="configurationValue.Type == 'int' " />

            <input type="text" [readonly]="disabled" id="{{inputAccessKey}}" class="form-control"
                [(ngModel)]="configurationValue[accessKey]" (input)="isChanged()"
                *ngIf="configurationValue.Type == 'str' " />

            <div *ngIf="configurationValue.Type == 'xml' ">
                <button type="button" id="{{inputAccessKey}}" *ngIf="configurationValue.Type == 'xml' "
                    (click)="viewEditComplexConfigValue()" class="form-control edit-button-green">
                    <span *ngIf="disabled">View</span>
                    <span *ngIf="!disabled">View/Edit</span>
                </button>
            </div>
        </div>
    </div>

    <span *ngIf="showGroupCount" id="TP-{{inputAccessKey}}" class="input-group-addon">
        <span class="badge">{{ configurationValue.GroupCount }}</span>
    </span>
    <dx-tooltip *ngIf="configurationValue.GroupCount" target="#TP-{{inputAccessKey}}" showEvent="dxhoverstart"
        hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">
            {{configurationValue.GroupName}}
        </div>
    </dx-tooltip>

    <span *ngIf="showDelete" class="input-group-btn">
        <button class="pdi-delete-button" type="button" [disabled]="disableEnableDeleteButton()"
            [class]="{buttonImageDisabled: disableEnableDeleteButton()}" (click)="preDeleteConfigurationValue()">
            <span class="pdi-icon-Delete"></span>
        </button>
    </span>
</div>
<span class="error-message" *ngIf="!disabled && configurationValue.invalid">
    <small class="error" *ngIf="configurationValue.Type == 'float'">
        {{ configurationValue.Name }} is not a valid decimal number
    </small>
    <small class="error" *ngIf="configurationValue.Type == 'int'">
        {{ configurationValue.Name }} is not a valid integer number
    </small>
</span>
<facnm-configuration-modal [readonly]="disabled" *ngIf="configurationValue.Type == 'xml' && showConfigModal"
    [configurationValue]="configurationValue" (popupClose)="popupClose()">
</facnm-configuration-modal>