<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
    <div id="vehicle" class="row h-100">
        <div class="col pr-0" style="overflow-y: auto;">
            <div id="filterOptions" style="margin-right: 15px;margin-top: 4px;">
                <div class="heading-container" (click)="isFilterOptionsOpen = !isFilterOptionsOpen">
                    <div class="heading-image">
                        <img src="assets/Content/Images/Icons/filter_icon.png" />
                    </div>
                    <div class="mt-1">
                        <span class="heading-text">Filter Options</span>
                        <div class="pull-right">
                            <i style="margin-right:5px;" class="pull-right glyphicon"
                                [ngClass]="{'glyphicon-chevron-up': isFilterOptionsOpen, 'glyphicon-chevron-down': !isFilterOptionsOpen}"></i>
                        </div>
                    </div>
                </div>
                <div id="FilterOptionsForms" *ngIf="!isFilterOptionsOpen">
                    <form #FilterOptionsForms="ngForm"  (ngSubmit)="filterVehicles()" novalidate>
                        <div class="container scrollable">
                            <div class="content filter-section">
                                <div class="col-lg-offset-2 col-md-offset-2 col-lg-8 col-md-9 col-sm-12 col-xs-12">
                                    <div class="row form-group"></div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Vehicle Type
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown class="input-md" [full]="true"
                                                [(ngModel)]="filterOptions.VehicleType" name="vehicleType">
                                                <pdi-dropdown-option *ngFor="let vehicleType of this.vehicleTypes"
                                                    [key]="vehicleType.Id">
                                                    {{vehicleType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Build Type
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown class="input-md" [full]="true"
                                                [(ngModel)]="filterOptions.BuildType" name="BuildType">
                                                <pdi-dropdown-option *ngFor="let vehicleBuildType of this.vehicleBuildTypesAll"
                                                    [key]="vehicleBuildType.Id">
                                                    {{vehicleBuildType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Vehicle Class
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown class="input-md" [full]="true"
                                                [(ngModel)]="filterOptions.ClassId" name="ClassId">
                                                <pdi-dropdown-option *ngFor="let vehicleClass of this.vehicleClasses"
                                                    [key]="vehicleClass.Id">
                                                    {{vehicleClass.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Carrier
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown placeholder="None" class="input-md" [full]="true"
                                                [(ngModel)]="filterOptions.CarrierId" name="CarrierId">
                                                <pdi-dropdown-option *ngFor="let company of this.companyList" [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Device Type
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.DeviceTypeId" name="DeviceTypeId">
                                                <pdi-dropdown-option *ngFor="let deviceType of this.deviceTypeList"
                                                    [key]="deviceType.Id">
                                                    {{deviceType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Device Config
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.DeviceConfigId" name="DeviceConfigId">
                                                <pdi-dropdown-option *ngFor="let deviceConfig of this.deviceConfigList"
                                                    [key]="deviceConfig.Id">
                                                    {{deviceConfig.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Box Config
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.BoxConfigId" name="BoxConfigId">
                                                <pdi-dropdown-option *ngFor="let boxConfig of this.boxConfigList"
                                                    [key]="boxConfig.Id">
                                                    {{boxConfig.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Use ignition for Break calc
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <input type="checkbox" ng-true-value="true" class="input-sm"
                                                ng-false-value="false" ng-model="filterOptions.UseIgnition" />
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Data Area
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">                                            
                                            <dx-select-box name="dataAreaId"
                                                [(ngModel)]="filterOptions.DataAreaId" [dataSource]="dataAreaList"
                                                displayExpr="Name" valueExpr="Id" searchExpr="Name"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Timezone
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.TimezoneName" name="TimezoneName">
                                                <pdi-dropdown-option *ngFor="let timezoneName of this.timezoneNameList"
                                                    [key]="timezoneName.Id">
                                                    {{timezoneName.DisplayName}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            State
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.State" name="State">
                                                <pdi-dropdown-option *ngFor="let deviceState of this.deviceStateList"
                                                    [key]="deviceState.Id">
                                                    {{deviceState.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Vehicle color
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    colorpicker colorpicker-position="bottom" name="VehicleColorHash"
                                                    class="w-100 bg-transparent control-look-input" type="color"
                                                    [(ngModel)]="filterOptions.VehicleColorHash" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Tank capacity
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input"
                                                    name="TankCapacity" type="number"
                                                    [(ngModel)]="filterOptions.TankCapacity" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Compartments
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="filterOptions.CompartmentSetId" name="CompartmentSetId">
                                                <pdi-dropdown-option *ngFor="let compartmentSet of this.compartmentSetList"
                                                    [key]="compartmentSet.Id">
                                                    {{compartmentSet.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Motorway speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    name="motorwaySpeedLimit" [min]="min" [max]="max" type="number"
                                                    [(ngModel)]="filterOptions.MotorSpeedLimit" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Country speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    name="countrySpeedLimit" [min]="min" [max]="max" type="number"
                                                    [(ngModel)]="filterOptions.countrySpeedLimit" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Residential speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    name="countrySpeedLimit" [min]="min" [max]="max" type="number"
                                                    [(ngModel)]="filterOptions.countrySpeedLimit" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home latitude
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    name="homeLatitude" maxlength="50" type="number"
                                                    [(ngModel)]="filterOptions.HomeLatitude" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home longitude
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div style="padding-left:0px;">
                                                <input class="form-custom-control control-look-input input-sm"
                                                    name="homeLongitude" maxlength="50" type="number"
                                                    [(ngModel)]="filterOptions.HomeLongitude" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home load point
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <dx-select-box name="HomeLoadlocationId"
                                                [(ngModel)]="filterOptions.HomeLoadlocationId" [dataSource]="loadPointList"
                                                valueExpr="Id" [searchExpr]="['Name1','Address','City','Postcode']"
                                                [displayExpr]="loadPointDisplayExpr"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder=""
                                                itemTemplate="item">
                                                <div *dxTemplate="let data of 'item'">
                                                    <div>{{data.Name1}}</div>
                                                    <div>{{data.Address}},{{data.City}},{{data.Postcode}}</div>
                                                </div>
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: -40px;margin-bottom: 88px;">
                                <div class="form-group">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button id="action-reset" type="button" (click)="resetfilterOptions()">
                                                <img src="assets/Content/Images/Icons/reset.png"
                                                    class="other-button-image" />
                                                <span class="other-button hidden-md hidden-sm hidden-xs">Reset</span>
                                            </button>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button id="action-filter" type="submit" (click)="filterVehicles()">
                                                <img src="assets/Content/Images/Icons/save.png"
                                                    class="other-button-image" />
                                                <span class="other-button hidden-md hidden-sm hidden-xs">Filter</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div id="entity-list" style="margin-right: 15px; height: calc(100vh - 200px);">
                <dx-data-grid #dataGrid [dataSource]="vehicleData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onContentReady)="onContentReadyAssigned()" (onCellPrepared)="onCellPrepared($event)"
                    (onSelectionChanged)="onSelectionChanged($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="true" [columnAutoWidth]="true" [columns]="vehicleColumns">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode" mode="multiple">
                    </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="this.vehicle && this.vehicle.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class="top-buffer alert alert-message text-center" *ngIf="multipleSelected.count > 1">
                        <p>All changes to the settings below will be automatically applied to the <strong>{{
                                multipleSelected.count }} selected vehicles</strong>. </p>
                    </div>
                    <div #vehicleDetailsDiv>
                        <div id="entity-errors">
                            <div>
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Vehicle settings
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-radio group="truckOrTrailer" [(ngModel)]="isTruck" value="true" name="truck"
                                            [label]="'Truck'" (change)="TruckTrailerChange(true)"></pdi-radio>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <pdi-radio group="truckOrTrailer" [(ngModel)]="isTruck" value="false" name="trailer"
                                            [label]="'Trailer'" (change)="TruckTrailerChange(false)"></pdi-radio>
                                        </div>
                                        <div *ngIf="isTruck == 'false'" class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <label>
                                                <input type="checkbox" name="gpsBox" [(ngModel)]="isTrailer"
                                                    value="false"> GPS
                                                Box
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Vehicle No.
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.VehicleNumber" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('vehicleNo')}" type="text"
                                                class="form-custom-control control-look-input" name="vehicleNo" required
                                                maxlength="10" [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('vehicleNo')">
                                                <small class="error" *ngIf="checkError('vehicleNo','required')">
                                                    Vehicle No is required
                                                </small>
                                                <small class="error" *ngIf="checkError('vehicleNo','maxlength')">
                                                    vehicle No cannot be longer than 10 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Vehicle Description
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.Description" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('Description')}" type="text"
                                                class="form-custom-control control-look-input" name="Description"
                                                required maxlength="100" [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Description')">
                                                <small class="error" *ngIf="checkError('Description','required')">
                                                    Vehicle Description is required
                                                </small>
                                                <small class="error" *ngIf="checkError('Description','maxlength')">
                                                    Vehicle Description cannot be more than 100 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ColorHash" class="col-form-label">Color</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input name="ColorHash" type="color"
                                                class="w-100 bg-transparent control-look-input"
                                                [(ngModel)]="this.vehicle.value.ColorHash" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <a pdiLink pdiLinkLabel="Vehicle Type" (click)="openModal()"></a>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" *ngIf="!vehicleTypeLoading">
                                            <pdi-dropdown [full]="true"
                                                [ngClass]="{'modelstate-invalid': hasError('vehicleType')}"
                                                [(ngModel)]="this.vehicle.value.TypeId" name="vehicleType" required>
                                                <pdi-dropdown-option *ngFor="let vehicleType of this.vehicleTypes"
                                                    [key]="vehicleType.Id">
                                                    {{vehicleType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('vehicleType')">
                                                <small class="error" *ngIf="checkError('vehicleType','required')">
                                                    Vehicle Type is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Build Type
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.BuildType" name="BuildType">
                                                <pdi-dropdown-option *ngFor="let vehicleBuildType of this.vehicleBuildTypes"
                                                    [key]="vehicleBuildType.Id">
                                                    {{vehicleBuildType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Vehicle Class
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.ClassId" name="ClassId">
                                                <pdi-dropdown-option *ngFor="let vehicleClass of this.vehicleClasses"
                                                    [key]="vehicleClass.Id">
                                                    {{vehicleClass.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            License Plate
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.LicensePlate" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('licensePlate')}" type="text"
                                                class="form-custom-control control-look-input" name="licensePlate"
                                                required maxlength="12" [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('licensePlate')">
                                                <small class="error" *ngIf="checkError('licensePlate','required')">
                                                    License Plate is required
                                                </small>
                                                <small class="error" *ngIf="checkError('licensePlate','maxlength')">
                                                    License Plate cannot be more than 12 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Carrier
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true" id="carrierId"
                                            [ngClass]="{'modelstate-invalid': hasError('CarrierId')}"
                                                [(ngModel)]="this.vehicle.value.CarrierId" name="CarrierId" required>
                                                <pdi-dropdown-option *ngFor="let company of this.companyList" [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('CarrierId')">
                                                <small class="error" *ngIf="checkError('CarrierId','required')">
                                                    Please select a Carrier
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="isTruck == 'false'">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Trailer State
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.State" id="trailerStateId" name="TrailerState" required>
                                                <pdi-dropdown-option *ngFor="let trailerState of this.trailerStatesList"
                                                    [key]="trailerState.Id">
                                                    {{trailerState.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('TrailerState')">
                                                <small class="error" *ngIf="checkError('TrailerState','required')">
                                                    Please select a Trailer
                                                </small>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div *ngIf="isTruck == 'true' || isTrailer" class="collapsible-header-container"
                                (click)="DSCollapse = !DSCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(DSCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Device settings
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isTruck == 'true' || isTrailer" id="DSCollapse" class="childs"
                                [ngClass]="{'showChild': !DSCollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Device ID
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.deviceDetailsVM.Id" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('DeviceId')}" type="text"
                                                class="form-custom-control control-look-input" name="DeviceId" required
                                                maxlength="10" [disabled]="!SetIDManually">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceId')">
                                                <small class="error" *ngIf="checkError('DeviceId','required')">
                                                    Device ID is required
                                                </small>
                                            </div>
                                            <div *ngIf="isAddForm == 1">
                                                <input type="checkbox" [(ngModel)]="SetIDManually" name="manualId" />
                                                Set ID Manually
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Device Name
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.deviceDetailsVM.Name" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('DeviceName')}" type="text"
                                                class="form-custom-control control-look-input" name="DeviceName"
                                                required maxlength="100" [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceName')">
                                                <small class="error" *ngIf="checkError('DeviceName','maxlength')">
                                                    Device Name cannot be more than 100 characters
                                                </small>
                                                <small class="error" *ngIf="checkError('DeviceName','required')">
                                                    Device Name is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Device Type
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-tag-box name="DeviceTypeId"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.Type"
                                                [items]="this.deviceTypeList" valueExpr="Id"
                                                [value]="this.vehicle.value.deviceDetailsVM.Type" displayExpr="Name"
                                                [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Device Config
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-tag-box name="DeviceTypeId"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.Config"
                                                [items]="this.deviceConfigList" valueExpr="Id"
                                                [value]="this.vehicle.value.deviceDetailsVM.Config" displayExpr="Name"
                                                [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            IMEI
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.deviceDetailsVM.IMEI" placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('DeviceIMEI')}" type="text"
                                                class="form-custom-control control-look-input" name="DeviceIMEI"
                                                maxlength="255" pattern="[a-zA-Z0-9]*$"
                                                [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceIMEI')">
                                                <small class="error" *ngIf="checkError('DeviceIMEI','maxlength')">
                                                    IMEI cannot be more than 255 characters
                                                </small>
                                                <small class="error" *ngIf="checkError('DeviceIMEI','pattern')">
                                                    IMEI must contain only alpha-numeric characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            GPS box IMEI
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.deviceDetailsVM.GPSboxIMEI"
                                                placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('DeviceGPSboxIMEI')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="DeviceGPSboxIMEI" maxlength="15"
                                                [disabled]="multipleSelected.count > 1">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceGPSboxIMEI')">
                                                <small class="error" *ngIf="checkError('DeviceGPSboxIMEI','maxlength')">
                                                    GPS box IMEI cannot be longer than 15 digits
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Phone number
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.vehicle.value.deviceDetailsVM.PhoneNumber"
                                                placeholder=""
                                                [ngClass]="{'modelstate-invalid': hasError('DevicePhoneNumber')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="DevicePhoneNumber" maxlength="25" pattern="^[0-9]{1,25}$">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DevicePhoneNumber')">
                                                <small class="error"
                                                    *ngIf="checkError('DevicePhoneNumber','maxlength')">
                                                    Phone number cannot be longer than 25 digits
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Box Config
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.BoxConfigId"
                                                name="BoxConfigId">
                                                <pdi-dropdown-option *ngFor="let boxConfig of this.boxConfigList"
                                                    [key]="boxConfig.Id">
                                                    {{boxConfig.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 control-label">
                                            <input type="checkbox"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.UseIgnitionForBreakCalc"
                                                name="UseIgnitionForBreakCalc" />
                                            Use ignition for Break calc
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Company
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true" id="companyId"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.CompanyId"
                                                name="CompanyId">
                                                <pdi-dropdown-option *ngFor="let company of this.nonprintcompanyList"
                                                    [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            State
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true" id="stateId"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.State"
                                                [ngClass]="{'modelstate-invalid': hasError('DeviceState')}"
                                                name="DeviceState" required>
                                                <pdi-dropdown-option *ngFor="let deviceState of this.deviceStateList"
                                                    [key]="deviceState.Id">
                                                    {{deviceState.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceState')">
                                                <small class="error" *ngIf="checkError('DeviceState','required')">
                                                    State is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Data Area
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <dx-select-box required name="DeviceDataAreaId"
                                            [ngClass]="{'modelstate-invalid': hasError('DeviceDataAreaId')}"
                                                [(ngModel)]="vehicle.value.deviceDetailsVM.DataAreaId" [dataSource]="dataAreaList"
                                                displayExpr="Name" valueExpr="Id" searchExpr="Name"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('DeviceDataAreaId')">
                                                <small class="error" *ngIf="checkError('DeviceDataAreaId','required')">
                                                    Data Area is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Timezone name
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.TimezoneName"
                                                name="TimezoneName">
                                                <pdi-dropdown-option key =""></pdi-dropdown-option>
                                                <pdi-dropdown-option *ngFor="let timezoneName of this.timezoneNameList"
                                                    [key]="timezoneName.Id">
                                                    {{timezoneName.DisplayName}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Timezone value
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.TimezoneValue"
                                                name="TimezoneValue" [isDisabled]="this.vehicle.value.deviceDetailsVM.TimezoneName">
                                                <pdi-dropdown-option key=""></pdi-dropdown-option>
                                                <pdi-dropdown-option *ngFor="let timezoneValue of this.timezoneValueList"
                                                    [key]="timezoneValue.Value">
                                                    {{timezoneValue.Text}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Receipt range
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.initialReceiptRange.CompanyId" name="Receipt"
                                                [isDisabled]="isAddForm == 1"
                                                (valueSelected)="UpdateReceiptRange(this.initialReceiptRange.CompanyId)">
                                                <pdi-dropdown-option
                                                    *ngFor="let RangeVM of this.vehicle.value.deviceDetailsVM.RangeVMs"
                                                    [key]="RangeVM.CompanyId">
                                                    {{RangeVM.CompanyName}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Company Name
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.SelectedReceiptRange.CompanyName" placeholder=""
                                                type="text" class="form-custom-control control-look-input"
                                                name="CompanyName" readonly>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Start
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input [(ngModel)]="this.SelectedReceiptRange.RangeStart" placeholder=""
                                                type="text" class="form-custom-control control-look-input"
                                                name="RangeStart" [disabled]="isAddForm == 1">
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label right">1. From
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <input type="text" name="Range1From"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="SelectedReceiptRange.Range1From"
                                                    [disabled]="isAddForm == 1">
                                                <div class="control-look"></div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">To</div>
                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <input type="text" name="Range1To"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="SelectedReceiptRange.Range1To"
                                                    [disabled]="isAddForm == 1" />
                                                <div class="control-look"></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label right">2. From
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <input type="text" name="Range2From"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="SelectedReceiptRange.Range2From"
                                                    [disabled]="isAddForm == 1">
                                                <div class="control-look"></div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">To</div>
                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <input type="text" name="Range2To"
                                                    class="form-custom-control control-look-input"
                                                    [(ngModel)]="SelectedReceiptRange.Range2To"
                                                    [disabled]="isAddForm == 1" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="TDCollapse = !TDCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(TDCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Tank details
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="TDCollapse" class="childs" [ngClass]="{'showChild': !TDCollapse}">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            Tank Capacity
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="number" id="Tank" name="Tank"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="this.vehicle.value.Tank"
                                                (change)="this.vehicle.value.CompartmentSet = null"
                                                [disabled]="this.vehicle.value.CompartmentSet" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <a pdiLink pdiLinkLabel="Compartments" (click)="openCompartmentSetModal()"></a>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true" placeholder="Select Option"
                                                [(ngModel)]="this.vehicle.value.CompartmentSet" name="CompartmentSet"
                                                (valueSelected)="this.vehicle.value.Tank = null"
                                                [isDisabled]="this.vehicle.value.Tank">
                                                <pdi-dropdown-option *ngFor="let default of this.defaultCompartment"
                                                    [key]="default.Id">
                                                    {{default.Name}}
                                                </pdi-dropdown-option>
                                                <pdi-dropdown-option *ngFor="let compartmentSet of this.compartmentSetList"
                                                    [key]="compartmentSet.Id">
                                                    {{compartmentSet.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div *ngIf="isTruck == 'true'" class="collapsible-header-container"
                                (click)="SLCollapse = !SLCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(SLCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Speed limits
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isTruck == 'true'" id="SLCollapse" class="childs"
                                [ngClass]="{'showChild': !SLCollapse}">
                                <div class="col-lg-offset-3 col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Motorway speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <input type="number" name="MotorwaySpeedLimit"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('MotorwaySpeedLimit')}"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.MotorwaySpeedLimit"
                                                min="1" max="999" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('MotorwaySpeedLimit')">
                                                <small class="error"
                                                    *ngIf="checkError('MotorwaySpeedLimit','maxlength')">
                                                    Motorway speed over limit cannot be more than 999
                                                </small>
                                                <small class="error"
                                                    *ngIf="checkError('MotorwaySpeedLimit','required')">
                                                    Motorway speed over limit cannot be less than 1
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Country speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                                            <input class="form-custom-control control-look-input input-sm"
                                                name="countrySpeedLimit" [min]="min" [max]="max" type="number"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.countrySpeedLimit" />
                                            <div class="control-look"></div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Residential speed over limit
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <input class="form-custom-control control-look-input input-sm"
                                                name="countrySpeedLimit" [min]="min" [max]="max" type="number"
                                                [(ngModel)]="this.vehicle.value.deviceDetailsVM.ResidentialSpeedLimit" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="HLCollapse = !HLCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(HLCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Home location
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="HLCollapse" class="childs" [ngClass]="{'showChild': !HLCollapse}">
                                <div class="col-lg-offset-3 col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home latitude
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                                            <input class="form-custom-control control-look-input" name="homeLatitude"
                                                maxlength="50" type="number"
                                                [(ngModel)]="this.vehicle.value.HomeLatitude" />
                                            <div class="control-look"></div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home longitude
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                                            <input class="form-custom-control control-look-input" name="homeLongitude"
                                                maxlength="50" type="number"
                                                [(ngModel)]="this.vehicle.value.HomeLongitude" />
                                            <div class="control-look"></div>

                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">
                                            Home load point
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <dx-select-box name="HomeLoadlocationId"
                                                [(ngModel)]="this.vehicle.value.HomeLoadlocationId" [dataSource]="loadPointList"
                                                valueExpr="Id" [searchExpr]="['Name1','Address','City','Postcode']"
                                                [displayExpr]="loadPointDisplayExpr"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder=""
                                                itemTemplate="item">
                                                <div *dxTemplate="let data of 'item'">
                                                    <div>{{data.Name1}}</div>
                                                    <div>{{data.Address}},{{data.City}},{{data.Postcode}}</div>
                                                </div>
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="SSACollapse = !SSACollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(SSACollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Select special action
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="SSACollapse" class="childs" [ngClass]="{'showChild': !SSACollapse}">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label">
                                            Select special action
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown [full]="true"
                                                [(ngModel)]="this.SelectedSpecialAction" name="SelectedSpecialAction">
                                                <pdi-dropdown-option *ngFor="let SpecialAction of this.SpecialActions"
                                                    [key]="SpecialAction.Id">
                                                    {{SpecialAction.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <button type="button" id="action-confirm" (click)="SubmitSpecialAction()">
                                                <img src="assets/Content/Images/Icons/save.png"
                                                    class="edit-button-image"
                                                    [ngClass]="{'edit-button-image-yellow': isAddForm === 1}" />
                                                <span class="edit-button hidden-md hidden-sm hidden-xs"
                                                    [ngClass]="{'edit-button-yellow': isAddForm === 1}">Confirm</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="vehicle"></facnm-action-buttons>
        </div>
    </div>
</div>

<ngx-smart-modal #VehicleType identifier="VehicleType" [customClass]="'modal-custom'" [closable]="true"
    [dismissable]="false" [escapable]="false">
    <div class="modal-header">
        <div class="modal-title model-header-label">Vehicle Type</div>
    </div>
    <div class="modal-body">
        <div class="container modal-control" id="entity-detail" style="font-size:14px;">
            <div id="entity-form" class="p-4">
                <form #VehicleTypeForm="ngForm" id="VehicleType-form" class="form-horizontal" novalidate>
                    <div id="entity-errors" class="row">
                        <div class="col">
                            <facnm-errors></facnm-errors>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <table class="table">
                                <tr>
                                    <td class="col-xs-3" style="border: 0; font-weight: bold">Color</td>
                                    <td class="col-xs-7" style="border: 0; font-weight: bold">Vehicle Type</td>
                                    <td class="col-xs-2" style="border: 0; text-align: end;">
                                        <button type="button" class="pdi-add-button" style="margin-right: 30px;"
                                            (click)="addNewVehicleTypeRow()">
                                            <span class="pdi-icon-Add"></span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 modal-product-categories">
                            <table class="table">
                                <tr *ngFor="let VehicleType of VehicleTypesList;let i = index">
                                    <td class="col-xs-3">
                                        <input type="color" id="{{'ColorHash'+i}}" name="{{'ColorHash'+i}}"
                                            class="w-100 bg-transparent control-look-input"
                                            [ngClass]="{'modelstate-invalid': isVehicleTypeFormInvalid && !VehicleType.ColorHash}"
                                            [(ngModel)]="VehicleType.ColorHash" required />
                                        <div class="control-look"></div>
                                        <div class="error-message"
                                            *ngIf="isVehicleTypeFormInvalid && !VehicleType.ColorHash">
                                            <small class="error">
                                                Color is required.
                                            </small>
                                        </div>
                                    </td>
                                    <td class="col-xs-7">
                                        <input type="text" id="{{'Name'+i}}" name="{{'name'+i}}"
                                            class="form-custom-control control-look-input"
                                            [ngClass]="{'modelstate-invalid': isVehicleTypeFormInvalid && !VehicleType.Name}"
                                            [(ngModel)]="VehicleType.Name" required />
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="isVehicleTypeFormInvalid && !VehicleType.Name">
                                            <small class="error">
                                                Vehicle Type is required.
                                            </small>
                                        </div>
                                    </td>
                                    <td class="col-xs-2">
                                        <button type="button" class="pdi-delete-button"
                                            (click)="checkDeleteVehicleType(VehicleType, i)">
                                            <span class="pdi-icon-Delete"></span>
                                        </button>
                                    </td>
                                </tr>
                            </table>

                        </div>

                    </div>
                </form>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="col-md-2 text-center">
            <button type="button" class="edit-button-green" (click)="closeVehicleType()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-2  text-center">
            <button type="submit" [disabled]="saveProgress" class="edit-button-green" (click)="saveVehicleType()">
                <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Save </span>
            </button>
        </div>
    </div>

</ngx-smart-modal>

<ngx-smart-modal #compartmentSet identifier="compartmentSet" [customClass]="'modal-custom'" [closable]="true"
    [dismissable]="false" [escapable]="false">
    <div class="modal-header">
        <div class="modal-title model-header-label">Compartments</div>
    </div>
    <div class="modal-body">
        <div class="container modal-control" id="entity-detail" style="font-size:14px;">
            <div id="entity-form" class="p-4">
                <form #compartmentSetForm="ngForm" id="compartmentSet-form" class="form-horizontal" novalidate>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-6">
                                <table class="table">
                                    <tr>
                                        <th class="col-md-9">Compartment setup name</th>
                                        <th class="col-md-3">
                                            <button type="button" class="pdi-add-button"
                                                (click)="addNewCompartmentSet()">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-xs-6">
                                <table class="table">
                                    <tr>
                                        <th class="col-md-9">Compartments</th>
                                        <th class="col-md-3">
                                            <button type="button" class="ms-2 pdi-add-button"
                                                (click)="addNewCompartment()">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </table>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-xs-6 modal-product-categories">
                                <table class="table">
                                    <tr *ngFor="let compartmentSet of compartmentSetList;let i = index"
                                        (click)="selectedCompartmentSet(i,compartmentSet)"
                                        [ngClass]="{selected: i == selectedCompartmentSetId}">
                                        <td>
                                            <input type="text" id="{{'name'+i}}" name="{{'name'+i}}"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': isCompartmentSetFormInvalid && !compartmentSet.Name }"
                                                [(ngModel)]="compartmentSet.Name" required />
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                *ngIf="isCompartmentSetFormInvalid && !compartmentSet.Name">
                                                <small class="error">
                                                    Compartment Set Name is required.
                                                </small>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="pdi-delete-button"
                                                (click)="deleteCompartmentSet(compartmentSet, i)">
                                                <span class="pdi-icon-Delete"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-xs-6">
                                <table class="table">
                                    <tr *ngFor="let compartment of compartmentList;let i = index">
                                        <td>
                                            <input type="number" id="{{'Capacity'+i}}" name="{{'Capacity'+i}}"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': isCompartmentSetFormInvalid && !compartment.Capacity}"
                                                [(ngModel)]="compartment.Capacity" required />
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                *ngIf="isCompartmentSetFormInvalid && !compartment.Capacity">
                                                <small class="error">
                                                    Compartment capacity is required.
                                                </small>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="pdi-delete-button ms-2"
                                                (click)="deleteCompartment(compartment, i)">
                                                <span class="pdi-icon-Delete"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="col-md-2 text-center">
            <button type="button" class="edit-button-green" (click)="closeCompartment()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-2  text-center">
            <button type="submit" [disabled]="saveProgress" class="edit-button-green" (click)="saveCompartments()">
                <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Save </span>
            </button>
        </div>
    </div>

</ngx-smart-modal>



<dx-popup title="Conflict - Receipt Numbers" [(visible)]="visibleReceiptNumberConflict" hideOnOutsideClick="true"
    [dragEnabled]="false" maxHeight="400" maxWidth="600" (onHidden)="cancelPopup()">
    <div>
        <div style="height:300px;overflow-y:auto;">
            <p>Receipt number conflict detected. Conflict with the following devices: </p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td>Saved Device</td>
                        <td>Conflicting Device</td>
                    </tr>
                </thead>
                <tr *ngFor="let conflict of ConflictingDevices;let i = index">
                    <td>
                        Device: {{conflict.DeviceId}} in Company: {{conflict.CompanyId}}<br />
                        Range-1: {{conflict.Range1BeginValue}} - {{conflict.Range1EndValue}} <br />
                        Range-2: {{conflict.Range2BeginValue}} - {{conflict.Range2EndValue}} <br />
                    </td>
                    <td>
                        <span *ngFor="let innerConflict of conflict.InnerConflictingDevices;let i = index">
                            Conflict Device: {{innerConflict.DeviceId}} in Company:
                            {{innerConflict.CompanyId}} <br />
                            Range-1: {{innerConflict.Range1BeginValue}} -
                            {{innerConflict.Range1EndValue}} <br />
                            Range-2: {{innerConflict.Range2BeginValue}} -
                            {{innerConflict.Range2EndValue}} <br />
                        </span>
                    </td>
                </tr>
            </table>
            <p class="top-buffer">Ignore the conflict and save changes?</p>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="confirmPopup()" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Yes</span>
                </button>
            </div>
            <div class="col">
                <button (click)="cancelPopup()" id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">No</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>


<dx-popup title="Receipt Numbers" [(visible)]="visibleReceiptNumber" hideOnOutsideClick="true" [dragEnabled]="false"
    (onHidden)="closePopup()" maxHeight="200" maxWidth="400">
    <div>
        Receipt number ranges were changed, send special action to device to reload receipt number ranges?
    </div>
    <div class="dialog-footer">

        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="receiptRangeChangingModal_onClick(false)" id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">No</span>
                </button>
            </div>
            <div class="col">
                <button (click)="receiptRangeChangingModal_onClick(true)" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Yes</span>
                </button>
            </div>
            
        </div>
    </div>
</dx-popup>

<facnm-delete-dialog [entityName]="entityName" [showVehicleDeleteMsg]="vehicleInUse" [attentionMsg]="attentionMsg"
    [height]="300" [width]="600"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>

<facnm-confirmation-dialog *ngIf="compartmentDeleteConfirm" (confirmed)=VehicleTypeDeleteConfirmed()
    (canceled)=VehicleTypeDeleteClosed() [message]="compartmentDeleteConfirmMsg">
</facnm-confirmation-dialog>
