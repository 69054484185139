import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class PriceGroup {
  Id: number;
  Name : string;
  NumberOfProducts: number;
  ProductId: number;
  PricePer: number;
  QuantityUnit: string;
  MinimumQty:number;
  Price:number;
  FromDate: any;
  ToDate:any;
  Products:any;
}

@Injectable()
export abstract class PriceGroupInterface implements EntityInterface<PriceGroup> {
  create: (data: Entity<PriceGroup>) => Observable<any>;
  save: (data: Entity<PriceGroup>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getEntity: (key: any) => Observable<PriceGroup>;
  ListRetrieved: Observable<PriceGroup[]>;
  notifyRetrieved: (data: PriceGroup[]) => void;
  getList: (lookup: any) => Observable<PriceGroup[]>;
  getNewEntity: () => Observable<PriceGroup>;
  getProductList: () => Observable<Enum[]>;
  getProductDetails: (data: any) => Observable<Enum[]>;
  getSystemPreferences: () => Observable<any>;
}