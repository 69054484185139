import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'facnm-data-area-assignment',
  templateUrl: './data-area-assignment.component.html',
  styleUrls: ['./data-area-assignment.component.scss']
})
export class DataAreaAssignmentComponent implements OnInit {

  constructor() { }
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  private dataGridAssignedDataAreas: DxDataGridComponent;
  @ViewChild('dataGridAssignedDataAreas', { static: false }) set assignedDataAreas(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAssignedDataAreas = content;
    }
  }

  private dataGridUnassignedDataAreas: DxDataGridComponent;
  @ViewChild('dataGridUnassignedDataAreas', { static: false }) set unassignedDataAreas(content: DxDataGridComponent) {
    if (content) {
      this.dataGridUnassignedDataAreas = content;
    }
  }
  @Input() AssignedDataAreas = [];
  @Input() UnAssignedDataAreas = [];
  @Input() isAddForm = 0;
  assignedDataAreaSelected = [];
  unassignedDataAreaSelected = [];
  DataAreaColumnConfig: any;
  allMode: string;
  checkBoxesMode: string;

  ngOnInit(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'onClick';
    this.DataAreaColumnConfig = [
      { caption: 'Id', dataField: 'Id', visible: false, dataType: 'string' },
      { alignment: 'center', caption: 'Data Area No', dataField: 'Number', cssClass: 'Number', dataType: 'string' },
      { alignment: 'center', caption: 'Data Area Name', dataField: 'Name', cssClass: 'Name', dataType: 'string' },
    ];
  }
  onContentReadyAssigned(e) {
    this.dataGridAssignedDataAreas.noDataText = this.dataGridAssignedDataAreas.instance.totalCount() ? '' : "No data";
  }
  onContentReadyUnAssigned(e) {
    this.dataGridUnassignedDataAreas.noDataText = this.dataGridUnassignedDataAreas.instance.totalCount() ? '' : "No data";
  }
  assignDataAreas() {
    let selectedRows = this.unassignedDataAreaSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.AssignedDataAreas.filter(a => a.Id == row.Id);
      this.UnAssignedDataAreas.splice(this.UnAssignedDataAreas.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.AssignedDataAreas.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  unassignDataAreas() {
    let selectedRows = this.assignedDataAreaSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.UnAssignedDataAreas.filter(a => a.Id == row.Id);
      this.AssignedDataAreas.splice(this.AssignedDataAreas.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.UnAssignedDataAreas.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  clearAndRefreshGrid() {
    if (this.dataGridUnassignedDataAreas && this.dataGridAssignedDataAreas) {
      this.dataGridUnassignedDataAreas.instance.clearSelection();
      this.dataGridUnassignedDataAreas.instance.refresh();
      this.dataGridAssignedDataAreas.instance.clearSelection();
      this.dataGridAssignedDataAreas.instance.refresh();
      this.setNoDataText();
    }
  }
  setNoDataText() {
    this.dataGridUnassignedDataAreas.noDataText = this.UnAssignedDataAreas?.length ? '' : "No data";
    this.dataGridAssignedDataAreas.noDataText = this.AssignedDataAreas?.length ? '' : "No data";
  }
}
