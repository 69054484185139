import CodeMirror from "codemirror";

import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/rulers';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/trailingspace';
import 'codemirror/addon/mode/simple';
import 'codemirror/addon/hint/show-hint';

import uniq from 'lodash/uniq';
import { PrintTemplateKeywords, linelenghtKeyword } from "./print-templates.model";
import { Injectable } from "@angular/core";



@Injectable()
export class PrintTemplatesEditService {


    codeMirror = (CodeMirror as any);

    verticalLine: { defaultLength: number, baseColor: string, additionalColor?: string } = {
        baseColor: '#07003D', defaultLength: 80, additionalColor: '#D9D8D6'
    };

    codeMirrorOptions = {
        lineNumbers: true,
        tabSize: 4,
        theme: 'base16-light',
        mode: 'printTemplate',
        styleActiveLine: { nonEmpty: true },
        rulers: [{ color: this.verticalLine.baseColor, column: this.verticalLine.defaultLength }],
        highlightSelectionMatches: true,
        extraKeys: { "Ctrl-Space": "autocomplete" },
        matchBrackets: true,
        showTrailingSpace: true,
        hint: this.codeMirror.hint.printTemplate
    };

    getCodeMirrorOptions() {

        return this.codeMirrorOptions;
    }

    setVerticalBaseLine(length: number) {

        this.verticalLine.defaultLength = length;
    }


    updateVerticalLines(templateContent: string) {

        this.codeMirrorOptions.rulers = [{ color: this.verticalLine.baseColor, column: this.verticalLine.defaultLength }];

        let lineLengthsPattern = RegExp(linelenghtKeyword + ':\\d+', 'g');
        let lineLengths: string[] = templateContent.match(lineLengthsPattern);
        if (lineLengths && lineLengths.length > 0) {
            let lineLengthsValues: number[] = [];
            lineLengths.forEach((l, i) => {
                lineLengthsValues.push(Number(l.split(':')[1]));
            });
            lineLengthsValues.forEach(lineLength => {

                this.codeMirrorOptions.rulers.push({ color: this.verticalLine.additionalColor, column: lineLength });
                this.codeMirrorOptions.rulers = uniq(this.codeMirrorOptions.rulers);
            });
        }
    }


    defineCodeMirrorMode(keywords: PrintTemplateKeywords) {

        let pageParts = keywords.PageParts.reverse().map((k) => '->' + k + '<-').join('|') || '#nothing_found#';
        let formatCommands = keywords.FormatCommands.reverse().join('|') || '#nothing_found#';
        let parsers = keywords.Parsers.reverse().join('|') || '#nothing_found#';
        let tablesAndHashes = keywords.TablesAndHashes.reverse().join('|') || '#nothing_found#';

        this.codeMirror.defineSimpleMode("printTemplate",
            {
                start: [
                    {
                        regex: RegExp('(?:' + pageParts + ')', 'g'),
                        token: 'pageParts'
                    },
                    {
                        regex: RegExp('(?:^|_)(' + formatCommands + ')(?![a-zA-Z])', 'g'),
                        token: "formatCommands"
                    },
                    {
                        regex: RegExp('(?:^|_)(' + parsers + ')(?![a-zA-Z])', 'g'),
                        token: "parsers"
                    },
                    {
                        regex: /[-+\/*=<>!%]+/,
                        token: "operator"
                    },
                    {
                        regex: /(?::|;|,|\(|\)|\[|\])/g,
                        token: "atom"
                    },
                    {
                        regex: RegExp('(?:^|_)(' + tablesAndHashes + ')(?![a-zA-Z])', 'g'),
                        token: 'tablesAndHashes'
                    },
                    {
                        regex: RegExp('(\d|_|\W|\b)(' + linelenghtKeyword + ')(\d|_|\W|\b)', 'g'),
                        token: ['', 'linelen', '']
                    },
                    {
                        regex: /_+/g,
                        token: "string"
                    },
                    {
                        regex: /\d+/g,
                        token: "number"
                    }
                ]
            });


        let dictionary: string[] = [].concat(
            keywords.TablesAndHashes,
            keywords.Parsers,
            keywords.FormatCommands,
            keywords.PageParts.map((k) => '->' + k + '<-')
        );

        this.codeMirror.hint.printTemplate = function (editor) {

            var cursor = editor.getCursor();
            var currentLine = editor.getLine(cursor.line);
            var start = cursor.ch;
            var end = start;
            while (end < currentLine.length && /(?!_|\(|\)|\[|\]|\+|>|-|=|\/|:|,|\s|;|\d)[\S$+]/.test(currentLine.charAt(end)))++end;
            while (start && /(?!_|\(|\)|\[|\]|\+|-|<|=|\/|:|,|\s|;|\d)[\S$+]/.test(currentLine.charAt(start - 1)))--start;
            var curWord = start != end && currentLine.slice(start, end);
            var regex = new RegExp('^' + curWord, 'i');
            var result = {
                list: (!curWord ? dictionary : dictionary.filter(function (item) {
                    return item.match(regex) || item.indexOf(curWord) >= 0;
                })),
                from: CodeMirror.Pos(cursor.line, start),
                to: CodeMirror.Pos(cursor.line, end)
            };

            return result;
        };
    }
}