import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment as e } from 'src/environments/environment';

import { Entity, Enum } from '@app/app.model';

import { TaxRatesInterface, TaxRate } from './tax-rates.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class TaxRatesService implements TaxRatesInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    
    create(data: Entity<TaxRate>): Observable<any> {
        return this._http.post<TaxRate>(
            e.endpoint.settings.taxRates,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }

    save(data: Entity<TaxRate>): Observable<any> {
        return this._http.put<TaxRate>(
            e.endpoint.settings.taxRates,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(data: any): Observable<any> {
        return this._http.request('delete',
            e.endpoint.settings.taxRates,
            {
                body: data
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    private listRetrieved: BehaviorSubject<TaxRate[]> = new BehaviorSubject<TaxRate[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<TaxRate[]> {
        return this._http.get<TaxRate[]>(e.endpoint.settings.taxRates)
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: TaxRate[]): void {
        this.listRetrieved.next(data);
    }

    
    getEntity(key: TaxRate): Observable<TaxRate> {

        return this._http.get<TaxRate>(e.endpoint.settings.taxRates + '/' + key.Id + '/' + key.TaxClass + '/' + key.TaxGroup + '/' + key.Type)
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    

    getTaxRatesTypes(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.settings.taxRates + '/tax-rates-types')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
}
