<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">

    <div id="manage-images"
         class="row h-100">

        <div class="col-4">

            <div id="entity-list"
                 class="h-100">

                <dx-data-grid #dataGrid
                              [dataSource]="manageImagesStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [columnMinWidth]="50">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>

                    <dxi-column dataField="Id"
                                [width]="50"
                                dataType="string"></dxi-column>
                    <dxi-column dataField="Description"></dxi-column>


                    <dxo-selection mode="single"> </dxo-selection>

                    <dxo-filter-row [visible]="true"> </dxo-filter-row>

                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                    <dxo-sorting mode="single"> </dxo-sorting>

                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>

                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="manage-images.dataGrid"></dxo-state-storing>

                </dx-data-grid>

            </div>

        </div>


        <div id="entity-detail"
             class="col-8 pr-0">

            <div *ngIf="manageImage && manageImage.key">


                <form id="entity-form"
                      #manageImageForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">

                        <div id="entity-errors"
                             class="row">
                            <div class="col">

                                <facnm-errors></facnm-errors>

                            </div>
                        </div>


                        <div id="entity-fields"
                             class="row">

                            <div class="col entity-image"
                                 [ngClass]="{'modelstate-invalid':!manageImage.value.Image && isInvalid('Image')}">

                                <img *ngIf="manageImage.value.Image && this.currentImageType"
                                     class="img-responsive"
                                     [src]="'data:image/'+ this.currentImageType + ';base64,'+ manageImage.value.Image" />

                            </div>

                            <div class="col">

                                <div class="container">


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="IdNew"
                                                   class="col-form-label">Id</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="manageImage.value.IdNew"
                                                   [ngClass]="{'modelstate-invalid': !IdNew.dirty && isInvalid('IdNew')}"
                                                   (keypress)="allowOnlyNumbers(event)"
                                                   type="number"
                                                   class="form-custom-control control-look-input"
                                                   name="IdNew"
                                                   #IdNew="ngModel">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!IdNew.dirty && isInvalid('IdNew')">
                                                <small class="error">
                                                    {{getError('IdNew')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="row form-group">

                                        <div class="col-4">
                                            <label for="BwNew"
                                                   class="col-form-label">Black and White?</label>
                                        </div>

                                        <div class="col-8">

                                            <input [(ngModel)]="manageImage.value.BwNew"
                                                   [ngClass]="{'modelstate-invalid': !BwNew.dirty && isInvalid('BwNew')}"
                                                   type="checkbox"
                                                   class="checkbox-inline"
                                                   name="BwNew"
                                                   #BwNew="ngModel">

                                            <div class="error-message"
                                                 *ngIf="!BwNew.dirty && isInvalid('BwNew')">
                                                <small class="error">
                                                    {{getError('BwNew')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>



                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Description"
                                                   class="col-form-label">Description</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="manageImage.value.Description"
                                                   [ngClass]="{'modelstate-invalid': !Description.dirty && isInvalid('Description')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="Description"
                                                   #Description="ngModel">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!Description.dirty && isInvalid('Description')">
                                                <small class="error">
                                                    {{getError('Description')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-1 d-flex"
                                             *ngIf="!manageImage.value.Image && isInvalid('Image')">

                                            <i class="align-self-center fa fa-exclamation-circle modelstate-invalid"></i>
                                        </div>

                                        <div class="col">

                                            <dx-file-uploader #imageUploader
                                                              selectButtonText="Select image"
                                                              (onValueChanged)="onImageUploadChanged($event)"
                                                              (onUploadStarted)="onImageUploadStarted($event)"
                                                              (onUploaded)="onImageUploaded($event)"
                                                              (onUploadError)="onImageUploadError($event)"
                                                              accept="image/*"
                                                              [uploadUrl]="imageUploadUrl"
                                                              [uploadHeaders]="imageUploadHeaders"
                                                              [(value)]="imageUploading">
                                            </dx-file-uploader>

                                        </div>
                                    </div>

                                    <div class="row pt-4"
                                         *ngIf="!manageImage.created && manageImage.value.Image && this.currentImageType">
                                        <div class="col">

                                            <button (click)="imageDownload('data:image/'+ this.currentImageType + ';base64,'+ manageImage.value.Image)"
                                                    id="action-imageDownload">
                                                <img src="assets/Content/Images/Icons/download.png"
                                                     class="edit-button-image" />
                                                <span class="edit-button d-none d-xl-inline-block">Download</span>
                                            </button>
                                            <div class="d-block d-xl-none">
                                                <dx-tooltip target="#action-imageDownload"
                                                            showEvent="dxhoverstart"
                                                            hideEvent="dxhoverend"
                                                            position="bottom">
                                                    Download
                                                </dx-tooltip>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>


                        <div class="row">


                        </div>


                    </div>

                </form>


            </div>

            <facnm-action-buttons [modelEntity]="manageImage"></facnm-action-buttons>

        </div>

    </div>

</div>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>