<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="data-area" class="row h-100">
        <div class="col-md-3 col-sm-3">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="DataAreaDataSource" [wordWrapEnabled]="true" noDataText=""
                    (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)" (onContentReady)="onContentReady($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='left'  caption="Data Area No" dataField="Number"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left'  caption="Data Area Name" dataField="Name"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="dataAreaEntity  &&  dataAreaEntity.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight" style="overflow-x: hidden;">
                    <div class="dataArea-Container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Data Area Number</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="dataAreaEntity.value.Number" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="DataAreaNumber">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Data Area Name</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-1">
                                        <input [(ngModel)]="dataAreaEntity.value.Name"
                                            [ngClass]="{'modelstate-invalid': hasError('Name')}" type="text"
                                            class="form-custom-control control-look-input" name="Name" required
                                            maxlength="50">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Name')">
                                            <small class="error" *ngIf="checkError('Name','required')">
                                                Data Area Name is required
                                            </small>
                                            <small class="error" *ngIf="checkError('Name','maxlength')">
                                                Data Area Name cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="PGCollapse = !PGCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(PGCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Product Groups
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="PGCollapse" class="childs" [ngClass]="{'showChild': !PGCollapse}">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="col-md-6 col-sm-5 col-xs-6">
                                            <div style="height: 470px;" [ngClass]="{
                                                editGridDiv: !this.isAddForm == 1, 
                                                newGridDiv: this.isAddForm == 1
                                            }">
                                                <dx-data-grid (onContentReady)="onContentReadyAssigned($event)"
                                                    #dataGridAssignedProducts noDataText=""
                                                    [dataSource]="this.dataAreaEntity.value.ProductGroups"
                                                    [allowColumnResizing]="false" [columnAutoWidth]="false"
                                                    (onSelectionChanged)="onSelectionChanges($event)"
                                                    [columns]="productGroupColumn">
                                                    <dxo-selection mode="multiple">
                                                    </dxo-selection>
                                                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                                    <dxo-scrolling mode="standard" showScrollbar="onHover">
                                                    </dxo-scrolling>
                                                    <dxo-sorting mode="single"> </dxo-sorting>
                                                    <dxo-paging [pageSize]="10"> </dxo-paging>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                        <div class="col-md-1 col-sm-2 col-xs-1">
                                            <div class="left-arrow text-center">
                                                <a *ngIf="!isProductGroupSelect">
                                                    <img class="arrow-move" src="assets/Content/Images/Icons/arrow_left.svg">
                                                </a>
                                                <div *ngIf="isProductGroupSelect">
                                                    <a (click)="selectProductGroups()">
                                                        <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_left_active.svg">
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="right-arrow text-center">
                                                <a *ngIf="!isProductGroupUnselect">
                                                    <img class="arrow-move" src="assets/Content/Images/Icons/arrow_right.svg">
                                                </a>
                                                <a (click)="unselectProductGroups()" *ngIf="isProductGroupUnselect">
                                                    <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_right_active.svg">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                            <div class="gridDiv" fix-grid-search-backspace>
                                                <dx-data-grid #dataGridAllProductGroups
                                                    (onContentReady)="onContentReadyUnAssigned($event)"
                                                    [dataSource]="this.productGroups" [allowColumnResizing]="false"
                                                    [columnAutoWidth]="false"
                                                    (onSelectionChanged)="onSelectionChanged($event)"
                                                    [columns]="productGroupColumn">
                                                    <dxo-selection mode="multiple">
                                                    </dxo-selection>
                                                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                                                    <dxo-scrolling mode="standard" showScrollbar="onHover">
                                                    </dxo-scrolling>
                                                    <dxo-sorting mode="single"> </dxo-sorting>
                                                    <dxo-paging [pageSize]="10"> </dxo-paging>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="dataAreaEntity"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName"  [showDataAreaDeleteMsg]="dataAreaEntityDeleteConfirm" [height]="210" [width]="405" [message]="dataAreaEntityDeleteConfirmMsg"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>
