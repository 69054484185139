import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";


export class ManageImage {

    Id: number;
    Bw: boolean;
    IdNew: number;
    BwNew: boolean;
    
    Description: string;

    Image: string;
}



@Injectable()
export abstract class ManageImagesInterface implements EntityInterface<ManageImage> {

    create: (data: Entity<ManageImage>) => Observable<any>;

    save: (data: Entity<ManageImage>) => Observable<any>;

    delete: (data: any) => Observable<any>;

    getList: () => Observable<ManageImage[]>;
    getEntity: (key: any) => Observable<ManageImage>;

    ListRetrieved: Observable<ManageImage[]>;

    notifyRetrieved: (data: ManageImage[]) => void;

    getUploadUrl: () => string;
    getUploadHeaders: () => any;
}