import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity,Enum } from "@app/app.model";

export class DeviceGroup {
  Id: number;
  Name: string;
  Deleted: boolean;
  UnAssignedVehicles: any;
  AssignedVehicles: any;
  AssignedProducts :any;
}

@Injectable()
export abstract class DeviceGroupInterface implements EntityInterface<DeviceGroup> {
  create: (data: Entity<DeviceGroup>) => Observable<any>;
  save: (data: Entity<DeviceGroup>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<DeviceGroup[]>;
  getEntity: (key: any) => Observable<DeviceGroup>;
  ListRetrieved: Observable<DeviceGroup[]>;
  notifyRetrieved: (data: DeviceGroup[]) => void;
  getProductList: (groupId: any) => Observable<Enum[]>;
  getNewEntity: () => Observable<DeviceGroup>;
}
