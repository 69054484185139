import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { LoadPoint, LoadPointTank, LoadPointInterface, Product } from './load-point.model';
import { LoadPointService } from './load-point.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from '@settings/company/company.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { ProductInterface } from '../products/product/product.model';
import { ProductService } from '../products/product/product.service';
import { ViewportScroller } from '@angular/common';
import { displayZeroValueText, dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-load-point',
  templateUrl: './load-point.component.html',
  styleUrls: ['./load-point.component.scss'],
  providers: [
    {
      provide: LoadPointInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new LoadPointService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    },
    {
      provide: ProductInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new ProductService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class LoadPointComponent implements OnInit {
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('bulkPlantDataGrid', { static: true }) bulkPlantDataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;
  @ViewChild('categoryForm') categoryForm: NgForm;
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('productDataGrid') productDataGrid: DxDataGridComponent;
  @ViewChild('LoadPointDetailsDiv') LoadPointDetailsDiv: ElementRef;


  scrollToTop() {
    console.log(this.LoadPointDetailsDiv.nativeElement);
    this.LoadPointDetailsDiv.nativeElement.scrollTo(0,0);
  }

  private unsubscribe$ = new Subject<void>();
  modalError: any;
  animation: any;
  shadingColor: any;
  saveTypeProgress: boolean;
  saveCategoryProgress: boolean;
  loadPointData: ArrayStore;
  loadPoint: Entity<LoadPoint>;
  loadPointTank: Entity<LoadPointTank>;

  loadPointTankData: ArrayStore;
  customDataSource: ArrayStore;
  entityName = 'Load Point';
  error = '';
  GICollapse = false;
  ADCollapse = false;
  COCollapse = false;
  CICollapse = false;
  BPTCollapse = false;
  loadPointList = [];
  filterOptions = {
    LoadPointNumber: null, LoadPointName: null,
    GridPagerOptions: { PageIndex: 0, PageSize: 20, TotalPages: null }
  };
  PageSize = 20;
  TotalCount = null;
  isAddForm = 0;
  collapseColor = 'green';
  nonPrintCompanies = [];
  
  newUnit: string;
  newloadPoint: LoadPoint;
  loadPointCategorySubmitted = false;
  categoryList = [];
  loadPointCategoryShapes = [];
  loadPointCategoryDeleteConfirm = false;
  loadPointCategoryDeleteConfirmMsg = 'Are you sure you want to delete?';
  deleteProCateItem: any;
  deleteProCateItemIndex: any;
  arrguments: {};
  LoadPointTanks = [];
  productDataSource: any;
  LiquidProductDataSource:any;
  loadLocation = [];
  companyList = [];
  countries = [];
  isBulkplant = false;
  popupVisible = false;
  deletePopupVisible =  false;
  resultDeleteBulkPlant=false;
  productFilterOptions = {
    ProductNumber: null, ProductName: null, ProductGroup: null,
    GridPagerOptions: { PageIndex: 0, PageSize: 1000000, TotalPages: null }
  };
  selectedProduct: Entity<Product>;
  selectedProductName: any;
  selectedProductId: any;
  productCategories = [];
  productType = [];

  noDataTextBulkPlant ="";
  constructor(
    private loadPoint$: LoadPointInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    public ngxSmartModalService: NgxSmartModalService,
    public DxPopupModule: DxPopupModule,
    private product: ProductService,
    private company: CompanyService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportScroller: ViewportScroller) {
  
    loadPoint$.ListRetrieved.subscribe(data => {
      if (data) {
        this.loadPointData = new ArrayStore({
          data: data,
          key: ['Id']
        });
        if (data.length == 0) {
          this.dataGrid.noDataText = 'No load Point Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });

    this.arrguments = {
      loadPointList: this.loadPoint$.getList(),
      productList: this.product.getList(this.productFilterOptions),
      loadLocationGroup: this.loadPoint$.getloadLocationGroup(),
      nonPrintCompanies: this.company.getNonPrintCompanyList(1),
      countries: this.loadPoint$.getCountryList(),
      categoryList: this.product.getProductCategoryList(),
      productType: this.product.getProductTypeDropdown(),
    };

    actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  OpenProductModel() {
    this.customDataSource = new ArrayStore({
      data: this.LiquidProductDataSource,
      key: ['Id']
    });
    this.productDataGrid.instance.deselectAll();
    this.productDataGrid.instance.clearFilter();
    this.ngxSmartModalService.open('ProductModel');
  }

  onPopupOk() {
      if (this.selectedProductName && this.selectedProductName) {
      this.loadPointTank['ProductName'] = this.selectedProductName;
        this.loadPointTank['ProductId'] = this.selectedProductId;
    }
    this.ngxSmartModalService.close('ProductModel');
  }

  onPopupCancel() {
    if (this.selectedProductName && this.selectedProductName) {
      this.selectedProductId = '';
      this.selectedProductName = '';
    }
    this.ngxSmartModalService.close('ProductModel');
  }

  getLoadPointList() {
    this.loadPoint$.getList().subscribe(listWithCreated => {
      this.loadPoint$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    forkJoin(
      this.arrguments,
    ).subscribe(
      res => {
        this.productType = res['productType'];
        this.productCategories = res['categoryList']
        this.countries = res['countries'];
        this.nonPrintCompanies = res['nonPrintCompanies'];
        this.productDataSource = res['productList'];
        this.LiquidProductDataSource = this.productDataSource.ProductListVM.filter(p=>p.IsLiquid);
        this.loadLocation = res['loadLocationGroup'];
        this.loadPoint$.notifyRetrieved(res['loadPointList']);
        this.app$.notifyScreenReady();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }


  isInvalid(field: any) { return this.errors$.isInvalid(field); }

  getError(field: any) { return this.errors$.getError(field); }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.loadPoint = new Entity();
    this.loadPoint.value.LoadLocationGroup = 0;
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    displayZeroValueText(this.loadLocation,'LoadLocationGroup','id','name');
    this.changeDetectorRef.detectChanges();
  }

  private deleteConfirm() {
    this.loadPoint$.delete(this.loadPoint.value.Id).subscribe(
      (okDelete) => {
        this.cancel();
        this.getLoadPointList();
        this.refreshGrid();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    if (this.loadPoint.value.LoadLocationGroup == 1 && !this.saveLoadPointTank()) {
      this.errors$.handleCustomError('Missing validation');
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.loadPoint$.create(this.loadPoint).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        this.getLoadPointList();
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deselectLoadPoint();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deselectLoadPoint() {
    this.dataGrid.instance.deselectRows(this.loadPoint.key);
    this.loadPoint.key = undefined;
  }

  private reset() {
    this.loadPoint.restore();
    if(this.loadPointTank)
      this.loadPointTank.key=undefined;
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deselectLoadPoint();
    this.deSelectBulkPlant();
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  onSelectionChanged($clikedRow: any) {
    if (!this.loadPoint)
      try {
        let keySaved = JSON.parse(localStorage.getItem('loadPoint.dataGrid')).selectedRowKeys[0];
        if (keySaved) {
          this.selectLoadPoint(keySaved);
        }
      }
      catch { }
  }

  private selectLoadPoint(key: any) {
    this.actions$.notifyEntityBusy();
    this.loadPoint = new Entity();
    this.loadPoint.key = key;
    this.changeDetectorRef.detectChanges();

    this.loadPoint$.getLoadPointDetails(key.Id).subscribe(
      (data) => {
        this.loadPoint.value = data;
        this.loadPointTank = this.loadPoint.value.LoadPointTanks
        this.app$.notifyScreenReady();
        this.isAddForm = 0;
        this.isBulkplant = this.loadPoint.value.LoadLocationGroup == 1;
        this.collapseColor = 'green';
        if(this.loadPoint.value.LoadLocationGroup == 0){
          displayZeroValueText(this.loadLocation,'LoadLocationGroup','id','name');
        }
        if(this.loadPoint.value.CompanyId == 0){
          displayZeroValueText(this.nonPrintCompanies,'CompanyId','Id','Name');
        }
        if(this.loadPoint.value.CountryId == 0){
          displayZeroValueText(this.countries,'country','Id','Name');
        }
      });
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    if(this.entityForm?.form.invalid)
      this.loadPoint.key = undefined;
    this.app$.setScreenTitle(this.entityName);
    if (!this.loadPoint)
      this.loadPoint = new Entity();
    if (JSON.stringify(this.loadPoint.key) === JSON.stringify($clikedRow.key)) {
      this.deselectLoadPoint();
    }
    else {
      this.selectLoadPoint($clikedRow.key);
    }
  }

  onCellPrepared(e: { rowType: string; column: { dataField: string; }; data: { Name2: any,Name1: any,Postcode:any,City:any,Address:any; }; cellElement: { innerHTML: string; }; }) {
    if (e.rowType === "data" && e.column.dataField === "Name1") {
        var Name1 = '<span>'+e.data.Name1+'<br></span>'
        var Name2 = '<span>'+e.data.Name2+'<br></span>';

        e.cellElement.innerHTML = Name1 + (Name2 ? Name2 : '');
    }

    if(e.rowType === "data" && e.column.dataField === "Address"){
      var postcodecity ="";
      if (e.data.Postcode != null) postcodecity = postcodecity + e.data.Postcode + " ";
      if (e.data.City != null) postcodecity = postcodecity + e.data.City;
      e.cellElement.innerHTML = '<span>'+e.data.Address+'<br></span>'+'<span>'+postcodecity+'<br></span>';
    }
  }

  onRowClicked($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.loadPointTank)
      this.loadPointTank = new Entity();
    if (JSON.stringify(this.loadPointTank.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectBulkPlant();
    }
    else {
      this.selectBulkPlant($clikedRow.key);
    }
  }

  private deSelectBulkPlant() {
    if(this.loadPointTank)
    this.loadPointTank.key = undefined;
  }

  private selectBulkPlant(key: any) {
    this.loadPointTank = new Entity();
    this.loadPointTank.key = key;
    this.loadPointTank = this.loadPoint.value.LoadPointTanks.find(lpt => lpt.Id === key);
    this.loadPointTank.key = key;
  }

  onProductRowClicked($clikedRow: { key: any; }) {
    this.customDataSource.byKey($clikedRow.key).then(pt => {
      this.selectedProductName = pt.ProductName;
      this.selectedProductId = pt.Id;
    });
  }

  addNewLoadPointTank = function () {
    var bulkTank = {};
    if (this.loadPoint && this.loadPoint.value.LoadPointTanks?.length > 0) {
      var sortLPT =  this.loadPoint.value.LoadPointTanks.sort((a,b) => b.Id - a.Id);
      var Id = sortLPT[0].Id; 
      bulkTank['Id'] = Id + 1;
    }
    else {
      bulkTank['Id'] = 1;
      this.loadPoint.value.LoadPointTanks = [];
    }
    this.loadPoint.value.LoadPointTanks.push(bulkTank);
    this.noDataTextBulkPlant='';
  }

  private saveLoadPointTank() {
    var isBulkPlantValid = true;
    this.errors$.error(undefined);
    if (!this.loadPoint.value.LoadPointTanks || this.loadPoint.value.LoadPointTanks.length == 0) {
       this.errors$.error('At least one Bulk Tank is required to save the Load Point.');
      isBulkPlantValid = false;
    }
    else {
      this.loadPoint.value.LoadPointTanks.forEach(loadpointTank => {
        var Id = loadpointTank.Id < 0 ? -loadpointTank.Id : loadpointTank.Id;
        if (!loadpointTank.TankId) {
          this.errors$.error("Tank ID is required for bulk plant tank " + Id);
          isBulkPlantValid = false;
        }
        if (loadpointTank.TankId && loadpointTank.TankId.length > 50) {
          this.errors$.error("Tank ID has more than 50 characters for bulk plant tank " + Id);
          isBulkPlantValid = false;
        }
        if (!loadpointTank.ProductId) {
          this.errors$.error("Product is required for bulk plant tank " + Id);
          isBulkPlantValid = false;
          return;
        }
      });
      if (isBulkPlantValid) {
        this.loadPoint.value.LoadPointTanks.forEach(loadpointTank => {
          if (!loadpointTank.LoadLocationId) {
            loadpointTank.LoadLocationId = this.loadPoint.value.Id;
            loadpointTank.Id = -loadpointTank.Id;
          }
        });
      }
      return isBulkPlantValid;
    }
  }

  handleRowRemoving(event: any) {
    if (this.loadPoint && this.loadPoint.value.LoadPointTanks.length < 2) {
      event.cancel = true; // Cancel the deletion
      this.popupVisible = true;
    }
  }

  deleteLoadPoint(item, index) {
    this.loadPoint$.delete(item.Id).subscribe((ok) => {
    }, (error) => {
      this.errors$.handleNetworkError(error);
    });
  }

  onLoadLocationGroupChange() {
    this.isBulkplant = this.loadPoint.value.LoadLocationGroup == 1;
    if (this.isBulkplant && !this.loadPoint.value.LoadPointTanks?.length)
      this.noDataTextBulkPlant = "No Data";
    else
      this.noDataTextBulkPlant = ''; 
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  isNumberOnly(event) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
