import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class FleetList {
  Id: number;
  Name: string;
  NumberOfAssignedVehicles: number;
  NumberOfAssignedDrivers: number;
}

export class Fleet {
  Id: number;
  IsNew: boolean;
  Name: string;
  AssignedDrivers: any;
  AssignedVehicles: any;
  UnassignedDrivers: any;
  UnassignedVehicles: any;
}

@Injectable()
export abstract class FleetManagementInterface implements EntityInterface<FleetList> {
  create: (data: Entity<any>) => Observable<any>;
  save: (data: Entity<FleetList>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<FleetList[]>;
  getEntity: (key: any) => Observable<FleetList>;
  ListRetrieved: Observable<FleetList[]>;
  notifyRetrieved: (data: FleetList[]) => void;

}