import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class LoginData {
    username: string;
    password: string;
    company: string;
}
export abstract class LoginInterface {
    authentincate: (data: object) => Observable<any>;
}