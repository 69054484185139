import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class DataArea {

  Id: number;
  Name: string;
  Number: string;
  ProductGroups: any;
  CustomerTypePostcodes: any;
}

@Injectable()
export abstract class DataAreaInterface implements EntityInterface<DataArea> {
  create: (data: Entity<DataArea>) => Observable<any>;
  save: (data: Entity<DataArea>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: (param) => Observable<DataArea[]>;
  getEntity: (key: any) => Observable<DataArea>;
  ListRetrieved: Observable<DataArea[]>;
  notifyRetrieved: (data: DataArea[]) => void;
  getProductGroupList: () => Observable<any[]>;
  getDataAreaByID:(Key)=> Observable<any>;
  saveDataArea:(param) => Observable<any>;
}