<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">

        <div class="col-md-6 pr-0">
            <div id="entity-list" class="h-100">

                <dx-data-grid  (onContentReady)="onContentReadyGroup($event)" #dataGrid noDataText="" 
                    [dataSource]="gridData"  (onInitialized)="onInitialized($event)"  (onRowClick)="onRowClick($event)" [allowColumnResizing]="false" [columnAutoWidth]="false"
                    [columns]="deviceMappingColumnConfig">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxo-selection   mode="single"></dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true" [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>               
            </div>
        </div>
        
        <div id="entity-detail" class="col-md-6 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="ProductNameNumber" class="col-form-label">Product ID on Register</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.ProductNameNumber" type="text"
                                            [ngClass]="{'modelstate-invalid': hasError('ProductNameNumber')}"  class="form-custom-control control-look-input" required [disabled]="!isAddForm"
                                            name="ProductNameNumber"  maxlength="50">
                                        <div class="control-look"></div>

                                        <div class="error-message" *ngIf="hasError('ProductNameNumber')">
                                            <small class="error" *ngIf="checkError('ProductNameNumber','required')">
                                                Product ID on Register is required
                                            </small>
                                            <small class="error" *ngIf="checkError('ProductNameNumber','maxlength')">
                                                Product ID on Register cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>  
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                        Product Number - Name
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                        <dx-select-box name="ProductId" required
                                                [ngClass]="{'modelstate-invalid': hasError('ProductId')}"
                                                [(ngModel)]="entityData.value.ProductId" [dataSource]="ProductList"
                                                displayExpr="ProductName" valueExpr="Id" searchExpr="ProductName"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="" [disabled]="!isAddForm">
                                            </dx-select-box>
                                        <div class="control-look"></div>
                                    
                                        <div class="error-message" *ngIf="hasError('ProductId')">
                                            <small class="error" *ngIf="checkError('ProductId','required')">
                                                Please select a Product
                                            </small>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                        Device
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                        <dx-select-box name="DeviceId" required
                                            [ngClass]="{'modelstate-invalid': hasError('DeviceId')}"
                                            [(ngModel)]="entityData.value.DeviceId" [dataSource]="DeviceList"
                                            [displayExpr]="deviceDisplayExpr" valueExpr="Id" [searchExpr]="['Id','Name']"
                                            [searchMode]="dxSelectOptions.searchMode"
                                            [searchTimeout]="dxSelectOptions.searchTimeout"
                                            [minSearchLength]="dxSelectOptions.minSearchLength"
                                            [searchEnabled]="true" placeholder="" [disabled]="!isAddForm">
                                        </dx-select-box>
                                        <div class="control-look"></div>

                                        <div class="error-message" *ngIf="hasError('DeviceId')"> 
                                            <small class="error" *ngIf="checkError('DeviceId','required')">
                                                Please select a Device
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 second-column">
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="ProductNameOnDevice" class="col-form-label">Product Name on Register</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [ngClass]="{'modelstate-invalid': hasError('ProductNameOnDevice')}" [(ngModel)]="entityData.value.ProductNameOnDevice" type="text" class="form-custom-control control-look-input"
                                             name="ProductNameOnDevice" maxlength="50" required>
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('ProductNameOnDevice')">
                                            <small class="error" *ngIf="checkError('ProductNameOnDevice','required')">
                                                Product Name on Register is required
                                            </small>
                                            <small class="error" *ngIf="checkError('ProductNameOnDevice','maxlength')">
                                                Product Name on Register cannot be longer than 50 characters    
                                            </small>
                                        </div>
                                    </div>
                                </div>                            
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="UnitOfProduct" class="col-form-label">Unit of Product</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input  [ngClass]="{'modelstate-invalid': hasError('UnitOfProduct')}" [(ngModel)]="entityData.value.UnitOfProduct" type="text" class="form-custom-control control-look-input"
                                            name="UnitOfProduct" maxlength="50" required>
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('UnitOfProduct')">
                                            <small class="error" *ngIf="checkError('UnitOfProduct','required')">
                                                Unit of Product is required.
                                            </small>
                                            <small class="error" *ngIf="checkError('UnitOfProduct','maxlength')">
                                                Unit of Product cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="GroupName" class="col-form-label">Group Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.GroupName" type="text" class="form-custom-control control-look-input"
                                            readonly="readonly" name="GroupName">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div style="clear:both;"></div>                            
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameNotification" 
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityNameNotification"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityNameNotification"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>