import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '@app/app.service';
import { ActionsService } from '../actions/actions.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'facnm-delete-dialog',
  templateUrl: './delete.dialog.html',
  styleUrls: ['./delete.dialog.scss']
})
export class DeleteDialog implements OnInit {

  private unsubscribe$ = new Subject<void>();

  constructor(private app$: AppService, private actions$: ActionsService) {

    this.animation = app$.getAnimationSettings();
    this.shadingColor = app$.getShadingColor();

    actions$.EntityDeleting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = true);
  }

  animation: any;
  shadingColor: any;
  maxHeight = 200;
  maxWidth = 400;

  visible: boolean;
  @Input() entityName: string;
  @Input() height: number;
  @Input() width: number;
  @Input() showVehicleDeleteMsg: boolean;
  @Input() attentionMsg: string;
  @Input() showDataAreaDeleteMsg: boolean;
  @Input() message: string;
  
  
 
  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  confirm() {
    this.actions$.notifyDeleteConfirmed();
    this.visible = false;
  }

  cancel() {
    this.visible = false;
  }
}
