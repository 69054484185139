<div class="col-lg-6">
  <div
    style="height: 470px"
    [ngClass]="{ editGridDiv: !isAddForm, newGridDiv: isAddForm }"
  >
    <dx-data-grid
      (onContentReady)="onContentReadyAssigned($event)"
      #dataGridAssignedDrivers
      noDataText=""
      [dataSource]="AssignedDrivers"
      [allowColumnResizing]="false"
      [columnAutoWidth]="false"
      [(selectedRowKeys)]="assignedDriverSelected"
      [columns]="driverColumnConfig"
    >
      <dxo-selection
        [selectAllMode]="allMode"
        [showCheckBoxesMode]="checkBoxesMode"
        mode="multiple"
      ></dxo-selection>
      <dxo-filter-row [visible]="true"> </dxo-filter-row>
      <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
      <dxo-sorting mode="single"> </dxo-sorting>
      <dxo-paging [pageSize]="10"> </dxo-paging>
    </dx-data-grid>
  </div>
</div>

<div class="col-lg-1 pl-0">
  <div class="left-arrow text-center">
    <a *ngIf="!unassignedDriverSelected.length">
      <img class="arrow-move" src="assets/Content/Images/Icons/arrow_left.svg">
    </a>
    <div *ngIf="unassignedDriverSelected.length">
      <a (click)="assignDrivers()">
        <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_left_active.svg">
      </a>
    </div>
  </div>
  <div class="right-arrow text-center">
    <a *ngIf="!assignedDriverSelected.length">
      <img class="arrow-move" src="assets/Content/Images/Icons/arrow_right.svg">
    </a>
    <a (click)="unassignDrivers()" *ngIf="assignedDriverSelected.length">
      <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_right_active.svg">
    </a>
  </div>
</div>
<div class="col-lg-5 pl-0">
  <div class="gridDiv" style="height: 470px">
    <dx-data-grid
      #dataGridUnassignedDrivers
      (onContentReady)="onContentReadyUnAssigned($event)"
      noDataText=""
      [dataSource]="UnAssignedDrivers"
      [allowColumnResizing]="false"
      [columnAutoWidth]="false"
      [(selectedRowKeys)]="unassignedDriverSelected"
      [columns]="driverColumnConfig"
    >
      <dxo-selection
        [selectAllMode]="allMode"
        [showCheckBoxesMode]="checkBoxesMode"
        mode="multiple"
      ></dxo-selection>
      <dxo-filter-row [visible]="true"> </dxo-filter-row>
      <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
      <dxo-sorting mode="single"> </dxo-sorting>
      <dxo-paging [pageSize]="10"> </dxo-paging>
    </dx-data-grid>
  </div>
</div>
