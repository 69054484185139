import { Injectable } from '@angular/core';
import { ManageImagesInterface, ManageImage } from './manage-images.model';
import { Entity } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
  providedIn: 'root'
})
export class ManageImagesService implements ManageImagesInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  create(data: Entity<ManageImage>): Observable<any> {
    return this._http.post<ManageImage>(
      e.endpoint.fleet.manageImages,
      data.value).pipe(catchError(this.errors$.handleNetworkError));
  }


  save(data: Entity<ManageImage>): Observable<any> {

    return this._http.put<ManageImage>(
      e.endpoint.fleet.manageImages,
      data.value).pipe(catchError(this.errors$.handleNetworkError));
  }


  delete(data: any): Observable<any> {

    return this._http.request('delete',
      e.endpoint.fleet.manageImages,
      {
        body: data
      }).pipe(catchError(this.errors$.handleNetworkError));
  }


  private listRetrieved: BehaviorSubject<ManageImage[]> = new BehaviorSubject<ManageImage[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  getList(): Observable<ManageImage[]> {

    return this._http.get<ManageImage[]>(e.endpoint.fleet.manageImages)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  notifyRetrieved(data: ManageImage[]): void {
    this.listRetrieved.next(data);
  }


  getEntity(key: any): Observable<ManageImage> {

    return this._http.get<ManageImage>(e.endpoint.fleet.manageImages + '/' + key.Id + '/' + key.Bw)
    .pipe(catchError(this.errors$.handleNetworkError));
  }


  getUploadUrl() { return e.endpoint.fleet.manageImagesUploadURL };
  getUploadHeaders() {
    return {
      'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
    }
  };
}
