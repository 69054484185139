import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { getConfigLables } from '@app/common_helper';
import { cloneDeep } from 'lodash';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranspacConfigurationInterface } from '../transpac-configuration.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { TranspacConfigurationService } from '../transpac-configuration.service';
import { ActionsService } from '@app/_shared/actions/actions.service';

@Component({
  selector: 'facnm-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrls: ['./configuration-modal.component.scss'],
  providers: [
    {
      provide: TranspacConfigurationInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new TranspacConfigurationService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class ConfigurationModalComponent implements OnInit {

  @Output() popupClose: EventEmitter<any> = new EventEmitter();
  @Input() configurationValue: any;
  @Input() readonly = false;

  modalHeading: any;
  multiFormSettings: any;
  labelConfig: any;
  newDataIndex: any;
  isNew: boolean;
  serverErrors: any

  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private configuration$: TranspacConfigurationInterface,
    private actions$: ActionsService,
    private renderer: Renderer2,
    private element: ElementRef
  ) { }

  ngAfterViewInit() {
    this.ngxSmartModalService.open('modal');
  }

  ngOnInit(): void {
    this.newDataIndex = [];
    this.modalHeading = this.configurationValue.Name;
    if (!this.configurationValue.XmlSchema.isSingle) {
      this.labelConfig = getConfigLables(this.configurationValue.Id);
      this.multiFormSettings = {
        newForm: cloneDeep(this.configurationValue.XmlSchema.fieldSequence.splice(this.configurationValue.XmlSchema.fieldSequence.length - 1, 1)),
        stagingComplexConfig: cloneDeep(this.configurationValue.XmlSchema.fieldSequence),
        selectedFormIndex: 0,
        copiedForm: null
      }
    }
  }

  addNewRegister() {
    this.modalHeading = this.labelConfig.addModalTitle;
    this.isNew = true;
    this.multiFormSettings.stagingComplexConfig.push(cloneDeep(this.multiFormSettings.newForm[0]));
    this.multiFormSettings.selectedFormIndex = this.multiFormSettings.stagingComplexConfig.length - 1;
    this.newDataIndex.push(this.multiFormSettings.selectedFormIndex);
  }

  isNewGroup() {
    this.isNew = this.newDataIndex.indexOf(this.multiFormSettings.selectedFormIndex) > -1
    if (this.isNew) {
      this.modalHeading = this.labelConfig.addModalTitle;
    } else {
      this.modalHeading = this.configurationValue.Name;
    }
  }

  onPopupClose() {
    this.popupClose.emit();
  }

  onPopupCancel() {
    this.ngxSmartModalService.close('modal');
  }

  onPopupSave() {
    this.closeServerErrors();
    if (!this.configurationValue.XmlSchema.isSingle) {
      this.configurationValue.XmlSchema.fieldSequence = cloneDeep(this.multiFormSettings.stagingComplexConfig);
    }

    this.configuration$.saveModalConfigurationValues(this.configurationValue).subscribe(
      (okCreate) => {
        if (!this.configurationValue.XmlSchema.isSingle) {
          this.newDataIndex = [];
          this.isNewGroup();
        }
        this.actions$.notifyEntityCustomAction({ title: 'Success!', message: "Saved Configuration details successfully" });
      },
      (errorCreate) => {
        this.serverErrors = errorCreate;
        this.scrollToModalTop()
      }
    )
  }

  copyConfigValue() {
    this.multiFormSettings.copiedForm = cloneDeep(this.multiFormSettings.stagingComplexConfig[this.multiFormSettings.selectedFormIndex]);
    this.actions$.notifyEntityCustomAction({ title: 'Success!', message: "Copied config values successfully" });
  }

  pasteConfigValue() {
    this.multiFormSettings.stagingComplexConfig[this.multiFormSettings.selectedFormIndex] =
      cloneDeep(this.multiFormSettings.copiedForm);
    this.actions$.notifyEntityCustomAction({ title: 'Success!', message: "Paste config values successfully" });
  }

  deleteConfig() {
    if (this.multiFormSettings.stagingComplexConfig.length) {
      this.multiFormSettings.stagingComplexConfig.splice(this.multiFormSettings.selectedFormIndex, 1);
      this.actions$.notifyEntityCustomAction({ title: 'Success!', message: "Config values deleted successfully" });
      if (this.multiFormSettings.stagingComplexConfig.length > 0)
        this.multiFormSettings.selectedFormIndex = 0;
    }
  }

  closeServerErrors() {
    this.serverErrors = "";
  }

  scrollToModalTop() {
    const bodyElement = this.element.nativeElement.querySelector('#modal-body');
    this.renderer.setProperty(bodyElement, 'scrollTop', 0);
  }

}
