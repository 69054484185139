import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Factor, FactorInterface } from './factor.model';
import { FactorService } from './factor.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsUndefinedOrNull, dxDataGridHeightAdjustment } from '@app/common_helper';


@Component({
  selector: 'facnm-factor',
  templateUrl: './factor.component.html',
  styleUrls: ['./factor.component.scss'],
  providers: [
    {
      provide: FactorInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new FactorService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class FactorComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  entityName = 'Factors'
  entityNameNotification = 'Factor';
  gridDataSource: any;
  entityData: Entity<Factor>;
  collapseColor = 'green';
  isAddForm = 0;
  newEntityData: Factor;
  CSCollapse = false;
  isNewQuantityUnit = false;
  newQuantityUnit: string;
  factorGridErrorMsg = '';
  numberPattern: any = "/^\d+([.]\d+)?$/";
  decimalPattern: any = "^[0-9]{0,4}(\.[0-9]{0,2})?$";

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  quantityUnitList = [];
  factorIdList = [];
  factorCodeList = [];
  getFactorIdList = [];
  getFactorCodeList = [];

  private dataGridFactorItems: DxDataGridComponent;
  @ViewChild('dataGridFactorItems', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridFactorItems = content;
    }
  }

  constructor(
    private factor$: FactorInterface,
    private app$: AppService,
    private errors$: ErrorsService,
    private actions$: ActionsService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.actions();
    forkJoin({
      factorList: this.factor$.getList(0),
      newEntity: this.factor$.getNewEntity(),
      quantityUnit: this.factor$.getQuantityUnitList(),

    }).subscribe(
      res => {
        this.quantityUnitList = res['quantityUnit'];
        this.factor$.notifyRetrieved(res['factorList']);
        this.newEntityData = res['newEntity'];
        this.app$.notifyScreenReady();

        this.getFactorIdList = res['factorList'];
        this.getFactorIdList.forEach(s => {
          this.factorIdList.push(s.Id);
        });

        this.getFactorCodeList = res['factorList'];
        this.getFactorCodeList.forEach(c => {
          this.factorCodeList.push(c.Code);
        });
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.factor$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridDataSource = new ArrayStore({
          data: data,
          key: ['Id', 'Code']
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.factor$.getList(0).subscribe(listWithCreated => {
      this.factor$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  addNewQuantityUnit() {
    this.isNewQuantityUnit = true;
    this.newQuantityUnit = "";
  }
  cancelNewQuantityUnit() {
    this.isNewQuantityUnit = false;
    this.newQuantityUnit = "";
  }
  saveNewQuantityUnit = function () {
    if (this.newQuantityUnit != "") {
      var flag = false;
      for (var i = 0; i < this.quantityUnitList.length; i++) {
        if (this.quantityUnitList[i].toLowerCase() == this.newQuantityUnit.toLowerCase()) {
          flag = true;
          this.newQuantityUnit = this.quantityUnitList[i];
        }
      }
      if (!flag) this.quantityUnitList.push(this.newQuantityUnit);
      this.entityData.value.QuantityUnit = this.newQuantityUnit;
      this.isNewQuantityUnit = false;
    }
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid || (this.entityData.value.Items.length < 1)) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    this.factor$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = { Id: this.entityData.value.Id, Code: this.entityData.value.Code };
    this.factor$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Factors Found";
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
    this.isNewQuantityUnit = false;
    this.newQuantityUnit = ""; 
   }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.factor$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
      this.refreshFactorItemsGrid();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }
  refreshGrid() {
    this.dataGrid.instance.refresh();
  }
  refreshFactorItemsGrid() {
    if (this.dataGridFactorItems) {
      this.dataGridFactorItems.instance.refresh();
      this.factorGridErrorMsg = "";
    }
  }

  onFactorItemsGridRowInserting(e) {
    this.factorGridErrorMsg = "";
    if (!IsUndefinedOrNull(e.data)) {
      if (IsUndefinedOrNull(e.data.Temperature) || IsUndefinedOrNull(e.data.Value)) {

        this.factorGridErrorMsg = "Please enter all values for new record.";
        e.cancel = true;
        return;
      }
      else {
        let checkIsNumeric = /^[0-9]*$/;
        if (!checkIsNumeric.test(e.data.Temperature)) {
          this.factorGridErrorMsg = "Please enter numeric value only for Temperature.";
          e.cancel = true;
          return;
        }
        this.factorGridErrorMsg = "";
      }
    }
    else {
      this.factorGridErrorMsg = "Please enter all values for new record.";
      e.cancel = true;
      return;
    }
  }
  private reset() {
    this.entityData.restore();
    this.isNewQuantityUnit = false;
    this.newQuantityUnit = ""; 
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityNameNotification);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
    this.factorGridErrorMsg = "";
    this.isAddForm = 1;
  }

  onContentReadyFactorItems(e: any) {
    this.dataGridFactorItems.noDataText = this.dataGridFactorItems.instance.totalCount() ? '' : "No Factor Items Found";
    this.factorGridErrorMsg = "";
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
}
