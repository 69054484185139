<div id="print-templates-edit"
     class="container-fluid p-4 h-100"
     #screen
     (window:resize)="onResize($event)">


    <div class="row"
         [style.height.px]="printTemplateEditor.height">

        <div class="col"
             [style.height.px]="printTemplateEditor.height - errorsHeight">


            <facnm-errors></facnm-errors>


            <div class="print-template-edit-sections">
                <dx-tabs #tabs
                         [dataSource]="[
                                        { text: 'EDIT', icon: 'text' },
                                        { text: 'ATTRIBUTES',icon:'table'},
                                        { text: 'TEST', icon: 'date' },
                                        { text: 'LOG', icon: 'table' }
                                    ]"
                         (onItemClick)="switchSection($event)"
                         (onContentReady)="onContentReady_sections($event)"
                         showNavButtons="false">

                    <div *dxTemplate="let section of 'item'">
                        <div class="section-name"
                             [ngStyle]="{'width': printTemplateEditSectionWidth + 'px'}">
                            <img class="img-icon" src="assets/Content/Images/Icons/{{section.icon}}.svg" />
                            {{section.text}}
                        </div>
                    </div>

                </dx-tabs>
            </div>

            <ng-container *ngIf="printTemplateEditSection == 'EDIT'">

                <div class="text-uppercase text-monospace">
                    <div class="alert alert-secondary">
                        <span class="badge badge-light"> template </span>
                        <span class="badge badge-secondary"> {{ printTemplate.TemplateName }} </span> |
                        <span class="badge badge-light"> language </span>
                        <span class="badge badge-secondary"> {{ printTemplate.Language == '' ? 'none' : printTemplate.Language }} </span> |
                        <span class="badge badge-light"> company </span>
                        <span class="badge badge-light"> company </span>
                        <span class="badge badge-secondary"> {{ getCompanyName() }} </span>
                    </div>
                </div>

                <ngx-codemirror #editorView
                                (ngModelChange)="onChangePrintTemplateContent($event)"
                                [(ngModel)]="printTemplateContent"
                                (cursorActivity)="cursorActivity()"
                                [options]="printTemplateEditor.options"> </ngx-codemirror>

                <div class="text-uppercase text-monospace">
                    <div class="alert alert-secondary">
                        <span class="badge badge-light"> line </span>
                        <span class="badge badge-secondary"> {{printTemplateEditor.cursor.line + 1}} </span> |
                        <span class="badge badge-light"> Column </span>
                        <span class="badge badge-secondary"> {{printTemplateEditor.cursor.ch}} </span>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="printTemplateEditSection == 'ATTRIBUTES'">
                <div class="print-template-test-search d-flex p-3 w-100 justify-content-center">
                    <ng-container>
                       <div class="d-flex pr-4  align-items-center ">
                          <div class="pl-4 pr-4">Top</div>
                          <div>
                             <input type="number" [(ngModel)]="printSettingsData.TopBorder" (ngModelChange)="onChangePrintTemplateContent('Attribute')" class="form-control" name="Top"  />
                          </div>
                       </div>
                       <div class="d-flex pr-4  align-items-center ">
                          <div class="pl-4 pr-4">Bottom</div>
                          <div>
                             <input type="number" [(ngModel)]="printSettingsData.BottomBorder" (ngModelChange)="onChangePrintTemplateContent('Attribute')" class="form-control" name="Bottom"  />
                          </div>
                       </div>
                       <div class="d-flex pr-4  align-items-center ">
                        <div class="pl-4 pr-4">Print payment slip</div>
                        <div class="checkbox-form-group">
                            <input (ngModelChange)="onChangePrintTemplateContent('Attribute')" [(ngModel)]="printSettingsData.PrintPaymentSlip" type="checkbox"
                                   class="checkbox-inline"/>
                         </div>
                       </div>
                       <div class="d-flex pl-4  "></div>
                    </ng-container>
                 </div>
                <div id="entity-list" 
                class="h-100"
                >
                <dx-data-grid #dataGridAttributeData
                                    [dataSource]="attributeDataStore"
                                    noDataText=""
                                    (onInitialized)="onInitialized_Attribute($event)"
                                    (onRowInserted)="onChangePrintTemplateContent('Grid')"
                                    (onRowUpdated)="onChangePrintTemplateContent('Grid')"
                                    (onRowRemoved)="onChangePrintTemplateContent('Grid')"
                                    (onSaved)="onChangePrintTemplateContent('Grid')"
                                    [allowColumnResizing]="true"
                                    [columnAutoWidth]="true"
                                    [columnMinWidth]="50"
                                    [width]="'100%'">
                                  
                        <dxo-editing
                            mode="row"
                            [allowUpdating]="true"
                            [allowDeleting]="true"
                            [allowAdding]="true">
                        </dxo-editing>
                        <dxi-column dataField="Keyword" alignment="center"></dxi-column>
                        <dxi-column dataField="DecimalPlaces" alignment="center"></dxi-column>
                        <dxi-column dataField="Bold" dataType="boolean"></dxi-column>
                        <dxi-column dataField="Italic" dataType="boolean"></dxi-column>
                        <dxi-column dataField="Underline" dataType="boolean"></dxi-column>
                        <dxi-column dataField="Value" alignment="center"></dxi-column>
                        
                        <dxo-selection mode="single"> </dxo-selection>

                        <dxo-filter-row [visible]="true"> </dxo-filter-row>
 
                        <dxo-scrolling mode="standard"> </dxo-scrolling>

                        <dxo-sorting mode="single"> </dxo-sorting>

                        <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                                   [showPageSizeSelector]="true"
                                   [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="20"> </dxo-paging>

                    </dx-data-grid>
                </div>
            </ng-container>

            <ng-container *ngIf="printTemplateEditSection == 'TEST' && canTestTemplate()">

                <div class="print-template-test-search d-flex p-3 w-100 justify-content-center">


                    <div class="d-flex pl-4  ">


                        <dx-drop-down-box #dropdownDevices
                                          [(value)]="currentDeviceId"
                                          valueExpr="Id"
                                          [displayExpr]="getSelectedDeviceName"
                                          placeholder="Select a device..."
                                          [showDropDownButton]="false"
                                          [showClearButton]="false"
                                          [deferRendering]="false"
                                          [dataSource]="devices">

                            <dxo-drop-down-options  width="auto"
                                                   container="#print-templates-edit"
                                                   [elementAttr]="{ id: 'dropdownDevices', class: 'dropdown-devices' }">
                            </dxo-drop-down-options>

                            <div *dxTemplate="let data of 'content'">


                                <div class="gridDiv">

                                    <dx-data-grid #dataGridDevices
                                                  [dataSource]="devicesStore"
                                                  (onRowClick)="onDevicesRowClick($event)"
                                                  [allowColumnResizing]="true"
                                                  [(selectedRowKeys)]="Id">

                                        <dxi-column dataField="IMEI"
                                                    [minWidth]="120"></dxi-column>

                                        <dxi-column dataField="Name"
                                                    [minWidth]="150"></dxi-column>

                                        <dxi-column dataField="Vehicle.VehicleNumber"
                                                    caption="Vehicle No"
                                                    [minWidth]="100"></dxi-column>

                                        <dxi-column dataField="Vehicle.LicensePlate"
                                                    caption="License Plate"
                                                    [minWidth]="150"></dxi-column>


                                        <dxo-selection [mode]="single"
                                                       [allowSelectAll]="false"> </dxo-selection>

                                        <dxo-filter-row [visible]="true">
                                        </dxo-filter-row>

                                        <dxo-scrolling showScrollbar="always"
                                                       useNative="true">
                                        </dxo-scrolling>

                                        <dxo-sorting mode="single"> </dxo-sorting>

                                        <dxo-paging [pageSize]="5"> </dxo-paging>
                                        <dxo-pager [showNavigationButtons]="true">
                                        </dxo-pager>

                                    </dx-data-grid>

                                </div>


                            </div>
                        </dx-drop-down-box>


                    </div>


                    <ng-container *ngIf="canSearchTemplateDataWithDateRange()">

                        <div class="d-flex pr-4  align-items-center">
                            <div class="pl-4 pr-4">From</div>
                            <div>
                                <pdi-date-picker label="" [enabledRange]="enabledRangeTo" [(ngModel)]="searchDateFrom_PrintTemplateTestData"
                                 [format]="dateFormat" (ngModelChange)="OnloadSearchDateTo_PrintTemplateTestData($event)"></pdi-date-picker>
                            </div>
                        </div>

                        <div class="d-flex pr-4  align-items-center">
                            <div class="pl-4 pr-4">To</div>
                            <div>
                                <pdi-date-picker label="" [enabledRange]="enabledRangeFrom" [(ngModel)]="searchDateTo_PrintTemplateTestData" 
                                [format]="dateFormat" (ngModelChange)="OnloadSearchDateFrom_PrintTemplateTestData($event)"></pdi-date-picker>
                            </div>
                        </div>

                    </ng-container>



                    <div class="d-flex pl-4">

                        <ng-container *ngIf="canSearchTemplateData()">

                            <button (click)="search()"
                                    id="action-confirm-search"
                                    [disabled]="isEmptySearchFilter()">
                                <img src="assets/Content/Images/Icons/search.png"
                                     class="search-button-image"
                                     [ngClass]="{'disabled': isEmptySearchFilter()}" />
                                <span class="search-button d-none d-lg-inline-block"
                                      [ngClass]="{'disabled': isEmptySearchFilter()}">Search</span>
                            </button>

                        </ng-container>

                    </div>


                </div>

                <div *ngIf="canSearchTemplateData()"
                     id="entity-list"
                     class="h-100">

                    <dx-data-grid #dataGridTestData
                                  [dataSource]="testDataStore"
                                  noDataText=""
                                  (onInitialized)="onInitialized_TestData($event)"
                                  (onRowClick)="onRowClick_TestData($event)"
                                  [allowColumnResizing]="true"
                                  [columnAutoWidth]="true"
                                  [columnMinWidth]="50"
                                  [width]="'100%'">


                        <ng-container *ngIf="isSaleTemplate() || isCustomerDocumentTemplate()">
                            <dxi-column dataField="SaleDate"></dxi-column>
                            <dxi-column dataField="ReceiptNumber"
                                        dataType="string"></dxi-column>
                            <dxi-column caption="Template Name"
                                        dataField="TemplateTypeId">
                                <dxo-lookup [dataSource]="templateTypesReduced"
                                            valueExpr="id"
                                            displayExpr="name">
                                </dxo-lookup>
                            </dxi-column>
                            <dxi-column dataField="CustomerName"></dxi-column>
                            <dxi-column dataField="CustomerAddress"></dxi-column>
                        </ng-container>


                        <ng-container *ngIf="isLoadingTemplate()">
                            <dxi-column dataField="TourNo"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="TourId"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="Id"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="LoadLocation"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="DateFrom"></dxi-column>
                            <dxi-column dataField="DateTo"></dxi-column>
                        </ng-container>


                        <ng-container *ngIf="isSafetyCheckTemplate()">
                            <dxi-column dataField="SessionId"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="Id"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="Date"></dxi-column>
                            <dxi-column dataField="DriverName"></dxi-column>
                            <dxi-column caption="Safety Group"
                                        dataField="SafetyGroupId">
                                <dxo-lookup [dataSource]="safetyGroupsReduced"
                                            valueExpr="id"
                                            displayExpr="name">
                                </dxo-lookup>
                            </dxi-column>
                        </ng-container>


                        <ng-container *ngIf="isSaleProtocolTemplate()">
                            <dxi-column dataField="SessionId"
                                        dataType="string"
                                        [visible]="!isTourSaleProtocol"></dxi-column>
                            <dxi-column dataField="TourId"
                                        dataType="string"
                                        [visible]="isTourSaleProtocol"></dxi-column>
                            <dxi-column dataField="TourNo"
                                        dataType="string"
                                        [visible]="isTourSaleProtocol"></dxi-column>
                            <dxi-column dataField="DateStart"></dxi-column>
                            <dxi-column dataField="DateEnd"></dxi-column>
                            <dxi-column dataField="DriverName"></dxi-column>
                        </ng-container>


                        <ng-container *ngIf="isAssessmentTemplate()">
                            <dxi-column dataField="SessionId"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="Id"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="FormName"></dxi-column>
                        </ng-container>

                        <ng-container *ngIf="isDeliveryAuthorizationDocumentTemplate()">
                            <dxi-column dataField="SessionId"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="Id"
                                        dataType="string"></dxi-column>
                            <dxi-column dataField="AuthorizationDate"></dxi-column>
                            <dxi-column dataField="CustomerName"></dxi-column>
                            <dxi-column dataField="CustomerAddress"></dxi-column>
                        </ng-container>

                        <dxo-selection mode="single"> </dxo-selection>

                        <dxo-filter-row [visible]="true"> </dxo-filter-row>

                        <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                        <dxo-sorting mode="single"> </dxo-sorting>

                        <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                                   [showPageSizeSelector]="true"
                                   [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="20"> </dxo-paging>


                    </dx-data-grid>

                </div>

            </ng-container>

            <ng-container *ngIf="printTemplateEditSection == 'LOG'">

                <div id="entity-list"
                     class="h-100 mt-5">

                    <dx-data-grid #dataGridLogData
                                  [dataSource]="logDataStore"
                                  noDataText=""
                                  (onInitialized)="onInitialized_LogData($event)"
                                  [allowColumnResizing]="true"
                                  [columnAutoWidth]="true"
                                  [columnMinWidth]="50"
                                  [width]="'100%'">
                        <dxo-load-panel>
                            <div *dxTemplate="let data of 'content'">
                                <pdi-loader size="medium"></pdi-loader>
                            </div>
                        </dxo-load-panel>
                        <dxi-column dataField="LogDate"
                                    dataType="datetime"></dxi-column>
                        <dxi-column dataField="UserName"></dxi-column>
                        <dxi-column caption="Lines Changes"
                                    [calculateCellValue]="calculateLogDataChanges"></dxi-column>

                        <dxo-selection mode="single"> </dxo-selection>

                        <dxo-filter-row [visible]="true"> </dxo-filter-row>

                        <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                        <dxo-sorting mode="single"> </dxo-sorting>

                        <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                                   [showPageSizeSelector]="true"
                                   [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="20"> </dxo-paging>


                    </dx-data-grid>

                </div>

            </ng-container>

        </div>
    </div>

    <div class="row text-center print-edit-actions">

        <div class="col">
            <button (click)="saveEditedTemplate()"
                    id="action-confirm-save"
                    [disabled]="isSaveDisabled">
                <img src="assets/Content/Images/Icons/save.png"
                     class="edit-button-image"
                     [ngClass]="{'disabled': isSaveDisabled}" />
                <span class="edit-button d-none d-sm-inline-block"
                      [ngClass]="{'disabled': isSaveDisabled}" >Save</span>
            </button>
        </div>
        <div class="col">
            <button (click)="test()"
                    id="action-confirm-test"
                    [disabled]="isEmptyTemplateData()">
                <img src="assets/Content/Images/Icons/print_button.png"
                     class="edit-button-image"
                     [ngClass]="{'disabled': isEmptyTemplateData()}" />
                <span class="edit-button d-none d-sm-inline-block"
                      [ngClass]="{'disabled': isEmptyTemplateData()}">Test</span>
            </button>
            <img *ngIf="okPrintTest"
                 width="25px"
                 class="ml-4"
                 src="assets/Content/Images/Icons/sync_yes.png" />
            <img *ngIf="okPrintTest == false"
                 width="25px"
                 class="ml-4 can-click"
                 src="assets/Content/Images/Icons/sync_no.png"
                 id="printErrorImage" />

            <ng-container *ngIf="PrintErrors && PrintErrors.length > 0">
                <dx-popover target="#printErrorImage"
                            showEvent="dxclick"
                            container="#print-templates-edit">
                    <div *dxTemplate="let data of 'content'">
                        <p *ngFor="let printError of PrintErrors">{{printError}}</p>
                    </div>
                </dx-popover>
            </ng-container>

        </div>
        <div class="col">
            <button (click)="cancelEditTemplate()"
                    id="action-confirm-cancel">
                <img src="assets/Content/Images/Icons/cancel.png"
                     class="edit-button-image" />
                <span class="edit-button d-none d-sm-inline-block">Cancel</span>
            </button>
        </div>
    </div>

</div>


<facnm-loading-screen-indicator></facnm-loading-screen-indicator>
