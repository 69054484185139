<div class="pdi-login-sso">
    <div class="pdi-login-card pdi-centered">
      <div class="pdi-login-logo-dark"></div>
      <div class="simple-separator"></div>
      <pdi-page-notification class="mb-0 left-align" [canDismiss]="false" *ngIf="error" [text]="error"
      type="alert"></pdi-page-notification>
      <form [formGroup]="loginSSOForm" #ngForm="ngForm" (ngSubmit)="onSubmit(ngForm)" class="pdi-login-sso-form" novalidate>
        <label class="pdi-login__label">Content Manager</label>
          <pdi-input [darkMode]="true" label="Company" placeholder="Company"
          formControlName="company" required [full]="true"
            [customErrorMessages]="{ 'customError': 'Company is required'}"></pdi-input>
            <pdi-button buttonId="submit" [darkMode]="true" size="primary" buttonType="submit" [text]="'Continue login with
            okta'"></pdi-button>
      </form>
    </div>
  </div>
  