<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">

    <div id="product-blends"
         class="row h-100">

        <div class="col-4">

            <div id="entity-list"
                 class="h-100">

                <dx-data-grid #dataGrid
                              [dataSource]="productBlendsStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true">

                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>

                    <dxi-column dataField="BlendName"></dxi-column>



                    <dxo-selection mode="single"> </dxo-selection>

                    <dxo-filter-row [visible]="true"> </dxo-filter-row>

                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                    <dxo-sorting mode="single"> </dxo-sorting>

                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>

                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="product-blends.dataGrid"></dxo-state-storing>

                </dx-data-grid>

            </div>

        </div>


        <div id="entity-detail"
             class="col-8 pr-0">

            <div *ngIf="productBlend && productBlend.key">


                <form id="entity-form"
                      #productBlendForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">

                            <div id="entity-errors"
                            class="row">
                           <div class="col">

                               <facnm-errors></facnm-errors>

                           </div>
                       </div>


                        <div class="row">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="BlendName"
                                                   class="col-form-label">Name</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="productBlend.value.BlendName"
                                                   [ngClass]="{'modelstate-invalid': !BlendName.dirty && isInvalid('BlendName')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="BlendName"
                                                   #BlendName="ngModel">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!BlendName.dirty && isInvalid('BlendName')">
                                                <small class="error">
                                                    {{getError('BlendName')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>



                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="ProductId"
                                                   class="col-form-label">Product <i *ngIf="!productBlend.value.ProductId && isInvalid('ProductId')"
                                                   class="fa fa-exclamation-circle modelstate-invalid"></i></label>
                                        </div>
                                        <div class="col-8">

                                            <div>

                                                <dx-drop-down-box #dropdownBlendProduct
                                                                  [(value)]="productBlend.value.ProductId"
                                                                  valueExpr="Id"
                                                                  [displayExpr]="getSelectedProductName"
                                                                  placeholder="Select a product..."
                                                                  [showDropDownButton]="false"
                                                                  [showClearButton]="false"
                                                                  [deferRendering]="false"
                                                                  [dataSource]="productList"
                                                                  [elementAttr]="{ class: !productBlend.value.ProductId && isInvalid('ProductId') ? 'modelstate-invalid' : 'modelstate-valid' }">

                                                    <div *dxTemplate="let data of 'content'">

                                                        <facnm-products-grid [(ProductId)]="productBlend.value.ProductId"
                                                                             (ProductIdChange)="onProductIdChange($event)"></facnm-products-grid>

                                                    </div>
                                                </dx-drop-down-box>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>


                        <div class="row">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-12">
                                            <label class="col-form-label">
                                                Products in blend <i *ngIf="!productBlend.value.BlendProductsId && isInvalid('BlendProductsId')"
                                                   class="fa fa-exclamation-circle modelstate-invalid"></i></label>
                                        </div>
                                        <div class="col-12">

                                            <div [ngClass]="{
                                                    editGridDiv: !productBlend.created, 
                                                    newGridDiv: productBlend.created
                                                }">
                                                <dx-data-grid #productsInBlend
                                                              [dataSource]="productsInBlendStore"
                                                              noDataText="Add products to this blend"
                                                              [allowColumnResizing]="true"
                                                              [(selectedRowKeys)]="selectedProductsInBlend">


                                                    <dxi-column dataField="ProductNumber"
                                                                [minWidth]="100"></dxi-column>
                                                    <dxi-column dataField="ProductName"
                                                                [width]="'65%'"></dxi-column>



                                                    <dxo-selection [mode]="'multiple'"
                                                                   [allowSelectAll]="false">
                                                    </dxo-selection>


                                                    <dxo-filter-row [visible]="true">
                                                    </dxo-filter-row>

                                                    <dxo-scrolling showScrollbar="always"
                                                                   useNative="true">
                                                    </dxo-scrolling>

                                                    <dxo-sorting mode="single"> </dxo-sorting>

                                                    <dxo-paging [pageSize]="5"> </dxo-paging>
                                                    <dxo-pager [showNavigationButtons]="true">
                                                    </dxo-pager>


                                                </dx-data-grid>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="col-1 mt-5 text-center p-0">

                                <img *ngIf="!selectedProducts || (selectedProducts && selectedProducts.length == 0)"
                                     class="pt-5 mt-5 arrow-move"
                                     src="assets/Content/Images/Icons/arrow_left.svg">
                                <img *ngIf="!productBlend.created && selectedProducts && selectedProducts.length > 0"
                                     (click)="addProducts()"
                                     class="img-button pt-5 mt-5 arrow-move active"
                                     src="assets/Content/Images/Icons/arrow_left_active.svg">
                                <img *ngIf="productBlend.created && selectedProducts && selectedProducts.length > 0"
                                     (click)="addProducts()"
                                     class="img-button pt-5 mt-5 arrow-move active"
                                     src="assets/Content/Images/Icons/arrow_left_active.svg">

                                <img *ngIf="!selectedProductsInBlend || (selectedProductsInBlend && selectedProductsInBlend.length == 0)"
                                     class="pt-5 mt-5 arrow-move"
                                     src="assets/Content/Images/Icons/arrow_right.svg">
                                <img *ngIf="selectedProductsInBlend && selectedProductsInBlend.length > 0"
                                     (click)="removeProducts()"
                                     class="img-button pt-5 mt-5 arrow-move active"
                                     src="assets/Content/Images/Icons/arrow_right_active.svg">

                            </div>

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-12">
                                            <label class="col-form-label">
                                                Products</label>
                                        </div>
                                        <div class="col-12">


                                            <facnm-products-grid #products
                                                                 [(ProductsId)]="selectedProducts"
                                                                 [selectionMode]="'multiple'"></facnm-products-grid>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="productBlend"></facnm-action-buttons>            

        </div>

    </div>

</div>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>