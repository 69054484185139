import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { DataArea, CustomerDataAreaInterface } from './customer-data-area.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataAreaService implements CustomerDataAreaInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  private listRetrieved: BehaviorSubject<DataArea[]> = new BehaviorSubject<DataArea[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: DataArea[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<DataArea>) => Observable<any>;

  create(data: Entity<DataArea>): Observable<any> {
    return this._http.put<DataArea>(
      e.endpoint.products.dataArea + "/customertype/",
      data.value)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  getList(lookup: any): Observable<DataArea[]> {
    return this._http.get<DataArea[]>(e.endpoint.products.dataArea + '/list/' + lookup)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    return this._http.delete(e.endpoint.products.dataArea + '/' + param.Id, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(param: any) {
    return this._http.get<DataArea>(e.endpoint.products.dataArea + "/customertype/" + param.Id)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity(): Observable<DataArea> {
    return this._http.get<DataArea>(e.endpoint.products.dataArea + '/newdataarea')
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  
}
