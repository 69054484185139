<div id="safetyCheck">
    <div class="col-md-8 pl-0" style="height:100%; overflow-y: auto;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
            <div class="filterHeading">
                <img src="assets/Content/Images/Icons/filter_icon.png" />
                <div class="ml-2 sub-heading">Filter Options</div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-2 form-horizontal">
            <div class="form-group" *ngIf="safetyCheckCompanies">
                <div class="col-md-6 col-sm-6 col-xs-6">Company</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <pdi-dropdown [full]="true" [(ngModel)]="safetyCheckCompanyId" (valueSelected)="companyChanged()">
                        <pdi-dropdown-option *ngFor="let opt of safetyCheckCompanies" [key]="opt.Id">
                            {{opt.Name}}
                        </pdi-dropdown-option>  
                    </pdi-dropdown>
                </div>
            </div>
            <div class="form-group" *ngIf="safetyCheckCompanyId">
                <div class="col-md-6 col-sm-6 col-xs-6">Safety check group</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <pdi-dropdown [full]="true" [(ngModel)]="safetyCheckGroupId" (valueSelected)="safetyCheckGroupChanged()">
                        <pdi-dropdown-option *ngFor="let opt of safetyCheckTextGroups" [key]="opt.Id">
                            {{opt.Name}}
                        </pdi-dropdown-option>
                    </pdi-dropdown>
                </div>
            </div>
            <div class="space-element" *ngIf="[7,8,9,10].includes(safetyCheckGroupId)"></div>
            <div class="form-group" *ngIf="(safetyCheckGroupId == 1 || safetyCheckGroupId == 11
                 || safetyCheckGroupId == 12) && vehicleTypes">
                <div class="col-md-6 col-sm-6 col-xs-6">Vehicle type</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <pdi-dropdown [full]="true" [(ngModel)]="vehicleTypeId" (valueSelected)="vehicleTypeDataAreaChanged()">
                        <pdi-dropdown-option *ngFor="let opt of vehicleTypes" [key]="opt.Id">
                            {{opt.DisplayName}}
                        </pdi-dropdown-option>
                    </pdi-dropdown>
                </div>
            </div>
            <div class="form-group" *ngIf="(safetyCheckGroupId == 1 || safetyCheckGroupId == 11) && dataAreas">
                <div class="col-md-6 col-sm-6 col-xs-6">Data area</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <dx-select-box name="dataAreaId"
                        [(ngModel)]="dataAreaId" [dataSource]="dataAreas"
                        displayExpr="DisplayName" valueExpr="Id" searchExpr="DisplayName"
                        [searchMode]="dxSelectOptions.searchMode"
                        [searchTimeout]="dxSelectOptions.searchTimeout"
                        [minSearchLength]="dxSelectOptions.minSearchLength"
                        [searchEnabled]="true" placeholder=""
                        (onValueChanged)="vehicleTypeDataAreaChanged()" >
                    </dx-select-box>
                </div>
            </div>
            <div class="form-group" *ngIf="safetyCheckGroupId == 1 || safetyCheckGroupId == 11">
                <div class="text-center top-buffer">
                    <button id="action-reset" type="button" (click)="resetFilterOptions()" [disabled]="!vehicleTypeId">
                        <img src="assets/Content/Images/Icons/reset.png" [ngClass]="{'disabled':!vehicleTypeId}" class="edit-button-image"/>
                        <span class="edit-button hidden-md hidden-sm hidden-xs" [ngClass]="{'disabled':!vehicleTypeId}">Reset</span>
                    </button>
                </div>
            </div>
            <div class="form-group" *ngIf="safetyCheckGroupId == 2 && customerTypes">
                <div class="col-md-6 col-sm-6 col-xs-6">Customer type</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <pdi-dropdown [full]="true" [(ngModel)]="customerTypeId" (valueSelected)="getSafetyChecks()">
                        <pdi-dropdown-option *ngFor="let opt of customerTypes" [key]="opt.Id">
                            {{opt.Name}}
                        </pdi-dropdown-option>
                    </pdi-dropdown>
                </div>
            </div>
            <div class="form-group" *ngIf="(safetyCheckGroupId == 5 || safetyCheckGroupId == 13) && productTypes">
                <div class="col-md-6 col-sm-6 col-xs-6">Product type</div>
                <div class="col-md-6 col-sm-6 col-xs-6 pl-0 pr-0">
                    <pdi-dropdown [full]="true" [(ngModel)]="productTypeId" (valueSelected)="getSafetyChecks()">
                        <pdi-dropdown-option *ngFor="let opt of productTypes" [key]="opt.Id">
                            {{opt.Name}}
                        </pdi-dropdown-option>
                    </pdi-dropdown>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mt-2 top-buffer" *ngIf="safetyCheckGroupId == 1 || safetyCheckGroupId == 11">
            <div class="gridDivDevices">
                <dx-data-grid #gridSafetyCheckDevices [dataSource]="safetyChecksDevice" height="230px" noDataText=""
                (onRowClick)="onRowClickDevice($event)" (onContentReady)="onContentReadyDevice()"
                 [allowColumnResizing]="false" [columnAutoWidth]="false"
                 [columns]="devicesColumn">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer" *ngIf="safetyCheckGroupId == 1 || safetyCheckGroupId == 11">
            <div class="gridDivSafetyCheck">

                <dx-data-grid #gridSafetyCheckLoginLogout [dataSource]="safetyChecksLoginLogout" noDataText=""
                (onRowClick)="onRowClick($event)" (onCellPrepared)="cellPreparedLoginLogout($event)"
                (onContentReady)="onContentReadyLoginLogout()" [allowColumnResizing]="false" [columnAutoWidth]="false">
                <dxo-load-panel>
                    <div *dxTemplate="let data of 'content'">
                        <pdi-loader size="medium"></pdi-loader>
                    </div>
                </dxo-load-panel>
                <dxi-column alignment='center' width="10%" caption="ID" dataField="Id" dataType="number"></dxi-column>
                <dxi-column alignment='center' width="40%" caption="Text" dataField="Text" dataType="string"
                    ></dxi-column>
                <dxi-column alignment='center' width="10%" caption="Mandatory" dataField="MandatoryGlobalImage"
                    [allowFiltering]="false" [allowSorting]="false" cellTemplate="mandatoryCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cell of 'mandatoryCellTemplate'">
                    <img src="assets/{{cell.data.MandatoryGlobalImage}}" />
                </div>
                <dxi-column alignment='center' width="10%" caption="Fleet" dataField="MandatoryForFleetImage"
                    [allowFiltering]="false" [allowSorting]="false" cellTemplate="fleetCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cell of 'fleetCellTemplate'">
                    <img src="assets/{{cell.data.MandatoryForFleetImage}}" />
                </div>
                <dxi-column alignment='center' width="10%" caption="Vehicle type" dataField="MandatoryForVehicleTypeImage"
                    [allowFiltering]="false" [allowSorting]="false" cellTemplate="vehicletypeCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cell of 'vehicletypeCellTemplate'">
                    <img src="assets/{{cell.data.MandatoryForVehicleTypeImage}}" />
                </div>
                <dxi-column alignment='center' width="10%" caption="Data area" dataField="MandatoryForDataAreaImage"
                    [allowFiltering]="false" [allowSorting]="false" cellTemplate="dataAreaCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cell of 'dataAreaCellTemplate'">
                    <img src="assets/{{cell.data.MandatoryForDataAreaImage}}" />
                </div>
                <dxi-column alignment='center' width="10%" caption="Device" dataField="MandatoryForVehicleImage"
                    [allowFiltering]="false" [allowSorting]="false" cellTemplate="deviceCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cell of 'deviceCellTemplate'">
                    <img src="assets/{{cell.data.MandatoryForVehicleImage}}" />
                </div>

                <dxo-selection mode="single"> </dxo-selection>
                <dxo-filter-row [visible]="true"> </dxo-filter-row>
                <dxo-scrolling mode="standard"> </dxo-scrolling>
                <dxo-sorting mode="single"> </dxo-sorting>
            </dx-data-grid>
                
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer" *ngIf="safetyCheckGroupId == 2">
            <div class="gridDivSafetyCheck">
                <dx-data-grid #gridSafetyCheckCustomer [dataSource]="safetyChecksCustomer" noDataText=""
                    (onRowClick)="onRowClick($event)" (onCellPrepared)="cellPreparedCustomer($event)"
                    (onContentReady)="onContentReadyCustomer()" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="ID" dataField="Id" dataType="number"
                        width="10%"></dxi-column>
                    <dxi-column alignment='left' caption="Text" dataField="Text" dataType="string"
                        width="50%"></dxi-column>
                    <dxi-column alignment='center' caption="Mandatory" dataField="MandatoryGlobalImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="mandatoryCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'mandatoryCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryGlobalImage}}" />
                    </div>
                    <dxi-column alignment='center' caption="Customer type" dataField="MandatoryForCustomerTypeImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="customerTypeCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'customerTypeCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryForCustomerTypeImage}}" />
                    </div>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer" *ngIf="safetyCheckGroupId == 12">
            <div class="gridDivSafetyCheck">

                <dx-data-grid #gridSafetyCheckVehicleInspection [dataSource]="safetyChecksVehicle" noDataText=""
                    (onRowClick)="onRowClick($event)" (onCellPrepared)="cellPreparedVehicle($event)"
                    (onContentReady)="onContentReadyVehicle()" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="ID" dataField="Id" dataType="number"
                        width="10%"></dxi-column>
                    <dxi-column alignment='left' caption="Text" dataField="Text" dataType="string"
                        width="50%"></dxi-column>
                    <dxi-column alignment='center' caption="Mandatory" dataField="MandatoryGlobalImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="mandatoryCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'mandatoryCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryGlobalImage}}" />
                    </div>
                    <dxi-column alignment='center' caption="Vehicle type" dataField="MandatoryForVehicleTypeImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="vehicleTypeCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'vehicleTypeCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryForVehicleTypeImage}}" />
                    </div>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer"
            *ngIf="safetyCheckGroupId == 5 || safetyCheckGroupId == 13">
            <div class="gridDivSafetyCheck">
                <dx-data-grid #gridSafetyCheckProduct [dataSource]="safetyChecksProduct" noDataText=""
                    (onRowClick)="onRowClick($event)" (onCellPrepared)="cellPreparedProduct($event)"
                    (onContentReady)="onContentReadyProduct()" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="ID" dataField="Id" dataType="number"
                        width="10%"></dxi-column>
                    <dxi-column alignment='left' caption="Text" dataField="Text" dataType="string"
                        width="50%"></dxi-column>
                    <dxi-column alignment='center' caption="Mandatory" dataField="MandatoryGlobalImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="mandatoryCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'mandatoryCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryGlobalImage}}" />
                    </div>
                    <dxi-column alignment='center' caption="Product type" dataField="MandatoryForProductTypeImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="productTypeCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'productTypeCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryForProductTypeImage}}" />
                    </div>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-buffer" *ngIf="safetyCheckGroupId == 7 || safetyCheckGroupId == 8 
             || safetyCheckGroupId == 9 || safetyCheckGroupId == 10">
            <div class="gridDivSafetyCheck">
                <dx-data-grid #gridSafetyCheckOthers [dataSource]="safetyChecksForOthers" noDataText=""
                    (onRowClick)="onRowClick($event)" (onCellPrepared)="cellPreparedOthers($event)"
                    (onContentReady)="onContentReadyOthers()" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="ID" dataField="Id" dataType="number"
                        width="10%"></dxi-column>
                    <dxi-column alignment='left' caption="Text" dataField="Text" dataType="string"
                        width="50%"></dxi-column>
                    <dxi-column alignment='center' caption="Mandatory" dataField="MandatoryGlobalImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="mandatoryCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'mandatoryCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryGlobalImage}}" />
                    </div>
                    <dxi-column alignment='center' caption="Fleet" dataField="MandatoryForOthersImage"
                        [allowFiltering]="false" [allowSorting]="false" cellTemplate="fleetTypeCellTemplate"
                        width="20%">
                    </dxi-column>
                    <div *dxTemplate="let cell of 'fleetTypeCellTemplate'">
                        <img src="assets/{{cell.data.MandatoryForOthersImage}}" />
                    </div>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                </dx-data-grid>
            </div>
        </div>
        <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 top-buffer text-center" *ngIf="safetyCheckGroupId">
            <div> <strong>Legend:</strong>
                <span>
                    <img src="assets/Content/Images/Icons/mandatory-global-24-black.png" /> - Global Mandatory
                </span>
                <span>
                    <img src="assets/Content/Images/Icons/notvisible-24-black.png" /> - Invisible
                </span>
                <span>
                    <img src="assets/Content/Images/Icons/optional-24-yellow.png" /> - Optional
                </span>
                <span>
                    <img src="assets/Content/Images/Icons/mandatory-24-red.png" /> - Mandatory
                </span>
                <span>
                    <img src="assets/Content/Images/Icons/disabled-24-grey.png" /> - Inactive
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-4" style="height: 100%;overflow-y: auto;">
        <div class="mt-4 form-div" *ngIf="isFormVisible || isAddNewTextVisible" [ngClass]="{'login-form-div':isSafetyCheckLoginLogoutFormVisible}">
            <div *ngIf="isFormVisible">
                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                    <div class="text-center">
                        <span class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Edit
                            Texts</span>
                    </div>
                </div>
            </div>
            <form name="safetyCheckTextForm" class="form-horizontal mt-4" novalidate>

                <div *ngIf="isSafetyCheckVehicleInspectionFormVisible">
                    <div class="form-group">
                        <div class=" col-md-6 col-sm-6 col-xs-6">
                            Safety check ID
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label>{{ selectedSafetyCheckVehicleInspection.Id }}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            Text
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <textarea name="SafetyCheckText" class="form-control"
                                [(ngModel)]="selectedSafetyCheckVehicleInspection.Text"
                                [ngClass]="{'state-invalid': requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]}"
                                (keyup)="checkValidation(selectedSafetyCheckVehicleInspection)" required></textarea>
                            <div class="error-message pt-2"
                                *ngIf="requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]">
                                <small class="error" *ngIf="requiredErr[safetyCheckGroupId]">
                                    Text is required
                                </small>
                                <small class="error" *ngIf="maxLengthErr[safetyCheckGroupId]">
                                    Text cannot be longer than 50 characters
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Mandatory</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckVehicleInspection.MandatoryGlobalImage }}"
                                (click)="updateGlobalMandatory(selectedSafetyCheckVehicleInspection.MandatoryGlobal, 'vehicleinspection')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="vehicleTypeId">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            Vehicle type
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckVehicleInspection.MandatoryForVehicleTypeImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckVehicleInspection.MandatoryForVehicleType, 'vehicletype')" />
                        </div>
                    </div>
                    <div style="clear:both;"></div>
                </div>

                <div *ngIf="isSafetyCheckCustomerFormVisible">
                    <div class="form-group">
                        <div class=" col-md-6 col-sm-6 col-xs-6">
                            Safety check ID
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label>{{ selectedSafetyCheckCustomer.Id }}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            Text
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <textarea name="SafetyCheckText" class="form-control"
                                [(ngModel)]="selectedSafetyCheckCustomer.Text"
                                [ngClass]="{'state-invalid': requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]}"
                                (keyup)="checkValidation(selectedSafetyCheckCustomer)" required></textarea>
                            <div class="error-message pt-2"
                                *ngIf="requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]">
                                <small class="error" *ngIf="requiredErr[safetyCheckGroupId]">
                                    Text is required
                                </small>
                                <small class="error" *ngIf="maxLengthErr[safetyCheckGroupId]">
                                    Text cannot be longer than 50 characters
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Mandatory</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckCustomer.MandatoryGlobalImage }}"
                                (click)="updateGlobalMandatory(selectedSafetyCheckCustomer.MandatoryGlobal, 'customer')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="customerTypeId">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            Customer Type
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckCustomer.MandatoryForCustomerTypeImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckCustomer.MandatoryForCustomerType, 'customertype')" />
                        </div>
                    </div>
                    <div style="clear:both;"></div>
                </div>

                <div *ngIf="isSafetyCheckLoginLogoutFormVisible">
                    <div class="form-group">
                        <div class=" col-md-6 col-sm-6 col-xs-6">
                            Safety check ID
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label>{{ selectedSafetyCheckLoginLogout.Id }}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            Text
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <textarea name="SafetyCheckText" class="form-control"
                                [(ngModel)]="selectedSafetyCheckLoginLogout.Text"
                                [ngClass]="{'state-invalid': requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]}"
                                (keyup)="checkValidation(selectedSafetyCheckLoginLogout)" required></textarea>
                            <div class="error-message pt-2"
                                *ngIf="requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]">
                                <small class="error" *ngIf="requiredErr[safetyCheckGroupId]">
                                    Text is required
                                </small>
                                <small class="error" *ngIf="maxLengthErr[safetyCheckGroupId]">
                                    Text cannot be longer than 50 characters
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Mandatory</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckLoginLogout.MandatoryGlobalImage }}"
                                (click)="updateGlobalMandatory(selectedSafetyCheckLoginLogout.MandatoryGlobal, 'loginlogout')" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Fleet</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckLoginLogout.MandatoryForFleetImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckLoginLogout.MandatoryForFleet, 'fleet')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="vehicleTypeId">
                        <div class="col-md-6 col-sm-6 col-xs-6">Vehicle type</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckLoginLogout.MandatoryForVehicleTypeImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckLoginLogout.MandatoryForVehicleType, 'vehicletype')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="dataAreaId">
                        <div class="col-md-6 col-sm-6 col-xs-6">Data Area</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckLoginLogout.MandatoryForDataAreaImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckLoginLogout.MandatoryForDataArea, 'dataarea')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="vehicleId">
                        <div class="col-md-6 col-sm-6 col-xs-6">Device</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckLoginLogout.MandatoryForVehicleImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckLoginLogout.MandatoryForVehicle, 'vehicle')" />
                        </div>
                    </div>
                    <div style="clear:both;"></div>
                </div>

                <div *ngIf="isSafetyCheckProductFormVisible">
                    <div class="form-group">
                        <div class=" col-md-6 col-sm-6 col-xs-6">
                            Safety check ID
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label>{{ selectedSafetyCheckProduct.Id }}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            Text
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <textarea name="SafetyCheckText" class="form-control"
                                [(ngModel)]="selectedSafetyCheckProduct.Text"
                                [ngClass]="{'state-invalid': requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]}"
                                (keyup)="checkValidation(selectedSafetyCheckProduct)" required></textarea>
                            <div class="error-message pt-2"
                                *ngIf="requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]">
                                <small class="error" *ngIf="requiredErr[safetyCheckGroupId]">
                                    Text is required
                                </small>
                                <small class="error" *ngIf="maxLengthErr[safetyCheckGroupId]">
                                    Text cannot be longer than 50 characters
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Mandatory</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckProduct.MandatoryGlobalImage }}"
                                (click)="updateGlobalMandatory(selectedSafetyCheckProduct.MandatoryGlobal, 'product')" />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="productTypeId">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            Product type
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckProduct.MandatoryForProductTypeImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckProduct.MandatoryForProductType, 'producttype')" />
                        </div>
                    </div>
                    <div style="clear:both;"></div>
                </div>

                <div *ngIf="isSafetyCheckOthersFormVisible">
                    <div class="form-group">
                        <div class=" col-md-6 col-sm-6 col-xs-6">
                            Safety check ID
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label>{{ selectedSafetyCheckOthers.Id }}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            Text
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <textarea name="SafetyCheckText" class="form-control"
                                [(ngModel)]="selectedSafetyCheckOthers.Text"
                                [ngClass]="{'state-invalid': requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]}"
                                (keyup)="checkValidation(selectedSafetyCheckOthers)" required></textarea>
                            <div class="error-message pt-2"
                                *ngIf="requiredErr[safetyCheckGroupId] || maxLengthErr[safetyCheckGroupId]">
                                <small class="error" *ngIf="requiredErr[safetyCheckGroupId]">
                                    Text is required
                                </small>
                                <small class="error" *ngIf="maxLengthErr[safetyCheckGroupId]">
                                    Text cannot be longer than 50 characters
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Mandatory</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckOthers.MandatoryGlobalImage }}"
                                (click)="updateGlobalMandatory(selectedSafetyCheckOthers.MandatoryGlobal, 'others')" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-6 col-sm-6 col-xs-6">Fleet</div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <img src="assets/{{ selectedSafetyCheckOthers.MandatoryForOthersImage }}"
                                (click)="updateMandatoryState(selectedSafetyCheckOthers.MandatoryForOthers, 'others')" />
                        </div>
                    </div>
                </div>
            </form>

            <div class="bottomBtn" *ngIf="isAddNewTextVisible">
                <div class="text-center top-buffer">
                    <button id="action-add" type="button" (click)="addNewSafetyCheck()">
                        <img src="assets/Content/Images/Icons/add.png" class="add-button-image" />
                        <span class="add-button hidden-md hidden-sm hidden-xs">Add new text</span>
                    </button>
                </div>
            </div>
            <div class="bottomBtn" *ngIf="isFormVisible">
                <div class="text-center top-buffer" *ngIf="isDeleteVisible">
                    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-3 top-buffer">
                        <button id="action-delete" type="button" (click)="showDeleteConfirm = true;">
                            <img src="assets/Content/Images/Icons/delete.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Delete</span>
                        </button>
                    </div>
                    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-3 top-buffer">
                        <button id="action-save" type="button" (click)="submitForm()" [disabled]="disableSaveBtn()">
                            <img src="assets/Content/Images/Icons/save.png" class="edit-button-image"
                                [ngClass]="{'disabled':saveDisabled}" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs"
                                [ngClass]="{'disabled':saveDisabled}">Save</span>
                        </button>
                    </div>
                    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-3 top-buffer">
                        <button id="action-reset" type="button" (click)="reset()">
                            <img src="assets/Content/Images/Icons/reset.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Reset</span>
                        </button>
                    </div>

                    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-3 top-buffer">
                        <button id="action-cancel" type="button" (click)="cancelForm()">
                            <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="text-center top-buffer" *ngIf="!isDeleteVisible">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 top-buffer">
                        <button id="action-save" type="button" (click)="submitForm()">
                            <img src="assets/Content/Images/Icons/save.png"
                                class="edit-button-image edit-button-image-yellow" />
                            <span class="edit-button edit-button-yellow hidden-md hidden-sm hidden-xs ">Save</span>
                        </button>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 top-buffer">
                        <button id="action-reset" type="button" (click)="reset()">
                            <img src="assets/Content/Images/Icons/reset.png"
                                class="edit-button-image edit-button-image-yellow" />
                            <span class="edit-button edit-button-yellow hidden-md hidden-sm hidden-xs">Reset</span>
                        </button>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 top-buffer">
                        <button id="action-cancel" type="button" (click)="cancelForm()">
                            <img src="assets/Content/Images/Icons/cancel.png"
                                class="edit-button-image edit-button-image-yellow" />
                            <span class="edit-button edit-button-yellow hidden-md hidden-sm hidden-xs">Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<dx-popup title="Delete" [(visible)]="showDeleteConfirm" [animation]="animation" hideOnOutsideClick="true"
    [dragEnabled]="false" [shadingColor]="shadingColor" maxHeight="170" maxWidth="350" (onHidden)="deleteCancel()">
    <div>
        <div> Are you sure you want to delete? </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="deleteConfirm()" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/delete.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Delete</span>
                </button>
            </div>
            <div class="col">
                <button (click)="deleteCancel()" id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>
