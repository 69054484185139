import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class StrappingChart {
  Id: number;
  ProfileId: string;
  ProfileDescription: number;
  Orientation: number;
  Shape: number;
  Length: string;
  Diameter: number;
  Height: number;
  Position: any;
  StrappingChartProfileIncrements: any;
}

@Injectable()
export abstract class StrappingChartInterface implements EntityInterface<StrappingChart> {
  create: (data: Entity<StrappingChart>) => Observable<any>;
  save: (data: Entity<StrappingChart>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<StrappingChart[]>;
  getEntity: (key: any) => Observable<StrappingChart>;
  ListRetrieved: Observable<StrappingChart[]>;
  notifyRetrieved: (data: StrappingChart[]) => void;

  getNewEntity: () => Observable<StrappingChart>;
  getOrientation: () => Observable<any>;
  getShape: () => Observable<any>;
}