import { Injectable } from '@angular/core';
import { PrintTemplatesInterface, PrintTemplate, PrintTemplateKeywords, PrintTemplateTest, PrintTemplateLog, PrintTemplateAttributes } from './print-templates.model';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class PrintTemplatesService implements PrintTemplatesInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    create(data: Entity<PrintTemplate>): Observable<any> {
        return this._http.post<PrintTemplate>(
            e.endpoint.settings.printTemplates,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }

    save(data: Entity<PrintTemplate>): Observable<any> {
        return this._http.put<PrintTemplate>(
            e.endpoint.settings.printTemplates,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(data: any): Observable<any> {
        return this._http.request('delete',
            e.endpoint.settings.printTemplates,
            {
                body: data,
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    private listRetrieved: BehaviorSubject<PrintTemplate[]> = new BehaviorSubject<PrintTemplate[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(companyId: number): Observable<PrintTemplate[]> {
        return this._http.get<PrintTemplate[]>(e.endpoint.settings.printTemplates + '/' + companyId).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: PrintTemplate[]): void {
        this.listRetrieved.next(data);
    }

    getEntity(key: any): Observable<PrintTemplate> {

        return this._http.get<PrintTemplate>(e.endpoint.settings.printTemplates, {
            params: new HttpParams().set('id', key.Id).set('companyId', key.CompanyId)
        }).pipe(catchError(this.errors$.handleNetworkError));
    };

    SyncPrinterForm(entity: PrintTemplate, isLive: boolean): Observable<any> {

        return this._http.get<PrintTemplate>(e.endpoint.settings.printTemplates + '/sync', {
            params: this.getHttpParams(entity, isLive)
        }).pipe(catchError(this.errors$.handleNetworkError));
    }


    DownloadPrinterForm(entity: PrintTemplate, isLive: boolean): Observable<any> {

        return this._http.get<PrintTemplate>(e.endpoint.settings.printTemplates + '/download', { 
            params: this.getHttpParams(entity, isLive)
        }).pipe(catchError(this.errors$.handleNetworkError));
    }



    UploadPrinterForm(file: File, entity: PrintTemplate, attributes: PrintTemplateAttributes): Observable<any> {

        const formData = new FormData();
        formData.append('file', file);

        return this._http.post<PrintTemplate>(e.endpoint.settings.printTemplates + '/upload',
            formData,
            {
                params: this.getHttpPrintParams(attributes,entity, false),
            }).pipe(catchError(this.errors$.handleNetworkError));
    }



    getAliases(): Observable<PrintTemplate[]> {
        return this._http.get<PrintTemplate[]>(e.endpoint.settings.printTemplates + '/aliases')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getKeywords(): Observable<PrintTemplateKeywords> {
        return this._http.get<PrintTemplateKeywords>(e.endpoint.settings.printTemplates + '/keywords')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getLineLength(): Observable<number> {
        return this._http.get<number>(e.endpoint.settings.printTemplates + '/line-length')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getTemplatesTypes(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.settings.printTemplates + '/print-templates-types')
        .pipe(catchError(this.errors$.handleNetworkError));
    }



    private testDataRetrieved: BehaviorSubject<PrintTemplateTest[]> = new BehaviorSubject<PrintTemplateTest[]>(undefined);
    TestDataRetrieved = this.testDataRetrieved.asObservable();
    getTestData(templateTypeId: number, companyId: number, deviceId: number, dateFrom: Date, dateTo: Date): Observable<any> {
        return this._http.get<any>(e.endpoint.settings.printTemplates + '/get-template-test-entity', {
            params: new HttpParams()
                .set('templateTypeId', templateTypeId.toString())
                .set('companyId', companyId.toString())
                .set('deviceId', deviceId.toString())
                .set('dateFrom', dateFrom.toISOString())
                .set('dateTo', dateTo.toISOString())
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyTestDataRetrieved(data: PrintTemplateTest[]): void {
        this.testDataRetrieved.next(data);
    }
    

    private logDataRetrieved: BehaviorSubject<PrintTemplateLog[]> = new BehaviorSubject<PrintTemplateLog[]>(undefined);
    LogDataRetrieved = this.logDataRetrieved.asObservable();
    getLogData(entity: PrintTemplate): Observable<any> {
        return this._http.get<any>(e.endpoint.settings.printTemplates + '/get-template-logs', {
            params: this.getHttpParams(entity, true)
            
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyLogDataRetrieved(data: PrintTemplateLog[]): void {
        this.logDataRetrieved.next(data);
    }

    private attributeDataRetrieved: BehaviorSubject<PrintTemplateAttributes[]> = new BehaviorSubject<PrintTemplateAttributes[]>(undefined);
    AttributeDataRetrieved = this.attributeDataRetrieved.asObservable();
    getAttributeData(entity: PrintTemplate): Observable<any> {
        return this._http.get<any>(e.endpoint.settings.printTemplates+'/get-template-attributes', {
            params: this.getHttpParams(entity, true)
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyAttributeData(data: PrintTemplateAttributes[]): void{
        this.attributeDataRetrieved.next(data);
    }


    TestPrinterForm(printTemplateContent: string, printTemplateTest: any, deviceId: number, templateTypeId: number): Observable<Blob> {

        let testParameter = new HttpParams()
            .set('printTemplateTestJson', JSON.stringify(printTemplateTest))
            .set('deviceId', deviceId.toString())
            .set('templateTypeId', templateTypeId.toString());

        return this._http.post(e.endpoint.settings.printTemplates + '/test-printer-form',

            JSON.stringify(printTemplateContent),

            {
                responseType: 'blob',
                headers: {
                    'content-type': 'application/json'
                },
                params: testParameter

            }).pipe(catchError(this.errors$.handleNetworkError));
    }



    private getHttpParams(entity: PrintTemplate, isLive: boolean): HttpParams {
        return new HttpParams()
            .set('id', (isLive ? entity.Id : -entity.Id).toString())
            .set('companyId', entity.CompanyId.toString())
            .set('language', entity.Language.toString());
    }

    private getHttpPrintParams(attributes: PrintTemplateAttributes, entity: PrintTemplate, isLive: boolean): HttpParams {
        return new HttpParams()
            .set('attributes',JSON.stringify(attributes))
            .set('id', (isLive ? entity.Id : -entity.Id).toString())
            .set('companyId', entity.CompanyId.toString())
            .set('language', entity.Language.toString());
    }


}
