import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { AppService } from '@app/app.service';
import { IsUndefinedOrNull } from '@app/common_helper';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CustomerService } from '@app/customers/customer/customer.service';

@Component({
  selector: 'facnm-billing-customer-popup',
  templateUrl: './billing-customer-popup.component.html',
  styleUrls: ['./billing-customer-popup.component.scss']
})
export class BillingCustomerPopupComponent implements OnInit {

  @Output() popupCancel: EventEmitter<any> = new EventEmitter();
  @Output() popupOk: EventEmitter<any> = new EventEmitter();
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  constructor(
    private customer$: CustomerService,
    private errors$: ErrorsService,
    private ngxSmartModalService: NgxSmartModalService,
    private app$: AppService,
  ) { }

  filterOptions = {
    CustomerNo: null, CustomerName: null,
    GridPagerOptions: { PageIndex: 0, PageSize: 20, TotalPages: null }
  };

  PageSize = 20;
  TotalCount = null;
  customDataSource: any;
  productType: any;
  productCategories: any;

  ngAfterViewInit() {
    this.ngxSmartModalService.open('modal');
    this.app$.notifyScreenBusy();
  }
  ngOnInit(): void {
    this.getCustomDataSource();
  }

  getCustomDataSource() {
    this.customDataSource = new CustomStore({
      key: ['Id'],
      load: (loadOptions) => {
        if (loadOptions != undefined) {
          this.filterOptions.CustomerName = null;
          this.filterOptions.CustomerNo = null;

          if (loadOptions.filter != undefined) { // accessing grid filter values    
            if (loadOptions.filter.length) {
              if (!Array.isArray(loadOptions.filter[0])) {
                let filter = loadOptions.filter;
                loadOptions.filter = [];
                loadOptions.filter.push(filter);
              }
            }
            for (var i = 0; i < loadOptions.filter.length; i++) {
              if (loadOptions.filter[i].length == 3) {
                if (loadOptions.filter[i][0] == "Name1") this.filterOptions.CustomerName = loadOptions.filter[i][2];
                else if (loadOptions.filter[i][0] == "Id") this.filterOptions.CustomerNo = loadOptions.filter[i][2];
              }
            }
          }
          this.filterOptions.GridPagerOptions.PageSize = (IsUndefinedOrNull(loadOptions.take) == true) ? this.PageSize : loadOptions.take;
          this.filterOptions.GridPagerOptions.PageIndex = ((IsUndefinedOrNull(loadOptions.take) && IsUndefinedOrNull(loadOptions.skip)) == true) ? 0 : (loadOptions.skip / loadOptions.take);
        }
        return this.customer$.getBillingCustomers(this.filterOptions)
          .toPromise()
          .then((response: any) => {
            let data = response.BillingCustomerListVM || [];
            this.app$.notifyScreenReady();
            return {
              data: data,
              totalCount: response.TotalCount,
            };
          })
          .catch((errorNetwork) => this.errors$.handleServerErrors(errorNetwork));
      }
    });
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Customers Found";
  }

  onPopupCancel() {
    this.ngxSmartModalService.close('modal');
    this.popupCancel.emit();
  }
  onPopupOk() {
    this.ngxSmartModalService.close('modal');
    this.popupOk.emit(this.dataGrid.instance.getSelectedRowsData());
  }

}
