import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, CompanyInterface } from './company.model';
import { CompanyService } from './company.service';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [
    {
      provide: CompanyInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new CompanyService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class CompanyComponent implements OnInit {

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  entityData: Entity<Company>;
  newEntityData: Company;
  isAddForm = 0;
  collapseColor = 'green';
  entityName = 'Company';
  GICollapse = false;
  ICollapse = false;
  ADCollapse = false;
  CICollapse = false;
  CRCollapse = false;
  msgReceiverGroups: any[];
  autoEmailOptionsLists: any[];
  showBillingCustomerModal: boolean;
  showPrintCompanyCheck: boolean;

  constructor(
    private company$: CompanyInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.actions();
    this.getList();
    forkJoin({
      newEntityData: this.company$.getNewEntity(),
      msgReceiverGroups: this.company$.getMsgReceiverGroups(),
      autoEmailOptionsLists: this.company$.getAutoEmailOptionsList(),
    }).subscribe(
      res => {
        this.newEntityData = res['newEntityData']
        this.msgReceiverGroups = res['msgReceiverGroups'];
        this.autoEmailOptionsLists = res['autoEmailOptionsLists'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.company$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ['Id']
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.company$.getList().subscribe(listWithCreated => {
      this.company$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = { ...this.newEntityData };
    this.entityData.created = true;
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
  }

  private deleteConfirm() {
    let param = { id: this.entityData.value.Id }
    this.company$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    if (!isSave) {
      this.app$.notifyScreenReady();
      this.showPrintCompanyCheck = true;
    } else {
      this.createOrUpdate(isSave);
    }
  }

  printCompany(isPrintCompany: boolean) {
    this.showPrintCompanyCheck = false;
    this.entityData.value.IsPrintCompany = isPrintCompany;
    this.createOrUpdate(false);
  }

  createOrUpdate(isSave: boolean) {
    this.company$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.company$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Company Found";
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData){
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  /**Billing customer  start*/

  viewBillingCustomerModal() {
    this.showBillingCustomerModal = true;
  }

  billingCustomerSelectionOk(data: any) {
    this.showBillingCustomerModal = false;
    if (data.length > 0) {
      let selectedRowData = data[0];
      this.entityData.value.DefaultCustomer = selectedRowData.Id;
      this.entityData.value.DefaultCustomerName = selectedRowData.Name1;
    }
  }

  billingCustomerSelectionCancel() {
    this.showBillingCustomerModal = false;
  }
  
  /**Billing customer end */

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  isNumberOnly(event) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
  }

  onPasteNumberOnly(event: ClipboardEvent) {
    const clipboardData = event.clipboardData
    const pastedText = clipboardData.getData('text');

    // Check if the pasted text is a valid integer
    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault(); // Prevent the paste operation
    }
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
