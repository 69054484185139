<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="user-management" class="row h-100">
        <div class="col pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="customDataSource" noDataText=""
                    (onInitialized)="onInitialized($event)" (onCellPrepared)="onCellPrepared($event)" (onSelectionChanged)="onSelectionChanged($event)"
                    (onRowClick)="onRowClick($event)" [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="150" caption="Product Number" dataField="ProductNumber" dataType="string"></dxi-column>
                    <dxi-column alignment='center' caption="Product Name" dataField="ShortProductName" dataType="string"></dxi-column>
                    <dxi-column  alignment='center' caption="Product Category" dataField="ProductCategoryId" dataType="string">
                        <dxo-lookup  [dataSource]="productCategoryList" valueExpr="Id" displayExpr="Name"></dxo-lookup>
                    </dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="none"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="product && product.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Number" class="col-form-label">Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="product.value.ProductNumber"
                                                [ngClass]="{'modelstate-invalid': hasError('ProductNumber')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="ProductNumber" required maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ProductNumber')">
                                                <small class="error" *ngIf="checkError('ProductNumber','required')">
                                                    Product number is required
                                                </small>
                                                <small class="error" *ngIf="checkError('ProductNumber','maxlength')">
                                                    Product number cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ProductName" class="col-form-label">Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="ProductName"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('ProductName')}"
                                                [(ngModel)]="product.value.ProductName" required maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ProductName')">
                                                <small class="error" *ngIf="checkError('ProductName','required')">
                                                    Product name is required
                                                </small>
                                                <small class="error" *ngIf="checkError('ProductName','maxlength')">
                                                    Product name cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ShortProductName" class="col-form-label">Short Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="ShortProductName"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('ShortProductName')}"
                                                [(ngModel)]="product.value.ShortProductName" required maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ShortProductName')">
                                                <small class="error" *ngIf="checkError('ShortProductName','required')">
                                                    Short name is required
                                                </small>
                                                <small class="error" *ngIf="checkError('ShortProductName','maxlength')">
                                                    Short name cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="CompanyId" class="col-form-label">Company</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown id="CompanyId" name="CompanyId" [full]="true" [(ngModel)]="product.value.CompanyId">
                                                <pdi-dropdown-option *ngFor="let company of nonPrintCompanies"
                                                    [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Barcode" class="col-form-label">Barcode</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="Barcode" type="text"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.Barcode" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="ColorHash" class="col-form-label">Color</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="ColorHash" type="color"
                                                class="w-100 bg-transparent control-look-input"
                                                [(ngModel)]="product.value.ColorHash" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="IsStockManaged" class="checkbox-inline"
                                                [(ngModel)]="product.value.IsStockManaged" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label for="IsStockManaged" class="col-form-label">Stock Managed</label>
                                        </div>
                                    </div>
                                    <div class="row form-group-inline">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input type="checkbox" name="IsLockedForUnplannedSales"
                                                class="checkbox-inline"
                                                [(ngModel)]="product.value.IsLockedForUnplannedSales" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label for="IsLockedForUnplannedSales" class="col-form-label">Locked (only
                                                useable as followup or transport product)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="SICollapse = !SICollapse">
                                <div class="collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(SICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">Sale
                                            Information</span>
                                    </div>
                                </div>
                            </div>
                            <div id="SICollapse" class="childs" [ngClass]="{'showChild': !SICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Price</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="ProductPrice"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('ProductPrice')}"
                                                [(ngModel)]="product.value.ProductPrice" required
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ProductPrice')">
                                                <small class="error" *ngIf="checkError('ProductPrice','required')">
                                                    Price is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label"> Stock Price </label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="ProductStockPrice"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.ProductStockPrice"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Price Changeable</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" id="PriceChange" name="PriceChange"
                                                [(ngModel)]="product.value.PriceChange">
                                                <pdi-dropdown-option *ngFor="let pricechange of priceChangeableDropdown"
                                                    [key]="pricechange.Id">
                                                    {{pricechange.PriceChangeText}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>

                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Sales Tax</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" id="SalesTaxId" name="SalesTaxId"
                                                [(ngModel)]="product.value.SalesTaxId">
                                                <pdi-dropdown-option *ngFor="let salestax of salesTaxDropdown"
                                                    [key]="salestax.Id">
                                                    {{salestax.SalesTaxText}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Tax Id</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="TaxId" type="text"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('TaxId')}"
                                                [(ngModel)]="product.value.TaxId" maxlength="20" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('TaxId')">
                                                <small class="error" *ngIf="checkError('TaxId','maxlength')">
                                                    Tax Id cannot be longer than 20 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Max Allowed Quantity</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="MaxAllowedQuantity"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.MaxAllowedQuantity"                                              
                                                (keypress)="isNumberOnlyWithoutDecimal($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Max Discount </label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="MaxDiscount"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('MaxDiscount')}"
                                                [(ngModel)]="product.value.MaxDiscount" required min="0" max="100"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('MaxDiscount')">
                                                <small class="error" *ngIf="checkError('MaxDiscount','required')">
                                                    Max discount is required
                                                </small>
                                                <small class="error" *ngIf="checkError('MaxDiscount','min')">
                                                    Max discount cannot be less than 0
                                                </small>
                                                <small class="error" *ngIf="checkError('MaxDiscount','max')">
                                                    Max discount cannot be more than 100
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Cash Discount</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="CashDiscount"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.CashDiscount" min="0" max="100"
                                                [ngClass]="{'modelstate-invalid': hasError('CashDiscount')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('CashDiscount')">
                                                <small class="error" *ngIf="checkError('CashDiscount','min')">
                                                    Cash discount cannot be less than 0
                                                </small>
                                                <small class="error" *ngIf="checkError('CashDiscount','max')">
                                                    Cash discount cannot be more than 100
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Quantity Unit </label>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pl-1">
                                            <pdi-dropdown [full]="true" name="QuantityUnit"
                                                [(ngModel)]="product.value.QuantityUnit" *ngIf="!isNewUnit"
                                                [ngClass]="{'modelstate-invalid': hasError('QuantityUnit')}" required>
                                                <pdi-dropdown-option *ngFor="let quantityunit of quantityUnitDropdown"
                                                    [key]="quantityunit">
                                                    {{quantityunit}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            
                                            <div class="control-look" *ngIf="!isNewUnit"></div>
                                            <input name="newUnit" id="txtNewUnit" type="text"
                                                class="form-custom-control control-look-input" [(ngModel)]="newUnit"
                                                focus-on="focusNewUnit" *ngIf="isNewUnit" />
                                            <div class="control-look" *ngIf="isNewUnit"></div>
                                            <div class="error-message" *ngIf="hasError('QuantityUnit')">
                                                <small class="error" *ngIf="checkError('QuantityUnit','required')">
                                                    Quantity Unit is required
                                                </small>
                                            </div>

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-top:4px;">
                                            <button type="button" class="pdi-add-button" (click)="addNewUnit()"
                                                *ngIf="!isNewUnit">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                            <button type="button" class="edit-button-image-green"
                                                (click)="saveNewUnit()" *ngIf="isNewUnit">
                                                <img src="assets/Content/Images/Icons/save.png" />
                                            </button>
                                            <button type="button" class="edit-button-image-green"
                                                (click)="cancelNewUnit()" *ngIf="isNewUnit">
                                                <img src="assets/Content/Images/Icons/cancel.png" />
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row form-group">

                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Quantity Decimal Places</label>
                                        </div>

                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">

                                            <input type="number" name="QuantityDecimalPlaces"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.QuantityDecimalPlaces" min="0" max="255"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()"
                                                [ngClass]="{'modelstate-invalid': hasError('QuantityDecimalPlaces')}" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('QuantityDecimalPlaces')">
                                                <small class="error" *ngIf="checkError('QuantityDecimalPlaces','max')">
                                                    Quantity Decimal Places cannot be higher than 255
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Price per</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="PricePer"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.PricePer" required min="0.0000000001"
                                                [ngClass]="{'modelstate-invalid': hasError('PricePer')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('PricePer')">
                                                <small class="error" *ngIf="checkError('PricePer','required')">
                                                    Price per is required
                                                </small>
                                                <small class="error" *ngIf="checkError('PricePer','min')">
                                                    Price per must be greater than 0
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="PACollapse = !PACollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(PACollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Physical Attributes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="PACollapse" class="childs" [ngClass]="{'showChild': !PACollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="IsEmptyProduct" type="checkbox" class="checkbox-inline"
                                                [(ngModel)]="product.value.IsEmptyProduct"
                                                (change)="setProductWeightDefaultValue()" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label class="col-form-label">Empty goods</label>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="IsLiquid" type="checkbox" class="checkbox-inline"
                                                [(ngModel)]="product.value.IsLiquid" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label class="col-form-label">Liquid</label>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="TemperatureCompensated" type="checkbox" class="checkbox-inline"
                                                [(ngModel)]="product.value.TemperatureCompensated" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label">
                                            <label class="col-form-label">Temperature Compensated</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Overall Weight</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="OverallWeight"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.OverallWeight"
                                                [ngClass]="{'modelstate-invalid': hasError('OverallWeight')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()"
                                                required min="0" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('OverallWeight')">
                                                <small class="error" *ngIf="checkError('OverallWeight','required')">
                                                    Overall Weight is required
                                                </small>
                                                <small class="error" *ngIf="checkError('OverallWeight','min')">
                                                    Overall Weight cannot be less than 0
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Product Weight</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="ProductWeight"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.ProductWeight"
                                                [readonly]="product.value.IsLiquid" required min="0"
                                                [ngClass]="{'modelstate-invalid': hasError('ProductWeight')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ProductWeight')">
                                                <small class="error" *ngIf="checkError('ProductWeight','required')">
                                                    Product Weight is required
                                                </small>
                                                <small class="error" *ngIf="checkError('ProductWeight','min')">
                                                    Product Weight cannot be less than 0
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Container Quantity</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="ContainerQunatity"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.ContainerQuantity"                                                
                                                (keypress)="isNumberOnlyWithoutDecimal($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>                                            
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer"
                                (click)="GroupsCollapse = !GroupsCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GroupsCollapse)">
                                    </div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Groups
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GroupsCollapse" class="childs" [ngClass]="{'showChild': !GroupsCollapse}">

                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Product Type</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown *ngIf="!loadingProductType" [full]="true" id="ProductTypeId" name="ProductTypeId"
                                                [(ngModel)]="product.value.ProductTypeId" focus-on="focusProductType">
                                                <pdi-dropdown-option *ngFor="let productType of productTypeDropdown"
                                                    [key]="productType.Id">
                                                    {{productType.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-top:4px;">
                                            <button type="button" (click)="showProductTypeModal = true;"
                                                data-toggle="modal" class="pdi-add-button">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                        </div>
                                    </div>
                                    
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <a pdiLink pdiLinkLabel="Product Category" (click)="openCategoryModal()"></a>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <pdi-dropdown *ngIf="!loadingProductCategory" [full]="true" id="ProductCategoryId" name="ProductCategoryId"
                                                [(ngModel)]="product.value.ProductCategoryId">
                                                <pdi-dropdown-option *ngFor="let productCategory of productCategoryList"
                                                    [key]="productCategory.Id">
                                                    {{productCategory.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Dangerous Goods</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="key" id="DangerousGoodsGroupId" name="DangerousGoodsGroupId"
                                                [(ngModel)]="product.value.DangerousGoodsGroupId">
                                                <pdi-dropdown-option *ngFor="let dangerousGood of dangerousGoodsDropdown"
                                                    [key]="dangerousGood.Id">
                                                    {{dangerousGood.Info}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="RPCollapse = !RPCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(RPCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Related Products
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="RPCollapse" class="childs" [ngClass]="{'showChild': !RPCollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Returned Empties</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-select-box name="ReturnedEmptyProductId"
                                                [(ngModel)]="product.value.ReturnedEmptyProductId" [dataSource]="productDropdown"
                                                displayExpr="ProductName" valueExpr="Id" [searchExpr]="['Id','ProductName']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Additional Product</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-select-box name="AdditionalProductId"
                                                [(ngModel)]="product.value.AdditionalProductId" [dataSource]="productDropdown"
                                                displayExpr="ProductName" valueExpr="Id" [searchExpr]="['Id','ProductName']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Substitute Product</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-select-box name="SubstituteProductId"
                                                [(ngModel)]="product.value.SubstituteProductId" [dataSource]="productDropdown"
                                                displayExpr="ProductName" valueExpr="Id" [searchExpr]="['Id','ProductName']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Follow-up product</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-select-box name="FollowUpProductId"
                                                [(ngModel)]="product.value.FollowUpProductId" [dataSource]="productDropdown"
                                                displayExpr="ProductName" valueExpr="Id" [searchExpr]="['Id','ProductName']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="product.value.FollowUpProductId">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="IsFollowUpProductUseWeight" type="checkbox"
                                                class="checkbox-inline"
                                                [(ngModel)]="product.value.IsFollowUpProductUseWeight" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1 control-label ">
                                            <label class="col-form-label">Use Weight for calculating amount</label>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="product.value.FollowUpProductId">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Amount Factor</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="FollowUpProductAmountFactor"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.FollowUpProductAmountFactor" min="0"
                                                [ngClass]="{'modelstate-invalid': hasError('FollowUpProductAmountFactor')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('FollowUpProductAmountFactor')">
                                                <small class="error"
                                                    *ngIf="checkError('FollowUpProductAmountFactor','min')">
                                                    Amount factor cannot be less than 0
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Transport product</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <dx-select-box name="TransportProductId"
                                                [(ngModel)]="product.value.TransportProductId" [dataSource]="productDropdown"
                                                displayExpr="ProductName" valueExpr="Id" [searchExpr]="['Id','ProductName']"
                                                [searchMode]="dxSelectOptions.searchMode"
                                                [searchTimeout]="dxSelectOptions.searchTimeout"
                                                [minSearchLength]="dxSelectOptions.minSearchLength"
                                                [searchEnabled]="true" placeholder="">
                                            </dx-select-box>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="product.value.TransportProductId">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Fixed Amount</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="TransportProductFixedAmount"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="product.value.TransportProductFixedAmount" min="0"
                                                [ngClass]="{'modelstate-invalid': hasError('TransportProductFixedAmount')}"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('TransportProductFixedAmount')">
                                                <small class="error"
                                                    *ngIf="checkError('TransportProductFixedAmount','min')">
                                                    Fixed Amount cannot be less than 0
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="product"></facnm-action-buttons>
        </div>
    </div>
</div>

<dx-popup title="New Product Type" [(visible)]="showProductTypeModal" [animation]="animation" [dragEnabled]="false"
    [shadingColor]="shadingColor" [width]="450" height="auto" (onHidden)="closeProductType()">
    <div id="entity-detail" class="modal-control">
        <div class="p-4" id="entity-form" style="font-size:14px">
            <pdi-page-notification  *ngIf="modalError" type="alert" text="<strong> Error! </strong> {{modalError}}">
            </pdi-page-notification>

            <div class="row form-group">
                <div class="col-md-4 control-label">
                    <label class="col-form-label"> Number </label>
                </div>
                <div class="col-md-8">
                    <input type="number" class="form-custom-control control-look-input" [(ngModel)]="productType.Id"
                        focus-on="focusctNumber" placeholder="Number" />
                    <div class="control-look"></div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4 control-label">
                    <label class="col-form-label">Name</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-custom-control control-look-input" [(ngModel)]="productType.Name"
                        focus-on="focusctName" placeholder="Name" />
                    <div class="control-look"></div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-md-12 d-flex" style="justify-content: space-around;">
                    <button [disabled]="saveTypeProgress" type="button" class="edit-button-yellow"
                        (click)=" addProductType()">
                        <img src="assets/Content/Images/Icons/save.png"
                            class="edit-button-image edit-button-image-yellow mr-2" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs edit-button-yellow h-100">Save </span>
                    </button>
                    <button type="button" class="edit-button-yellow" (click)="closeProductType()">
                        <img src="assets/Content/Images/Icons/cancel.png"
                            class="edit-button-image edit-button-image-yellow mr-2" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs edit-button-yellow h-100">Close</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</dx-popup>


<ngx-smart-modal #CategoryModal identifier="CategoryModal" [customClass]="'modal-custom'" [closable]="true" [dismissable]="false"
    [escapable]="false">
    <div class="modal-header">
        <div class="modal-title model-header-label">Product Categories</div>
    </div>
    <div class="modal-body">
        <div class="container modal-control" id="entity-detail" style="font-size:14px;">
            <div id="entity-form" class="p-4">
                <form #categoryForm="ngForm" name="productCategoriesForm" class="form-horizontal" novalidate>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-6">
                                <table class="table">
                                    <tr>
                                        <td style="border: 0; font-weight: bold">Categories</td>
                                        <td style="border: 0; text-align: end;">
                                            <button type="button" class="pdi-add-button" style="margin-right: 30px;"
                                                (click)="addNewProductCategory()">
                                                <span class="pdi-icon-Add"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-xs-6">
                                <table class="table">
                                    <tr>
                                        <td style="border: 0; font-weight: bold">Category details</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-6 modal-product-categories">
                                <table class="table">
                                    <tr *ngFor="let category of categoryList;let i = index"
                                        (click)="selectProductCategory(i,category)"
                                        [ngClass]="{selected: i == selectedProductCategoryId}">
                                        <td>
                                            <input type="text" id="{{'name'+i}}" name="{{'name'+i}}"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': productCategorySubmitted && !category.Name}"
                                                [(ngModel)]="category.Name" required />
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                *ngIf="productCategorySubmitted && !category.Name">
                                                <small class="error">
                                                    Product Category Name is required.
                                                </small>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="pdi-delete-button"
                                                (click)="checkDeleteProductCategory(category, i)">
                                                <span class="pdi-icon-Delete"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </table>

                            </div>

                            <div class="col-xs-6" *ngIf="currentProductCategory">

                                <table class="table">
                                    <tr>
                                        <td style="border: 0; padding:1em">Shape</td>
                                        <td style="border: 0">
                                            <pdi-dropdown [full]="true" *ngIf="productCategoryShapes.length" id="Shape" name="Shape"
                                                [(ngModel)]="currentProductCategory.Shape">
                                                <pdi-dropdown-option *ngFor="let shapes of productCategoryShapes"
                                                    [key]="shapes.id">
                                                    {{shapes.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td style="border: 0; padding:1em">Setup Time (m)</td>
                                        <td style="border: 0">
                                            <input type="number" name="SetupTime"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="currentProductCategory.SetupTime"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border: 0; padding:1em">Pump Time (m)</td>
                                        <td style="border: 0">
                                            <input type="number" name="PumpTime"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="currentProductCategory.PumpTime"
                                                (keypress)="isNumberOnly($event)" onpaste="event.preventDefault()" />
                                            <div class="control-look"></div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 text-center">
            <button type="button" class="edit-button-green" (click)="closeCategoryModal()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-2  text-center">
            <button type="submit" [disabled]="saveCategoryProgress" class="edit-button-green"
                (click)="saveProductCategory()">
                <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Save </span>
            </button>
        </div>
    </div>
</ngx-smart-modal>
<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>
<facnm-confirmation-dialog *ngIf="productCategoryDeleteConfirm" (confirmed)=productCategoryDeleteConfirmed()
    (canceled)=productCategoryDeleteClosed() [message]="productCategoryDeleteConfirmMsg">
</facnm-confirmation-dialog>