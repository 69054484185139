import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class AdditionalFields {

  Id: Number;
  Visibility: any;
  ViewMode: any[];
  FieldLocation: any;
  DataSource: String;
  DataType: Number;
  DigitsAfterComma: Number;
  DigitsBeforeComma: Number;
  ExportFieldName: String;
  FieldType: Number;
  IsViewModeReadOnly: boolean;
  MaxLength: Number
  MinLength: Number;
  Sort: Number;
  Title: String;

}
export enum FieldLocationEng {
  Loading = 17,
  LoadingDetail = 11,
  LoadingDetailPerProduct = 19,
  LoadLocation = 15,
  Order = 18,
  OrderDetail = 13,
  Sale = 14,
  TimeLog = 16
}
export enum AdditionalFieldViewLocation {
  LoadingStart = 1,
  LoadingProductsDone = 2,
  LoadingProductDetail = 4,
  LoadingProductCompartment = 8,
  LoadingLoadLocationProductCompartment = 16,
  OrderStart = 1024,
  OrderProductsDone = 2048,
  OrderAfterSignature = 4096,
  OrderProductDetail = 8192,
  OrderPrintSaleDone = 16384,
  OrderRegisterReceiveScreen = 32768,
  TimeLog = 1048576
}

@Injectable()
export abstract class AdditionalFieldsInterface implements EntityInterface<AdditionalFields> {
  create: (data: Entity<AdditionalFields>) => Observable<any>;
  save: (data: Entity<AdditionalFields>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<AdditionalFields[]>;
  getEntity: (key: any) => Observable<AdditionalFields>;
  ListRetrieved: Observable<AdditionalFields[]>;
  notifyRetrieved: (data: AdditionalFields[]) => void;

  getDataTypeList: () => Observable<Enum[]>;
  getFieldLocations: () => Observable<Enum[]>;
  getViewModeTypes: () => Observable<Enum[]>;
  getTimeLogTypeList: () => Observable<Enum[]>;
  getLoadLocationTypes: () => Observable<Enum[]>;
  getNewEntity: () => Observable<AdditionalFields>;
}