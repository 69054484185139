import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class DataArea {
  Id: number;
  Name: string;
  Number: string;
  CustomerTypePostcodes: any;
  ProductGroups: any;
}

@Injectable()
export abstract class CustomerDataAreaInterface implements EntityInterface<DataArea> {
  create: (data: Entity<DataArea>) => Observable<any>;
  save: (data: Entity<DataArea>) => Observable<any>;
  delete: (param: any) => Observable<any>;
  getList: (param: any) => Observable<DataArea[]>;
  getEntity: (param: any) => Observable<DataArea>;
  ListRetrieved: Observable<DataArea[]>;
  notifyRetrieved: (data: DataArea[]) => void;
  getNewEntity: () => Observable<DataArea>;
}