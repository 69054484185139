import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Product, ProductInterface } from './product.model';
import { ProductService } from './product.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { CompanyService } from '@settings/company/company.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { displayZeroValueText, dxDataGridHeightAdjustment, dxSelectOptions, makePDIDropDownOptions, refreshElement } from '@app/common_helper';

@Component({
  selector: 'facnm-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [
    {
      provide: ProductInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new ProductService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class ProductComponent implements OnInit {
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;
  @ViewChild('categoryForm') categoryForm: NgForm;
  private unsubscribe$ = new Subject<void>();
  private Active = 1;
  modalError: any;
  animation: any;
  shadingColor: any;
  saveTypeProgress: boolean;
  saveCategoryProgress: boolean;
  productData: ArrayStore;
  product: Entity<Product>;
  customDataSource: any;
  entityName = 'Product';
  error = '';
  GICollapse = false;
  SICollapse = false;
  PACollapse = false;
  GroupsCollapse = false;
  RPCollapse = false;
  productCategoryList = [];
  filterOptions = {
    ProductNumber: null, ProductName: null, ProductGroup: null,
    GridPagerOptions: { PageIndex: 0, PageSize: 20, TotalPages: null }
  };
  PageSize = 20;
  TotalCount = null;
  isAddForm = 0;
  collapseColor = 'green';
  nonPrintCompanies = [];
  priceChangeableDropdown = [];
  salesTaxDropdown = [];
  quantityUnitDropdown = [];
  productTypeDropdown = [];
  dangerousGoodsDropdown = [];
  productDropdown = [];
  isNewUnit = false;
  newUnit: string;
  newProduct: Product;
  selectedProductCategoryId: any;
  currentProductCategory: any;
  productCategorySubmitted = false;
  categoryList = [];
  productCategoryShapes = [];
  productCategoryDeleteConfirm = false;
  productCategoryDeleteConfirmMsg = 'This Product Category is used by some products. Are you sure, you want to delete it?';
  deleteProCateItem: any;
  deleteProCateItemIndex: any;
  showProductTypeModal = false;
  showProductCategoryModal = false;
  productType = { Id: "", Name: "" }
  loadingProductType = false;
  loadingProductCategory = false;
  dxSelectOptions = new dxSelectOptions();
  
  constructor(
    private company$: CompanyService,
    private product$: ProductInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef, 
    public ngxSmartModalService: NgxSmartModalService
  ) {

    product$.ListRetrieved.subscribe(data => {
      if (data) {
        this.productData = new ArrayStore({
          data: data,
          key: ['Id']
        });

        if (data.length == 0) {
          this.dataGrid.noDataText = 'No Product Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });

    actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.getCustomDataSource();
    forkJoin({
      nonPrintCompanies: this.company$.getNonPrintCompanyList(1),
      categoryList: this.product$.getProductCategoryList(),
      priceChangeableDropdown: this.product$.getPriceChangeableDropdown(),
      salesTaxDropdown: this.product$.getSalesTaxDropdown(),
      quantityUnitDropdown: this.product$.getQuantityUnitDropdown(),
      productTypeDropdown: this.product$.getProductTypeDropdown(),
      dangerousGoodsDropdown: this.product$.getDangerousGoodsDropdown(),
      productDropdown: this.product$.getProductDropdown(),
      newProduct: this.product$.getNewProduct(),
    }).subscribe(
      res => {
        this.productCategoryList = res['categoryList'];
        this.nonPrintCompanies = res['nonPrintCompanies'];
        this.priceChangeableDropdown = res['priceChangeableDropdown'];
        this.salesTaxDropdown = res['salesTaxDropdown'];
        this.quantityUnitDropdown = res['quantityUnitDropdown'];
        this.productTypeDropdown = res['productTypeDropdown'];
        this.dangerousGoodsDropdown = res['dangerousGoodsDropdown'];
        this.productDropdown = res['productDropdown'];
        this.newProduct = <Product>res['newProduct'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  getCustomDataSource() {
    this.customDataSource = new CustomStore({
      key: ['Id'],
      load: (loadOptions) => {
        if (loadOptions != undefined) {
          this.filterOptions.ProductNumber = null;
          this.filterOptions.ProductName = null;
          this.filterOptions.ProductGroup = null;
          if (loadOptions.filter != undefined) { // accessing grid filter values    
            if (loadOptions.filter.length) {
              if (!Array.isArray(loadOptions.filter[0])) {
                let filter = loadOptions.filter;
                loadOptions.filter = [];
                loadOptions.filter.push(filter);
              }
            }
            for (var i = 0; i < loadOptions.filter.length; i++) {
              if (loadOptions.filter[i].length == 3) {
                if (loadOptions.filter[i][0] == "ProductNumber") this.filterOptions.ProductNumber = loadOptions.filter[i][2];
                else if (loadOptions.filter[i][0] == "ShortProductName") this.filterOptions.ProductName = loadOptions.filter[i][2];
                else if (loadOptions.filter[i][0] == "ProductCategoryId") this.filterOptions.ProductGroup = loadOptions.filter[i][2];
              }
            }
          }
          this.filterOptions.GridPagerOptions.PageSize = (IsUndefinedOrNull(loadOptions.take) == true) ? this.PageSize : loadOptions.take;
          this.filterOptions.GridPagerOptions.PageIndex = ((IsUndefinedOrNull(loadOptions.take) && IsUndefinedOrNull(loadOptions.skip)) == true) ? 0 : (loadOptions.skip / loadOptions.take);
        }
        return this.product$.getList(this.filterOptions)
          .toPromise()
          .then((response: any) => {
            this.app$.notifyScreenReady();
            this.product$.notifyRetrieved(response.ProductListVM);
            return {
              data: response.ProductListVM,
              totalCount: response.TotalCount,
            };
          })
          .catch((errorNetwork) => this.errors$.handleServerErrors(errorNetwork));
      }
    });
  }

  isInvalid(field: any) { return this.errors$.isInvalid(field); }

  getError(field: any) { return this.errors$.getError(field); }

  addNewUnit() {
    this.isNewUnit = true;
    this.newUnit = "";
  }

  cancelNewUnit() {
    this.isNewUnit = false;
    this.newUnit = "";

  }

  saveNewUnit = function () {
    if (this.newUnit != "") {
      var flag = false;
      for (var i = 0; i < this.quantityUnitDropdown.length; i++) {
        if (this.quantityUnitDropdown[i].toLowerCase() == this.newUnit.toLowerCase()) {
          flag = true;
          this.newUnit = this.quantityUnitDropdown[i];
        }
      }
      if (!flag) this.quantityUnitDropdown.push(this.newUnit);
      this.product.value.QuantityUnit = this.newUnit;
      this.isNewUnit = false;
    }
  }

  setProductWeightDefaultValue() {
    if (this.product.value.IsEmptyProduct == true && this.product.value.ProductWeight != 0)
      this.product.value.ProductWeight = 0;
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.product = new Entity();
    this.product.value = { ...this.newProduct };
    this.product.created = true;
    this.product.value.ValidDate = new Date();
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    displayZeroValueText(this.nonPrintCompanies,'CompanyId','Id','Name');
    displayZeroValueText(this.priceChangeableDropdown,'PriceChange','Id','PriceChangeText');
    displayZeroValueText(this.salesTaxDropdown,'SalesTaxId','Id','SalesTaxText');
    this.changeDetectorRef.detectChanges();
  }

  private deleteConfirm() {
    this.product$.delete(this.product.value.Id).subscribe(
      (okDelete) => {
        this.cancel();
        this.refreshGrid();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    this.product$.create(this.product).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deselectProduct();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deselectProduct() {
    this.dataGrid.instance.deselectRows(this.product.key);
    this.product.key = undefined;
  }

  private reset() {
    this.product.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deselectProduct();
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  onCellPrepared(e:any) {
    if (e.rowType === "data" && e.column.dataField === "ShortProductName") {
        e.cellElement.innerHTML = '<span class="pull-left">'+e.data.ShortProductName+'</span>';
    }
  }

  onSelectionChanged($clikedRow: any) {
    if (!this.product)
      try {
        let keySaved = JSON.parse(localStorage.getItem('product.dataGrid')).selectedRowKeys[0];
        if (keySaved) {
          this.selectproduct(keySaved);
        }
      }
      catch { }
  }

  private selectproduct(key: any) {
    this.actions$.notifyEntityBusy();
    this.product = new Entity();
    this.product.key = key;
    this.productData.byKey(key)
      .then(pt => {
        this.product.value = cloneDeep(pt);
        if(this.product.value.CompanyId ==0 ){
          displayZeroValueText(this.nonPrintCompanies,'CompanyId','Id','Name');
        }
        if(this.product.value.PriceChange ==0 ){
          displayZeroValueText(this.priceChangeableDropdown,'PriceChange','Id','PriceChangeText');
        }
        if(this.product.value.SalesTaxId ==0 ){
          displayZeroValueText(this.salesTaxDropdown,'SalesTaxId','Id','SalesTaxText');
        }
        if(this.product.value.ProductTypeId == 0 ){
          displayZeroValueText(this.productTypeDropdown,'ProductTypeId','Id','Name');
        }
        if(this.product.value.DangerousGoodsGroupId == 0 ){
          displayZeroValueText(this.dangerousGoodsDropdown,'DangerousGoodsGroupId','Id','Info');
        }
        this.app$.notifyScreenReady();
        this.isAddForm = 0;
        this.collapseColor = 'green';
        this.changeDetectorRef.detectChanges();
      });
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.product)
      this.product = new Entity();
    if (JSON.stringify(this.product.key) === JSON.stringify($clikedRow.key)) {
      this.deselectProduct();
    }
    else {
      this.selectproduct($clikedRow.key);
    }
  }

  addProductType() {
    this.modalError = "";
    this.saveTypeProgress = true;
    this.product$.saveProductType(this.productType).subscribe(
      (okCreate) => {
        this.saveTypeProgress = false;
        this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Product Type created successfully' });
        this.closeProductType();
        this.product$.getProductTypeDropdown().subscribe((res) => {
          this.productTypeDropdown = res;
          refreshElement(this,'loadingProductType');
        }, (err) => {
          this.errors$.handleServerErrors(err);
        })
      },
      (errorCreate) => {
        this.saveTypeProgress = false;
        this.modalError = errorCreate.message;
      }
    );
  }

  closeProductType() {
    this.productType.Id = '';
    this.productType.Name = '';
    this.showProductTypeModal = false;
    this.saveTypeProgress = false;
    this.modalError = '';
  }

  selectProductCategory(index, category) {
    this.selectedProductCategoryId = index;
    this.currentProductCategory = category;
    if(this.currentProductCategory.Shape == 0){
      displayZeroValueText(this.productCategoryShapes,'Shape','id','name');
    }
  }

  saveProductCategory() {
    this.productCategorySubmitted = true;
    if (this.categoryForm.form.invalid) {
      return;
    }
    this.saveCategoryProgress = true;
    this.product$.saveProductCategory(this.categoryList).subscribe((okCreate) => {
      this.closeCategoryModal();
      this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Product category saved successfully' });
      this.getProductCategoryList();
    }, (errorCreate) => {
      this.errors$.handleServerErrors(errorCreate);
    })
  }

  getProductCategoryList() {
    this.product$.getProductCategoryList().subscribe((res) => {
      this.productCategoryList = res;
      refreshElement(this,'loadingProductCategory');
    }, (error) => {
      this.errors$.handleServerErrors(error);
    })
  }

  removeProductCategory(index) {
    this.categoryList.splice(index, 1);
  }

  productCategoryDeleteConfirmed() {
    this.productCategoryDeleteConfirm = false;
    if (this.deleteProCateItem) {
      this.deleteProductCategory(this.deleteProCateItem, this.deleteProCateItemIndex);
    }
  }

  productCategoryDeleteClosed() {
    this.productCategoryDeleteConfirm = false;
    this.deleteProCateItem = null;
    this.deleteProCateItemIndex = null;
  }

  deleteProductCategory(item, index) {
    this.product$.deleteProductCategory(item.Id).subscribe((ok) => {
      this.getProductCategoryList();
      this.removeProductCategory(index);
    }, (error) => {
      this.errors$.handleNetworkError(error);
    });
  }

  checkDeleteProductCategory(item, index) {
    if (item.Id < 0) {
      this.removeProductCategory(index);
    }
    else {
      this.product$.isProductCategoryDeletable(item.Id).subscribe(
        (okDelete) => {
          if (okDelete) {
            this.deleteProductCategory(item, index)
          } else {
            this.productCategoryDeleteConfirm = true;
            this.deleteProCateItem = item;
            this.deleteProCateItemIndex = index;
          }
        }, (error) => {
          this.errors$.handleNetworkError(error);
        })
    }
  }

  addNewProductCategory() {
    var newidcount = -1;
    for (var i = 0; i <= this.productCategoryList.length - 1; i++) {
      if (this.productCategoryList[i].Id <= 0) newidcount--;
    }
    this.categoryList.push({ Id: newidcount, Name: '', Shape: 0, SetupTime: 0, PumpTime: 0 });
  }

  closeCategoryModal() {
    this.productCategorySubmitted = false;
    this.showProductCategoryModal = false;
    this.saveCategoryProgress = false;
    this.ngxSmartModalService.close('CategoryModal');
  }

  openCategoryModal() {
    if (!this.productCategoryShapes.length) {
      this.product$.getProductCategoryShapes().subscribe(
        (res) => {
          this.productCategoryShapes = res;
        },
        (errorDelete) => {
          this.errors$.handleServerErrors(errorDelete);
        })
    }
    this.productCategorySubmitted = false;
    this.saveCategoryProgress = false;
    this.categoryList = cloneDeep(this.productCategoryList);
    this.selectProductCategory(0, this.categoryList[0]);
    this.ngxSmartModalService.open('CategoryModal');
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  isNumberOnly(event) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
  }

  isNumberOnlyWithoutDecimal(event) {
    return !(event.charCode > 31 && (event.charCode < 48 || event.charCode > 57))
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
function IsUndefinedOrNull(val) {
  return val === undefined || val === null;
}

