<dx-popup title="Delete"
          [(visible)]="visible"
          [animation]="animation"
          hideOnOutsideClick="true"
          [dragEnabled]="false"
          [shadingColor]="shadingColor"
          [(maxHeight)]="showVehicleDeleteMsg || showDataAreaDeleteMsg ? height  : maxHeight"
          [(maxWidth)]="showVehicleDeleteMsg || showDataAreaDeleteMsg ? width  : maxWidth"
          (onHidden)="cancel()">
  <div>
    <div>
      <div  *ngIf="showVehicleDeleteMsg">
        <b>Attention</b>: {{attentionMsg}}
        <br/>
        <br/>
        Do you still want to delete this {{entityName}}?
      </div>
      <div *ngIf="!showVehicleDeleteMsg && !showDataAreaDeleteMsg">
        Are you sure you want to delete this {{entityName}}?
      </div>
      <div  *ngIf="showDataAreaDeleteMsg">
        {{message}}
      </div>
    </div>
    <div class="row text-center fixed-bottom pb-4">
      <div class="col">
        <button (click)="confirm()"
                id="action-confirm-delete">
          <img src="assets/Content/Images/Icons/delete.png"
               class="edit-button-image" />
          <span class="edit-button d-none d-xl-inline-block">Delete</span>
        </button>
      </div>
      <div class="col">
        <button (click)="cancel()"
                id="action-confirm-cancel">
          <img src="assets/Content/Images/Icons/cancel.png"
               class="edit-button-image" />
          <span class="edit-button d-none d-xl-inline-block">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</dx-popup>