import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class DeviceMap {
  DeviceId: number;
  ProductId: number;
  DeviceIdName: string;
  ProductIdName: string;
  ProductNumber: number;
  DeviceName: string;
  ProductNameOnDevice: string;
  ProductName: string;
  ProductNameNumber:string;
  UnitOfProduct: string;
  GroupId: number;
  GroupName:string;
}

@Injectable()
export abstract class DeviceMapInterface implements EntityInterface<DeviceMap> {
  create: (data: Entity<DeviceMap>) => Observable<any>;
  save: (data: Entity<DeviceMap>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<DeviceMap[]>;
  getEntity: (key: any) => Observable<DeviceMap>;
  ListRetrieved: Observable<DeviceMap[]>;
  notifyRetrieved: (data: DeviceMap[]) => void;
  getNewEntity: () => Observable<DeviceMap>;
  getProductList: () => Observable<any>;
  getDeviceList: () => Observable<any>;
}