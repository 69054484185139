import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class DriverManagement {

    Id: number;
    Number: string;
    Name: string;
    ValidDate: Date;
    Barcode: string;
    iButtonId: string;
    Language: string;
    CanbusDriverId: string;
    Password: string;
    Deleted: boolean;
    CompanyId: string;

}

@Injectable()
export abstract class DriverManagementInterface implements EntityInterface<DriverManagement> {

    create: (data: Entity<DriverManagement>) => Observable<any>;
    save: (data: Entity<DriverManagement>) => Observable<any>;
    delete: (data: any) => Observable<any>;
    getList: () => Observable<DriverManagement[]>;
    getEntity: (key: any) => Observable<DriverManagement>;

    ListRetrieved: Observable<DriverManagement[]>;
    notifyRetrieved: (data: DriverManagement[]) => void;
    getLanguageList: () => Observable<Enum[]>;
    getRoleList: () => Observable<Enum[]>;
}