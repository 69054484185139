<div class="config-import">
    <div *ngIf="compareConfigurationSet == null" class="import-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <!-- File Upload Div  -->
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                <div class="widget-container flex-box">
                    <div id="dropzone-external" class="flex-box open-file" [ngClass]="
                        isDropZoneActive
                          ? ['dx-theme-accent-as-border-color', 'dropzone-active']
                          : ['dx-theme-border-color']">
                        <div id="dropzone-text" class="flex-box">
                            <div class="row">
                                <img src="assets/Content/Images/Icons/upload-cloud.svg" width="100" />
                            </div>
                            <div class="row top-buffer">
                                <span>Drag & Drop File Here</span><br />
                            </div>
                        </div>
                    </div>
                    <dx-file-uploader #fileUploader selectButtonText="Select File" dialogTrigger=".open-file"
                        dropZone="#dropzone-external" [multiple]="false" [showFileList]="true" accept=".json,.txt"
                        [uploadMode]="'useButtons'" [visible]="false" [(value)]="imageUploading"
                        (onDropZoneEnter)="onDropZoneEnter($event)" (onDropZoneLeave)="onDropZoneLeave($event)"
                        (onValueChanged)="onPreview()"></dx-file-uploader>
                    <div class="row">
                        <button type="button" id="action-select" class="open-file">
                            <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Select File</span>
                        </button>
                    </div>
                </div>

                <div *ngIf="file">
                    <label>File: </label>{{file.name}}
                    <div *ngIf="notValidFile" class="error-message"> Not a valid config file.</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
        </div>
        <div class="row " *ngIf="fileString">
            <!-- Display contents of the file uploaded -->
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <pre class="preview-div">{{fileString}}</pre>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <button type="button" id="action-upload" (click)="uploadFile()">
                            <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Upload File</span>
                        </button>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <button type="button" id="action-cancel" (click)="cancel()">
                            <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                            <span class="edit-button hidden-md hidden-sm hidden-xs">Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
        </div>
    </div>
    <div *ngIf="compareConfigurationSet != null" class="fullWidth">
        <!-- Configuration Comparision Div  -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 import-config-content top-buffer">
            <facnm-errors></facnm-errors>
            <table class="table">
                <thead>
                    <tr>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"></td>
                        <td class="col-md-4 col-sm-4 col-xs-4"><strong>Setting Type</strong></td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left"><strong>Imported Config Settings</strong></td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left"><strong>Current DB Settings</strong></td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                    <tr>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"></td>
                        <td class="col-md-4 col-sm-4 col-xs-4">Company Name</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.ImportedCompanyName}}
                        </td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.CurrentCompanyName}}
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                    <tr>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"></td>
                        <td class="col-md-4 col-sm-4 col-xs-4">Database Version</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.ImportedDBVersion}}
                        </td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.CurrentDBVersion}}
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                    <tr>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"></td>
                        <td class="col-md-4 col-sm-4 col-xs-4">Number of Config Values</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">
                            {{compareConfigurationSet.ImportedDBConfigCount}}</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">
                            {{compareConfigurationSet.CurrentDBConfigCount}}</td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                    <tr *ngIf="compareConfigurationSet.ConfigurationSetType != 'Default'">
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"></td>
                        <td class="col-md-4 col-sm-4 col-xs-4">Group Id : Group Name</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.ImportedDBGroupId}} :
                            {{compareConfigurationSet.ImportedDBGroupName}}</td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">{{compareConfigurationSet.CurrentDBGroupId}} :
                            {{compareConfigurationSet.CurrentDBGroupName}}</td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                    <tr>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center"><strong>Config Id</strong></td>
                        <td class="col-md-4 col-sm-4 col-xs-4"><strong>Description</strong></td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left"><strong>Imported Config Value</strong></td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left"><strong>Current DB Config Value</strong></td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let configurationvalue of compareConfigurationSet.ConfigurationValues"
                        [ngClass]="{'danger': configurationvalue.IsDifferent == true  }">
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                            <div class="pt-3"><span class="configIdContainer">{{configurationvalue.Id }}</span></div>
                        </td>
                        <td class="col-md-4 col-sm-4 col-xs-4">
                            <div class="pt-3">
                                <div> {{ configurationvalue.Name }} </div>
                            </div>
                        </td>
                        <td class="col-md-3 col-sm-3 col-xs-3 text-left">
                            <div>
                                <div *ngIf="configurationvalue.ValueOptionsList.length > 0">
                                    <div class="disabled-tag" *ngIf="configurationvalue.Type == 'flags'">
                                        <dx-tag-box name="tags" disabled="true"
                                            [(ngModel)]="configurationvalue.ImportedDBFlagsValue"
                                            [items]="configurationvalue.ValueOptionsList" valueExpr="Id"
                                            [value]="configurationvalue.ImportedDBFlagsValue" displayExpr="Name">
                                        </dx-tag-box>
                                    </div>
                                    <pdi-dropdown [full]="true" [(ngModel)]="configurationvalue.ImportedDBValue"
                                        *ngIf="configurationvalue.Type == 'int' " [isDisabled]="true">
                                        <pdi-dropdown-option *ngFor="let optionvalue of configurationvalue.ValueOptionsList"
                                            [key]="optionvalue.IdString"> {{optionvalue.Name}}</pdi-dropdown-option>
                                </pdi-dropdown>
                                </div>
                                <div *ngIf="configurationvalue.ValueOptionsList.length == 0">
                                    <input type="checkbox" class="form-control checkbox-inline"
                                        [(ngModel)]="configurationvalue.ImportedDBBooleanValue"
                                        *ngIf="configurationvalue.Type == 'bool' " disabled="true" />

                                    <input type="color" class="form-control"
                                        [(ngModel)]="configurationvalue.ImportedDBValue"
                                        *ngIf="configurationvalue.Type == 'color' " disabled="true" />

                                    <input type="text" class="form-control"
                                        [(ngModel)]="configurationvalue.ImportedDBValue" *ngIf="configurationvalue.Type == 'byte' || configurationvalue.Type == 'dastr' ||
                                        configurationvalue.Type == 'float' ||
                                        configurationvalue.Type == 'int' ||
                                        configurationvalue.Type == 'str' " disabled="true" />

                                    <div *ngIf="configurationvalue.Type == 'xml' ">
                                        <button type="button"
                                            (click)="viewComplexConfigValue(configurationvalue,configurationvalue.ImportedDBXmlValue)"
                                            class="form-control edit-button-green">
                                            View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="col-md-3 col-sm-3 col-xs-3">
                            <!-- Current DB Config Values-->
                            <div>
                                <div *ngIf="configurationvalue.ValueOptionsList.length > 0">
                                    <div class="disabled-tag" *ngIf="configurationvalue.Type == 'flags'">
                                        <dx-tag-box name="tags" disabled="true"
                                            [(ngModel)]="configurationvalue.CurrentDBFlagsValue"
                                            [items]="configurationvalue.ValueOptionsList" valueExpr="Id"
                                            [value]="configurationvalue.CurrentDBFlagsValue" displayExpr="Name">
                                        </dx-tag-box>
                                    </div>
                                    <pdi-dropdown [full]="true" [(ngModel)]="configurationvalue.CurrentDBValue"
                                        *ngIf="configurationvalue.Type == 'int' " [isDisabled]="true">
                                        <pdi-dropdown-option *ngFor="let optionvalue of configurationvalue.ValueOptionsList"
                                            [key]="optionvalue.IdString"> {{optionvalue.Name}}</pdi-dropdown-option>
                                    </pdi-dropdown>
                                </div>
                                <div *ngIf="configurationvalue.ValueOptionsList.length == 0">
                                    <input type="checkbox" class="form-control checkbox-inline"
                                        [(ngModel)]="configurationvalue.CurrentDBBooleanValue"
                                        *ngIf="configurationvalue.Type == 'bool' " disabled="true" />

                                    <input type="color" focus-on="focusEdit" class="form-control"
                                        [(ngModel)]="configurationvalue.CurrentDBValue"
                                        *ngIf="configurationvalue.Type == 'color' " disabled="true" />

                                    <input type="text" class="form-control"
                                        [(ngModel)]="configurationvalue.CurrentDBValue" *ngIf="configurationvalue.Type == 'byte' || 
                                        configurationvalue.Type == 'dastr' || 
                                        configurationvalue.Type == 'float' || 
                                        configurationvalue.Type == 'int' || 
                                        configurationvalue.Type == 'str'  " disabled="true" />

                                    <div *ngIf="configurationvalue.Type == 'xml' ">
                                        <button type="button"
                                            (click)="viewComplexConfigValue(configurationvalue,configurationvalue.CurrentDBXmlValue)"
                                            class="form-control edit-button-green">
                                            View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 contentBoxButtons center100 top-buffer">
            <div class="col-md-4 col-sm-4 col-xs-4 text-center"></div>
            <div class="col-md-4 col-sm-4 col-xs-4 text-center">
                <div class="col-md-6 col-sm-6 col-xs-6 text-center">
                    <button type="button" id="action-import" (click)="importConfigurations()">
                        <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs">Import</span>
                    </button>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 text-center">
                    <button type="button" id="action-cancel" (click)="cancel()">
                        <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                        <span class="edit-button hidden-md hidden-sm hidden-xs">Cancel</span>
                    </button>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4 text-center"></div>
        </div>
    </div>
</div>

<facnm-configuration-modal readonly="true" *ngIf="showConfigModal" [configurationValue]="modalConfigurationValue"
    (popupClose)="popupClose()">
</facnm-configuration-modal>
<facnm-loading-screen-indicator></facnm-loading-screen-indicator>
<facnm-notifications [entityName]="entityName"></facnm-notifications>