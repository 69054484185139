import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { AppService } from '@app/app.service';
import { Entity, Enum } from '@app/app.model';


import uniq from 'lodash/uniq';
import find from 'lodash/find';
import without from 'lodash/without';


import { saveAs } from 'file-saver';

import { PrintTemplatesInterface, PrintTemplate, PrintTemplateAttributes } from './print-templates.model';
import { PrintTemplatesService } from './print-templates.service';

import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { SharedService } from '@app/_shared/shared.service';
import { Company } from '@app/_shared/shared.model';
import { dxDataGridHeightAdjustment, makePDIDropDownOptions } from '@app/common_helper';

@Component({
    selector: 'facnm-print-templates',
    templateUrl: './print-templates.component.html',
    styleUrls: ['./print-templates.component.scss'],
    providers: [
        {
            provide: PrintTemplatesInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new PrintTemplatesService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class PrintTemplatesComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Print Template';

    companies: Company[];
    currentCompanyId: number;
    currentInUseChanged: boolean;
    currentAliasChanged: boolean;

    printTemplate: Entity<PrintTemplate>;
    printTemplatesStore: ArrayStore;

    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
    @ViewChild('printTemplatesForm') printTemplatesForm: NgForm;

    deletingLanguagesVisible: boolean;
    syncingVisible: boolean;

    clicked_actions: boolean;

    overSyncFromShadow = [];
    overRevertTemplate = [];
    overDownloadShadow = [];
    overUploadShadow = [];
    overDownloadLive = [];
    overEditTemplate = [];

    allTemplatesLanguages: string[];
    allTemplatesNames: PrintTemplate[];
    allTemplatesAliases: PrintTemplate[];
    templateTypes: Enum[];

    syncingTemplate: PrintTemplate;
    syncingIsLive: boolean;
    aliasTemplate: PrintTemplate;

    editingTemplate: PrintTemplate;

    animation: any;
    shadingColor: any;
    currentTemplateChanged: boolean;
    companiesOptions: any;

    constructor(private printTemplates$: PrintTemplatesInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private shared$: SharedService) {

        this.animation = app$.getAnimationSettings();
        this.shadingColor = app$.getShadingColor();

        printTemplates$.ListRetrieved.pipe(

            map(data => {
                if (data) {
                    data.forEach(pt => {
                        let templateType = this.templateTypes.filter(t => t.id == pt.Id)[0];

                        if (templateType) {
                            pt.TemplateName = templateType.name;
                        }
                    });
                }
                return data;
            }))

            .subscribe(dataAliased => {
                if (dataAliased) {

                    this.printTemplatesStore = new ArrayStore({
                        data: dataAliased,
                        key: ['Id', 'CompanyId']
                    });
                    if (dataAliased.length == 0) {
                        this.dataGrid.noDataText = 'No print templates';
                    }
                    else {
                        this.dataGrid.noDataText = '';
                    }
                }
            });


        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });
    }



    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(
            this.shared$.getCompanyList(),
            this.printTemplates$.getAliases(),
            this.printTemplates$.getTemplatesTypes()
        ).subscribe(
            multipleData => {

                this.companies = multipleData[0];
                this.companiesOptions  = makePDIDropDownOptions(multipleData[0],'Id','name');
                this.currentCompanyId = this.companies[0].Id;

                this.allTemplatesNames = multipleData[1]?.map(alias=>{
                    alias.label = alias.Id + ' - ' + alias.TemplateName + ' (' + this.getCompanyName(alias.CompanyId) + ')';
                    alias.key = alias;
                    return alias;
                })

                this.templateTypes = multipleData[2];

                this.printTemplates$.getList(this.companies[0].Id).subscribe(printTemplatesData => {
                    this.printTemplates$.notifyRetrieved(printTemplatesData);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }


    onCompanyChange(companyId: number) {
        if (this.printTemplate)
            this.printTemplate.key = undefined;
        this.currentCompanyId = companyId;

        this.printTemplates$.getList(this.currentCompanyId).subscribe(printTemplatesData => {
            this.printTemplates$.notifyRetrieved(printTemplatesData);
            this.app$.notifyScreenReady();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }


    getCompanyName(companyId: number) {

        if (companyId) {
            let company = find(this.companies, c => c.Id == companyId);

            if (company)
                return company.name;
        }
    }


    onSelectionChanged($clikedRow) {

        if (!this.printTemplate) {
            try {
                let keySaved = JSON.parse(localStorage.getItem('print-templates.dataGrid')).selectedRowKeys[0];
                if (keySaved) {
                    this.selectPrintTemplate(keySaved);
                }
            }
            catch{ }
        }
    }


    onRowClick($clikedRow) {

        if (!this.clicked_actions) this.app$.setScreenTitle(this.entityName);

        if (!this.printTemplate) {
            this.printTemplate = new Entity();
        }
        if (JSON.stringify(this.printTemplate.key) === JSON.stringify($clikedRow.key)) {
            if (!this.clicked_actions) this.deselectPrintTemplate();
        }
        else {
            this.selectPrintTemplate($clikedRow.key);
        }

        if (this.clicked_actions) {
            this.clicked_actions = false;
        }
    }

    onCellPrepared($cell) {

        if ($cell.rowType == 'data' && !$cell.data.AnyLivePrinterForms && !$cell.data.AnyShadowPrinterForms && !($cell.data.AliasId > 0 && $cell.data.AliasCompanyId > 0)) {

            if ($cell.column.dataField == 'TemplateName')
                $cell.cellElement.style = 'color: rgba(0,0,0,.4); font-style: italic; ' + $cell.cellElement.style;
            else if ($cell.column.dataField == 'Id')
                $cell.cellElement.style = 'color: rgba(0,0,0,.4); text-align: right; font-style: italic; ' + $cell.cellElement.style;
        }
    }


    onCustomLanguageCreating(e) {

        this.allTemplatesLanguages.push(e.text);
    }

    onTemplatesLanguagesChanged(e) {

        this.printTemplate.value.AllLanguages = e.value;

        this.printTemplate.value.InUse = (this.printTemplate.value.AllLanguages.length > 0 || (this.printTemplate.value.AliasId > 0 && this.printTemplate.value.AliasCompanyId > 0));
    }

    onChangeAlias(alias: PrintTemplate) {

        if (!alias) {

            if (this.currentAliasChanged) {
                this.printTemplate.value.AllLanguages = this.printTemplate.original.AllLanguages;

                this.printTemplate.value.AliasCompanyId = this.printTemplate.original.AliasCompanyId;
                this.printTemplate.value.AliasId = this.printTemplate.original.AliasId;
                this.currentAliasChanged = false;
                this.currentInUseChanged = false;
            }
            else {
                setTimeout(() => {

                    this.printTemplate.value.AliasCompanyId = 0;
                    this.printTemplate.value.AliasId = 0;
                    if (this.printTemplate.value.AllLanguagesOriginal.length == 0) {
                        this.printTemplate.value.AllLanguages = ['none'];
                    }
                });
                this.currentAliasChanged = true;
            }
        }
        else {
            this.printTemplate.value.AllLanguages = [];

            this.printTemplate.value.AliasCompanyId = alias.CompanyId;
            this.printTemplate.value.AliasId = alias.Id;
            this.currentAliasChanged = true;
        }



        this.printTemplate.value.InUse = (this.printTemplate.value.AllLanguages.length > 0 || (this.printTemplate.value.AliasId > 0 && this.printTemplate.value.AliasCompanyId > 0));
    }


    onInUseChanged(e) {

        this.printTemplate.value.InUse = e.value;


        if (this.printTemplate.value.InUse && !this.aliasTemplate && this.printTemplate.value.AllLanguages.length == 0) {

            if (this.currentInUseChanged) {

                if (this.printTemplate.original.InUse) {
                    this.printTemplate.value.AllLanguages = this.printTemplate.original.AllLanguages;
                }
                else {
                    this.printTemplate.value.AllLanguages = ['none'];
                }

                this.printTemplate.value.AliasCompanyId = this.printTemplate.original.AliasCompanyId;
                this.printTemplate.value.AliasId = this.printTemplate.original.AliasId;

                this.aliasTemplate = this.allTemplatesAliases.find(t => t.Id == this.printTemplate.original.AliasId && t.CompanyId == this.printTemplate.original.AliasCompanyId);

                this.currentAliasChanged = false;
                this.currentInUseChanged = false;
            }
            else {
                this.printTemplate.value.AllLanguages = ['none'];
                this.currentInUseChanged = true;
            }
        }

        else if (!this.printTemplate.value.InUse) {

            this.aliasTemplate = undefined;

            if (this.printTemplate.original.InUse == false) {
                this.printTemplate.value.AliasCompanyId = this.printTemplate.original.AliasCompanyId;
                this.printTemplate.value.AliasId = this.printTemplate.original.AliasId;
            }
            else {
                this.printTemplate.value.AliasCompanyId = 0;
                this.printTemplate.value.AliasId = 0;
            }
            this.printTemplate.value.AllLanguages = [];
            this.currentInUseChanged = true;
        }
    }


    clickDownloadShadow(e: PrintTemplate) {

        this.clicked_actions = true;

        this.printTemplates$.DownloadPrinterForm(e, false).subscribe(
            (okDownloadShadow) => {

                var blob = new Blob([okDownloadShadow], {
                    type: "text/plain;charset=utf-8"
                });

                saveAs(blob, e.Id + '_' + e.TemplateName + '_' + e.Language + '_shadow.txt');

                this.actions$.notifyEntityCustomAction({ title: 'Downloaded', message: 'The print template was downloaded from shadow' });
            },

            (errorDownloadShadow) => {
                this.errors$.handleServerErrors(errorDownloadShadow);
            }
        );
    }


    clickDownloadLive(e: PrintTemplate) {

        this.clicked_actions = true;

        this.printTemplates$.DownloadPrinterForm(e, true).subscribe(
            (okDownloadLive) => {

                var blob = new Blob([okDownloadLive], {
                    type: "text/plain;charset=utf-8"
                });

                saveAs(blob, e.Id + '_' + e.TemplateName + '_' + e.Language + '.txt');

                this.actions$.notifyEntityCustomAction({ title: 'Downloaded', message: 'The print template was downloaded from live' });
            },

            (errorDownloadLive) => {
                this.errors$.handleServerErrors(errorDownloadLive);
            }
        );
    }

    clickUploadShadow(uploadShadow) {

        this.clicked_actions = true;

        setTimeout(() => {
            uploadShadow.click();
        }, 500);
    }

    changeUploadShadow($event, e: PrintTemplate) {
        this.clicked_actions = true;

        let file = $event.target.files[0];
        $event.target.value = null;

        let currentCompanyId = e.CompanyId;

      this.printTemplates$.UploadPrinterForm(file, e).subscribe(
            (okUploadShadow) => {

                this.deselectPrintTemplate();

                this.actions$.notifyEntityCustomAction({ title: 'Uploaded', message: 'The print template was uploaded to shadow' });

                this.printTemplates$.getList(currentCompanyId).subscribe(printTemplatesData => {
                    this.printTemplates$.notifyRetrieved(printTemplatesData);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

            },

            (errorUploadShadow) => {
                this.errors$.handleServerErrors(errorUploadShadow);
                this.selectPrintTemplate(this.printTemplate.key);
            }
        );

    }



    clickSyncFromShadow(e: PrintTemplate) {

        this.clicked_actions = true;

        this.syncingVisible = true;

        this.syncingTemplate = e;
        this.syncingIsLive = false;

    }

    clickSyncFromLive(e: PrintTemplate) {

        this.clicked_actions = true;

        this.syncingVisible = true;

        this.syncingTemplate = e;
        this.syncingIsLive = true;
    }


    syncConfirm(printTemplate, isLive) {

        this.app$.notifyScreenBusy();
        
        this.syncingVisible = false;

        let currentCompanyId = printTemplate.CompanyId;

        this.printTemplates$.SyncPrinterForm(printTemplate, isLive).subscribe(
            (okSync) => {

                this.actions$.notifyEntityCustomAction({ title: isLive ? 'Published' : 'Restored', message: isLive ? 'The print template was published to the previous Live version' : 'The print template was restored to Live' });

                this.deselectPrintTemplate();

                this.printTemplates$.getList(currentCompanyId).subscribe(printTemplatesData => {
                    this.printTemplates$.notifyRetrieved(printTemplatesData);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorSync) => {
                this.errors$.handleServerErrors(errorSync);
            },

            () => {
                this.syncingTemplate = undefined;
                this.syncingIsLive = undefined;
            }
        );
    }

    syncCancel() {
        this.syncingVisible = false;
        this.syncingTemplate = undefined;
        this.syncingIsLive = undefined;
    }


    clickEditTemplate(e: PrintTemplate) {

        this.app$.setScreenTitle('Edit ' + this.entityName);
        this.clicked_actions = true;
        this.editingTemplate = e;
    }

    onDoneEditingTemplate() {

        this.app$.notifyScreenBusy();

        this.currentCompanyId = this.editingTemplate.CompanyId;

        this.printTemplates$.getList(this.currentCompanyId).subscribe(printTemplatesData => {
            this.printTemplates$.notifyRetrieved(printTemplatesData);
            this.app$.notifyScreenReady();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

        this.editingTemplate = undefined;
    }


    private reset() {

        this.printTemplate.restore();

        this.aliasTemplate = this.allTemplatesNames.find(t => t.Id == this.printTemplate.original.AliasId && t.CompanyId == this.printTemplate.original.AliasCompanyId);
    }


    private save() {

        this.deletingLanguagesVisible = !this.printTemplate.original.AllLanguages.every(l => this.printTemplate.value.AllLanguages.includes(l));

        if (!this.deletingLanguagesVisible) {
            this.saveConfirm();
        }
    }

    onConfirmSaveShowing(e) {
        this.app$.notifyScreenReady();
    }

    saveConfirm() {
        this.deletingLanguagesVisible = false;
        this.handleFormSubmit();
        let currentCompanyId = this.currentCompanyId;
        this.printTemplate.value.AllLanguages = this.printTemplate.value.AllLanguages.map(l => { if (l == 'none') return ''; return l });
        this.printTemplates$.save(this.printTemplate).subscribe(
            (okSave) => {

                this.actions$.notifySaved();

                this.deselectPrintTemplate();

                this.printTemplates$.getList(currentCompanyId).subscribe(printTemplatesData => {
                    this.printTemplates$.notifyRetrieved(printTemplatesData);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorSave) => {

                this.printTemplate.value.AllLanguages = this.printTemplate.value.AllLanguages.map(l => { if (l == '') return 'none'; return l });

                this.printTemplate.value.InUse = (this.printTemplate.value.AllLanguages.length > 0 || (this.printTemplate.value.AliasId > 0 && this.printTemplate.value.AliasCompanyId > 0));

                this.errors$.handleServerErrors(errorSave);
            }
        );
    }

    saveCancel() {

        this.deletingLanguagesVisible = false;
    }


    private cancel() {
        this.app$.setScreenTitle(this.entityName);

        this.deselectPrintTemplate();
    }

    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    private selectPrintTemplate(key: any) {

        this.actions$.notifyEntityBusy();

        this.printTemplates$.getEntity(key).pipe(

            map(entity => {

                if (entity) {
                    entity.AllLanguages = entity.AllLanguages.map(l => { if (l == '') return 'none'; return l });

                    entity.InUse = (entity.AllLanguages.length > 0 || (entity.AliasId > 0 && entity.AliasCompanyId > 0));


                    let templateType = this.templateTypes.filter(t => t.id == entity.Id)[0];

                    if (templateType) {
                        entity.TemplateName = templateType.name;
                    }
                }
                return entity;
            })

        ).subscribe(
            entityMapped => {

                this.actions$.notifyEntityReady();

                this.printTemplate = new Entity();

                this.printTemplate.value = entityMapped;
                this.printTemplate.key = key;

                this.printTemplatesStore.load().then(s => {

                    let languages = s.map((t: PrintTemplate) => {

                        if (t.Language == '')
                            return 'none';

                        return t.Language;
                    });

                    this.allTemplatesLanguages = uniq(languages);
                });

                this.allTemplatesAliases = without(this.allTemplatesNames, this.allTemplatesNames.find(t => t.Id == entityMapped.Id && t.CompanyId == entityMapped.CompanyId));
                this.aliasTemplate = this.allTemplatesAliases.find(t => t.Id == entityMapped.AliasId && t.CompanyId == entityMapped.AliasCompanyId);

                this.currentInUseChanged = false;
                this.currentAliasChanged = false;
                this.currentTemplateChanged = true;
            },

            (errorGetEntity) => {

                this.errors$.handleServerErrors(errorGetEntity);
            }
        );
    }

    private deselectPrintTemplate() {
        this.dataGrid.instance.deselectAll();

        this.aliasTemplate = undefined;

        if (this.printTemplate) {
            this.printTemplate.key = undefined;
        }
    }


    private handleFormSubmit() {
        this.printTemplatesForm.form.markAsPristine();
        this.printTemplatesForm.form.markAsUntouched();
        this.printTemplatesForm.form.updateValueAndValidity();
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onContentReady(e) {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';

        let companySelectHeight = 30;
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment - companySelectHeight);

    }
}
