import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DxDataGridComponent, DxFileUploaderComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { AppService } from '@app/app.service';
import { Entity } from '@app/app.model';

import { saveAs } from 'file-saver';

import { ManageImagesInterface, ManageImage } from './manage-images.model';
import { ManageImagesService } from './manage-images.service';

import { ErrorsService } from '@app/_shared/errors/errors.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ServerError } from '@app/_shared/shared.model';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
    selector: 'facnm-manage-images',
    templateUrl: './manage-images.component.html',
    styleUrls: ['./manage-images.component.scss'],
    providers: [
        {
            provide: ManageImagesInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new ManageImagesService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class ManageImagesComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Image';

    manageImage: Entity<ManageImage>;
    manageImagesStore: ArrayStore;

    @ViewChild('manageImageForm') manageImageForm: NgForm;
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

    @ViewChild('imageUploader') imageUploader: DxFileUploaderComponent;
    imageUploading: any[] = [];
    imageUploadUrl;
    imageUploadHeaders;
    currentImageType: string;

    constructor(private manageImages$: ManageImagesInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService) {

        this.imageUploadUrl = manageImages$.getUploadUrl();
        this.imageUploadHeaders = manageImages$.getUploadHeaders();

        manageImages$.ListRetrieved.subscribe(data => {
            if (data) {
                this.manageImagesStore = new ArrayStore({
                    data: data,
                    key: ['Id', 'Bw']
                });
                if (data.length == 0) {
                    this.dataGrid.noDataText = 'No Images';
                }
                else {
                    this.dataGrid.noDataText = '';
                }
            }
        });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });
    }


    ngOnInit() {

        this.app$.setScreenTitle('Manage ' + this.entityName + 's');

        forkJoin(

            this.manageImages$.getList()

        ).subscribe(
            multipleData => {

                this.manageImages$.notifyRetrieved(multipleData[0]);
                this.app$.notifyScreenReady();
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );

    }


    onSelectionChanged($clikedRow) {

        if (!this.manageImage)
            try {
                let keySaved = JSON.parse(localStorage.getItem('manage-images.dataGrid')).selectedRowKeys[0];
                if (keySaved) {
                    this.selectManageImage(keySaved);
                }
            }
            catch{ }
        else {
        }
    }

    onRowClick($clikedRow) {

        this.app$.setScreenTitle('Manage ' + this.entityName + 's');

        if (!this.manageImage)
            this.manageImage = new Entity();
        if (JSON.stringify(this.manageImage.key) === JSON.stringify($clikedRow.key)) {
            this.deselectManageImage();
        }
        else {
            this.selectManageImage($clikedRow.key);
        }
    }


    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    onImageUploadChanged(e) {
        this.imageUploadUrl = this.updateQueryStringParameter(this.imageUploadUrl, "id", this.manageImage.value.IdNew);
        e.component.option("uploadUrl", this.imageUploadUrl);
    }


    onImageUploadStarted(e) {

        this.actions$.notifyEntityBusy();
    }



    onImageUploaded(e) {


        this.manageImage.value.Image = JSON.parse(e.request.response).Image;
        this.currentImageType = this.app$.getImageType(this.manageImage.value.Image);

        this.app$.notifyScreenReady();
    }

    onImageUploadError(e) {

        let error = JSON.parse(e.request.response);

        let serverError = new ServerError();
        serverError.message = error.Message;
        serverError.details = error.ExceptionMessage;

        this.errors$.handleServerErrors(serverError);
    }

    imageDownload(url) {

        let description = this.manageImage.value.Description;
        let imageType = this.currentImageType;

        fetch(url)
            .then(response => response.blob())
            .then(blob => saveAs(blob, description + '.' + imageType))
    }

    updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }


    allowOnlyNumbers(evt) {

        return this.app$.allowOnlyNumbers(evt);
    }

    private addNewEntity() {

        this.app$.setScreenTitle('New ' + this.entityName);

        this.manageImage = new Entity();
        this.manageImage.created = true;
    }

    private reset() {

        this.manageImage.restore();
    }

    private create() {

        this.handleFormSubmit();

        this.manageImages$.create(this.manageImage).subscribe(
            (okCreate) => {

                this.app$.setScreenTitle('Manage ' + this.entityName + 's');

                this.manageImages$.getList().subscribe(listWithCreated => {

                    this.manageImages$.notifyRetrieved(listWithCreated);
                    this.app$.notifyScreenReady();

                    this.deselectManageImage();
                },
                    (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }


    private save() {

        this.handleFormSubmit();

        this.manageImages$.save(this.manageImage).subscribe(
            (okSave) => {

                this.actions$.notifySaved();

                this.deselectManageImage();

                this.manageImages$.getList().subscribe(listWithSaved => {

                    this.manageImages$.notifyRetrieved(listWithSaved)
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorSave) => {
                this.errors$.handleServerErrors(errorSave);
            }
        );
    }


    private deleteConfirm() {

        this.manageImages$.delete(this.manageImage.key).subscribe(
            (okDelete) => {

                this.actions$.notifyDeleted();

                this.deselectManageImage();

                this.manageImages$.getList().subscribe(data => {

                    this.manageImages$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorDelete) => {
                this.errors$.handleServerErrors(errorDelete);
            }
        );
    }


    private cancel() {

        this.app$.setScreenTitle('Manage ' + this.entityName + 's');

        this.deselectManageImage();
    }


    private handleFormSubmit() {
        this.manageImageForm.form.markAsPristine();
        this.manageImageForm.form.markAsUntouched();
        this.manageImageForm.form.updateValueAndValidity();
    }


    private selectManageImage(key: any) {

        this.actions$.notifyEntityBusy();

        this.manageImages$.getEntity(key).pipe(
            map(entity => {
                return entity;
            }),
        ).subscribe(

            (entityMapped) => {
                this.manageImage = new Entity();
                this.manageImage.value = entityMapped;
                this.manageImage.key = key;

                this.currentImageType = this.app$.getImageType(this.manageImage.value.Image);

                this.app$.notifyScreenReady()
            },

            (errorGetEntity) => this.errors$.handleServerErrors(errorGetEntity)
        );
    }

    private deselectManageImage() {
        this.dataGrid.instance.deselectRows(this.manageImage.key);
        this.manageImage.key = undefined;

        this.imageUploader.value = [];
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onInitialized(e) {
        this.resizeScreenContent();
    }
    ngAfterViewInit() {
        this.resizeScreenContent();
    }
    onResize(e): void {
        this.resizeScreenContent();
    }
    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }
}
