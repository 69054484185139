import { Component } from "@angular/core";

@Component({
    selector: 'tooltip-conditions-info-assessment',
    templateUrl: './tooltip-conditions-info.html',
    styleUrls: ['./tooltip-conditions-info.scss'],
})
export class TooltipConditionsInfo_Assessment {
    

}
