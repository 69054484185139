import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranspacConfigurationService } from './transpac-configuration.service';
import { Config, TranspacConfigurationInterface } from './transpac-configuration.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { AppService } from '@app/app.service';
import { ConfigurationGroupService } from '@fleet/configuration-group/configuration-group.service';
import { DriverManagementService } from '@fleet/driver-management/driver-management.service';
import { IsUndefinedOrNull, checkPattern, dxSelectOptions } from '@app/common_helper';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'facnm-transpac-configuration',
  templateUrl: './transpac-configuration.component.html',
  styleUrls: ['./transpac-configuration.component.scss'],
  providers: [
    {
      provide: TranspacConfigurationInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new TranspacConfigurationService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class TranspacConfigurationComponent implements OnInit {
  entityName = "TransPac Configuration";
  configurationGroups: Config[];
  selectedConfigurationType: any;
  selectedDeviceDriver: any;
  groupSpecificConfigurationGroupNameId: any;
  searchString: any;
  deviceList: any;
  driverList: any;
  configurationGroupsList: any;
  selectedConfigurationGroupId: any;
  configurationValues: any[];
  transPacConfigurationForm: any;
  selectedDeviceSpecific: boolean;
  selectedGroupSpecificConfigurationGroup: boolean;
  isSearchActive: any;
  searchConfigSubject: Subject<string> = new Subject<string>();
  accessKeys = { 1: 'DefaultValue', 2: 'DeviceValue', 3: 'DriverValue', 4: 'GroupValue' };
  flagsValueKeys = { 1: 'DefaultFlagsValue', 2: 'DeviceFlagsValue', 3: 'DriverFlagsValue', 4: 'GroupFlagsValue' };
  booleanValueKeys = { 1: 'DefaultBooleanValue', 2: 'DeviceBooleanValue', 3: 'DriverBooleanValue', 4: 'GroupBooleanValue' };
  xmlKeys = { 1: 'DefaultXmlSchema', 2: 'DeviceXmlSchema', 3: 'DriverXmlSchema', 4: 'GroupXmlSchema' };
  deleteConfirmMsg: string;
  deleteIndex: any;
  deleteConfigValue: any;
  ShowDeleteConfirm = false;
  oldSearchString: any;
  dxSelectOptions = new dxSelectOptions();

  constructor(
    private configuration$: TranspacConfigurationInterface,
    private configurationGroup$: ConfigurationGroupService,
    private driver$: DriverManagementService,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
  ) { }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.selectedConfigurationType = 1;
    this.getConfigurationGroup();
    this.searchConfigSubject.pipe(
      debounceTime(1000),
      )
      .subscribe(content => {
        this.searchConfiguration(content);
      });
  }

  getConfigurationGroup() {
    this.app$.notifyScreenBusy();
    this.configuration$.getConfigurationGroup().subscribe(res => {
      this.configurationGroups = res;
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  onConfigurationTypeChange() {
    this.configurationValues = [];
    this.selectedConfigurationGroupId = null;
    switch (this.selectedConfigurationType) {
      case "2":
        this.deviceSpecificConfig();
        break;
      case "3":
        this.driverSpecificConfig();
        break;
      case "4":
        this.groupSpecificConfig();
        break;

      default:
        this.onDeviceDriverChange();
        break;
    }
  }

  onDeviceDriverChange() {
    this.configurationValues = [];
    if (this.selectedConfigurationType == "1" || this.selectedConfigurationType == "3") {
      if (this.selectedConfigurationGroupId != null)
        this.getConfigurationValuesByGroupId(this.selectedConfigurationGroupId);
      else this.getConfigurationValuesByGroupId(0);
    }
    else if (this.selectedConfigurationType == "2") {
      if (this.selectedConfigurationGroupId != null && this.selectedDeviceSpecific == false)
        this.getConfigurationValuesByGroupId(this.selectedConfigurationGroupId);
      else this.getConfigurationValuesByDeviceId();
    }
  }

  deviceSpecificConfig() {
    if (this.deviceList) {
      this.selectedDeviceDriver = this.deviceList[0].Id;
      this.onDeviceDriverChange();
    } else {
      this.app$.notifyScreenBusy();
      this.configuration$.getDeviceList().subscribe(res => {
        this.app$.notifyScreenReady();
        this.deviceList = res;
        this.selectedDeviceDriver = this.deviceList[0].Id;
        this.onDeviceDriverChange();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
  }

  driverSpecificConfig() {
    if (this.driverList) {
      this.selectedDeviceDriver = this.driverList[0].Id;
      this.onDeviceDriverChange();
    } else {
      this.app$.notifyScreenBusy();
      this.driver$.getList().subscribe(res => {
        this.app$.notifyScreenReady();
        this.driverList = res;
        this.selectedDeviceDriver = this.driverList[0].Id;
        this.onDeviceDriverChange();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

    }
  }

  groupSpecificConfig() {
    if (this.configurationGroupsList) {
      this.groupSpecificConfigurationGroupNameId = this.configurationGroupsList[0].Id;
      this.onConfigurationGroupChange();
    } else {
      this.app$.notifyScreenBusy();
      this.configurationGroup$.getList().subscribe(res => {
        this.app$.notifyScreenReady();
        this.configurationGroupsList = res;
        this.groupSpecificConfigurationGroupNameId = this.configurationGroupsList[0].Id;
        this.onConfigurationGroupChange();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

    }
  }

  onConfigurationGroupChange = function () {
    if (this.groupSpecificConfigurationGroupNameId != null) {
      this.getConfigurationValuesByConfigurationGroupId();
    }
    else {
      this.getConfigurationValuesByGroupId(this.selectedConfigurationGroupId);
    }
  }

  getConfigurationValuesByConfigurationGroupId() {
    this.selectedGroupSpecificConfigurationGroup = true;
    this.selectedConfigurationGroupId = null;
    this.selectedDeviceSpecific = false;
    this.searchString = "";
    this.oldSearchString = "";
    this.isSearchActive = false;
    this.configurationValues = [];
    if (this.selectedConfigurationType == "4" && this.groupSpecificConfigurationGroupNameId != null) {
      this.app$.notifyScreenBusy();
      this.configuration$.getConfigurationValuesByGroupSpecific(this.groupSpecificConfigurationGroupNameId).subscribe(res => {
        this.app$.notifyScreenReady();
        this.configurationValues = res;
        this.noConfigValueNotifiction();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
  }

  getConfigurationValuesByDeviceId() {
    this.selectedConfigurationGroupId = null;
    this.selectedGroupSpecificConfigurationGroup = false;
    this.selectedDeviceSpecific = true;
    this.searchString = "";
    this.oldSearchString = "";
    this.isSearchActive = false;
    this.configurationValues = [];
    if (this.selectedConfigurationType == "2" && this.selectedDeviceDriver != null) {
      this.app$.notifyScreenBusy();
      this.configuration$.getConfigurationValuesByDeviceId(this.selectedDeviceDriver).subscribe(res => {
        this.app$.notifyScreenReady();
        this.configurationValues = res;
        this.noConfigValueNotifiction();
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
  }

  noConfigValueNotifiction() {
    if (this.configurationValues != null && this.configurationValues.length == 0) {
      this.actions$.notifyEntityCustomAction({ type: 'error', title: 'Error!', message: 'No Configuration values found!' });
    }
  }

  // On selecting a configuration from left side list
  getConfigurationValuesByGroupId(groupId: any) {
    this.selectedGroupSpecificConfigurationGroup = false;
    this.selectedDeviceSpecific = false;
    this.searchString = "";
    this.oldSearchString = "";
    this.isSearchActive = false;
    this.selectedConfigurationGroupId = groupId;
    this.configurationValues = [];
    let deviceId = null;
    let driverId = null;
    let configGroupId = null;
    if (this.selectedDeviceDriver != null) {
      if (this.selectedConfigurationType == "2") {
        deviceId = this.selectedDeviceDriver;
      } else if (this.selectedConfigurationType == "3") {
        driverId = this.selectedDeviceDriver;
      }
    }

    if (this.selectedConfigurationType == "4") {
      configGroupId = this.groupSpecificConfigurationGroupNameId;
    }

    if (this.selectedConfigurationType == '1' ||
      (this.selectedConfigurationType == '2' && deviceId != null) ||
      (this.selectedConfigurationType == '3' && driverId != null) ||
      (this.selectedConfigurationType == "4" && this.groupSpecificConfigurationGroupNameId != null)) {
      this.app$.notifyScreenBusy();
      this.configuration$.getConfigurationValues({ groupId, deviceId, driverId, configGroupId }).subscribe(res => {
        this.app$.notifyScreenReady();
        this.configurationValues = res;
      }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }
  }

  searchConfigurationDebounceTime() {
    this.searchConfigSubject.next(this.searchString);
  }

  searchConfiguration(searchString: any) {
    let deviceDriverId = null;
    let configGroupId = null;
    let configurationType = this.selectedConfigurationType;
    searchString = searchString.trim();
    if (this.oldSearchString == searchString) return;
    this.oldSearchString = searchString;

    if (!IsUndefinedOrNull(searchString)) {
      if (searchString.length == 1 && isNaN(searchString)) { // if length == 1 and is not a number 
        this.configurationValues = [];
      }
      else if (searchString.length >= 2 || (searchString.length == 1 && !isNaN(searchString))) { // if length > 1 || if length ==1 and is a number
        this.selectedConfigurationGroupId = null;
        this.app$.notifyScreenBusy();

        if ((this.selectedConfigurationType == "2" || this.selectedConfigurationType == "3") && (this.selectedDeviceDriver != null && this.selectedDeviceDriver != -1)) {
          deviceDriverId = this.selectedDeviceDriver;
        }
        else if (this.selectedConfigurationType == "4") {
          configGroupId = this.groupSpecificConfigurationGroupNameId;
        }
        this.configurationValues = [];
        this.configuration$.getConfigurationValuesBySearch({ searchString, configurationType, deviceDriverId, configGroupId }).subscribe(res => {
          this.app$.notifyScreenReady();
          this.configurationValues = res;
          this.isSearchActive = true;
          this.noConfigValueNotifiction();
        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList)
        );
      }
    }
  }

  saveConfigurationValueOnBlur(configValue: any, index: any) {
    configValue.IsSubmitted = true;
    if (configValue.invalid)
      return;

    if (configValue.IsChanged) {
      if (this.selectedConfigurationType == "2") {
        configValue.deviceId = this.selectedDeviceDriver;
      }
      else if (this.selectedConfigurationType == "3") {
        configValue.driverId = this.selectedDeviceDriver;
      }
      else if (this.selectedConfigurationType == "4") {
        configValue.GroupId = this.groupSpecificConfigurationGroupNameId;
      }

      this.configuration$.saveConfigurationValue(configValue).subscribe(
        (okCreate) => {
          this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Saved Configuration ' + configValue.Id + ' - ' + configValue.Name });
          if (this.selectedConfigurationType == "2" && this.selectedDeviceSpecific) {
            this.getConfigurationValuesByDeviceId();
          }
          else if (this.selectedConfigurationType == "4" && this.selectedGroupSpecificConfigurationGroup == true) {
            this.getConfigurationValuesByConfigurationGroupId();
          }
          else {

            let param = {
              configId: configValue.Id,
              deviceId: configValue.deviceId,
              driverId: configValue.driverId,
              groupId: configValue.GroupId
            }
            this.configuration$.getConfigurationValue(param).subscribe(res => {
              this.configurationValues[index] = res;
            }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

          }
        },
        (errorCreate) => {
          this.errors$.handleServerErrors(errorCreate);
        }
      );

    }
  }

  resetConfigurationValue(configurationValue) {
    switch (configurationValue.Type) {
      case "xml":
        let xmlKey = this.xmlKeys[this.selectedConfigurationType];
        configurationValue[xmlKey] = configurationValue.OriginalXmlSchema;
        break;

      case "bool":
        let booleanValueKey = this.booleanValueKeys[this.selectedConfigurationType];
        configurationValue[booleanValueKey] = configurationValue.OriginalBooleanValue;
        break;

      case "flags":
        let flagsValueKey = this.flagsValueKeys[this.selectedConfigurationType];
        configurationValue[flagsValueKey] = configurationValue.OriginalFlagsValue;
        break;

      case "color":
      case "dastr":
      case "float":
      case "int":
      case "str":
        let accessKey = this.accessKeys[this.selectedConfigurationType];
        configurationValue[accessKey] = configurationValue.OriginalValue;
        if (configurationValue.Type == 'int' || configurationValue.Type == 'float') {
          configurationValue.invalid = !checkPattern(configurationValue.Type, configurationValue[accessKey]);
        }
        break;

      default:
        break;
    }
    configurationValue.IsChanged = false;
  }

  preDeleteConfigurationValue(configurationValue: any, index: any) {
    if (configurationValue.Type == 'xml') {
      this.deleteConfirmMsg = 'Are you sure you want to delete Configuration: ' + configurationValue.Id + ' - ' + configurationValue.Name + '?';
      this.deleteIndex = index;
      this.deleteConfigValue = configurationValue;
      this.ShowDeleteConfirm = true;
    } else {
      this.deleteConfigurationValue(configurationValue, index);
    }
  }

  deleteConfirm() {
    this.ShowDeleteConfirm = false;
    this.deleteConfigurationValue(this.deleteConfigValue, this.deleteIndex);
  }

  deleteCancel() {
    this.ShowDeleteConfirm = false;
    this.deleteIndex = null;
    this.deleteConfigValue = null;
  }

  deleteConfigurationValue(configValue: any, index: any) {

    if (this.selectedConfigurationType == "2") {
      configValue.deviceId = this.selectedDeviceDriver;
    }
    else if (this.selectedConfigurationType == "3") {
      configValue.driverId = this.selectedDeviceDriver;
    }
    else if (this.selectedConfigurationType == "4") {
      configValue.GroupId = this.groupSpecificConfigurationGroupNameId;
    }

    let param = {
      configId: configValue.Id,
      deviceId: configValue.deviceId,
      driverId: configValue.driverId,
      groupId: configValue.GroupId
    }

    this.configuration$.deleteConfigurationValue(param).subscribe(
      (okCreate) => {
        this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Deleted Configuration ' + configValue.Id + ' - ' + configValue.Name });
        if (this.selectedConfigurationType == "2" && this.selectedDeviceSpecific) {
          this.getConfigurationValuesByDeviceId();
        }
        else if (this.selectedConfigurationType == "4" && this.selectedGroupSpecificConfigurationGroup) {
          this.getConfigurationValuesByConfigurationGroupId();
        }
        else {
          this.configuration$.getConfigurationValue(param).subscribe(res => {
            this.configurationValues[index] = res;
          }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
        }
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  refreshComplexConfig() {
    if (!IsUndefinedOrNull(this.searchString) && this.isSearchActive) {
      this.oldSearchString ="";
      this.searchConfiguration(this.searchString);
    }
    else {
      if (this.selectedConfigurationGroupId == null) {
        if (this.selectedGroupSpecificConfigurationGroup) this.getConfigurationValuesByConfigurationGroupId();
        else if (this.selectedDeviceSpecific) this.getConfigurationValuesByDeviceId();
      }
      else
        this.getConfigurationValuesByGroupId(this.selectedConfigurationGroupId);
    }
  }

  deviceDisplayExpr(item) {
    return item ? `${item.Id} - ${item.Name}` : '';
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

}
