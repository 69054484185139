import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export class safetyCheckGroup {
  Id: number;
  Name: string;
}

@Injectable()
export abstract class SafetyCheckInterface {
  getSafetyCheckTextGroups: () => Observable<safetyCheckGroup[]>;
  getSafetyCheckForCustomer: (arg: any) => Observable<any>;
  getSafetyCheckForLogin: (arg: any) => Observable<any>;
  getSafetyCheckForLogout: (arg: any) => Observable<any>;
  getSafetyCheckForProduct: (arg: any) => Observable<any>;
  getSafetyCheckForLoadingProduct: (arg: any) => Observable<any>;
  getSafetyCheckForVehicleInspection: (arg: any) => Observable<any>;
  getSafetyCheckForOthers: (arg: any) => Observable<any>;
  getNewSafetyCheckForVehicleInspection: (arg: any) => Observable<any>;
  getNewSafetyCheckForOthers: (arg: any) => Observable<any>;
  getNewSafetyCheckForCustomer: (arg: any) => Observable<any>;
  getNewSafetyCheckForLogin: (arg: any) => Observable<any>;
  getNewSafetyCheckForLogout: (arg: any) => Observable<any>;
  getSafetyGlobalMandatoryStates: () => Observable<any>;
  getSafetyMandatoryStates: () => Observable<any>;
  getDataAreaList: () => Observable<any>;
  getDevicesList: (arg: any) => Observable<any>;
  getNewSafetyCheckForProduct: (arg: any) => Observable<any>;
  saveSafetyCheckForCustomer: (arg: any) => Observable<any>;
  saveSafetyCheckForLogin: (arg: any) => Observable<any>;
  saveSafetyCheckForLogout: (arg: any) => Observable<any>;
  saveSafetyCheckForProduct: (arg: any) => Observable<any>
  saveSafetyCheckForLoadingProduct: (arg: any) => Observable<any>
  saveSafetyCheckForOthers: (arg: any) => Observable<any>
  saveSafetyCheckForVehicleInspection: (arg: any) => Observable<any>
  deleteSafetyCheck: (arg: any) => Observable<any>;

}