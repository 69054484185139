import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { EntityInterface, Entity, Enum } from "@app/app.model";


export class TaxRate {

    Id: string;
    TaxGroup: string;
    Rate: number;
    Type: number;
    Description: string;
    TaxClass: number;
    SequenceNo: number;
}


@Injectable()
export abstract class TaxRatesInterface implements EntityInterface<TaxRate> {

    create: (data: Entity<TaxRate>) => Observable<any>;
    save: (data: Entity<TaxRate>) => Observable<any>;
    delete: (data: any) => Observable<any>;

    getList: () => Observable<TaxRate[]>;
    getEntity: (key: any) => Observable<TaxRate>;
    
    ListRetrieved: Observable<TaxRate[]>;
    notifyRetrieved: (data: TaxRate[]) => void;

    getTaxRatesTypes:() => Observable<Enum[]>;
}