import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export class EntityList {
    Name: string
    DisplayName: string;
}

export class Log {
    Id: number;
    Number: string;
    Name: string;
    TableKey1: string;
    TableKey2: string;
    TableKey3: string;
    TableKey4: string;
    TableKey5: string;
    NewValue: any;
    OldValue: any;
    LogType: any;
    LogDate: any;
    TableName: string;
    UserName: string;
}

export class filterOptions {
    TableName: string;
    LogType: string;
    Username: string;
    DateFrom: any;
    DateTo: any;
    GridPagerOptions: { PageIndex: number, PageSize: number, TotalPages: null }
}

@Injectable()
export abstract class AuditLogInterface {
    getEntityList: () => Observable<EntityList[]>;
    getAuditLogList: (filterOptions: any) => Observable<Log[]>;
}