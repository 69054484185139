import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsComponent } from './errors/errors.component';
import { ActionButtonsComponent } from './actions/action-buttons.component';
import { DxTooltipModule, DxPopupModule, DxLoadPanelModule, DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { NotificationsComponent } from './notifications/notifications.component';
import { DeleteDialog } from './dialogs/delete.dialog';
import { LoadingScreenIndicator } from './loading-indicators/loading-screen.indicator';
import { LoadingEntityIndicator } from './loading-indicators/loading-entity.indicator';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TrueFalseValueDirective } from './directive/true-false-value.directive';
import { BillingCustomerPopupComponent } from './billing-customer-popup/billing-customer-popup.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { VehicleAssignmentComponent } from './vehicle-assignment/vehicle-assignment.component';
import { DriverAssignmentComponent } from './driver-assignment/driver-assignment.component';
import { ProductAssignmentComponent } from './product-assignment/product-assignment.component';
import { DataAreaAssignmentComponent } from './data-area-assignment/data-area-assignment.component';
import { PdiLinkModule,PdiIconModule,PdiNotificationsModule,PdiDropdownModule,PdiLoaderModule } from '@pdi/platform-angular-template';


@NgModule({
  declarations: [
    ErrorsComponent,
    ActionButtonsComponent,
    NotificationsComponent,
    LoadingScreenIndicator, LoadingEntityIndicator,
    DeleteDialog,
    ConfirmationDialogComponent,
    TrueFalseValueDirective,
    BillingCustomerPopupComponent,
    VehicleAssignmentComponent,
    DriverAssignmentComponent,
    ProductAssignmentComponent,
    DataAreaAssignmentComponent,
  ],
  exports: [
    ErrorsComponent,
    ActionButtonsComponent,
    NotificationsComponent,
    LoadingScreenIndicator, LoadingEntityIndicator,
    DeleteDialog,
    ConfirmationDialogComponent,
    TrueFalseValueDirective,
    BillingCustomerPopupComponent,
    VehicleAssignmentComponent,
    DriverAssignmentComponent,
    ProductAssignmentComponent,
    DataAreaAssignmentComponent,
    PdiNotificationsModule,
    PdiLinkModule,
    PdiIconModule,
    PdiDropdownModule,
    PdiLoaderModule,
    DxSelectBoxModule
  ],
  imports: [
    CommonModule,
    DxTooltipModule,
    DxPopupModule,
    DxLoadPanelModule,
    DxDataGridModule,
    NgxSmartModalModule.forRoot(),
    PdiNotificationsModule,
    PdiDropdownModule,
    PdiLoaderModule,
    DxSelectBoxModule
  ]
})
export class SharedModule { }
