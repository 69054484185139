import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class Customer {

  Id: number;
  Number: string;
  Name: string;
  CompanyId: any;
  Position: number;
  UnloadPoints: any;
  DefaultCustomerName: any;
  SpecialPriceProducts: any;
  DeliveryCustomers: any;
  PriceGroupId: any;
  originalId: any;
  originalPos: any;
  AutoEmailOptions: number;
  TypeId:number;
  PaymentTermsId:number;
  CountryId: number;
}

@Injectable()
export abstract class CustomerInterface implements EntityInterface<Customer> {

  create: (data: Entity<Customer>) => Observable<any>;
  save: (data: Entity<Customer>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: (data: any) => Observable<Customer[]>;
  getEntity: (key: any) => Observable<Customer>;
  ListRetrieved: Observable<Customer[]>;
  notifyRetrieved: (data: Customer[]) => void;

  getNewEntity: () => Observable<Customer>;
  getPaymentTermList: (companyId: any) => Observable<Enum[]>;
  getSpecialPriceGroupList: () => Observable<Enum[]>;
  getProductList: (groupId: any) => Observable<Enum[]>;
  getProductDropdown: () => Observable<Enum[]>;
  // getUnloadPointById: (id: any, customerId: any, position: any) => Observable<Enum[]>;
  getLastDeliveryLocationById: (customerId: any, position: any) => Observable<Enum[]>;
  getBillQuantityTypeList: () => Observable<Enum[]>;
  getAutoEmailOptionsList: () => Observable<Enum[]>;
  getSignatureSettingsOptionList: () => Observable<Enum[]>;
  getProductOptions: () => Observable<Enum[]>;
  getPrintOptions: () => Observable<Enum[]>;
  getStrappingChartProfile: () => Observable<Enum[]>;
  getDippingType: () => Observable<Enum[]>;

  //getCustomerCategoryList: () => Observable<Enum[]>;
  //getPriceChangeableDropdown: () => Observable<Enum[]>;
  //getSalesTaxDropdown: () => Observable<Enum[]>;
  //getQuantityUnitDropdown: () => Observable<Enum[]>;
  //getCustomerTypeDropdown: () => Observable<Enum[]>;
  //getDangerousGoodsDropdown: () => Observable<Enum[]>;
  //getCustomerDropdown: () => Observable<Enum[]>;
  //getNewCustomer: () => Observable<Customer>;
  //saveCustomerType: (data: any) => Observable<any>;
  //getCustomerCategoryShapes: () => Observable<Enum[]>;
  //saveCustomerCategory: (data: any) => Observable<any>;
  //isCustomerCategoryDeletable: (data: any) => Observable<any>;
  //deleteCustomerCategory: (data: any) => Observable<any>;
}