import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { AppService } from '@app/app.service';
import { Entity, Enum } from '@app/app.model';


import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import { PrintTextsInterface, PrintText, PrintTextReference, PrintTextReferenceTypeEng } from './print-text.model';
import { PrintTextsService } from './print-text.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
    selector: 'facnm-print-text',
    templateUrl: './print-text.component.html',
    styleUrls: ['./print-text.component.scss'],
    providers: [
        {
            provide: PrintTextsInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new PrintTextsService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class PrintTextsComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Print Text';

    printText: Entity<PrintText>;
    printTextsStore: ArrayStore;

    printTextReference: PrintTextReference;

    printTextReferenceTypes: Enum[];
    printTextReferenceTypeName: string;

    @ViewChild('printTextForm') printTextForm: NgForm;
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
    @ViewChild('dataGridReferences') dataGridReferences: DxDataGridComponent;

    overRemoveReference = [];
    overDeleteReference = [];
    animation: any;
    shadingColor: any;
    savingConfirmVisible: boolean;
    savingReferenceConfirmOverwriteVisible: boolean;
    addingReference: boolean;
    invalidPrintTextReference: boolean;

    constructor(private printTexts$: PrintTextsInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService) {

        printTexts$.ListRetrieved
            .subscribe(data => {
                if (data) {
                    this.printTextsStore = new ArrayStore({
                        data: data,
                        key: ['Id']
                    });
                    if (data.length == 0) {
                        this.dataGrid.noDataText = 'No print texts';
                    }
                    else {
                        this.dataGrid.noDataText = '';
                    }
                }
            });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (this.printText.value.References.some(r => r.ClientDeleted == true)) {
                this.savingConfirmVisible = true;
            }
            else this.save();
        });
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
            if (errors) {
                let entityForm = document.getElementById('entity-form');
                if (entityForm) {
                    entityForm.scrollTop = 0;
                }

                this.cancelPrintTextReference();
            }
        });


        this.animation = app$.getAnimationSettings();
        this.shadingColor = app$.getShadingColor();
    }

    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(

            this.printTexts$.getPrintTextTypes()

        ).subscribe(

            multipleData => {

                this.printTextReferenceTypes = multipleData[0];

                this.printTexts$.getList().subscribe(data => {

                    this.printTexts$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },
            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }

    onSelectionChanged($clikedRow) {

        this.deselectPrintTextReference();

        if (!this.printText)
            try {
                let keySaved = JSON.parse(localStorage.getItem('print-text.dataGrid')).selectedRowKeys[0];
                if (keySaved) {
                    this.selectPrintText(keySaved);
                }
            }
            catch{ }
        else {
        }
    }

    onRowClick($clikedRow) {

        this.app$.setScreenTitle(this.entityName);

        this.addingReference = false;

        if (!this.printText)
            this.printText = new Entity();
        if (JSON.stringify(this.printText.key) === JSON.stringify($clikedRow.key)) {
            this.deselectPrintText();
        }
        else {
            this.selectPrintText($clikedRow.key);
        }
    }


    onRowPrepared_References($row) {

        if ($row.data && $row.data.ClientDeleted == true) {
            $row.rowElement.classList.add('dx-row-client-deleted');
        }
    }

    onRowClick_References($clikedRow) {

        if (!this.printTextReference)
            this.printTextReference = new PrintTextReference();
        if ($clikedRow.data.ClientDeleted == true || JSON.stringify(this.printTextReference) === JSON.stringify($clikedRow.data)) {
            this.deselectPrintTextReference();
        }
        else {
            this.printTextReference = new PrintTextReference();
            this.printTextReference = $clikedRow.data;
            this.printTextReferenceTypeName = PrintTextReferenceTypeEng[this.printTextReference.ReferenceType] || '';
        }
    }


    addPrintTextReference() {

        this.addingReference = true;

        this.printTextReference = new PrintTextReference();

        this.printTextReference.ClientId = this.printText.value.References.length;
        this.printTextReference.ReferenceType = PrintTextReferenceTypeEng.Product;
        this.printTextReferenceTypeName = PrintTextReferenceTypeEng[this.printTextReference.ReferenceType] || '';

    }

    savingPrintTextReference() {

        this.printTexts$.getPrintTextReference(this.printTextReference).subscribe(data => {

            if (data) {
                this.printTextReference
                this.savingReferenceConfirmOverwriteVisible = true;
            }
            else {
                this.savePrintTextReference();
            }

        }, (errorGetReference) => this.errors$.handleServerErrors(errorGetReference));

    }


    savePrintTextReferenceConfirm() {
        this.savingReferenceConfirmOverwriteVisible = false;
        this.savePrintTextReference();
    }

    savePrintTextReferenceCancel() {
        this.savingReferenceConfirmOverwriteVisible = false;
    }

    private savePrintTextReference() {


        var alreadyAddingReference = some(this.printText.value.References, (r) => isEqual(
            omit(r, ['ClientId']),
            omit(this.printTextReference, ['ClientId'])
        ));

        if (!alreadyAddingReference) {
            this.printText.value.References.push(this.printTextReference);
        }

        this.deselectPrintTextReference();

        this.addingReference = false;
    }

    cancelPrintTextReference() {

        this.printTextReferenceTypeName = undefined;
        this.printTextReference = undefined;

        this.addingReference = false;
    }

    onInvalidChange($event) {
        this.invalidPrintTextReference = $event;
    }

    click_RemoveReference(reference: PrintTextReference) {

        this.printText.value.References[reference.ClientId].ClientDeleted = true;

        this.deselectPrintTextReference();
        this.dataGridReferences.instance.refresh();
    }

    click_RestoreReference(reference: PrintTextReference) {

        this.printText.value.References[reference.ClientId].ClientDeleted = false;

        this.deselectPrintTextReference();
        this.dataGridReferences.instance.refresh();
    }

    onConfirmSaveShowing($event) {
        this.app$.notifyScreenReady();
    }
    saveConfirm() {
        this.savingConfirmVisible = false;
        this.save();
    }
    saveCancel() {
        this.savingConfirmVisible = false;
    }


    isInvalid(field) { return this.errors$.isInvalid(field); }
    getError(field) { return this.errors$.getError(field); }


    allowOnlyNumbers(evt) {

        return this.app$.allowOnlyNumbers(evt);
    }


    private deselectPrintTextReference() {
        if (this.dataGridReferences) {
            this.dataGridReferences.instance.deselectAll();
        }
        this.printTextReferenceTypeName = undefined;
        this.printTextReference = undefined;
    }


    private addNewEntity() {
        this.app$.setScreenTitle('New ' + this.entityName);
        this.printText = new Entity();
        this.printText.value.Text = '';
        this.printText.value.References = [];
        this.printText.created = true;
    }

    private reset() {
        this.deselectPrintTextReference();
        this.printText.restore();

        this.addingReference = false;

        if (this.printText.created) {
            this.printText.value.References = [];
        }
    }

    private create() {
        this.printTextReferenceTypeName = undefined;
        this.handleFormSubmit();
        this.printTexts$.create(this.printText).subscribe(
            (okCreate) => {
                this.app$.setScreenTitle(this.entityName);
                this.actions$.notifyCreated();
                this.deselectPrintText();
                this.printTexts$.getList().subscribe(data => {
                    this.printTexts$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },
            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }

    private save() {
        this.printTextReferenceTypeName = undefined;
        this.printTextReference = undefined;
        this.handleFormSubmit();
        this.printTexts$.save(this.printText).subscribe(
            (okSave) => {
                this.actions$.notifySaved();
                this.deselectPrintText();
                this.printTexts$.getList().subscribe(data => {
                    this.printTexts$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },
            (errorSave) => {
                this.errors$.handleServerErrors(errorSave);
            }
        );
    }

    private deleteConfirm() {
        this.printTextReferenceTypeName = undefined;
        this.printTextReference = undefined;
        this.printTexts$.delete(this.printText.key).subscribe(
            (okDelete) => {
                this.actions$.notifyDeleted();
                this.deselectPrintText();
                this.printTexts$.getList().subscribe(data => {
                    this.printTexts$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();
                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },
            (errorDelete) => {
                this.errors$.handleServerErrors(errorDelete);
            }
        );
    }

    private cancel() {
        this.printTextReferenceTypeName = undefined;
        this.printTextReference = undefined;
        this.app$.setScreenTitle(this.entityName);
        this.deselectPrintText();
    }

    private selectPrintText(key: any) {

        this.actions$.notifyEntityBusy();

        this.printText = new Entity();
        this.printText.key = key;

        this.printTextsStore.byKey(key)
            .then(pt => {

                this.printText.value = cloneDeep(pt);

                this.printTexts$.getEntity(key).pipe(
                    map(entity => {
                        if (entity) {
                            entity.References.forEach((ptr: PrintTextReference, i) => {
                                ptr.ClientId = i;
                            });
                        }
                        return entity;
                    }),
                ).subscribe(
                    entityMapped => {

                        this.printText.value = entityMapped;

                        this.app$.notifyScreenReady();
                    },
                    (errorGetEntity) => this.errors$.handleServerErrors(errorGetEntity)
                );

            });
    }

    private deselectPrintText() {
        this.dataGrid.instance.deselectRows(this.printText.key);
        this.printText.key = undefined;

        this.cancelPrintTextReference();
    }


    private handleFormSubmit() {
        this.printTextForm.form.markAsPristine();
        this.printTextForm.form.markAsUntouched();
        this.printTextForm.form.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onInitialized(e) {
        this.resizeScreenContent();
    }
    ngAfterViewInit() {
        this.resizeScreenContent();
    }
    onResize(e): void {
        this.resizeScreenContent();
    }
    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }
}
