<div *ngFor="let fielditem of form">
    <div
        *ngIf="fielditem.fieldType == 'ComplexType' && fielditem.minOccurs == '0' && fielditem.maxOccurs == 'unbounded'">
        <hr />
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <b>{{formatLabel(fielditem.fieldName)}}</b>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sorting-container" *ngFor="let complexitem of fielditem.fieldSequence">
                <dx-list [dataSource]="complexitem.fieldOptions" [itemDragging]="{ allowReordering: true }"
                    (onItemReordered)="onItemReordered(complexitem,$event)">
                    <div *dxTemplate="let option of 'item'">
                        <div class="dx-item-custom-class">
                            <input type="checkbox" name="{{option.Name}}" [(ngModel)]="option.IsSelected"
                                (change)="updateSeletedSortedList(complexitem)" />
                            <div>{{option.Name}}</div>
                        </div>
                    </div>
                </dx-list>
            </div>
        </div>
        <div class="clearfix"></div>
        <hr />
    </div>
    <div
        *ngIf="fielditem.fieldType == 'ComplexType' && fielditem.minOccurs == undefined && fielditem.maxOccurs == undefined">
        <hr />
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <b>{{formatLabel(fielditem.fieldName)}}</b>
        </div>
        <hr />
        <div class="form-group row" *ngFor="let complexitem of fielditem.fieldSequence">
            <span
                class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">{{formatLabel(complexitem.fieldName)}}</span>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div *ngIf="complexitem.fieldOptions.length > 0">
                    <pdi-dropdown [full]="true" name="{{ complexitem.fieldName }}" [(ngModel)]="complexitem.fieldValue"
                        required [isDisabled]="readonly">
                        <pdi-dropdown-option *ngFor="let opt of complexitem.fieldOptions" [key]="opt.Name">{{opt.Name}}
                        </pdi-dropdown-option>
                    </pdi-dropdown>

                    <div class="error-message">
                        <small class="error" *ngIf="!complexitem.fieldValue && !readonly">
                            {{ formatLabel(fielditem.fieldName) }} is required
                        </small>
                    </div>
                </div>

                <div *ngIf="complexitem.fieldOptions.length == 0">
                    <input type="checkbox" *ngIf="complexitem.fieldType == 'Boolean' "
                        class="form-control checkbox-inline" [(ngModel)]="complexitem.fieldValue" trueFalseValue
                        trueValue="true" falseValue="false" id="{{ complexitem.fieldName }}" [disabled]="readonly" />

                    <input type="text"
                        *ngIf="complexitem.fieldType == 'String' || complexitem.fieldType == 'Int' || complexitem.fieldType == 'Decimal' "
                        class="form-control" [(ngModel)]="complexitem.fieldValue" id="{{ complexitem.fieldName }}"
                        [disabled]="readonly" />

                    <div *ngIf="complexitem.fieldType == 'Int' || complexitem.fieldType == 'Decimal'"
                        class="error-message">
                        <small class="error" *ngIf="!complexitem.fieldValue && requiredCheck">
                            {{ formatLabel(complexitem.fieldName) }} is required
                        </small>
                        <small class="error"
                            *ngIf="complexitem.fieldType == 'Int' && checkPattern('int',complexitem.fieldValue)">
                            {{ formatLabel(complexitem.fieldName) }} is not a valid integer number
                        </small>
                        <small class="error"
                            *ngIf="complexitem.fieldType == 'Decimal' && checkPattern('float',complexitem.fieldValue)">
                            {{ formatLabel(complexitem.fieldName) }} is not a valid decimal number
                        </small>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="fielditem.fieldType != 'ComplexType'">
        <span class="col-lg-6 col-md-6 col-sm-6 col-xs-6 control-label">{{formatLabel(fielditem.fieldName)}}</span>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div *ngIf="fielditem.fieldOptions.length > 0">
                <pdi-dropdown [full]="true" name="{{ fielditem.fieldName }}" [(ngModel)]="fielditem.fieldValue"
                    [isDisabled]="readonly">
                    <pdi-dropdown-option *ngFor="let opt of fielditem.fieldOptions" [key]="opt.Name">{{opt.Name}}
                    </pdi-dropdown-option>
                </pdi-dropdown>
                <div class="error-message">
                    <small class="error" *ngIf="!fielditem.fieldValue && !readonly">
                        {{ formatLabel(fielditem.fieldName) }} is required
                    </small>
                </div>
            </div>

            <div *ngIf="fielditem.fieldOptions.length == 0">
                <input type="checkbox" *ngIf="fielditem.fieldType == 'Boolean' " class="form-control checkbox-inline"
                    [(ngModel)]="fielditem.fieldValue" id="{{ fielditem.fieldName }}" trueFalseValue trueValue="true"
                    falseValue="false" [disabled]="readonly" />
                <input type="text"
                    *ngIf="fielditem.fieldType == 'String' || fielditem.fieldType == 'Int' || fielditem.fieldType == 'Decimal' "
                    class="form-control" [(ngModel)]="fielditem.fieldValue" id="{{ fielditem.fieldName }}"
                    [disabled]="readonly" />

                <div *ngIf="fielditem.fieldType == 'Int' || fielditem.fieldType == 'Decimal'" class="error-message">
                    <small class="error" *ngIf="!fielditem.fieldValue && requiredCheck">
                        {{ formatLabel(fielditem.fieldName) }} is required
                    </small>
                    <small class="error"
                        *ngIf="fielditem.fieldType == 'Int' && checkPattern('int',fielditem.fieldValue)">
                        {{ formatLabel(fielditem.fieldName) }} is not a valid integer number
                    </small>
                    <small class="error"
                        *ngIf="fielditem.fieldType == 'Decimal' && checkPattern('float',fielditem.fieldValue)">
                        {{ formatLabel(fielditem.fieldName) }} is not a valid decimal number
                    </small>
                </div>
            </div>

        </div>
    </div>
</div>