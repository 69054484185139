<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
  <div id="entity-management" class="row h-100">
    <div class="col-md-4 pr-0">
      <div id="entity-list" class="h-100">
        <dx-data-grid
          (onContentReady)="onContentReady($event)"
          #dataGrid
          [dataSource]="gridData"
          noDataText=""
          (onInitialized)="onInitialized($event)"
          (onRowClick)="onRowClick($event)"
          [allowColumnResizing]="false"
          [columnAutoWidth]="false"
        >
          <dxo-load-panel>
            <div *dxTemplate="let data of 'content'">
              <pdi-loader size="medium"></pdi-loader>
            </div>
          </dxo-load-panel>
          <dxi-column
            alignment="center"
            caption="Fleet ID"
            dataField="Id"
            dataType="string"
          ></dxi-column>

          <dxi-column
            alignment="left"
            caption="Fleet Name"
            dataField="Name"
            dataType="string"
          ></dxi-column>
          <dxi-column
            alignment="center"
            caption="Vehicles"
            dataField="NumberOfAssignedVehicles"
            dataType="string"
          ></dxi-column>
          <dxi-column
            alignment="center"
            caption="Drivers"
            dataField="NumberOfAssignedDrivers"
            dataType="string"
          ></dxi-column>
          <dxo-selection mode="single"> </dxo-selection>
          <dxo-filter-row [visible]="true"> </dxo-filter-row>
          <dxo-scrolling mode="standard"> </dxo-scrolling>
          <dxo-sorting mode="single"> </dxo-sorting>
          <dxo-pager
            [allowedPageSizes]="[10, 20, 30]"
            [showPageSizeSelector]="true"
            [visible]="true"
          ></dxo-pager>
          <dxo-paging [pageSize]="20"> </dxo-paging>
        </dx-data-grid>
      </div>
    </div>
    <div id="entity-detail" class="col-md-8 pr-0">
      <div *ngIf="entityData && entityData.key">
        <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
          <div class="container">
            <div id="entity-errors" class="row">
              <div class="col">
                <facnm-errors></facnm-errors>
              </div>
            </div>
            <div class="top-buffer">
              <div class="col-md-12">
                <div class="row form-group">
                  <div class="col-lg-3 col-md-2 col-sm-1 hidden-xs"></div>

                  <div
                    class="col-lg-3 col-md-4 col-sm-5 col-xs-6 control-label"
                  >
                    <label for="Number" class="col-form-label">Fleet ID</label>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <input
                      [(ngModel)]="entityData.value.Id"
                      type="text"
                      class="form-custom-control control-look-input"
                      readonly="readonly"
                      name="Id"
                    />
                    <div class="control-look"></div>
                  </div>
                </div>

                <div class="row form-group">
                  <div class="col-lg-3 col-md-2 col-sm-1 hidden-xs"></div>

                  <div
                    class="col-lg-3 col-md-4 col-sm-5 col-xs-6 control-label"
                  >
                    <label for="Title" class="col-form-label">Fleet Name</label>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <input
                      [(ngModel)]="entityData.value.Name"
                      [ngClass]="{
                        'modelstate-invalid': hasError('Name')
                      }"
                      type="text"
                      class="form-custom-control control-look-input"
                      name="Name"
                      required
                      maxlength="50"
                    />
                    <div class="control-look"></div>
                    <div class="error-message" *ngIf="hasError('Name')">
                      <small
                        class="error"
                        *ngIf="checkError('Name', 'required')"
                      >
                        Fleet Name is required
                      </small>
                      <small
                        class="error"
                        *ngIf="checkError('Name', 'maxlength')"
                      >
                        Fleet Name cannot be longer than 50 characters
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>

              <div
                class="collapsible-header-container top-buffer"
                (click)="VCollapse = !VCollapse"
              >
                <div
                  class="collapsible-header-left collapsible-header-{{
                    collapseColor
                  }}"
                >
                  <div
                    class="collapsible-header-icon"
                    [ngClass]="collapseClass(VCollapse)"
                  ></div>
                  <div class="text-center">
                    <span
                      class="collapsible-header-content collapsible-header-content-{{
                        collapseColor
                      }}"
                      >Vehicles</span
                    >
                  </div>
                </div>
              </div>

              <div
                id="VCollapse"
                class="childs"
                [ngClass]="{ showChild: !VCollapse }"
              >
                <div
                  class="error-message mb-4"
                  *ngIf="
                    this.entityForm?.submitted &&
                    entityData.value.AssignedVehicles.length == 0
                  "
                ></div>
                <div class="col-lg-12">
                  <facnm-vehicle-assignment
                    [AssignedVehicles]="entityData.value.AssignedVehicles"
                    [UnAssignedVehicles]="entityData.value.UnassignedVehicles"
                    [isAddForm]="isAddForm"
                  >
                  </facnm-vehicle-assignment>
                </div>
              </div>
              <br />

              <div
                class="collapsible-header-container top-buffer"
                (click)="DCollapse = !DCollapse"
              >
                <div
                  class="collapsible-header-left collapsible-header-{{
                    collapseColor
                  }}"
                >
                  <div
                    class="collapsible-header-icon"
                    [ngClass]="collapseClass(DCollapse)"
                  ></div>
                  <div class="text-center">
                    <span
                      class="collapsible-header-content collapsible-header-content-{{
                        collapseColor
                      }}"
                      >Drivers</span
                    >
                  </div>
                </div>
              </div>

              <div
                id="DCollapse"
                class="childs"
                [ngClass]="{ showChild: !DCollapse }"
              >
                <div class="col-lg-12">
                  <facnm-driver-assignment
                    [AssignedDrivers]="entityData.value.AssignedDrivers"
                    [UnAssignedDrivers]="entityData.value.UnassignedDrivers"
                    [isAddForm]="isAddForm"
                  >
                  </facnm-driver-assignment>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <facnm-action-buttons
        [entityName]="entityAddName"
        [modelEntity]="entityData"
        [disableDelete]="isAddForm"
      ></facnm-action-buttons>
    </div>
  </div>
</div>

<facnm-delete-dialog [entityName]="entityAddName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityAddName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>
