<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
    <div id="entity-management" class="row h-100">
        <div class="col-md-3 pr-0 h-100 right-panel overflow-y-auto">
            <div class="selectContainer">
                <pdi-dropdown [full]="true" [(ngModel)]="selectedConfigurationType"
                    (valueSelected)="onConfigurationTypeChange()">
                    <pdi-dropdown-option [key]="1">Default Configurations</pdi-dropdown-option>
                    <pdi-dropdown-option [key]="'2'">Device Specific Configurations</pdi-dropdown-option>
                    <pdi-dropdown-option [key]="'3'">Driver Specific Configurations</pdi-dropdown-option>
                    <pdi-dropdown-option [key]="'4'">Group Specific Configurations</pdi-dropdown-option>
                </pdi-dropdown>
            </div>
            <div class="selectContainer" *ngIf="selectedConfigurationType == '2' && deviceList">
                <dx-select-box name="deviceDropdown"
                    [(ngModel)]="selectedDeviceDriver" [dataSource]="deviceList"
                    [displayExpr]="deviceDisplayExpr" valueExpr="Id" [searchExpr]="['Id','Name']"
                    [searchMode]="dxSelectOptions.searchMode"
                    [searchTimeout]="dxSelectOptions.searchTimeout"
                    [minSearchLength]="dxSelectOptions.minSearchLength"
                    [searchEnabled]="true" (onValueChanged)="onDeviceDriverChange()" placeholder="">
                </dx-select-box>
            </div>
            <div class="selectContainer" *ngIf="selectedConfigurationType == '3' && driverList">
                <dx-select-box name="driverDropdown"
                    [(ngModel)]="selectedDeviceDriver" [dataSource]="driverList"
                    displayExpr="Name" valueExpr="Id" searchExpr="Name"
                    [searchMode]="dxSelectOptions.searchMode"
                    [searchTimeout]="dxSelectOptions.searchTimeout"
                    [minSearchLength]="dxSelectOptions.minSearchLength"
                    [searchEnabled]="true" (onValueChanged)="onDeviceDriverChange()" placeholder="">
                </dx-select-box>
            </div>
            <div class="selectContainer" *ngIf="selectedConfigurationType == '4' && configurationGroupsList">
                <pdi-dropdown [full]="true" [(ngModel)]="groupSpecificConfigurationGroupNameId"
                    name="configurationGroup" (valueSelected)="onConfigurationGroupChange()">
                    <pdi-dropdown-option *ngFor="let entity of configurationGroupsList" [key]="entity.Id">{{entity.Id + ' - ' +
                        entity.Name}}</pdi-dropdown-option>
                </pdi-dropdown>
            </div>
            <div class="selectContainer">
                <input type="search" name="searchStr" class="form-control" placeholder="Search"
                    [(ngModel)]="searchString" (input)="searchConfigurationDebounceTime()" />
            </div>
            <div class="selectContainer">
                <div class="linkContainer" *ngIf="selectedConfigurationType == '2'"
                    (click)="getConfigurationValuesByDeviceId()" [ngClass]="{selected: selectedDeviceSpecific}">
                    <span>Device Specific Configurations</span>
                </div>
                <div class="linkContainer" *ngIf="selectedConfigurationType == '4'"
                    (click)="getConfigurationValuesByConfigurationGroupId()"
                    [class]="{selected: selectedGroupSpecificConfigurationGroup}">
                    <span>Group Specific Configurations</span>
                </div>
                <div class="linkContainer" (click)="getConfigurationValuesByGroupId(group.Id, '')"
                    *ngFor="let group of configurationGroups"
                    [ngClass]="{selected: group.Id == selectedConfigurationGroupId}">
                    <span>{{ group.Name }}</span>
                </div>
            </div>
        </div>

        <div id="entity-detail" class="col-md-9 pr-3 h-100 overflow-y-auto">
            <div id="entity-errors" class="mt-4">
                <div class="col">
                    <facnm-errors></facnm-errors>
                </div>
            </div>
            <div *ngIf="configurationValues && configurationValues.length > 0">
                <table class="table">
                    <thead>
                        <tr>
                            <td class="col-md-1 col-sm-1 col-xs-1 text-center"><strong>Config Id</strong></td>
                            <td class="col-md-4 col-sm-4 col-xs-4"><strong>Description</strong></td>
                            <td class="col-md-2 col-sm-2 col-xs-2 text-left"><strong>Default Config Value</strong>
                            </td>
                            <td *ngIf="selectedConfigurationType == 2" class="col-md-2 col-sm-2 col-xs-2 text-left">
                                <strong>Group Value</strong>
                            </td>
                            <td *ngIf="selectedConfigurationType == 2" class="col-md-2 col-sm-2 col-xs-2 text-left">
                                <strong>Device Value</strong>
                            </td>
                            <td *ngIf="selectedConfigurationType == 3" class="col-md-3 col-sm-3 col-xs-3 text-left">
                                <strong>Driver Value</strong>
                            </td>
                            <td *ngIf="selectedConfigurationType == 4" class="col-md-3 col-sm-3 col-xs-3 text-left">
                                <strong>Group Value</strong>
                            </td>
                            <td class="col-md-5 col-sm-5 col-xs-5"></td>
                        </tr>
                    </thead>
                    <tr *ngFor="let configurationvalue of configurationValues; let i = index">
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                            <div class="pt-3"><span class="configIdContainer">{{ configurationvalue.Id }}</span></div>
                        </td>
                        <td class="col-md-4 col-sm-4 col-xs-4">
                            <div class="pt-3"> {{ configurationvalue.Name }} </div>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2 text-left">
                            <facnm-config-field [selectedGroupNameId]="groupSpecificConfigurationGroupNameId"
                                [selectedDeviceDriver]="selectedDeviceDriver" [configType]="1"
                                [configurationValue]="configurationvalue"
                                [disabled]="selectedConfigurationType !=1" (refreshComplexConfig)="refreshComplexConfig()"></facnm-config-field>
                        </td>
                        <td *ngIf="selectedConfigurationType == 2" class="col-md-2 col-sm-2 col-xs-2 text-left">
                            <facnm-config-field [selectedGroupNameId]="groupSpecificConfigurationGroupNameId"
                                [selectedDeviceDriver]="selectedDeviceDriver" [configType]="4" [showGroupCount]="true"
                                [disabled]="true" [configurationValue]="configurationvalue" (refreshComplexConfig)="refreshComplexConfig()"></facnm-config-field>
                        </td>
                        <td *ngIf="selectedConfigurationType == '2'" class="col-md-2 col-sm-2 col-xs-2 text-left pr-2">
                            <facnm-config-field [selectedGroupNameId]="groupSpecificConfigurationGroupNameId"
                                [selectedDeviceDriver]="selectedDeviceDriver" [configType]="2" [showDelete]="true"
                                [configurationValue]="configurationvalue"
                                (deleteConfig)="preDeleteConfigurationValue(configurationvalue,i)" (refreshComplexConfig)="refreshComplexConfig()"></facnm-config-field>
                        </td>
                        <td *ngIf="selectedConfigurationType == '3'" class="col-md-3 col-sm-3 col-xs-3 text-left pr-2">
                            <facnm-config-field [selectedGroupNameId]="groupSpecificConfigurationGroupNameId"
                                [selectedDeviceDriver]="selectedDeviceDriver" [configType]="3" [showDelete]="true"
                                [configurationValue]="configurationvalue"
                                (deleteConfig)="preDeleteConfigurationValue(configurationvalue,i)" (refreshComplexConfig)="refreshComplexConfig()"></facnm-config-field>
                        </td>
                        <td *ngIf="selectedConfigurationType == 4 " class="col-md-3 col-sm-3 col-xs-3 text-left pr-2">
                            <facnm-config-field [selectedGroupNameId]="groupSpecificConfigurationGroupNameId"
                                [selectedDeviceDriver]="selectedDeviceDriver" [configType]="4" [showDelete]="true"
                                [configurationValue]="configurationvalue"
                                (deleteConfig)="preDeleteConfigurationValue(configurationvalue,i)" (refreshComplexConfig)="refreshComplexConfig()"></facnm-config-field>
                        </td>
                        <td class="col-md-5 col-sm-5 col-xs-5 pl-0 pr-0">
                            <div *ngIf="configurationvalue.Type != 'xml' ">
                                <button type="button" (click)="saveConfigurationValueOnBlur(configurationvalue, i)"
                                    class="btn-icon-action mr-2"
                                    [ngClass]="{buttonDisabled: configurationvalue.IsChanged != true}"
                                    [disabled]="configurationvalue.IsChanged != true">
                                    <img src="assets/Content/Images/Icons/save.png" />
                                </button>
                                <button type="button" (click)="resetConfigurationValue(configurationvalue)"
                                    class="btn-icon-action"
                                    [ngClass]="{buttonDisabled: configurationvalue.IsChanged != true}"
                                    [disabled]="configurationvalue.IsChanged != true">
                                    <img src="assets/Content/Images/Icons/cancel.png" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>


<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>

<facnm-confirmation-dialog *ngIf="ShowDeleteConfirm" (confirmed)=deleteConfirm() (canceled)=deleteCancel()
    [message]="deleteConfirmMsg">
</facnm-confirmation-dialog>