import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export class Config {
    Id: any;
    Name: any;
    BooleanValue: any;
    ConfigurationGroupId: any;
    DefaultBooleanValue: any;
    DefaultFlagsValue: any;
    DefaultValue: any;
    DefaultXmlSchema: any;
    DeviceBooleanValue: any;
    DeviceFlagsValue: any;
    DeviceValue: any;
    DeviceXmlSchema: any;
}

@Injectable()
export abstract class TranspacConfigurationInterface {
    getConfigurationGroup: () => Observable<any[]>;
    getDeviceList: () => Observable<Config[]>;
    getConfigurationValues: (data: any) => Observable<Config[]>;
    getConfigurationValuesByDeviceId: (data: any) => Observable<Config[]>;
    getConfigurationValuesByGroupSpecific: (data: any) => Observable<Config[]>;
    getConfigurationValuesBySearch: (data: any) => Observable<Config[]>;
    saveConfigurationValue: (data: any) => Observable<any>;
    getConfigurationValue: (data: any) => Observable<Config[]>;
    deleteConfigurationValue: (data: any) => Observable<any>;
    saveModalConfigurationValues: (data: any) => Observable<any>;
}