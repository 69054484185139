import { Component, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { ErrorsService } from '../errors/errors.service';
import { ActionsService } from '../actions/actions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'facnm-loading-entity-indicator',
    templateUrl: './loading-entity.indicator.html',
    styleUrls: ['./loading-entity.indicator.scss']
})
export class LoadingEntityIndicator implements OnInit {

    private unsubscribe$ = new Subject<void>();

    animation: any;
    shadingColor: any;

    visible: boolean;

    constructor(private app$: AppService, private actions$: ActionsService, private errors$: ErrorsService) {

        this.animation = app$.getAnimationSettings();

        actions$.EntityBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = true);

        actions$.EntityReady.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = false);

        app$.ScreenBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = false);

        app$.ScreenReady.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.visible = false);

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
            if (errors) {
                this.visible = false;
            }
        });

    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
