<ngx-smart-modal #modal identifier="modal" [customClass]="'modal-custom'" (onClose)="onPopupCancel()" [closable]="true"
    [dismissable]="false" [escapable]="false">

    <div class="modal-header">
        <div class="modal-title model-header-label">Product</div>
    </div>
    <div class="modal-body" style="overflow-y: auto;">
        <div class="container modal-control">
            <dx-data-grid #dataGrid height="450px" class="no-data-min-height-md" (onContentReady)="onContentReady($event)" [dataSource]="customDataSource" noDataText=""
                [allowColumnResizing]="true" [columnAutoWidth]="true">
                <dxi-column alignment='center' width="150" caption="Product Number" dataField="ProductNumber"
                    dataType="string"></dxi-column>
                <dxi-column alignment='center' caption="Product Name" dataField="ShortProductName"
                    dataType="string"></dxi-column>
                <dxi-column alignment='center' caption="UOM" dataField="QuantityUnit" dataType="string"
                    width="150"></dxi-column>
                <dxi-column alignment='center' caption="Product Category" dataField="ProductCategoryId"
                    dataType="string">
                    <dxo-lookup [dataSource]="productCategories" valueExpr="Id" displayExpr="Name">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column alignment='center' caption="Product Type" dataField="ProductTypeId" dataType="string">
                    <dxo-lookup [dataSource]="productType" valueExpr="Id" displayExpr="Name">
                    </dxo-lookup>
                </dxi-column>
                <dxo-selection mode="single"> </dxo-selection>
                <dxo-filter-row [visible]="true"> </dxo-filter-row>
                <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                <dxo-sorting mode="none"> </dxo-sorting>
                <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true" [visible]="true"></dxo-pager>
                <dxo-paging [pageSize]="20"> </dxo-paging>
                <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
            </dx-data-grid>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 text-center">
            <button type="button" class="edit-button-green" (click)="onPopupCancel()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-2  text-center">
            <button type="submit" class="edit-button-green" (click)="onPopupOk()">
                <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">OK</span>
            </button>
        </div>
    </div>
</ngx-smart-modal>