import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'facnm-driver-assignment',
  templateUrl: './driver-assignment.component.html',
  styleUrls: ['./driver-assignment.component.scss']
})
export class DriverAssignmentComponent implements OnInit {

  constructor() { }
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;

  private dataGridAssignedDrivers: DxDataGridComponent;
  @ViewChild('dataGridAssignedDrivers', { static: false }) set assignedDrivers(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAssignedDrivers = content;
    }
  }

  private dataGridUnassignedDrivers: DxDataGridComponent;
  @ViewChild('dataGridUnassignedDrivers', { static: false }) set unassignedDrivers(content: DxDataGridComponent) {
    if (content) {
      this.dataGridUnassignedDrivers = content;
    }
  }
  @Input() AssignedDrivers = [];
  @Input() UnAssignedDrivers = [];
  @Input() isAddForm = 0;
  assignedDriverSelected = [];
  unassignedDriverSelected = [];
  driverColumnConfig: any;
  allMode: string;
  checkBoxesMode: string;

  ngOnInit(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'onClick';
    this.driverColumnConfig = [
      { alignment: 'center', caption: 'Driver ID', dataField: 'Id', dataType: 'number' },
      {
        alignment: 'center', caption: 'Driver No', dataField: 'Number', dataType: 'number',
      },
      { alignment: 'left', caption: 'Driver Name', dataField: 'Name', dataType: 'string' },
    ];
  }
  onContentReadyAssigned(e) {
    this.dataGridAssignedDrivers.noDataText = this.dataGridAssignedDrivers.instance.totalCount() ? '' : "No data";
  }
  onContentReadyUnAssigned(e) {
    this.dataGridUnassignedDrivers.noDataText = this.dataGridUnassignedDrivers.instance.totalCount() ? '' : "No data";
  }
  assignDrivers() {
    let selectedRows = this.unassignedDriverSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.AssignedDrivers.filter(a => a.Id == row.Id);
      this.UnAssignedDrivers.splice(this.UnAssignedDrivers.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.AssignedDrivers.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  unassignDrivers() {
    let selectedRows = this.assignedDriverSelected;
    selectedRows.forEach(row => {
      let alreadyAssigned = this.UnAssignedDrivers.filter(a => a.Id == row.Id);
      this.AssignedDrivers.splice(this.AssignedDrivers.indexOf(row), 1);
      if (alreadyAssigned.length == 0) {
        this.UnAssignedDrivers.push(row);
      }
    });
    this.clearAndRefreshGrid();
  }
  clearAndRefreshGrid() {
    if (this.dataGridUnassignedDrivers && this.dataGridAssignedDrivers) {
      this.dataGridUnassignedDrivers.instance.clearSelection();
      this.dataGridUnassignedDrivers.instance.refresh();
      this.dataGridAssignedDrivers.instance.clearSelection();
      this.dataGridAssignedDrivers.instance.refresh();
      this.setNoDataText();
    }
  }
  setNoDataText() {
    this.dataGridUnassignedDrivers.noDataText = this.UnAssignedDrivers?.length ? '' : "No data";
    this.dataGridAssignedDrivers.noDataText = this.AssignedDrivers?.length ? '' : "No data";
  }
}

