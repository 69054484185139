import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Enum, Entity } from "@app/app.model";


export class ProductTransformation {

    Flag: number;
    Type: number;

    ProductFromId: number;
    ProductToId: number;

    Surcharge: number;
}



@Injectable()
export abstract class ProductTransformationInterface implements EntityInterface<ProductTransformation> {


    create: (data: Entity<ProductTransformation>) => Observable<any>;

    save: (data: Entity<ProductTransformation>) => Observable<any>;

    delete: (data: any) => Observable<any>;

    getList: () => Observable<ProductTransformation[]>;
    getEntity: (key: any) => Observable<ProductTransformation>;

    ListRetrieved: Observable<ProductTransformation[]>;

    notifyRetrieved: (data: ProductTransformation[]) => void;


    getProductTransformationTypeList: () => Observable<Enum[]>;
}