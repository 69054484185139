import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class User {

    CompanyId: number;
    EmailAddress: string;
    FirstName: string;
    FleetId: number;
    Id: number;
    IsLocked: boolean;
    LastName: string;
    LastUserActivity: Date;
    Password: string;
    SecurityAnswer: string;
    SecurityQuestion: string;
    UserName: string;
    UserRoleIds: number;
}

@Injectable()
export abstract class UserInterface implements EntityInterface<User> {

    create: (data: Entity<User>) => Observable<any>;
    save: (data: Entity<User>) => Observable<any>;
    delete: (data: any) => Observable<any>;
    getList: () => Observable<User[]>;
    getEntity: (key: any) => Observable<User>;

    ListRetrieved: Observable<User[]>;
    notifyRetrieved: (data: User[]) => void;
    getFleetList: () => Observable<Enum[]>;
    getCompanyList: () => Observable<Enum[]>;
    getUserDetailById: (userId: any) => Observable<User>;
    getRoleList: (isSysAdmin:any) => Observable<Enum[]>;
}