<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="load-point" class="row h-100">
        <div class="col pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="loadPointData" noDataText=""
                    (onInitialized)="onInitialized($event)" (onCellPrepared)="onCellPrepared($event)"
                    (onSelectionChanged)="onSelectionChanged($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column width="150" caption="Load Point No" dataField="Number" dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Load Point Name" dataField="Name1"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Load Point Address" dataField="Address"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="loadPoint && loadPoint.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div #LoadPointDetailsDiv class="container scrollable">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Number" class="col-form-label">Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="loadPoint.value.Number" placeholder="Number"
                                                [ngClass]="{'modelstate-invalid': hasError('loadPointNumber')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="loadPointNumber" required maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('loadPointNumber')">
                                                <small class="error" *ngIf="checkError('loadPointNumber','required')">
                                                    Number is required
                                                </small>
                                                <small class="error" *ngIf="checkError('loadPointNumber','maxlength')">
                                                    Number cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name1" class="col-form-label">Name 1</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="loadPoint.value.Name1" placeholder="Name 1"
                                                [ngClass]="{'modelstate-invalid': hasError('loadPointName1')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="loadPointName1" required maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('loadPointName1')">
                                                <small class="error" *ngIf="checkError('loadPointName1','required')">
                                                    Name 1 is required
                                                </small>
                                                <small class="error" *ngIf="checkError('loadPointName1','maxlength')">
                                                    Name 1 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name2" class="col-form-label">Name 2</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="loadPoint.value.Name2" placeholder="Name 2"
                                                [ngClass]="{'modelstate-invalid': hasError('loadPointName2')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="loadPointName2" required maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('loadPointName2')">
                                                <small class="error" *ngIf="checkError('loadPointName2','required')">
                                                    Name 2 is required
                                                </small>
                                                <small class="error" *ngIf="checkError('loadPointName2','maxlength')">
                                                    Name 2 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="LoadLocationGroup" class="col-form-label">Load Location
                                                Group</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" id="LoadLocationGroup" name="LoadLocationGroup"
                                                [(ngModel)]="loadPoint.value.LoadLocationGroup"
                                                (valueSelected)="onLoadLocationGroupChange()">
                                                <pdi-dropdown-option *ngFor="let location of loadLocation" [key]="location.id">
                                                    {{location.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Name3" class="col-form-label">Name 3</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input [(ngModel)]="loadPoint.value.Name3" placeholder="Name 3"
                                                [ngClass]="{'modelstate-invalid': hasError('loadPointName3')}"
                                                type="text" class="form-custom-control control-look-input"
                                                name="loadPointName3" maxlength="50">
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('loadPointName3')">
                                                <small class="error" *ngIf="checkError('loadPointName3','maxlength')">
                                                    Name 3 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="CompanyId" class="col-form-label">Company</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" id="CompanyId" name="CompanyId"
                                                [ngClass]="{'modelstate-invalid': hasError('CompanyId')}"
                                                [(ngModel)]="loadPoint.value.CompanyId" required>
                                                <pdi-dropdown-option *ngFor="let company of nonPrintCompanies"
                                                    [key]="company.Id">
                                                    {{company.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('CompanyId')">
                                                <small class="error" *ngIf="checkError('CompanyId','required')">
                                                    Please select a company
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="Barcode" class="col-form-label">Barcode</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="Barcode" type="text" placeholder="Barcode"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="loadPoint.value.Barcode" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="categorType" class="col-form-label">Category/Type</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input name="categorType" type="text" placeholder="Type"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="loadPoint.value.Type" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="AllowRegisterDataChange" class="col-form-label">Allow Register
                                                Data Change</label>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                            <input name="AllowRegisterDataChange" type="checkbox"
                                                class="checkbox-inline"
                                                [(ngModel)]="loadPoint.value.AllowRegisterDataChange" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="ADCollapse = !ADCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(ADCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Address
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="ADCollapse" class="childs" [ngClass]="{'showChild': !ADCollapse}">
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label class="col-form-label">Address</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-1">
                                            <input type="text" name="address" placeholder="address"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('address')}"
                                                [(ngModel)]="loadPoint.value.Address" required maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('address')">
                                                <small class="error" *ngIf="checkError('address','required')">
                                                    Address is required
                                                </small>
                                                <small class="error" *ngIf="checkError('address','maxlength')">
                                                    Address cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">ZIP/Postal Code</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="Postcode" placeholder="Postcode"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('Postcode')}"
                                                [(ngModel)]="loadPoint.value.Postcode" required
                                                (keypress)="isNumberOnly($event)" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('Postcode')">
                                                <small class="error" *ngIf="checkError('Postcode','required')">
                                                    ZIP/Postal Code is required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">State</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="state" placeholder="State"
                                                [(ngModel)]="loadPoint.value.State"
                                                class="form-custom-control control-look-input" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">City/Place</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="city" placeholder="City"
                                                class="form-custom-control control-look-input"
                                                [ngClass]="{'modelstate-invalid': hasError('city')}"
                                                [(ngModel)]="loadPoint.value.City" maxlength="50" required />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('city')">
                                                <small class="error" *ngIf="checkError('city','required')">
                                                    City/Place is required
                                                </small>
                                                <small class="error" *ngIf="checkError('city','maxlength')">
                                                    City/Place cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label for="country" class="col-form-label">Country</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <pdi-dropdown [full]="true" id="country" name="country"
                                                [(ngModel)]="loadPoint.value.CountryId">
                                                <pdi-dropdown-option *ngFor="let country of countries" [key]="country.Id">
                                                    {{country.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="COCollapse = !COCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(COCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Coordinates
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="COCollapse" class="childs" [ngClass]="{'showChild': !COCollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Latitude</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="Latitude" placeholder="Latitude"
                                                [(ngModel)]="loadPoint.value.Latitude"
                                                class="form-custom-control control-look-input" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Longitude</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="number" name="Longitude" placeholder="Longitude"
                                                [(ngModel)]="loadPoint.value.Longitude"
                                                class="form-custom-control control-look-input" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container" (click)="CICollapse = !CICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(CICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Contact Information
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="CICollapse" class="childs" [ngClass]="{'showChild': !CICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Contact Person</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="ContactName" placeholder="Person Name"
                                                [(ngModel)]="loadPoint.value.ContactName"
                                                class="form-custom-control control-look-input" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 second-column">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Telephone</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                            <input type="text" name="ContactPhone" placeholder="Telephone number"
                                                [(ngModel)]="loadPoint.value.ContactPhone"
                                                class="form-custom-control control-look-input" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="loadPoint.value.LoadLocationGroup == 1" class="collapsible-header-container" (click)="BPTCollapse = !BPTCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(BPTCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Bulk Plant Tanks
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="loadPoint.value.LoadLocationGroup == 1" id="BPTCollapse" class="childs" [ngClass]="{'showChild': !BPTCollapse}">
                                <div class="col-lg-6">
                                    <div class="dataGridDiv">
                                        <div class="datagrid-header-title">
                                            <span>Bulk Plant Tanks</span>
                                            <div class="dx-edit-button add-bulkplanttank" (click)="addNewLoadPointTank()">
                                        <i class="pdi-icon-Add"></i></div>
                                    </div>
                                    <dx-data-grid #bulkPlantDataGrid height="300px" [noDataText]="noDataTextbulkPlant" keyExpr="Id"
                                        [dataSource]="loadPoint.value.LoadPointTanks"
                                        (onInitialized)="onInitialized($event)" [focusedRowEnabled]="true"
                                        (onRowClick)="onRowClicked($event)" (onItemDeleting)="onItemDeleting($event)"
                                        (onRowRemoving)="handleRowRemoving($event)" [allowColumnResizing]="true"
                                        [columnAutoWidth]="false">
                                        <dxi-column caption="Tanks ID" dataType="string"
                                            dataField="TankId" [allowEditing]="false"></dxi-column>
                                        <dxi-column caption="Product" dataType="string"
                                            dataField="ProductName" [allowEditing]="false"></dxi-column>
                                        <dxo-selection mode="single"> </dxo-selection>
                                        <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                        <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                                        <dxo-sorting mode="single"> </dxo-sorting>
                                        <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                                            [visible]="true"></dxo-pager>
                                        <dxo-paging [pageSize]="10"> </dxo-paging>
                                        <dxo-editing mode="single" [allowAdding]="false" [allowDeleting]="true"
                                            [allowUpdating]="false"></dxo-editing>
                                        <dxo-remote-operations [paging]="true"
                                            [filtering]="false"></dxo-remote-operations>
                                    </dx-data-grid>
                                </div>
                                    <div style="height: 84px;width: 100%;clear: both;"></div>
                                </div>
                                <div class="col-lg-6">
                                    <div *ngIf="loadPointTank && loadPointTank.key">
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                Internal ID
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" name="Id" [(ngModel)]="loadPointTank.Id"
                                                    class="form-custom-control form-control-disabled control-look-input"
                                                    readonly="readonly" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                Tank ID
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input [(ngModel)]="loadPointTank.TankId" placeholder="TankId"
                                                    type="text" class="form-custom-control control-look-input"
                                                    name="TankId" required maxlength="50" />
                                                <div class="control-look" (click)="clickHalfDiv('TankId')"></div>
                                                <div class="error-message" *ngIf="hasError('TankId')">
                                                    <small class="error" *ngIf="checkError('TankId','required')">
                                                        Please enter a valid Tank ID
                                                    </small>
                                                    <small class="error" *ngIf="checkError('TankId','maxlength')">
                                                        Tank ID cannot be longer than 50 characters
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                <a pdiLink pdiLinkLabel="Product" (click)="OpenProductModel()"></a>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input type="text" name="ProductName"
                                                    [(ngModel)]="loadPointTank.ProductName"
                                                    class="form-custom-control form-control-disabled control-look-input"
                                                    readonly="readonly" required />
                                                <div class="control-look" (click)="clickHalfDiv('ProductName')"></div>
                                                <div class="error-message" *ngIf="hasError('ProductName')">
                                                    <small class="error" *ngIf="checkError('ProductName','required')">
                                                        product is required
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                Barcode
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input [(ngModel)]="loadPointTank.Barcode" placeholder="Barcode"
                                                    type="text" class="form-custom-control control-look-input"
                                                    name="Barcode"/>
                                                <div class="control-look" (click)="clickHalfDiv('Barcode')"></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                                Change Date
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                <input [(ngModel)]="loadPointTank.ChangeDate" type="text"
                                                    class="form-custom-control form-control-disabled control-look-input"
                                                    name="ChangeDate" readonly="readonly" />
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="loadPoint"></facnm-action-buttons>
        </div>
    </div>
</div>

<dx-popup title="Warning" [(visible)]="popupVisible" [animation]="animation" hideOnOutsideClick="true"
    [dragEnabled]="false" shadingColor="shadingColor" maxHeight="200" maxWidth="400">
    <div>
        <div> Cannot delete bulk plant tank, Atleast one is required per Bulk Plant.</div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="popupVisible = false" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">OK</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<dx-popup title="Warning" [(visible)]="deletePopupVisible" [animation]="animation" hideOnOutsideClick="true"
    [dragEnabled]="false" shadingColor="shadingColor" maxHeight="200" maxWidth="400">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col">
                <div> Are you sure you want to delete this record? </div>
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="resultDeleteBulkPlant = true" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Yes</span>
                </button>
            </div>
            <div class="col">
                <button (click)="resultDeleteBulkPlant = false" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">No</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<ngx-smart-modal #ProductModel identifier="ProductModel" [customClass]="'modal-custom'" [closable]="true"
    [dismissable]="false" [escapable]="false">
    <div class="modal-header">
        <div class="modal-title model-header-label">Product</div>
    </div>
    <div class="modal-body" style="overflow-y: auto;">
        <div class="container modal-control" style="font-size:14px;">
            <dx-data-grid #productDataGrid height="450px" [dataSource]="customDataSource" noDataText=""
                (onRowClick)="onProductRowClicked($event)"
                [allowColumnResizing]="true" [columnAutoWidth]="true">
                <dxo-load-panel>
                    <div *dxTemplate="let data of 'content'">
                        <pdi-loader size="medium"></pdi-loader>
                    </div>
                </dxo-load-panel>
                <dxi-column alignment='center' width="150" caption="Product Number" dataField="ProductNumber"
                    dataType="string"></dxi-column>
                <dxi-column alignment='center' caption="Product Name" dataField="ShortProductName"
                    dataType="string"></dxi-column>
                <dxi-column alignment='center' caption="UOM" dataField="QuantityUnit" dataType="string"
                    width="150"></dxi-column>
                <dxi-column alignment='center' caption="Product Category" dataField="ProductCategoryId"
                    dataType="string">
                    <dxo-lookup [dataSource]="productCategories" valueExpr="Id" displayExpr="Name">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column alignment='center' caption="Product Type" dataField="ProductTypeId" dataType="string">
                    <dxo-lookup [dataSource]="productType" valueExpr="Id" displayExpr="Name">
                    </dxo-lookup>
                </dxi-column>
                <dxo-selection mode="single"> </dxo-selection>
                <dxo-filter-row [visible]="true"> </dxo-filter-row>
                <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                <dxo-sorting mode="none"> </dxo-sorting>
                <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true" [visible]="true"></dxo-pager>
                <dxo-paging [pageSize]="10"> </dxo-paging>
                <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
            </dx-data-grid>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 text-center">
            <button type="button" class="edit-button-green" (click)="onPopupCancel()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-2  text-center">
            <button type="submit" [disabled]="saveCategoryProgress" class="edit-button-green" (click)="onPopupOk()">
                <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">OK</span>
            </button>
        </div>
    </div>
</ngx-smart-modal>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>

<facnm-confirmation-dialog *ngIf="loadPointCategoryDeleteConfirm" (confirmed)=loadPointDeleteConfirmed()
    (canceled)=loadPointDeleteClosed() [message]="loadPointCategoryDeleteConfirmMsg">
</facnm-confirmation-dialog>