import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class ProductGroup {
  Id: number;
  Number: string;
  Name: string;
  ChangeDate: Date;
  RelatedProducts: any;
  AssignedDataAreas: any;
  UnassignedDataAreas: any;
  AssignedProducts: any;
  UnassignedProducts: any;
}

@Injectable()
export abstract class ProductGroupInterface implements EntityInterface<ProductGroup> {
  create: (data: Entity<ProductGroup>) => Observable<any>;
  save: (data: Entity<ProductGroup>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<ProductGroup[]>;
  getEntity: (key: any) => Observable<ProductGroup>;
  ListRetrieved: Observable<ProductGroup[]>;
  notifyRetrieved: (data: ProductGroup[]) => void;
}