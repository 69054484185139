import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export class Config {
  Id: any;
  Name: any;
}
@Injectable()
export abstract class ExportConfigurationInterface {
  getConfigurationGroup: () => Observable<any[]>;
  exportConfigurationSetByid: (data: any) => Observable<any[]>;
  getConfigurationValues: (data: any) => Observable<any[]>;
}

