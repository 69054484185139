<pdi-page-notification *ngIf="ServerErrors" type="alert" text="<strong>Error!</strong>">
  {{ ServerErrors.message }}
  <div> {{ ServerErrors.details }}</div>
</pdi-page-notification>

<pdi-page-notification *ngIf="ModelStateErrors" type="alert" text="<strong>Fix the below validations!</strong>">
  <ul class="example-list">
    <li *ngFor="let messageGroup of ModelStateErrors | keyvalue">
      <div *ngFor="let errorMessage of messageGroup.value">{{errorMessage}}</div>
    </li>
  </ul>
</pdi-page-notification>

<pdi-page-notification *ngIf="alertError" type="alert" text="<strong>Fix the below validations!</strong>">
  <div *ngFor="let alert of alerts">
    {{ alert }}
  </div>
</pdi-page-notification>