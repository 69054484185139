import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { TimeLog, TimeLogInterface } from './time-log.model';

@Injectable({
  providedIn: 'root'
})
export class TimeLogService implements TimeLogInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  private listRetrieved: BehaviorSubject<TimeLog[]> = new BehaviorSubject<TimeLog[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<TimeLog>) => Observable<any>;
  getEntity: (data: any) => Observable<any>;

  notifyRetrieved(data: TimeLog[]): void {
    this.listRetrieved.next(data);
  }

  getList(): Observable<TimeLog[]> {
    return this._http.get<TimeLog[]>(e.endpoint.fleet.timeLog)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<TimeLog>): Observable<any> {
    return this._http.put<TimeLog>(
      e.endpoint.fleet.timeLog,
      data.value)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    return this._http.delete(e.endpoint.fleet.timeLog + "/" + param.TimeLogType + "/" + param.TimeLogId + "/" + param.Number, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity() {
    return this._http.get<TimeLog>(e.endpoint.fleet.timeLog + '/newtimelog')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getTimeLogTypeList() {
    return this._http.get<TimeLog>(e.endpoint.fleet.timeLog + '/timelogtypes')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

}
