import { Component, OnInit, OnDestroy } from '@angular/core';

import { ServerError } from '../shared.model';
import { ErrorsService } from './errors.service';
import { ActionsService } from '../actions/actions.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'facnm-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    ServerErrors: ServerError[];
    ModelStateErrors: any;
    alerts: string[] = [];
    alertError: boolean;

    constructor(private errors$: ErrorsService, private actions$: ActionsService) {

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            this.ServerErrors = undefined;
            this.ModelStateErrors = undefined;

            if (errors) {
                if (errors.modelState) {
                    this.ModelStateErrors = errors.modelState;
                }
                else {
                    this.ServerErrors = errors;
                }
            }

        });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });
        actions$.EntityBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { errors$.clearErrors() });

        this.errors$.getAlerts().subscribe(alert => {
            if (!alert) {
                this.alertError = false;
                this.alerts = [];
            }
            else {
                this.alertError = true;
                this.alerts.push(alert);
            }
        });

    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    closeServerErrors() { this.ServerErrors = undefined; }
    closeModelStateErrors() { this.ModelStateErrors = undefined; }
    closeClientErrors() {this.alerts = undefined, this.alertError=false;}
}
