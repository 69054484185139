import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScreenNotification } from '../shared.model';



@Injectable({
    providedIn: 'root'
})

export class ActionsService {
    
    constructor() { }    

    private entityReady: Subject<void> = new Subject<void>();
    EntityReady = this.entityReady.asObservable();
    notifyEntityReady(): void {
        this.entityReady.next();
    }

    private entityBusy: Subject<void> = new Subject<void>();
    EntityBusy = this.entityBusy.asObservable();
    notifyEntityBusy(): void {
        this.entityBusy.next();
    }

    private entityCustomAction: Subject<ScreenNotification> = new Subject<ScreenNotification>();
    EntityCustomAction = this.entityCustomAction.asObservable();
    notifyEntityCustomAction(notification: ScreenNotification): void {
        this.entityCustomAction.next(notification);
    }

    private entityAdding: Subject<void> = new Subject<void>();
    EntityAdding = this.entityAdding.asObservable();
    notifyAdding(): void {
        this.entityAdding.next();
    }

    private entityCreating: Subject<void> = new Subject<void>();
    EntityCreating = this.entityCreating.asObservable();
    notifyCreating(): void {
        this.entityCreating.next();
    }
    private entityCreated: Subject<void> = new Subject<void>();
    EntityCreated = this.entityCreated.asObservable();
    notifyCreated(): void {
        this.entityCreated.next();
    }

    private entitySaving: Subject<void> = new Subject<void>();
    EntitySaving = this.entitySaving.asObservable();
    notifySaving(): void {
        this.entitySaving.next();
    }
    private entitySaved: Subject<void> = new Subject<void>();
    EntitySaved = this.entitySaved.asObservable();
    notifySaved(): void {
        this.entitySaved.next();
    }

    private entityDeleting: Subject<void> = new Subject<void>();
    EntityDeleting = this.entityDeleting.asObservable();
    notifyDeleting(): void {
        this.entityDeleting.next();
    }
    private entityDeleteConfirmed: Subject<void> = new Subject<void>();
    EntityDeleteConfirmed = this.entityDeleteConfirmed.asObservable();
    notifyDeleteConfirmed(): void {
        this.entityDeleteConfirmed.next();
    }
    private entityDeleted: Subject<void> = new Subject<void>();
    EntityDeleted = this.entityDeleted.asObservable();
    notifyDeleted(): void {
        this.entityDeleted.next();
    }

    private entityResetting: Subject<void> = new Subject<void>();
    EntityResetting = this.entityResetting.asObservable();
    notifyResetting(): void {
        this.entityResetting.next();
    }

    private entityCanceling: Subject<void> = new Subject<void>();
    EntityCanceling = this.entityCanceling.asObservable();
    notifyCanceling(): void {
        this.entityCanceling.next();
    }


}
