import {ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuditLogInterface, EntityList, Log, filterOptions } from './audit-log.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { AuditLogService } from './audit-log.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AppService } from '@app/app.service';
import { IsUndefinedOrNull } from '@app/common_helper';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'facnm-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  providers: [
    {
      provide: AuditLogInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new AuditLogService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class AuditLogComponent implements OnInit {
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  entityName = 'Audit Log';
  filterApplied = false;
  showFilterModal = false;
  collapseColor = 'green';
  pageSize = 20;
  filterOptions: filterOptions;
  customDataSource: any;
  auditLog: any;
  entityData: any;
  totalCount: any;
  entityList: EntityList[];
  objectKeys: string[];
  dateFormat= '';

  constructor(
    private auditLog$: AuditLogInterface,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { this.dateFormat = this.app$.dateFormat;}

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.initFilterOption();
    this.getCustomDataSource();
  }

  initFilterOption() {
    this.filterOptions = {
      TableName: null,
      LogType: null,
      Username: null,
      DateFrom: null,
      DateTo: null,
      GridPagerOptions: { PageIndex: 0, PageSize: 20, TotalPages: null }
    };
  }

  getCustomDataSource() {
    this.customDataSource = new CustomStore({
      key: ['Id'],
      load: (loadOptions) => {
        if (loadOptions != undefined) {
          this.filterOptions.GridPagerOptions.PageSize = (IsUndefinedOrNull(loadOptions.take) == true) ? this.pageSize : loadOptions.take;
          this.filterOptions.GridPagerOptions.PageIndex = ((IsUndefinedOrNull(loadOptions.take) && IsUndefinedOrNull(loadOptions.skip)) == true) ? 0 : (loadOptions.skip / loadOptions.take);
        }
        return this.auditLog$.getAuditLogList(this.filterOptions)
          .toPromise()
          .then((response: any) => {
            this.app$.notifyScreenReady();
            this.totalCount = response.TotalCount;
            return {
              data: response.AuditLogList,
              totalCount: response.TotalCount,
            };
          })
          .catch((errorNetwork) => this.errors$.handleServerErrors(errorNetwork));
      }
    });
  }

  onContentReadyGrid(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Audit Logs Found";
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Log();
      this.entityData.key = null;
    }

    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow);
    }
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = null;
  }

  private selectEntity(selectedRow: any) {
    this.entityData = selectedRow.data;
    this.entityData.LogDate = new Date(this.entityData.LogDate);
    this.entityData.key = selectedRow.key;
    this.getObjectKeys();
    this.changeDetectorRef.detectChanges();
  }

  cancelDetails() {
    this.deSelectEntity();
  }

  getObjectKeys() {
    if (this.entityData != null) {
      if (this.entityData.OldValue != null) this.objectKeys = Object.keys(this.entityData.OldValue);

      if (this.objectKeys.length == 0) {
        if (this.entityData.NewValue != null) this.objectKeys = Object.keys(this.entityData.NewValue);
      }
    }
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel,
      'collapsible-header-icon-green-down': pannel,
    }
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  applyFilter() {
    this.filterApplied = true;
    if (!this.filterOptions.DateFrom && this.filterOptions.DateTo) return;
    this.showFilterModal = false;
    this.dataGrid.instance.refresh();
    this.dataGrid.instance.deselectAll();
    this.entityData = null;
  }

  resetfilterOptions() {
    this.initFilterOption();
    this.applyFilter();
  }

  viewFilterOptionsModal() {

    if (!this.entityList) {
      this.app$.notifyScreenBusy();
      this.auditLog$.getEntityList().subscribe(
        (res) => {
          this.app$.notifyScreenReady();
          this.entityList = res;
          this.showFilterOptionsModal();
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          this.app$.notifyScreenReady();
          this.errors$.handleServerErrors(err);
        }
      );
    } else {
      this.showFilterOptionsModal();
    }
  }

  showFilterOptionsModal() {
    this.filterApplied = false;
    if (!this.filterOptions.DateFrom) this.filterOptions.DateFrom = new Date(new Date().setHours(0, 0, 0, 0));
    if (!this.filterOptions.DateTo) this.filterOptions.DateTo = new Date();
    this.showFilterModal = true;
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - 90);
    this.changeDetectorRef.detectChanges();
  }

}
