import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { Company, CompanyInterface } from './company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements CompanyInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  save: (data: Entity<Company>) => Observable<any>;

  private listRetrieved: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: Company[]): void {
    this.listRetrieved.next(data);
  }

  getNonPrintCompanyList(lookup): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.settings.nonPrintCompanyDropdown + lookup).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<Company>): Observable<any> {
    return this._http.put<Company>(
      e.endpoint.company,
      data.value)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  getList(): Observable<Company[]> {
    return this._http.get<Company[]>(e.endpoint.company)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(key: any): Observable<Company> {
    return this._http.get<Company>(e.endpoint.company + '/' + key.Id)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity(): Observable<Company> {
    return this._http.get<Company>(e.endpoint.company + '/newcompany')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    return this._http.delete(e.endpoint.company + "/" + param.id, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getCompanyDropDown(lookup: any): Observable<Company> {
    return this._http.get<Company>(e.endpoint.company + '/dropdown/' + lookup)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getMsgReceiverGroups(): Observable<any> {
    return this._http.get<any>(e.endpoint.company + '/GetMsgReceiverGroups')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getAutoEmailOptionsList(): Observable<any> {
    return this._http.get<any>(e.endpoint.company + '/GetAutoEmailOptionsList')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

}
