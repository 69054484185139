import { Component, OnInit, Input } from '@angular/core';
import { ErrorsService } from '../errors/errors.service';
import { ActionsService } from '../actions/actions.service';
import { AppService } from '@app/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PdiNotificationService } from '@pdi/platform-angular-template';

@Component({
    selector: 'facnm-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    private unsubscribe$ = new Subject<void>();

    @Input() entityName;

    constructor(private app$: AppService, private errors$: ErrorsService, private actions$: ActionsService, private notificationService: PdiNotificationService) {

        actions$.EntityCreated.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.page("Created " + this.entityName + " successfully", 'success');
        });

        actions$.EntitySaved.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.page("Updated " + this.entityName + " successfully", 'success');
        });

        actions$.EntityDeleted.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.page("Deleted " + this.entityName + " successfully", 'success');
        });

        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.page(this.entityName + " details were reset", 'success');
        });


        actions$.EntityBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.closeNotification();
        });

        app$.ScreenBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.notificationService.closeNotification();
        });

        actions$.EntityCustomAction.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            let type = value.type ? value.type : 'success';
            let toastMessage = "<strong>" + value.title + "</strong> " + value.message;
            switch (type) {
                case 'success':
                    this.notificationService.page(toastMessage, 'success');
                    break;
                case 'error':
                    this.notificationService.page(toastMessage, 'alert');
                    break;
                case 'warning':
                    this.notificationService.page(toastMessage, 'warning');
                    break;
                default:
                    break;
            }

        });
    }


    ngOnInit() {

        this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
            if (errors) {
                let toastMessage = errors.message;
                if (errors.modelState) {
                    toastMessage = 'The ' + this.entityName + ' contains errors';
                }
                this.notificationService.page(toastMessage, 'alert');
            }
        });
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
