import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment as e } from 'src/environments/environment';


import { ServerConfigurationInterface, ServerConfiguration } from './server-configuration.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class ServerConfigurationService implements ServerConfigurationInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    

    save(data: ServerConfiguration): Observable<any> {
        return this._http.put<ServerConfiguration>(
            e.endpoint.configuration + '/server-configurations',
            data).pipe(catchError(this.errors$.handleNetworkError));
    }


    getList(): Observable<ServerConfiguration[]> {
        return this._http.get<ServerConfiguration[]>(e.endpoint.configuration + '/server-configurations')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

}
