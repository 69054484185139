import { Component, Input, OnInit } from '@angular/core';
import { checkPattern } from '@app/common_helper';

@Component({
  selector: 'facnm-modal-config-field',
  templateUrl: './modal-config-field.component.html',
  styleUrls: ['./modal-config-field.component.scss']
})

export class ModalConfigFieldComponent implements OnInit {
  @Input() form: any;
  @Input() readonly = false;
  @Input() isSingle: any;
  @Input() configId: any;
  requiredCheck = true;
  noDefaultValConfigs = [409, 283];

  constructor() { }

  ngOnInit(): void {
    this.requiredCheck = !this.readonly && (!this.isSingle || this.isNoDefaultValConfig());
    this.form.map(fielditem => {
      if (fielditem.fieldType == 'ComplexType' && fielditem.minOccurs == '0' && fielditem.maxOccurs == 'unbounded') {
        fielditem.fieldSequence.map(complexitem => {
          complexitem.fieldValue = complexitem.fieldOptions.filter((item) => item.IsSelected);
        });
      }
    });
  }

  formatLabel(str) {
    return str.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, "$1 $2").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  checkPattern(type: any, val: any) {
    return !checkPattern(type, val);
  }

  onItemReordered(complexItem: any, e: any) {
    complexItem.fieldOptions.splice(e.toIndex, 0, complexItem.fieldOptions.splice(e.fromIndex, 1)[0]);
    this.updateSeletedSortedList(complexItem);
  }

  updateSeletedSortedList(complexItem: any) {
    complexItem.fieldValue = complexItem.fieldOptions.filter((i) => i.IsSelected);
  }

  isNoDefaultValConfig() {
    // No default attributes in XML schema.
    return this.noDefaultValConfigs.includes(this.configId);
  }
}
