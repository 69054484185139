import { ChangeDetectorRef,Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import ArrayStore from "devextreme/data/array_store";
import { ProductsGridComponent } from '@products/products-grid/products-grid.component';
import { forkJoin, Subject } from 'rxjs';
import { ProductService } from '@products/products.service';
import { Product } from '@products/products.model';

import { ProductBlendsInterface } from './product-blends.model';
import { ProductBlendsService } from './product-blends.service';
import { Entity } from '@app/app.model';
import { ProductBlend } from '../product-blends/product-blends.model';
import { AppService } from '@app/app.service';

import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';

import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { takeUntil } from 'rxjs/operators';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
    selector: 'facnm-product-blends',
    templateUrl: './product-blends.component.html',
    styleUrls: ['./product-blends.component.scss'],
    providers: [
        {
            provide: ProductBlendsInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new ProductBlendsService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]

})
export class ProductBlendsComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Product Blend';

    productBlend: Entity<ProductBlend>;
    productBlendsStore: ArrayStore;
    productsInBlendStore: ArrayStore;

    productList: Product[];

    @ViewChild("productBlendForm") productBlendForm: NgForm;
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;


    selectedProducts: number[];
    @ViewChild('products') private productsGrid: ProductsGridComponent;
    selectedProductsInBlend: any[];
    @ViewChild('productsInBlend') private productsInBlendGrid: DxDataGridComponent;
    @ViewChild('dropdownBlendProduct') private productBlendDropDown: DxDropDownBoxComponent;

    selectedBlendProduct: number;


    constructor(private productBlends$: ProductBlendsInterface, private product$: ProductService, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {


        productBlends$.ListRetrieved.subscribe(data => {

            if (data) {

                this.productBlendsStore = new ArrayStore({
                    data: data,
                    key: ['BlendId']
                });

                if (data.length == 0) {
                    this.dataGrid.noDataText = 'No Product Blends';
                }
                else {
                    this.dataGrid.noDataText = '';
                }
            }
        });


        product$.ListRetrieved.subscribe(data => {
            if (data) {
                this.productList = data;
            }
        });
        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });

    }



    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(

            this.product$.getList()

        ).subscribe(
            multipleData => {

                this.product$.notifyRetrieved(multipleData[0]);

                this.productBlends$.getList().subscribe(productBlendsData => {
                    this.productBlends$.notifyRetrieved(productBlendsData); this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList))
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }


    onSelectionChanged($clikedRow) {

        if (!this.productBlend)
            try {
                let keySaved = JSON.parse(localStorage.getItem('product-blends.dataGrid')).selectedRowKeys[0];

                if (keySaved) {
                    this.selectProductBlend(keySaved);
                }
            }
            catch{ }

        else {

            this.refreshProductsInBlendGrid();
        }
    }


    onRowClick($clikedRow) {
        this.app$.setScreenTitle(this.entityName);

        if (!this.productBlend)
            this.productBlend = new Entity();

        if (JSON.stringify(this.productBlend.key) === JSON.stringify($clikedRow.key)) {
            this.deselectProductBlend();
        }
        else {
            this.selectProductBlend($clikedRow.key);
        }
    }

    onProductIdChange($event) {

        this.productBlendDropDown.instance.close();
    }


    getSelectedProductName(product) {
        if (product)
            return product.ProductNumber + ' - ' + product.ProductName;
    }

    addProducts() {

        this.productBlend.value.BlendProductsId = uniq(this.productBlend.value.BlendProductsId.concat(this.selectedProducts));
        this.selectedProducts = [];

        this.refreshProductsInBlendGrid();
    }

    removeProducts() {

        this.productBlend.value.BlendProductsId = this.productBlend.value.BlendProductsId.filter(id => !this.selectedProductsInBlend.map(s => s.Id).includes(id));

        this.refreshProductsInBlendGrid();

    }


    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    private refreshProductsInBlendGrid() {

        this.productsInBlendStore = new ArrayStore({
            data: this.productList.filter(p => this.productBlend.value.BlendProductsId.includes(p.Id))
        });

        if (this.productsInBlendGrid) {
            if (this.productBlend.value.BlendProductsId.length == 0)
                this.productsInBlendGrid.noDataText = 'No Products in Blend';
            else this.productsInBlendGrid.noDataText = '';
        }

    }


    private addNewEntity() {

        this.app$.setScreenTitle('New ' + this.entityName);

        this.productBlend = new Entity();
        this.productBlend.value.BlendProductsId = [];
        this.productBlend.created = true;
        this.changeDetectorRef.detectChanges();

        this.refreshProductsInBlendGrid();
    }


    private reset() {

        this.productBlend.restore();

        if (this.productBlend.created)
            this.productBlend.value.BlendProductsId = [];

        this.refreshProductsInBlendGrid();
    }


    private create() {

        this.handleFormSubmit();

        this.productBlends$.create(this.productBlend).subscribe(

            (okCreate) => {
                this.app$.setScreenTitle(this.entityName);
                this.actions$.notifyCreated();

                this.deselectProductBlend();

                this.productBlends$.getList().subscribe(listWithCreated => {
                    this.productBlends$.notifyRetrieved(listWithCreated);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }



    private save() {


        this.handleFormSubmit();

        this.productBlends$.save(this.productBlend).subscribe(

            (okSave) => {

                this.actions$.notifySaved();

                this.deselectProductBlend();

                this.productBlends$.getList().subscribe(listWithSaved => {

                    this.productBlends$.notifyRetrieved(listWithSaved);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }

        );
    }


    private deleteConfirm() {

        this.productBlends$.delete(this.productBlend.key).subscribe(

            (okDelete) => {

                this.actions$.notifyDeleted();

                this.deselectProductBlend();

                this.productBlends$.getList().subscribe(listWithSaved => {

                    this.productBlends$.notifyRetrieved(listWithSaved);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }



    private cancel() {

        this.app$.setScreenTitle(this.entityName);

        this.deselectProductBlend();
    }


    private selectProductBlend(key: any) {

        this.actions$.notifyEntityBusy();

        this.productBlend = new Entity();
        this.productBlend.key = key;

        this.productBlendsStore.byKey(key)
            .then(pt => {

                this.productBlend.value = cloneDeep(pt);

                this.refreshProductsInBlendGrid()
                this.changeDetectorRef.detectChanges()
                
                this.app$.notifyScreenReady()
            });
  }


    private deselectProductBlend() {

        this.dataGrid.instance.deselectRows(this.productBlend.key);
        this.productBlend.key = undefined;
    }


    private handleFormSubmit() {
        this.productBlendForm.form.markAsPristine();
        this.productBlendForm.form.markAsUntouched();
        this.productBlendForm.form.updateValueAndValidity();
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onInitialized(e) {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }
}
