<div id="entity-actions"
     class="container-fluid h-100 align-items-end">

  <div *ngIf="modelEntity && modelEntity.key"
       class="row text-center">

    <div class="col"
         *ngIf="!modelEntity.created && !hideDelete">
      <button [disabled]="disableDelete" (click)="delete()"
              id="action-delete">
        <img src="assets/Content/Images/Icons/delete.png"
             class="edit-button-image" [ngClass]="{'disabled': disableDelete}" />
        <span class="edit-button d-none d-xl-inline-block" [ngClass]="{'disabled': disableDelete}" >Delete</span>
      </button>
      <div class="d-block d-xl-none">
        <dx-tooltip target="#action-delete"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom">
          Delete
        </dx-tooltip>
      </div>
    </div>

    <div class="col">
      <button (click)="reset()"
              id="action-reset">
        <img src="assets/Content/Images/Icons/reset.png"
             class="edit-button-image"
             [ngClass]="{'edit-button-image-yellow': modelEntity.created === true}" />
        <span class="edit-button d-none d-xl-inline-block"
              [ngClass]="{'edit-button-yellow': modelEntity.created === true}">Reset</span>
      </button>
      <div class="d-block d-xl-none">
        <dx-tooltip target="#action-reset"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom">
          Reset
        </dx-tooltip>
      </div>
    </div>

    <div class="col">

      <button *ngIf="modelEntity.created"
              (click)="create()"
              id="action-save">
        <img src="assets/Content/Images/Icons/save.png"
             class="edit-button-image edit-button-image-yellow" />
        <span class="edit-button edit-button-yellow d-none d-xl-inline-block">Save</span>
      </button>

      <button *ngIf="!modelEntity.created"
              (click)="save()"
              id="action-save"
              [disabled]="!modelEntity.modified">
        <img src="assets/Content/Images/Icons/save.png"
             class="edit-button-image"
             [ngClass]="{'disabled': modelEntity.modified == false}" />
        <span class="edit-button d-none d-xl-inline-block"
              [ngClass]="{'disabled': modelEntity.modified == false}">Save</span>
      </button>

      <div class="d-block d-xl-none">
        <dx-tooltip target="#action-save"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom">
          Save
        </dx-tooltip>
      </div>
    </div>

    <div class="col">
      <button (click)="cancel()"
              id="action-cancel">
        <img src="assets/Content/Images/Icons/cancel.png"
             class="edit-button-image"
             [ngClass]="{'edit-button-image-yellow': modelEntity.created === true}" />
        <span class="edit-button d-none d-xl-inline-block"
              [ngClass]="{'edit-button-yellow': modelEntity.created === true}">Cancel</span>
      </button>
      <div class="d-block d-xl-none">
        <dx-tooltip target="#action-cancel"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom">
          Cancel
        </dx-tooltip>
      </div>
    </div>

  </div>

  <div *ngIf="!hideAdd && (!modelEntity || modelEntity && !modelEntity.key)"
       id="entity-action-add-new"
       class="row text-center">
    <div class="col">
      <button id="action-new" (click)="addNewEntity()">
        <img src="assets/Content/Images/Icons/add.png"
             class="add-button-image">
        <span class="add-button d-none d-lg-inline-block">Add New {{entityName}}</span>
      </button>
    </div>
  </div>

</div>