import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';
import { DxDataGridModule, DxTooltipModule, DxToastModule, DxLoadPanelModule, DxPopupModule, DxTagBoxModule, DxScrollViewModule, DxTemplateModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxButtonModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';

import { CustomerComponent } from './customer/customer.component';
import { ProductPopupComponent } from '@products/product-popup/product-popup.component';
import { CustomerDataAreaComponent } from './customer-data-area/customer-data-area.component';
import { StrappingChartComponent } from './strapping-chart/strapping-chart.component';

@NgModule({
  declarations: [
    CustomerComponent,
    ProductPopupComponent,
    CustomerDataAreaComponent,
    StrappingChartComponent

  ],
  providers: [NgxSmartModalService],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    DxDataGridModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxToastModule,
    DxPopupModule,
    DxTagBoxModule,
    
    DxScrollViewModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxButtonModule,
    NgxSmartModalModule.forRoot()
  ]
})
export class CustomersModule { }
