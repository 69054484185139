import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";


export class Assessment {

    FormId: number;
    FormName: string;

    FormSections: FormSection[] = [];
}

export class FormSection {

    SectionName: string;
    SequenceNo: number;
    Condition: string;

    SectionFields: SectionField[] = [];
    
    formID: number;
    id: number;

    deleted: boolean;
}

export class SectionField {

    FieldName: string;
    FieldType: number;
    SequenceNo: number;
    VariableName: string;
    Condition: string;
    Unit: string;

    FieldOptions: FieldOption[] = [];
    
    formID: number;
    id: number;
    sectionID: number;

    deleted: boolean;
}

export class FieldOption {

    OptionName: string;

    fieldID: number;
    formID: number;
    id: number;
    sectionID: number;

    deleted: boolean;
}

@Injectable()
export abstract class AssessmentInterface implements EntityInterface<Assessment> {


    create: (data: Entity<Assessment>) => Observable<any>;

    save: (data: Entity<Assessment>) => Observable<any>;

    delete: (data: any) => Observable<any>;

    getList: () => Observable<Assessment[]>;
    getEntity: (key: any) => Observable<Assessment>;

    ListRetrieved: Observable<Assessment[]>;

    notifyRetrieved: (data: Assessment[]) => void;

    getFieldTypeList: () => Observable<Enum[]>;

}