<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="data-area" class="row h-100">

        <div class="col-md-3 col-sm-3">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="gridDataSource" [wordWrapEnabled]="true" noDataText=""
                    (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    (onContentReady)="onContentReady($event)" [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="Data Area No" dataField="Number"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Data Area Name" dataField="Name"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>

        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData  &&  entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight" style="overflow-x: hidden;">
                    <div class="dataArea-Container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Data Area Number</label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-1">
                                        <input [(ngModel)]="entityData.value.Id" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="DataAreaNumber">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Data Area Name</label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-1">
                                        <input [(ngModel)]="entityData.value.Name"
                                            [ngClass]="{'modelstate-invalid': hasError('Name')}" type="text"
                                            class="form-custom-control control-look-input" name="Name" required
                                            maxlength="20">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('Name')">
                                            <small class="error" *ngIf="checkError('Name','required')">
                                                Data Area Name is required
                                            </small>
                                            <small class="error" *ngIf="checkError('Name','maxlength')">
                                                Data Area Name cannot be longer than 20 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div class="collapsible-header-container top-buffer" (click)="CSCollapse = !CSCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(CSCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Customer Settings
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="CSCollapse" class="childs" [ngClass]="{'showChild': !CSCollapse}">

                                <div class="dataGridDiv mt-3" style="min-height:400px">
                                    <div class="customer_settings">Customer Settings</div>
                                    <dx-data-grid #dataGridCustomerSettings class="no-data-min-height-sp"
                                        (onContentReady)="onContentReadyCustomerSettings($event)"
                                        [dataSource]="entityData.value.CustomerTypePostcodes" noDataText=""
                                        [allowColumnResizing]="true" [columnAutoWidth]="true">
                                        <dxi-column width="30%" dataField="CustomerTypeId" caption="Customer Type"
                                            [lookup]="{ dataSource: customerTypes, valueExpr: 'Id', displayExpr: 'Name'}"></dxi-column>
                                        <dxi-column width="30%" alignment='center' caption="Country"
                                            dataField="CountryId"
                                            [lookup]="{ dataSource: countries, valueExpr: 'Id', displayExpr: 'Name'}"></dxi-column>
                                        <dxi-column width="15%" alignment='center' caption="ZIP Code From"
                                            dataField="PostcodeFrom" dataType="number"></dxi-column>
                                        <dxi-column width="15%" alignment='center' caption="ZIP Code To"
                                            dataField="PostcodeTo" dataType="number"></dxi-column>
                                        <dxo-selection mode="single"> </dxo-selection>
                                        <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                        <dxo-scrolling mode="standard" showScrollbar="onHover">
                                        </dxo-scrolling>
                                        <dxo-sorting mode="single"> </dxo-sorting>
                                        <dxo-paging [pageSize]="10"> </dxo-paging>
                                        <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                                            mode="row" [texts]="{ addRow: '' }">
                                        </dxo-editing>
                                    </dx-data-grid>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="entityData"></facnm-action-buttons>
        </div>

    </div>

</div>

<facnm-delete-dialog [entityName]="entityName" [showDataAreaDeleteMsg]="entityDataDeleteConfirm" [height]="210"
    [width]="405" [message]="entityDataDeleteConfirmMsg"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>