<div class="col-lg-6" >
    <div style="height: 470px;" [ngClass]="{ editGridDiv: !isAddForm, newGridDiv: isAddForm }" >
        <dx-data-grid (onContentReady)="onContentReadyAssigned($event)" #dataGridAssignedProducts
        noDataText="" [dataSource]="AssignedProducts" [allowColumnResizing]="false"
        [columnAutoWidth]="false" [(selectedRowKeys)]="assignedProductSelected" [columns]="productColumnConfig">
            <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode"
                mode="multiple"></dxo-selection>
            <dxo-filter-row [visible]="true"> </dxo-filter-row>
            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
            <dxo-sorting mode="single"> </dxo-sorting>
            <dxo-paging [pageSize]="10"> </dxo-paging>
        </dx-data-grid>
    </div>
</div>

<div class="col-lg-1 pl-0">
    <div class="left-arrow text-center">
        <a *ngIf="!unassignedProductSelected.length">
            <img class="arrow-move" src="assets/Content/Images/Icons/arrow_left.svg">
        </a>
        <div *ngIf="unassignedProductSelected.length">
            <a (click)="assignProducts()">
                <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_left_active.svg">
            </a>
        </div>
    </div>
    <div class="right-arrow text-center">
        <a *ngIf="!assignedProductSelected.length">
            <img class="arrow-move" src="assets/Content/Images/Icons/arrow_right.svg">
        </a>
        <a (click)="unassignProducts()" *ngIf="assignedProductSelected.length">
            <img class="arrow-move active" src="assets/Content/Images/Icons/arrow_right_active.svg">
        </a>
    </div>

</div>
<div class="col-lg-5 pl-0">
    <div class="gridDiv" style="height: 470px;">
        <dx-data-grid #dataGridUnassignedProducts (onContentReady)="onContentReadyUnAssigned($event)" noDataText=""
        [dataSource]="UnAssignedProducts" [allowColumnResizing]="false"
        [columnAutoWidth]="false" [(selectedRowKeys)]="unassignedProductSelected"
        [columns]="productColumnConfig">
            <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode"
                mode="multiple"></dxo-selection>
            <dxo-filter-row [visible]="true"> </dxo-filter-row>
            <dxo-scrolling mode="standard" showScrollbar="onHover"> </dxo-scrolling>
            <dxo-sorting mode="single"> </dxo-sorting>
            <dxo-paging [pageSize]="10"> </dxo-paging>
        </dx-data-grid>
    </div>
</div>
