import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class Factor {
  Id: number;
  Code: string;
  Value: number;
  QuantityUnit: number;
  Temperature: number;
  Items: any;

}

@Injectable()
export abstract class FactorInterface implements EntityInterface<Factor> {
  create: (data: Entity<Factor>) => Observable<any>;
  save: (data: Entity<Factor>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getEntity: (key: any) => Observable<Factor>;
  ListRetrieved: Observable<Factor[]>;
  notifyRetrieved: (data: Factor[]) => void;
  getList: (lookup: any) => Observable<Factor[]>;
  getNewEntity: () => Observable<Factor>;
  getQuantityUnitList: () => Observable<Enum[]>;
}