<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col-md-3 pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReadyGroup($event)" [wordWrapEnabled]="true" #dataGrid
                    [dataSource]="gridData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onRowClick)="onRowClick($event)" [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' width="150px" caption="No" dataField="Number"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='left' caption="Time Log" dataField="TimeLogName"
                        dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Number" class="col-form-label">Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.Number" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="Number">
                                        <div class="control-look"></div>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 control-label">
                                        <label for="Title" class="col-form-label">Time Log</label>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-6 pl-1">
                                        <input [(ngModel)]="entityData.value.TimeLogName"
                                            [ngClass]="{'modelstate-invalid': hasError('TimeLogName')}" type="text"
                                            class="form-custom-control control-look-input" name="TimeLogName" required
                                            maxlength="50">
                                        <div class="control-look"></div>
                                        <div class="error-message" *ngIf="hasError('TimeLogName')">
                                            <small class="error" *ngIf="checkError('TimeLogName','required')">
                                                Time Log is required
                                            </small>
                                            <small class="error" *ngIf="checkError('TimeLogName','maxlength')">
                                                Time Log cannot be longer than 50 characters
                                            </small>
                                        </div>
                                    </div>
                                </div>


                                <div class="row form-group">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                        Time Log Type
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-1">
                                        <pdi-dropdown [ngClass]="{'modelstate-invalid': hasError('TimeLogType')}"
                                            [full]="true" name="TimeLogType"
                                            [(ngModel)]="entityData.value.TimeLogType"
                                            ng-class="{submitted:timeLogForm.submitted}" required
                                            [isDisabled]="!isAddForm">
                                            <pdi-dropdown-option *ngFor="let timeLogType of timeLogTypeList"
                                                [key]="timeLogType.Id">
                                                {{timeLogType.Name}}
                                            </pdi-dropdown-option>
                                        </pdi-dropdown>
                                        <div class="control-look"></div>

                                        <div class="error-message" *ngIf="hasError('TimeLogType')">
                                            <small class="error" *ngIf="checkError('TimeLogType','required')">
                                                Please select a Time Log Type
                                            </small>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group form-group-inline">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                        <input name="WorkType" type="checkbox" class="checkbox-inline"
                                            [(ngModel)]="entityData.value.WorkType" intValue="true" trueFalseValue
                                            trueValue="1" falseValue="2" />
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label">
                                        <label for="Title" class="col-form-label">Paid</label>
                                    </div>
                                </div>

                            </div>
                            <div style="clear:both;"></div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [disableDelete]="!entityData?.value.IsEditable"
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>