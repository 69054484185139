<footer id="footer" class="footerBox">
    <div class="container row">
        <div class="col-xs-6 col-sm-6 col-md-6 text-left">
            &#169; {{ currentYear }} PDI Technologies, Inc. All Rights Reserved
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-right">
            {{ ApiFileVersion }} - Web v. {{ BuildVersionFull }}
            <img *ngIf="DbVersion < RequiredDbVersion" src="assets/Content/Images/Icons/database_red.png"
                title="DB version is {{DbVersion}}. Required DB version is {{RequiredDbVersion}}." />
            <img *ngIf="DbVersion > RequiredDbVersion" src="assets/Content/Images/Icons/database_yellow.png"
                title="DB version is {{DbVersion}}. Required DB version is {{RequiredDbVersion}}." />
            <img *ngIf="DbVersion== 0 " src="assets/Content/Images/Icons/database_bw.png"
                title="Unable to check DB version until logged in." />
        </div>

    </div>
</footer>