<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">
  <div id="entity-management" class="row h-100">
    <div class="col-md-6">
      <div class="row top-buffer">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Company</div>
        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
          <pdi-dropdown *ngIf="companyList" [full]="true" [(ngModel)]="selectedCompany"
            (valueSelected)="getList()">
            <pdi-dropdown-option *ngFor="let company of companyList" [key]="company.Id">
              {{ company.Name }}
            </pdi-dropdown-option>
          </pdi-dropdown>
          <div class="control-look"></div>
        </div>
      </div>

      <div id="entity-list" style="height: calc(100vh - 180px)">
        <dx-data-grid
          (onContentReady)="onContentReadyGroup($event)"
          #dataGrid
          [dataSource]="gridData"
          noDataText=""
          (onInitialized)="onInitialized($event)"
          (onRowClick)="onRowClick($event)"
          [allowColumnResizing]="false"
          [columnAutoWidth]="false"
        >
          <dxo-load-panel>
            <div *dxTemplate="let data of 'content'">
              <pdi-loader size="medium"></pdi-loader>
            </div>
          </dxo-load-panel>
          <dxi-column
            alignment="center"
            width="150px"
            caption="ID"
            dataField="Id"
            dataType="number"
          ></dxi-column>
          <dxi-column
            alignment="left"
            caption="Payment Term"
            dataField="Name"
            dataType="string"
          ></dxi-column>
          <dxo-selection mode="single"> </dxo-selection>
          <dxo-filter-row [visible]="true"> </dxo-filter-row>
          <dxo-scrolling mode="standard"> </dxo-scrolling>
          <dxo-sorting mode="single"> </dxo-sorting>
          <dxo-pager
            [allowedPageSizes]="[10, 20, 30]"
            [showPageSizeSelector]="true"
            [visible]="true"
          ></dxo-pager>
          <dxo-paging [pageSize]="20"> </dxo-paging>
        </dx-data-grid>
      </div>
    </div>
    <div id="entity-detail" class="col-md-6 pr-0">
      <div *ngIf="entityData && entityData.key">
        <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
          <div class="container">
            <div id="entity-errors" class="row">
              <div class="col">
                <facnm-errors></facnm-errors>
              </div>
            </div>
            <pdi-page-notification  *ngIf="hasErrorList()" type="alert" text="<strong>Fix the below validations!</strong>">
              <ul>
                <li *ngFor="let errorL of errorList">{{ errorL }}</li>
              </ul>
            </pdi-page-notification>
            <div class="col-md-7">
              <div class="row form-group">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                  Payment Term ID
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                  <input
                    type="text"
                    name="ID"
                    class="form-custom-control control-look-input"
                    [(ngModel)]="entityData.value.Id"
                    required
                    [ngClass]="{ 'modelstate-invalid': hasError('Id') }"
                    placeholder="Payment Term ID"
                    [disabled]="isAddForm"
                  />
                  <div class="control-look"></div>
                  <div class="error-message" *ngIf="hasError('Id')">
                    <small class="error" *ngIf="checkError('Id', 'required')">
                      Payment Term ID is required
                    </small>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="Cash"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.Cash"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label">Cash Sale</label>
                </div>
              </div>

              <div class="row form-group" style="margin-left: 30px">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="Cheque"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.Cheque"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Cheque Payment</label
                  >
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="Cheque"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.Cheque"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Cheque Payment</label
                  >
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="CashCard"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.CashCard"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Cash card payment</label
                  >
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="CreditCard"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.CreditCard"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Credit card payment</label
                  >
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="Invoice"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.Invoice"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label">Invoice</label>
                </div>
              </div>

              <div class="row form-group" style="margin-left: 30px">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="DirectDebit"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.DirectDebit"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Payment by bank transfer</label
                  >
                </div>
              </div>

              <div class="row form-group" style="margin-left: 30px">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="Prepayment"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.Prepayment"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label">Prepayment</label>
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="DeliveryNote"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.DeliveryNote"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Delivery Note</label
                  >
                </div>
              </div>

              <div class="row form-group" style="margin-left: 30px">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="DeliveryNoteWithPrices"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.DeliveryNoteWithPrices"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label">With price</label>
                </div>
              </div>

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="OmitAddress"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.OmitAddress"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label"
                    >Omit delivery address</label
                  >
                </div>
              </div>
              <hr />

              <div class="row form-group">
                <div
                  class="col-lg-3 col-md-3 col-sm-3 col-xs-3 checkbox-form-group"
                >
                  <input
                    name="OptionEnabled"
                    type="checkbox"
                    class="checkbox-inline"
                    [(ngModel)]="entityData.value.OptionEnabled"
                  />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 control-label">
                  <label for="Title" class="col-form-label">Use Options</label>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                  Select Options
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <dx-tag-box
                    name="Option"
                    [(ngModel)]="entityData.value.Option"
                    [items]="this.paymentTermsOptionList"
                    valueExpr="Id"
                    [value]="entityData.value.Option"
                    displayExpr="Name"
                    [hideSelectedItems]="true"
                    [searchEnabled]="true"
                    [disabled]="!entityData.value.OptionEnabled"
                  >
                  </dx-tag-box>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
        </form>
      </div>

      <facnm-action-buttons
        [entityName]="addEntityName"
        [disableDelete]="entityData?.value.Id < 0"
        [modelEntity]="entityData"
      ></facnm-action-buttons>
    </div>
  </div>
</div>

<facnm-delete-dialog [entityName]="addEntityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="addEntityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>
