import {ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { Modules, ModulesInterface } from './modules.model';
import { NgForm } from '@angular/forms';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { AppService } from '@app/app.service';
import { ModulesService } from './modules.service';
import { HttpClient } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'facnm-modules',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.scss'],
    providers: [
        {
            provide: ModulesInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new ModulesService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})

export class ModulesComponent implements OnInit, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    entityName = 'Modules';
    modules: Modules[];
    modulesOriginal: Modules[];
    errorsHeight: number = 0;

    constructor(private modules$: ModulesInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {
        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            errors ? this.errorsHeight = 50 : this.errorsHeight = 0;
            this.resizeScreenContent();
        });
    }

    ngOnInit() {
        this.app$.setScreenTitle(this.entityName);
        this.app$.loginUserInfo.subscribe((res: any) => {
            if (!res.IsSysAdmin) {
                this.actions$.notifyEntityCustomAction({ type: 'warning', title: 'Error!', message: 'You do not have the access for this page.' });
                this.app$.notifyScreenReady();
                this.app$.redirectTo('home');
            }
        });

        forkJoin(
            this.modules$.getList()
        ).subscribe(multipleDataMapped => {
            this.modulesOriginal = multipleDataMapped[0];
            this.modules = cloneDeep(this.modulesOriginal);
            this.app$.notifyScreenReady();
            this.resizeScreenContent();
        }, (errorNetwork) => {
            this.errors$.handleServerErrors(errorNetwork);
        });
    }

    onChangeModule(module: Modules) {
        module.invalid = false;
        module.modified = this.modulesOriginal.find(c => c.ModuleID == module.ModuleID).Enabled != module.Enabled;
    }

    saveModule(module: Modules) {
        this.app$.notifyScreenBusy();
        this.errors$.clearErrors();
        this.modules$.save(module).subscribe(() => {
            this.modulesOriginal.find(c => c.ModuleID == module.ModuleID).Enabled = module.Enabled;
            module.modified = false;
            this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Saved Module ' + ' - ' + module.ModuleName });
            this.app$.notifyScreenReady();
        }, (errorNetwork) => {
            module.invalid = true;
            this.errors$.handleServerErrors(errorNetwork);
        });
    }

    resetModule(module: Modules) {
        module.Enabled = this.modulesOriginal.find(c => c.ModuleID == module.ModuleID).Enabled;
        module.modified = false;
        module.invalid = false;
        this.errors$.clearErrors();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 100 - this.errorsHeight + 'px';
        this.changeDetectorRef.detectChanges();
    }
}
