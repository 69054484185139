import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class BankAccount {
  Id: number;
  BankName: string;
  BankNumber: number;
  AccountNumber: number;
  Owner:string;
  IBAN : string;
  BIC : string 
  IsEditable: boolean;
}

@Injectable()
export abstract class BankAccountInterface implements EntityInterface<BankAccount> {
  create: (data: Entity<BankAccount>) => Observable<any>;
  save: (data: Entity<BankAccount>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<BankAccount[]>;
  getEntity: (key: any) => Observable<BankAccount>;
  ListRetrieved: Observable<BankAccount[]>;
  notifyRetrieved: (data: BankAccount[]) => void;

  getNewEntity: () => Observable<BankAccount>;
}