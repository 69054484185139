import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class Company {
    Id: number;
    Name: string;
    DefaultCustomerName: string;
    DefaultCustomer: number;
    ShortName: string;
    StreetAddress: string;
    Suburb: string;
    Telephone: string;
    IsPrintCompany: any;
}

@Injectable()
export abstract class CompanyInterface implements EntityInterface<Company> {

    create: (data: Entity<Company>) => Observable<any>;
    save: (data: Entity<Company>) => Observable<any>;
    delete: (data: any) => Observable<any>;
    getList: () => Observable<Company[]>;
    getEntity: (key: any) => Observable<Company>;
    ListRetrieved: Observable<Company[]>;
    notifyRetrieved: (data: Company[]) => void;

    getNonPrintCompanyList: (data: any) => Observable<Enum[]>;
    getMsgReceiverGroups: () => Observable<any[]>;
    getAutoEmailOptionsList: () => Observable<any[]>;
    getNewEntity: () => Observable<Company>;

}