import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { Product, ProductInterface } from './product.model';


import cloneDeep from 'lodash/cloneDeep';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService implements ProductInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    private listRetrieved: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    notifyRetrieved(data: Product[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<Product>) => Observable<any>;
    getEntity(id: any): Observable<Product> {
        return this._http.get<Product>(e.endpoint.products.product + '/' + id)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    create(data: Entity<Product>): Observable<any> {
        return this._http.put<Product>(
            e.endpoint.products.product,
            data.value)
            .pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(arg): Observable<Product[]> {
        return this._http.put<Product[]>(e.endpoint.products.product + '/filter/', arg)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(id: any): Observable<any> {
        return this._http.delete(e.endpoint.products.product + '/' + id, {
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductCategoryList() {
        return this._http.get<Enum[]>(e.endpoint.products.productCategory)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getPriceChangeableDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.priceChangeable)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getSalesTaxDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.salesTax)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getQuantityUnitDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.quantityUnit)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductTypeDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.productType)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getDangerousGoodsDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.dangerousGoods)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.productDropdown)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    saveProductType(data): Observable<Product> {
        return this._http.put<any>(e.endpoint.products.productTypes, data)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getNewProduct(): Observable<Product> {
        return this._http.get<Product>(e.endpoint.products.newproduct)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductCategoryShapes(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.productShapes)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    saveProductCategory(data): Observable<Product> {
        return this._http.put<any>(e.endpoint.products.productCategory, data)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    isProductCategoryDeletable(id): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.productCategoryDeletable + '/' + id)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteProductCategory(id: any): Observable<any> {
        return this._http.delete(e.endpoint.products.productCategory + '/' + id, {
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }
}