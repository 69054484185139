<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="entity-management" class="row h-100">
        <div class="col pr-0">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="gridData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onRowClick)="onRowClick($event)" (onContentReady)="onContentReady($event)"[allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='left' caption="Name" dataField="name" dataType="string" sortIndex="0"
                        sortOrder="asc"></dxi-column>
                    <dxi-column alignment='left' caption="Description" dataField="name2" dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Address" dataField="StreetAddress"
                        dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Suburb" dataField="Suburb" dataType="string"></dxi-column>
                    <dxi-column alignment='left' caption="Post Code" dataField="ZipCode" dataType="string"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling mode="standard"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class=" container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">

                            <div class="collapsible-header-container top-buffer" (click)="GICollapse = !GICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(GICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            General Information
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="GICollapse" class="childs" [ngClass]="{'showChild': !GICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="name" name="name"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.name"
                                                [ngClass]="{'modelstate-invalid': hasError('name')}" required
                                                maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('name')">
                                                <small class="error" *ngIf="checkError('name','required')">
                                                    Name is required
                                                </small>
                                                <small class="error" *ngIf="checkError('name','maxlength')">
                                                    Name cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Name 2</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="name2" name="name2"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.name2"
                                                [ngClass]="{'modelstate-invalid': hasError('name2')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('name2')">
                                                <small class="error" *ngIf="checkError('name2','maxlength')">
                                                    Name 2 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Name 3</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="name3" name="name3"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.name3"
                                                [ngClass]="{'modelstate-invalid': hasError('name3')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('name3')">
                                                <small class="error" *ngIf="checkError('name3','maxlength')">
                                                    Name 3 cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Short Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="ShortName" name="ShortName"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ShortName"
                                                [ngClass]="{'modelstate-invalid': hasError('ShortName')}"
                                                maxlength="15" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ShortName')">
                                                <small class="error" *ngIf="checkError('ShortName','maxlength')">
                                                    Short Name cannot be longer than 15 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <a pdiLink pdiLinkLabel="Default Customer" (click)="viewBillingCustomerModal()"></a>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="defaultCustomer"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.DefaultCustomerName"
                                                readonly="readonly" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Tax Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="taxnumber" name="taxnumber"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ustID"
                                                [ngClass]="{'modelstate-invalid': hasError('ustID')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('ustID')">
                                                <small class="error" *ngIf="checkError('ustID','maxlength')">
                                                    Tax Number cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Company Id</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="companyId" name="companyId"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Id" readonly="readonly" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Company Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="companyNumber" name="companyNumber"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.nr"
                                                [ngClass]="{'modelstate-invalid': hasError('nr')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('nr')">
                                                <small class="error" *ngIf="checkError('nr','maxlength')">
                                                    Company Number cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="!isAddForm">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 checkbox-form-group">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label"
                                            *ngIf="entityData.value.IsPrintCompany">
                                            Is Print Company
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 control-label"
                                            *ngIf="!entityData.value.IsPrintCompany">
                                            Is Not Print Company
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="ICollapse = !ICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(ICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Images
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="ICollapse" class="childs" [ngClass]="{'showChild': !ICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Small Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="number" max="9999999999" id="smallImageID" name="smallImageID"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.smallImageID" pattern="^[0-9]*$"
                                                (paste)="onPasteNumberOnly($event)" (keypress)="isNumberOnly($event)"
                                                [ngClass]="{'modelstate-invalid': hasError('smallImageID')}" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('smallImageID')">
                                                <small class="error"
                                                    *ngIf="checkError('smallImageID','pattern') || checkError('smallImageID','max')">
                                                    Small Image is not valid number
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Large Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="number" max="9999999999" id="largeImageID" name="largeImageID"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.largeImageID" pattern="^[0-9]*$"
                                                (paste)="onPasteNumberOnly($event)" (keypress)="isNumberOnly($event)"
                                                [ngClass]="{'modelstate-invalid': hasError('largeImageID')}" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('largeImageID')">
                                                <small class="error"
                                                    *ngIf="checkError('largeImageID','pattern') || checkError('largeImageID','max') ">
                                                    Large Image is not valid number
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="ADCollapse = !ADCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(ADCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Address Information
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="ADCollapse" class="childs" [ngClass]="{'showChild': !ADCollapse}">
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label class="col-form-label">Address</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                            <input type="text" name="StreetAddress"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.StreetAddress" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Suburb</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="Suburb"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Suburb" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Post Code</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="ZipCode"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ZipCode" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="CICollapse = !CICollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(CICollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Contact Information
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="CICollapse" class="childs" [ngClass]="{'showChild': !CICollapse}">
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Phone</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="Telephone"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.Telephone" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Fax</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="fax" name="fax"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.fax"
                                                [ngClass]="{'modelstate-invalid': hasError('fax')}" maxlength="25" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('fax')">
                                                <small class="error" *ngIf="checkError('fax','maxlength')">
                                                    Fax cannot be longer than 25 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Contact Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="ContactName"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.ContactName" />
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Email</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" id="email" name="email"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.email"
                                                [ngClass]="{'modelstate-invalid': hasError('email')}" maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('email')">
                                                <small class="error" *ngIf="checkError('email','maxlength')">
                                                    Email cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">Auto Email Options</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 ">
                                            <dx-tag-box class="text-left" name="autoEmailOption"
                                                [(ngModel)]="entityData.value.autoEmailOption"
                                                [items]="autoEmailOptionsLists" valueExpr="id"
                                                [value]="entityData.value.autoEmailOption" displayExpr="name"
                                                [hideSelectedItems]="true" [searchEnabled]="true">
                                            </dx-tag-box>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label class="col-form-label">Web Address</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                            <input type="text" id="homepage" name="homepage"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.homepage"
                                                [ngClass]="{'modelstate-invalid': hasError('homepage')}"
                                                maxlength="50" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('homepage')">
                                                <small class="error" *ngIf="checkError('homepage','maxlength')">
                                                    Web Address cannot be longer than 50 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>

                            <div class="collapsible-header-container top-buffer" (click)="CRCollapse = !CRCollapse">
                                <div class=" collapsible-header-left collapsible-header-{{collapseColor}}">
                                    <div class="collapsible-header-icon" [ngClass]="collapseClass(CRCollapse)"></div>
                                    <div class="text-center">
                                        <span
                                            class="collapsible-header-content collapsible-header-content-{{collapseColor}}">
                                            Cash Registration
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div id="CRCollapse" class="childs" [ngClass]="{'showChild': !CRCollapse}">
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label class="col-form-label">Service Url</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                            <input type="text" name="receiptSignatureServiceUrl"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.receiptSignatureServiceUrl"
                                                pattern="(^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$)"
                                                [ngClass]="{'modelstate-invalid': hasError('receiptSignatureServiceUrl')}"
                                                maxlength="100" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('receiptSignatureServiceUrl')">
                                                <small class="error"
                                                    *ngIf="checkError('receiptSignatureServiceUrl','maxlength')">
                                                    Service Url cannot be longer than 100 characters
                                                </small>
                                                <small class="error"
                                                    *ngIf="checkError('receiptSignatureServiceUrl','pattern')">
                                                    Service Url is not valid.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row form-group">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 control-label">
                                            <label class="col-form-label">Access Token</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                            <input type="text" name="receiptSignatureAccessToken"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.receiptSignatureAccessToken"
                                                [ngClass]="{'modelstate-invalid': hasError('receiptSignatureAccessToken')}"
                                                maxlength="100" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('receiptSignatureAccessToken')">
                                                <small class="error"
                                                    *ngIf="checkError('receiptSignatureAccessToken','maxlength')">
                                                    Access Token cannot be longer than 100 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label">
                                            <label class="col-form-label">CashBox Id</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <input type="text" name="receiptSignatureCashBoxId"
                                                class="form-custom-control control-look-input"
                                                [(ngModel)]="entityData.value.receiptSignatureCashBoxId"
                                                pattern="(^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$)"
                                                [ngClass]="{'modelstate-invalid': hasError('receiptSignatureCashBoxId')}" />
                                            <div class="control-look"></div>
                                            <div class="error-message" *ngIf="hasError('receiptSignatureCashBoxId')">
                                                <small class="error"
                                                    *ngIf="checkError('receiptSignatureCashBoxId','pattern')">
                                                    CashBox Id must be a valid GUID
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row form-group">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 control-label pr-0">
                                            <label class="col-form-label">Message Receiver Group</label>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <pdi-dropdown [full]="true" name="receiptSignatureMsgReceiverGroupID"
                                                [(ngModel)]="entityData.value.receiptSignatureMsgReceiverGroupID">
                                                <pdi-dropdown-option key=""></pdi-dropdown-option>
                                                <pdi-dropdown-option *ngFor="let entity of msgReceiverGroups" [key]="entity.Id">
                                                    {{entity.Id}} - {{entity.Name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>                                           
                                            <div class="control-look"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="clear:both;"></div>

                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityName" [modelEntity]="entityData"></facnm-action-buttons>
        </div>
    </div>
</div>

<dx-popup title="Confirm" [(visible)]="showPrintCompanyCheck" hideOnOutsideClick="true" [dragEnabled]="false"
    shadingColor="shadingColor" maxHeight="175" maxWidth="460">
    <div class="container-fluid">
        <div class="row">
            <div> Should the new company be print-only company?</div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button type="button" class="edit-button-green mr-4" (click)="printCompany(true)">
                    <img src="assets/Content/Images/Icons/save.png" class="edit-button-image mr-2" />
                    <span class="edit-button hidden-xs">Yes</span>
                </button>
                <button type="button" class="edit-button-green" (click)="printCompany(false)">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                    <span class="edit-button hidden-xs">No</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<facnm-billing-customer-popup *ngIf="showBillingCustomerModal" (popupCancel)="billingCustomerSelectionCancel()"
    (popupOk)="billingCustomerSelectionOk($event)">
</facnm-billing-customer-popup>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>