<dx-popup title="Delete" [(visible)]="visible" [animation]="animation" closeOnOutsideClick="true" [dragEnabled]="false"
    [shadingColor]="shadingColor" maxHeight="200" width="500" (onHidden)="cancel()">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col">
                {{message}}
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <button (click)="confirm()" id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/delete.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Delete</span>
                </button>
            </div>
            <div class="col-md-4">
                <button (click)="cancel()" id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</dx-popup>