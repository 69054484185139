<div #screen
     class="container-fluid h-100"
     (window:resize)="!editingTemplate && onResize($event)">


    <facnm-print-templates-edit *ngIf="editingTemplate"
                                [printTemplate]="editingTemplate"
                                [companyId]="currentCompanyId"
                                (onDone)="onDoneEditingTemplate()"></facnm-print-templates-edit>


    <div id="print-templates"
         [hidden]="editingTemplate"
         class="row h-100 flex-nowrap">
        <div class="col-7 col-entity-list">
            <div id="entity-list"
                 class="h-100"
                 (mouseout)="
                    overSyncFromShadow = [];
                    overRevertTemplate = [];
                    overDownloadShadow = [];
                    overUploadShadow = [];
                    overDownloadLive = [];
                    overEditTemplate = [];
                 ">

                <div class="d-flex">
                    <div class="p-2 pr-3 pl-4 font-weight-bolder">
                        Company:
                    </div>

                    <div class="w-100">
                        <pdi-dropdown [full] ="true" name="companies"
                                #companiesSelect
                                [(ngModel)]="currentCompanyId"
                                style="cursor:pointer"
                                class="w-100 h-100 border-0"
                                (valueSelected)="onCompanyChange($event)"
                                [options]="companiesOptions">
                        </pdi-dropdown>
                    </div>
                </div>

                <dx-data-grid #dataGrid
                              [dataSource]="printTemplatesStore"
                              noDataText=""
                              (onContentReady)="onContentReady($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              (onCellPrepared)="onCellPrepared($event)"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true">


                    <dxi-column dataField="Id"
                                [width]="45"></dxi-column>

                    <dxi-column dataField="Language"
                                [width]="65"></dxi-column>

                    <dxi-column dataField="TemplateName">
                    </dxi-column>


                    <dxi-column caption="Status"
                                alignment="center"
                                cellTemplate="cellStatus"
                                [width]="55"></dxi-column>
                    <div *dxTemplate="let cell of 'cellStatus'">
                        <div *ngIf="!cell.data.IsSyncedShadowLive && !(cell.data.AliasId > 0 && cell.data.AliasCompanyId > 0)">
                            <img width="25px"
                                 style="margin: 0 3px;"
                                 src="assets/Content/Images/Icons/sync_no.png" />
                        </div>
                        <div *ngIf="cell.data.AnyLivePrinterForms && cell.data.AnyShadowPrinterForms && cell.data.IsSyncedShadowLive && !(cell.data.AliasId > 0 && cell.data.AliasCompanyId > 0)">
                            <img width="25px"
                                 src="assets/Content/Images/Icons/sync_yes.png" />
                        </div>
                    </div>


                    <dxi-column caption="Live"
                                alignment="center"
                                cellTemplate="cellLive"
                                [width]="55"></dxi-column>
                    <div *dxTemplate="let cell of 'cellLive'">
                        <div *ngIf="cell.data.AnyLivePrinterForms && !(cell.data.AliasId > 0 && cell.data.AliasCompanyId > 0)">
                            <img (click)="clickDownloadLive(cell.data)"
                                 (mouseover)="overDownloadLive[cell.rowIndex] = true"
                                 (mouseout)="overDownloadLive = []"
                                 [ngStyle]="{opacity: !overDownloadLive[cell.rowIndex] ? '0.3': '1'}"
                                 width="25px"
                                 src="assets/Content/Images/Icons/file_download.png" />
                        </div>
                    </div>


                    <dxi-column caption="Publish"
                                alignment="center"
                                cellTemplate="cellPublish"
                                [width]="80"></dxi-column>
                    <div *dxTemplate="let cell of 'cellPublish'"
                         (mouseout)="overSyncFromShadow = []; overRevertTemplate = []">
                        <div *ngIf="!cell.data.IsSyncedShadowLive && !(cell.data.AliasId > 0 && cell.data.AliasCompanyId > 0)">
                            <div (mouseover)="overSyncFromShadow[cell.rowIndex] = true"
                                 (mouseout)="overSyncFromShadow = []"
                                 (click)="clickSyncFromShadow(cell.data)"
                                 class="d-inline-block">
                                <img *ngIf="!overSyncFromShadow[cell.rowIndex]"
                                     width="25px"
                                     src="assets/Content/Images/Icons/arrow_light_gray_left.png" />
                                <img id="action-publish-{{cell.data.Id + '-'+ cell.data.Language}}"
                                     *ngIf="overSyncFromShadow[cell.rowIndex]"
                                     width="25px"
                                     src="assets/Content/Images/Icons/arrow_yellow_left.png" />
                                <dx-tooltip target="#action-publish-{{cell.data.Id + '-'+ cell.data.Language}}"
                                            showEvent="dxhoverstart"
                                            hideEvent="dxhoverend"
                                            position="bottom">
                                    Publish to Live
                                </dx-tooltip>
                            </div>
                            <div class="d-inline-block">
                                <img id="action-revert-{{cell.data.Id + '-'+ cell.data.Language}}"
                                     (click)="clickSyncFromLive(cell.data)"
                                     width="23px"
                                     (mouseover)="overRevertTemplate[cell.rowIndex] = true"
                                     (mouseout)="overRevertTemplate = []"
                                     [ngStyle]="{opacity: !overRevertTemplate[cell.rowIndex] ? '0.2': '0.7'}"
                                     style="margin-left: 3px;"
                                     src="assets/Content/Images/Icons/undo.png" />
                                <dx-tooltip target="#action-revert-{{cell.data.Id + '-'+ cell.data.Language}}"
                                            showEvent="dxhoverstart"
                                            hideEvent="dxhoverend"
                                            position="bottom">
                                    Revert to previous Live version
                                </dx-tooltip>
                            </div>
                        </div>
                    </div>

                    <dxi-column caption="Shadow"
                                alignment="center"
                                cellTemplate="cellShadow"
                                [width]="80"></dxi-column>
                    <div *dxTemplate="let cell of 'cellShadow'">
                        <div *ngIf="!(cell.data.AliasId > 0 && cell.data.AliasCompanyId > 0)">

                            <img (click)="clickUploadShadow( uploadShadow)"
                                 width="25px"
                                 (mouseover)="overUploadShadow[cell.rowIndex] = true"
                                 (mouseout)="overUploadShadow = []"
                                 [ngStyle]="{opacity: !overUploadShadow[cell.rowIndex] ? '0.3': '1'}"
                                 src="assets/Content/Images/Icons/file_upload.png" />

                            <input id="file-{{cell.rowIndex}}"
                                   hidden
                                   type="file"
                                   #uploadShadow
                                   (click)="uploadShadow.value = null"
                                   (change)="changeUploadShadow($event, cell.data)" />
                            <div style="display: inline-block; width: 25px"
                                 *ngIf="!cell.data.AnyShadowPrinterForms"></div>
                            <img *ngIf="cell.data.AnyShadowPrinterForms"
                                 (mouseover)="overDownloadShadow[cell.rowIndex] = true"
                                 (mouseout)="overDownloadShadow = []"
                                 [ngStyle]="{opacity: !overDownloadShadow[cell.rowIndex] ? '0.3': '1'}"
                                 (click)="clickDownloadShadow(cell.data)"
                                 width="25px"
                                 style="margin-left: 3px;"
                                 src="assets/Content/Images/Icons/file_download.png" />
                        </div>
                    </div>

                    <dxi-column caption="Template"
                                alignment="center"
                                cellTemplate="cellEditTemplate"></dxi-column>
                    <div *dxTemplate="let cell of 'cellEditTemplate'">
                        <div *ngIf="cell.data.AnyShadowPrinterForms">
                            <img (click)="clickEditTemplate(cell.data)"
                                 (mouseover)="overEditTemplate[cell.rowIndex] = true"
                                 (mouseout)="overEditTemplate = []"
                                 [ngStyle]="{opacity: !overEditTemplate[cell.rowIndex] ? '0.3': '1'}"
                                 width="25px"
                                 src="assets/Content/Images/Icons/edit.png" />
                        </div>
                    </div>


                    <dxo-selection mode="single"> </dxo-selection>

                    <dxo-filter-row [visible]="true"> </dxo-filter-row>

                    <dxo-sorting mode="single"> </dxo-sorting>

                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>

                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="print-templates.dataGrid"></dxo-state-storing>


                </dx-data-grid>
            </div>
        </div>

        <div id="entity-detail"
             class="col col-entity-detail">
            <div *ngIf="printTemplate && printTemplate.key">

                <form id="entity-form"
                      #printTemplatesForm="ngForm"
                      [style.height]="formHeight">
                    <div class="container">


                        <div id="entity-errors"
                             class="row">
                            <div class="col">

                                <facnm-errors></facnm-errors>

                            </div>
                        </div>


                        <div class="row">


                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Id"
                                                   class="col-form-label">Id</label>
                                        </div>
                                        <div class="col-8">
                                            <input [ngModel]="printTemplate.value.Id"
                                                   type="text"
                                                   class="form-custom-control control-look-input form-control-disabled"
                                                   name="Id"
                                                   #Id="ngModel"
                                                   [disabled]="true">

                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                        <div class="row">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="TemplateName"
                                                   class="col-form-label">Template Name</label>
                                        </div>
                                        <div class="col-8">
                                            <input [ngModel]="printTemplate.value.TemplateName"
                                                   type="text"
                                                   class="form-custom-control control-look-input form-control-disabled"
                                                   name="TemplateName"
                                                   #TemplateName="ngModel"
                                                   [disabled]="true">

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div class="row">


                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Languages"
                                                   class="col-form-label">Languages <i *ngIf="isInvalid('AllLanguages')"
                                                   class="fa fa-exclamation-circle modelstate-invalid"></i></label>
                                        </div>
                                        <div class="col-8">

                                            <dx-tag-box [disabled]="(printTemplate.value.AliasId > 0 && printTemplate.value.AliasCompanyId > 0)"
                                                        [items]="allTemplatesLanguages"
                                                        [value]="(printTemplate.value.AliasId > 0 && printTemplate.value.AliasCompanyId > 0)? [] :printTemplate.value.AllLanguages"
                                                        [acceptCustomValue]="true"
                                                        (onCustomItemCreating)="onCustomLanguageCreating($event)"
                                                        (onValueChanged)="onTemplatesLanguagesChanged($event)"
                                                        [showSelectionControls]="true"
                                                        placeholder="Add a language and hit enter or choose from existing ones"
                                                        hint="Add a language and hit enter or choose from existing ones">
                                            </dx-tag-box>

                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                        <div class="row">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="TemplateName"
                                                   class="col-form-label">Use same template as</label>
                                        </div>
                                        <div class="col-8">
                                            <pdi-dropdown [full]="true" name="Alias"
                                                    [(ngModel)]="aliasTemplate"
                                                    (valueSelected)="onChangeAlias($event)" 
                                                    [options]="allTemplatesAliases" >
                                            </pdi-dropdown>
                                            <div class="control-look"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div class="row mt-3">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="InUse"
                                                   class="col-form-label">In Use</label>
                                        </div>
                                        <div class="col-8">

                                            <dx-switch [value]="printTemplate.value.InUse"
                                                       [elementAttr]="{ class: printTemplate.value.InUse? 'on': '' }"
                                                       (onValueChanged)="onInUseChanged($event)"></dx-switch>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="printTemplate"
                                  [hideAdd]="true"
                                  [hideDelete]="true"></facnm-action-buttons>

        </div>

    </div>

</div>



<dx-popup title="Confirm Save"
          [(visible)]="deletingLanguagesVisible"
          (onShowing)="onConfirmSaveShowing($event)"
          [animation]="animation"
          hideOnOutsideClick="true"
          [dragEnabled]="false"
          shadingColor="shadingColor"
          maxHeight="250"
          maxWidth="320">
    <div>
        <div>
            <div>
                The printer forms associated with the removed languages will be deleted.
                Are you sure you want to save?
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="saveConfirm()"
                        id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Save</span>
                </button>
            </div>
            <div class="col">
                <button (click)="saveCancel()"
                        id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>


<dx-popup title="{{ syncingIsLive ? 'Revert' : 'Publish' }}"
          [(visible)]="syncingVisible"
          [animation]="animation"
          hideOnOutsideClick="true"
          [dragEnabled]="false"
          shadingColor="shadingColor"
          maxHeight="200"
          maxWidth="320"
          >
    <div>
        <div>
            <div
                 *ngIf="syncingIsLive">
                The previous Live version will be restored.
            </div>
            <div
                 *ngIf="!syncingIsLive">
                The Shadow version will be published to Live.
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="syncConfirm(syncingTemplate, syncingIsLive)"
                        id="action-confirm-sync">
                    <img src="assets/Content/Images/Icons/save.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">{{ syncingIsLive ? 'Revert' : 'Publish' }}</span>
                </button>
            </div>
            <div class="col">
                <button (click)="syncCancel()"
                        id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>