import { ChangeDetectorRef,Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, DoCheck } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormControl, NgForm, Validators } from '@angular/forms';

import { forkJoin, Subject } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';

import { Enum, Entity } from '@app/app.model';

import { AppService } from '@app/app.service';

import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { takeUntil } from 'rxjs/operators';
import { UserInterface, User } from './users.model';
import { UsersService } from './users.service';
import { displayZeroValueText, dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [
    {
      provide: UserInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new UsersService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class UsersComponent implements OnInit, OnDestroy {
  User;
  error;
  private unsubscribe$ = new Subject<void>();
  private arrguments = {};
  private Active = 1;
  roleList: Enum[];
  entityName = 'User';
  fleetDropDown: Enum[];
  companyDropDown: Enum[];

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  userData: ArrayStore;
  user: Entity<User>;
  data_toggle: boolean;
  isAddForm = 1;
  GSCollapse = true;
  collapseColor = "yellow";
  isSysAdmin: any;
  constructor(private user$: UserInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {

    user$.ListRetrieved.subscribe(data => {
      if (data) {
        this.userData = new ArrayStore({
          data: data,
          key: ['Id']
        });

        if (data.length == 0) {
          this.dataGrid.noDataText = 'No Drivers Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });


    actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    // actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

    errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

      if (errors) {

        let entityForm = document.getElementById('entity-form');

        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }

    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserList() {
    this.user$.getList().subscribe(listWithCreated => {
      this.user$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }
  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.app$.loginUserInfo.subscribe((res: any) => {      
    this.isSysAdmin = res.IsSysAdmin;
    });
    this.getUserList();
    forkJoin({
        fleet: this.user$.getFleetList(),
        company: this.user$.getCompanyList(),
        user: this.user$.getList(),
        roles: this.user$.getRoleList(this.isSysAdmin)
    }).subscribe(
      multipleData => {
        this.fleetDropDown = multipleData['fleet'];
        this.companyDropDown = multipleData['company'];
        this.roleList = multipleData['roles'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }
  private addNewEntity() {

    this.app$.setScreenTitle('New ' + this.entityName);

    this.user = new Entity();
    this.user.value.Id = 0;
    //this.user.value.ValidDate = new Date();
    this.user.created = true;
    this.changeDetectorRef.detectChanges();
  }
  onCellPrepared(e: { rowType: string; column: { dataField: string; }; data: { Deleted: any; }; cellElement: { innerHTML: string; }; }) {
    if (e.rowType === "data" && e.column.dataField === "Deleted") {
      if (e.data.Deleted)
        e.cellElement.innerHTML = '<span class="status-icon-closed-by-driver InActive"></span>';
      else
        e.cellElement.innerHTML = '<span class="status-icon-closed-by-driver Active"></span>';
    }
  }
  onRowClick($clikedRow: { key: any; }) {

    this.app$.setScreenTitle(this.entityName);

    if (!this.user)
      {
      this.user = new Entity();
      this.changeDetectorRef.detectChanges();
      }

    if (JSON.stringify(this.user.key) === JSON.stringify($clikedRow.key)) {
      this.deselectUser();
    }
    else {
      this.selectUser($clikedRow.key);
    }
  }
  onInitialized(e: any) {
    this.resizeScreenContent();
  }
  onSelectionChanged($clikedRow: any) {
    if (!this.user)
      try {
        let keySaved = JSON.parse(localStorage.getItem('user.dataGrid')).selectedRowKeys[0];

        if (keySaved) {
          this.selectUser(keySaved);
        }
      }
      catch { }
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    console.log(this.entityForm.form)
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    this.user$.create(this.user).subscribe(

      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deselectUser();
        this.getUserList();
      },

      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private selectUser(key: any) {
    this.actions$.notifyEntityBusy();
    this.user = new Entity();
    this.user.key = key;
    this.user$.getUserDetailById(key.Id).subscribe(data => {
      this.user.value = data;
      this.app$.notifyScreenReady();
      if(this.user.value.FleetId == 0){
        displayZeroValueText(this.fleetDropDown,'fleetId','Id','Name');
      }        
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }
  private deselectUser() {
    this.dataGrid.instance.deselectRows(this.user.key);
    this.user.key = undefined;
    this.Active = 2;
  }
  private reset() {
    this.user.restore();
    if(this.user.created){
      this.user.value.Id = 0
    }     
  }
  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deselectUser();
  }
  collapse() {
    this.data_toggle = !this.data_toggle;
  }
  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }
  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': !pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': !pannel && this.isAddForm,
    }
  }
  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control).errors;
  }
  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }
}
