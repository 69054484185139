<div class="container"
     *ngIf="printTextReference">

    <div id="entity-fields"
         class="row">

        <div class="entity-fields-column col-lg-6 col-md-12">

            <div class="container">

                <div class="row form-group">
                    <div class="col-4">
                        <label for="Type"
                               class="col-form-label">Reference Type</label>
                    </div>
                    <div class="col-8">
                        <pdi-dropdown [full]="true" [(ngModel)]="printTextReference.ReferenceType"
                                #Type="ngModel"
                                name="ReferenceType"
                                (valueSelected)="onChange_ReferenceType($event)" [options]="printTextReferenceTypes">
                        </pdi-dropdown>
                        <div class="control-look"></div>
                    </div>
                </div>

            </div>
        </div>


        <div class="entity-fields-column col-lg-6 col-md-12">

            <div class="container">

                <div class="row form-group">

                    <div class="col-4">

                        <label for="NumberOfLines"
                               class="col-form-label">Number Of Lines</label>

                    </div>

                    <div class="col">

                        <input [(ngModel)]="printTextReference.NumberOfLines"
                               [ngClass]="{
                                 'modelstate-invalid': isInvalidByteNumberInput(NumberOfLines) 
                                 }"
                               type="number"
                               max="255"
                               min="0"
                               pattern="^[0-9]*$"
                               (keypress)="allowOnlyNumbers(event)"
                               class="form-custom-control control-look-input"
                               name="NumberOfLines"
                               #NumberOfLines="ngModel">
                        <div class="control-look"></div>

                        <div class="error-message"
                             *ngIf="isInvalidByteNumberInput(NumberOfLines)">
                            <small class="error">
                                Number Of Lines value can be between 0 and 255
                            </small>

                        </div>

                    </div>

                </div>

            </div>
        </div>

        <ng-container *ngIf="typeName == 'SafetyCheck'">

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="SafetyGroup"
                                   class="col-form-label">Safety Group</label>

                        </div>

                        <div class="col">
                            <pdi-dropdown [full]="true" [(ngModel)]="printTextReference.extID1"
                                    #Type="ngModel"
                                    name="SafetyGroup" [options]="safetyGroups">
                            </pdi-dropdown>
                            <div class="control-look"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="SafetyTypes"
                                   class="col-form-label">Safety Summary Types</label>

                        </div>

                        <div class="col">

                            <pdi-dropdown [full]="true" id="safetyTypes" name="SafetyTypes" #Type="ngModel"
                             [(ngModel)]="printTextReference.extID2" [options]="safetySummaryTypes">
                            </pdi-dropdown>
                            <div class="control-look"></div>

                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


        <div class="entity-fields-column  col-lg-6 col-md-12">

            <div class="container">
                <div class="row form-group">
                    <div class="col-4">

                        <label for="TextType"
                               class="col-form-label">Text Type</label>

                    </div>

                    <div class="col">

                        <input [(ngModel)]="printTextReference.TextType"
                               [ngClass]="{'modelstate-invalid':isInvalidByteNumberInput(TextType)}"
                               type="number"
                               max="255"
                               min="0"
                               pattern="^[0-9]*$"
                               (keypress)="allowOnlyNumbers(event)"
                               class="form-custom-control control-look-input"
                               name="TextType"
                               #TextType="ngModel">
                        <div class="control-look"></div>

                        <div class="error-message"
                             *ngIf="isInvalidByteNumberInput(TextType)">
                            <small class="error">
                                Text Type value can be between 0 and 255
                            </small>

                        </div>

                    </div>
                </div>
            </div>
        </div>


        <ng-container *ngIf="typeName == 'CompanyVehicleTypeId'">

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="VehicleType"
                                   class="col-form-label">Vehicle Type</label>

                        </div>

                        <div class="col">

                            <pdi-dropdown [full]="true" [(ngModel)]="printTextReference.extID2" #Type="ngModel"                                    
                                    name="VehicleType" id="vehicleType" [options]="vehicleTypes">
                            </pdi-dropdown>

                            <div class="control-look"></div>

                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


        <ng-container *ngIf="typeName == 'CustomerPrintType'">

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="PrintType"
                                   class="col-form-label">Print Type</label>

                        </div>

                        <div class="col">

                            <input [(ngModel)]="printTextReference.extID1"
                                   type="number"
                                   pattern="^[0-9]*$"
                                   (keypress)="allowOnlyNumbers(event)"
                                   class="form-custom-control control-look-input"
                                   name="PrintType"
                                   #PrintType="ngModel">

                            <div class="control-look"></div>


                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


        <ng-container *ngIf="typeName == 'CustomerTaxGroup'">

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="TaxGroup"
                                   class="col-form-label">Tax Group</label>

                        </div>

                        <div class="col">

                            <input [(ngModel)]="printTextReference.extID1"
                                   type="number"
                                   pattern="^[0-9]*$"
                                   (keypress)="allowOnlyNumbers(event)"
                                   class="form-custom-control control-look-input"
                                   name="TaxGroup"
                                   #TaxGroup="ngModel">

                            <div class="control-look"></div>


                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


        <ng-container *ngIf="hasTemplate()">

            <div class="entity-fields-column  col-lg-6 col-md-12">

                <div class="container">
                    <div class="row form-group">
                        <div class="col-4">

                            <label for="TemplateType"
                                   class="col-form-label">Template Type</label>

                        </div>

                        <div class="col">
                            <pdi-dropdown [full]="true" id="TemplateType" [(ngModel)]="printTextReference.extID2"
                                    #Type="ngModel"
                                    name="TemplateType"
                                    [options]="printTemplateTypes">
                            </pdi-dropdown>
                            <div class="control-look"></div>

                        </div>
                    </div>
                </div>
            </div>

        </ng-container>


        <ng-container *ngIf="hasReferenceData()">

            <div class="entity-fields-column col-12">

                <div class="container">
                    <div class="row form-group">

                        <div class="col-2">

                            <label class="col-form-label">
                                Reference
                            </label>

                        </div>

                        <div class="col">


                            <div [ngClass]="{
                                            editGridDiv: !printTextCreated, 
                                            newGridDiv: printTextCreated
                                        }">

                                <dx-data-grid #dataGridReferenceTypeData
                                              [dataSource]="printTextReferenceDataStore"
                                              (onRowClick)="onRowClick($event)"
                                              noDataText = ""
                                              [allowColumnResizing]="true"
                                              [(selectedRowKeys)]="selectedReference">


                                    <ng-container *ngIf="typeName == 'Product' || typeName == 'ProductTextAtConfigurablePosition'">
                                        <dxi-column dataField="ProductNumber"
                                                    [minWidth]="100"></dxi-column>
                                        <dxi-column dataField="ProductName"
                                                    [width]="'65%'"></dxi-column>
                                    </ng-container>

                                    <ng-container *ngIf="typeName == 'Company' || typeName == 'CompanyTemplateType' || typeName == 'CompanyVehicleTypeId'">
                                        <dxi-column dataField="Id"
                                                    [width]="100"></dxi-column>
                                        <dxi-column dataField="CompanyName"></dxi-column>
                                    </ng-container>

                                    <ng-container *ngIf="typeName == 'SafetyCheck'">
                                        <dxi-column dataField="SafetyCheckName"></dxi-column>
                                        <dxi-column dataField="SafetyGroup"></dxi-column>
                                    </ng-container>


                                    <dxo-selection [mode]="'single'"
                                                   [allowSelectAll]="false">
                                    </dxo-selection>

                                    <dxo-filter-row [visible]="true">
                                    </dxo-filter-row>

                                    <dxo-scrolling showScrollbar="always"
                                                   useNative="true">
                                    </dxo-scrolling>

                                    <dxo-sorting mode="single"> </dxo-sorting>

                                    <dxo-paging [pageSize]="5"> </dxo-paging>
                                    <dxo-pager [showNavigationButtons]="true">
                                    </dxo-pager>

                                </dx-data-grid>

                            </div>


                        </div>

                    </div>

                </div>

            </div>

        </ng-container>


    </div>

</div>