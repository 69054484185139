import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DataArea, DataAreaInterface } from './data-area.model';
import { DataAreaService } from './data-area.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-data-area',
  templateUrl: './data-area.component.html',
  styleUrls: ['./data-area.component.scss'],
  providers: [
    {
      provide: DataAreaInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new DataAreaService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class DataAreaComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  entityName = 'Data Area';
  DataAreaDataSource: any;
  DataArea: ArrayStore;
  dataAreaEntity: Entity<DataArea>;
  collapseColor = 'green';
  isAddForm = 0;
  newdataArea = {};
  productGroups = [];

  assignedProductSelected = [];
  unassignedProductSelected = [];
  productGroupColumn = [];
  isProductGroupSelect = false;
  isProductGroupSelectNew = false;
  isProductGroupUnselect = false;
  dataAreaEntityDeleteConfirm = false;
  dataAreaEntityDeleteConfirmMsg = "Data area will be removed for both products and customers. Are you sure you want to delete?";
  PGCollapse = false;

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private dataGridAssignedProducts: DxDataGridComponent;
  @ViewChild('dataGridAssignedProducts', { static: false }) set assignedProducts(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAssignedProducts = content;
    }
  }

  private dataGridAllProductGroups: DxDataGridComponent;
  @ViewChild('dataGridAllProductGroups', { static: false }) set unassignedProducts(content: DxDataGridComponent) {
    if (content) {
      this.dataGridAllProductGroups = content;
    }
  }

  constructor(private dataArea$: DataAreaInterface, private app$: AppService, private errors$: ErrorsService, private actions$: ActionsService, private changeDetectorRef: ChangeDetectorRef ) { }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.buttonActions();
    forkJoin({
      dataAreaList: this.dataArea$.getList(0),
      productGroups: this.dataArea$.getProductGroupList()
     }).subscribe(
      res => {
        this.productGroups = res['productGroups'];
        this.dataArea$.notifyRetrieved(res['dataAreaList']);
        this.app$.notifyScreenReady();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );

    this.productGroupColumn = [
      { caption: 'Product Group No', dataField: 'Number', dataType: 'string' },
      {
        alignment: 'left', caption: 'Product Group Name', dataField: 'Name', cssClass: 'Name', dataType: 'string',
        cellTemplate: function (container, options) {
          $('<span />')
            .text(options.data.Name)
            .appendTo(container);
        }
      }
    ]
  }

  buttonActions(){
    this.dataArea$.ListRetrieved.subscribe(data => {
      if (data) {
        this.DataAreaDataSource = new ArrayStore({
          data: data,
          key: ['Id']
        });

        if (data.length == 0) {
          this.dataGrid.noDataText = 'No Product Group Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });
    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  onContentReadyAssigned() {
    this.dataGridAssignedProducts.noDataText = this.dataGridAssignedProducts.instance.totalCount() ? '' : "No data";
  }
  
  onContentReadyUnAssigned() {
    this.dataGridAllProductGroups.noDataText = this.dataGridAllProductGroups.instance.totalCount() ? '' : "No data";
  }

  getList() {
    this.dataArea$.getList(0).subscribe(listWithCreated => {
      this.dataArea$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.dataArea$.create(this.dataAreaEntity).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  deleteConfirm() {
    let param = { Id: this.dataAreaEntity.value.Id };
    this.dataArea$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

 onSelectionChanged($clikedRow: any) {
    if ($clikedRow.selectedRowsData.length > 0) {
      this.isProductGroupSelect = true;
    }
    else {
      this.isProductGroupSelect = false;
    }
    if (this.isAddForm == 1 && this.isProductGroupSelect) {
      this.isProductGroupSelectNew = true;
    }
    else {
      this.isProductGroupSelectNew = false;
    }
  }

  onSelectionChanges($clikedRow: any) {
    if ($clikedRow.selectedRowsData.length > 0) {
      this.isProductGroupUnselect = true;
    }
    else {
      this.isProductGroupUnselect = false;
    }
  }

  selectProductGroups() {
    this.dataGridAllProductGroups.instance.getSelectedRowsData().forEach((sr) => {
      let prd = this.dataAreaEntity.value.ProductGroups.filter((pgrp) =>  pgrp.Id == sr.Id);
      if (prd.length == 0) {
        this.dataAreaEntity.value.ProductGroups.push(sr);
      }
    });
    this.clearAndRefreshGrid();
  };

  unselectProductGroups() {
    this.dataGridAssignedProducts.instance.getSelectedRowsData().forEach((sr) => {
      this.dataAreaEntity.value.ProductGroups.splice(this.dataAreaEntity.value.ProductGroups.indexOf(sr), 1);
    });
    this.clearAndRefreshGrid();
  }


  clearAndRefreshGrid() {
    if (this.dataGridAssignedProducts && this.dataGridAllProductGroups) {
      this.dataGridAssignedProducts.instance.clearSelection();
      this.dataGridAssignedProducts.instance.refresh();
      this.dataGridAllProductGroups.instance.clearSelection();
      this.dataGridAllProductGroups.instance.refresh();
      this.setNoDataText();
    }
  }

  setNoDataText() {
    this.dataGridAssignedProducts.noDataText = this.dataAreaEntity.value.ProductGroups?.length ? '' : "No data";
  }

 onInitialized(e: any) {
   this.resizeScreenContent();
 }
 onContentReady(e) {
   this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Product Group Found";
 }
 onRowClick($clikedRow: { key: any; }) {
   this.app$.setScreenTitle(this.entityName);
   if (!this.dataAreaEntity) {
     this.dataAreaEntity = new Entity();
     this.dataAreaEntity.key = undefined;
   }
   if (JSON.stringify(this.dataAreaEntity.key) === JSON.stringify($clikedRow.key)) {
     this.deSelectEntity();
   }
   else {
     this.selectEntity($clikedRow.key);
   }
 }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.dataAreaEntity.key);
    this.dataAreaEntity.key = undefined;
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.dataArea$.getDataAreaByID(key).subscribe((res: any) => {
      this.dataAreaEntityDeleteConfirm = true;
      this.app$.notifyScreenReady();
      this.dataAreaEntity = new Entity();
      this.dataAreaEntity.key = key;
      this.dataAreaEntity.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
      this.dataAreaEntity.value.ProductGroups = res.ProductGroups;
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private reset() {
    this.dataAreaEntity.restore();
    this.clearAndRefreshGrid();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
    this.isProductGroupSelect = false;
    this.isProductGroupSelectNew = false;
    this.isProductGroupUnselect = false;
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.dataAreaEntity = new Entity();
    this.dataAreaEntity.value.ProductGroups = [];
    this.changeDetectorRef.detectChanges();
    this.collapseColor = 'yellow';
    this.isAddForm = 1;
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
