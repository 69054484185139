import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, DoCheck } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { forkJoin, Subject } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';

import { Enum, Entity } from '@app/app.model';

import { AppService } from '@app/app.service';

import cloneDeep from 'lodash/cloneDeep';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { takeUntil } from 'rxjs/operators';
import { DriverManagementInterface, DriverManagement } from './driver-management.model';
import { DriverManagementService } from './driver-management.service';
import { dxDataGridHeightAdjustment, dxSelectOptions, makePDIDropDownOptions } from '@app/common_helper';

@Component({
  selector: 'facnm-driver-management',
  templateUrl: './driver-management.component.html',
  styleUrls: ['./driver-management.component.scss'],
  providers: [
    {
      provide: DriverManagementInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new DriverManagementService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class DriverManagementComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  private Active = 1;
  private data_toggle = false;
  private arrguments = {};
  languageList: Enum[];
  roleList: Enum[];
  entityName = 'Driver';
  dateFormat = '';

  ActiveInactiveDriverLookup = [
    { Deleted: false, Text: 'Active' },
    { Deleted: true, Text: 'InActive' }
];
  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  driverData: ArrayStore;
  driverManagement: Entity<DriverManagement>;
  dxSelectOptions = new dxSelectOptions();

  constructor(private driverManagement$: DriverManagementInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService) {

    driverManagement$.ListRetrieved.subscribe(data => {
      if (data) {
        this.driverData = new ArrayStore({
          data: data,
          key: ['Id']
        });

        if (data.length == 0) {
          this.dataGrid.noDataText = 'No Drivers Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
      this.dateFormat = this.app$.dateFormat;
    });

    this.arrguments = {
      language: this.driverManagement$.getLanguageList(),
      role: this.driverManagement$.getRoleList()
    };

    actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    // actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

    errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

      if (errors) {

        let entityForm = document.getElementById('entity-form');

        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getDriverList(){
    this.driverManagement$.getList().subscribe(listWithCreated => {
      this.driverManagement$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    forkJoin(
      this.arrguments
    ).subscribe(
      multipleData => {
        this.languageList = multipleData['language'];
        this.roleList = multipleData['role'];
        this.roleList = this.roleList.filter(a=>a.id>0);
        this.getDriverList();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  isInvalid(field: any) { return this.errors$.isInvalid(field); }

  getError(field: any) { return this.errors$.getError(field); }

  collapse() {
    this.data_toggle = !this.data_toggle;
  }


  private addNewEntity() {

    this.app$.setScreenTitle('New ' + this.entityName);

    this.driverManagement = new Entity();
    this.driverManagement.value.Id = 0;
    this.driverManagement.value.ValidDate = new Date();
    this.driverManagement.value.Deleted = false;
    this.driverManagement.created = true;
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    if (!this.driverManagement.value.ValidDate) {
      this.driverManagement.value.ValidDate = null;
      this.entityForm.form.get('ValidDate').markAsTouched();
    }
    this.driverManagement$.create(this.driverManagement).subscribe(

      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deselectDriverManagement();
        this.getDriverList();
      },

      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deselectDriverManagement() {
    this.dataGrid.instance.deselectRows(this.driverManagement.key);
    this.driverManagement.key = undefined;
    this.Active = 2;
  }

  private reset() {
    this.driverManagement.restore();
  }
  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deselectDriverManagement();   
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }
  onResize(e: any): void {
    this.resizeScreenContent(); 
  }

  onSelectionChanged($clikedRow: any) {
    if (!this.driverManagement)
      try {
        let keySaved = JSON.parse(localStorage.getItem('driverManagement.dataGrid')).selectedRowKeys[0];

        if (keySaved) {
          this.selectDriverManagement(keySaved);
        }
      }
      catch{ }
  }

  private selectDriverManagement(key: any) {
    this.actions$.notifyEntityBusy();
    this.driverManagement = new Entity();
    this.driverManagement.key = key;

    this.driverData.byKey(key)
      .then(pt => {
        this.driverManagement.value = cloneDeep(pt);
        this.driverManagement.value.ValidDate = new Date(this.driverManagement.value.ValidDate);
        this.Active = this.driverManagement.value.Deleted ? 1 : 2;
        this.app$.notifyScreenReady()
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();    
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {

    this.app$.setScreenTitle(this.entityName);

    if (!this.driverManagement)
      this.driverManagement = new Entity();

    if (JSON.stringify(this.driverManagement.key) === JSON.stringify($clikedRow.key)) {
      this.deselectDriverManagement();
    }
    else {
      this.selectDriverManagement($clikedRow.key);
    }
  }

  onCellPrepared(e: { rowType: string; column: { dataField: string; }; data: { Deleted: any; }; cellElement: { innerHTML: string; }; }) {
    if (e.rowType === "data" && e.column.dataField === "Deleted") {
      if (e.data.Deleted)
        e.cellElement.innerHTML = '<span class="status-icon-closed-by-driver InActive"></span>';
      else
        e.cellElement.innerHTML = '<span class="status-icon-closed-by-driver Active"></span>';
    }
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

}
