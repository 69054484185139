import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from "@app/home/home.component";
import { ProductComponent } from "@products/product/product.component";
import { UsersComponent } from "@settings/users/users.component";
import { AdditionalFieldsComponent } from "@app/fleet/additional-fields/additional-fields.component";
import { ConfigurationGroupComponent } from '@app/fleet/configuration-group/configuration-group.component';
import { CustomerComponent } from "@app/customers/customer/customer.component";
import { VehicleComponent } from '@app/fleet/vehicle/vehicle.component';
import { DataAreaComponent } from "@products/data-area/data-area.component";
import { AuditLogComponent } from "@settings/audit-log/audit-log.component";
import { CompanyComponent } from "@settings/company/company.component";
import { DeviceMappingComponent } from "@products/device-mapping/device-mapping.component";
import { CustomerDataAreaComponent } from "@app/customers/customer-data-area/customer-data-area.component";
import { TranspacConfigurationComponent } from "@fleet/transpac-configuration/transpac-configuration.component";
import { StrappingChartComponent } from "@app/customers/strapping-chart/strapping-chart.component";
import { DeviceGroupComponent } from "@products/device-group/device-group.component";
import { PaymentTermComponent } from "@settings/payment-term/payment-term.component";
import { BankAccountComponent } from "@settings/bank-account/bank-account.component";
import { FactorComponent } from "@products/factor/factor.component";
import { ProductGroupComponent } from "@products/product-group/product-group.component"
import { PriceGroupComponent } from "@products/price-group/price-group.component";
import { ImportConfigurationComponent } from "@fleet/import-configuration/import-configuration.component";
import { ProductTransformationComponent } from "@products/product-transformation/product-transformation.component";
import { ProductBlendsComponent } from "@products/product-blends/product-blends.component";
import { AssessmentComponent } from "@app/fleet/assessment/assessment.component";
import { ManageImagesComponent } from "@app/fleet/manage-images/manage-images.component";
import { PrintTemplatesComponent } from "@app/settings/print-templates/print-templates.component";
import { TaxRatesComponent } from "@app/settings/tax-rates/tax-rates.component";
import { PrintTextsComponent } from "@settings/print-text/print-text.component";
import { ServerConfigurationComponent } from "@settings/server-configuration/server-configuration.component";
import { ModulesComponent } from "@settings/modules/modules.component";
import { DriverManagementComponent } from "@app/fleet/driver-management/driver-management.component";
import { TimeLogComponent } from "@app/fleet/time-log/time-log.component";
import { FleetManagementComponent } from "@app/settings/fleet-management/fleet-management.component";
import { ExportConfigurationComponent } from "@app/fleet/export-configuration/export-configuration.component";
import { LoadPointComponent } from './load-point/load-point.component';
import { LoginSSOComponent } from './login-sso/login-sso.component';
import { SafetyCheckComponent } from './safety-check/safety-check.component';
import { AuthGuard } from './auth.guard';


export const appRoutes: Routes = [
    { path: 'login', component: LoginComponent, },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'additionalfields', component: AdditionalFieldsComponent, canActivate: [AuthGuard] },
    { path: 'auditlog', component: AuditLogComponent, canActivate: [AuthGuard] },
    { path: 'bankaccount', component: BankAccountComponent, canActivate: [AuthGuard] },
    { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] },
    { path: 'configurationgroup', component: ConfigurationGroupComponent, canActivate: [AuthGuard] },
    { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
    { path: 'customerdataarea', component: CustomerDataAreaComponent, canActivate: [AuthGuard] },
    { path: 'strappingChartProfiles', component: StrappingChartComponent, canActivate: [AuthGuard] },
    { path: 'dataarea', component: DataAreaComponent, canActivate: [AuthGuard] },
    { path: 'devicemapping', component: DeviceMappingComponent, canActivate: [AuthGuard] },
    { path: 'assessment', component: AssessmentComponent, canActivate: [AuthGuard] },
    { path: 'deviceproductgroup', component: DeviceGroupComponent, canActivate: [AuthGuard] },
    { path: 'driver', component: DriverManagementComponent, canActivate: [AuthGuard] },
    { path: 'exportconfigurationset', component: ExportConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'factor', component: FactorComponent, canActivate: [AuthGuard] },
    { path: 'fleetmanagement', component: FleetManagementComponent, canActivate: [AuthGuard] },
    { path: 'importconfigurationset', component: ImportConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'loadpoint', component: LoadPointComponent, canActivate: [AuthGuard] },
    { path: 'loginSSO', component: LoginSSOComponent, },
    { path: 'manageImages', component: ManageImagesComponent, canActivate: [AuthGuard] },
    { path: 'paymentterm', component: PaymentTermComponent, canActivate: [AuthGuard] },
    { path: 'pricegroup', component: PriceGroupComponent, canActivate: [AuthGuard] },
    { path: 'printTemplates', component: PrintTemplatesComponent, canActivate: [AuthGuard] },
    { path: 'printTexts', component: PrintTextsComponent, canActivate: [AuthGuard] },
    { path: 'product', component: ProductComponent, canActivate: [AuthGuard] },
    { path: 'productgroup', component: ProductGroupComponent, canActivate: [AuthGuard] },
    { path: 'productTransformation', component: ProductTransformationComponent, canActivate: [AuthGuard] },
    { path: 'productBlends', component: ProductBlendsComponent, canActivate: [AuthGuard] },
    { path: 'safetychecktext', component: SafetyCheckComponent, canActivate: [AuthGuard] },
    { path: 'serverConfiguration', component: ServerConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'taxRates', component: TaxRatesComponent, canActivate: [AuthGuard] },
    { path: 'transpacconfiguration', component: TranspacConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'timelogdefinitions', component: TimeLogComponent, canActivate: [AuthGuard] },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
    { path: 'vehicle', component: VehicleComponent, canActivate: [AuthGuard] },
    { path: 'modules', component: ModulesComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: '/home' }
];
@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRouting { }
