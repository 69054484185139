import { Injectable } from "@angular/core";
import { Observable } from "rxjs";


export class ServerConfiguration {

    Id: string;
    Description: string;

    Value: any;

    ValueFloat: number;
    ValueInt: number;

    Type: ServerConfigurationType;

    modified: boolean;
    invalid: boolean;
}

export enum ServerConfigurationType {
    
    bool = 'bool',
    str = 'str',
    int = 'int',
    float = 'float'
}

@Injectable()
export abstract class ServerConfigurationInterface  {

    save: (data: ServerConfiguration) => Observable<any>;
    getList: () => Observable<ServerConfiguration[]>;
}