<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">

    <div id="assessment"
         class="row h-100">

        <div class="col-4">

            <div id="entity-list"
                 class="h-100">

                <dx-data-grid #dataGrid
                              [dataSource]="assessmentsStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [columnMinWidth]="50">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>


                    <dxi-column dataField="FormId"
                                [width]="50"
                                dataType="string"></dxi-column>
                    <dxi-column dataField="FormName"></dxi-column>


                    <dxo-selection mode="single"> </dxo-selection>

                    <dxo-filter-row [visible]="true"> </dxo-filter-row>

                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>

                    <dxo-sorting mode="single"> </dxo-sorting>

                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>

                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="assessment.dataGrid">
                    </dxo-state-storing>

                </dx-data-grid>

            </div>

        </div>

        <div id="entity-detail"
             class="col-8 pr-0">

            <div *ngIf="assessment && assessment.key">


                <form id="entity-form"
                      #entityForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">

                            <div id="entity-errors"
                                 class="row">
                                <div class="col">
    
                                    <facnm-errors></facnm-errors>
    
                                </div>
                            </div>

                        <div class="row form-general-settings">

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">

                                        <div class="col-4">
                                            <label for="FormId"
                                                   class="col-form-label">Id</label>
                                        </div>

                                        <div class="col-8">

                                            <input [value]="assessment.value.FormId"
                                                   type="text"
                                                   class="form-custom-control form-control-disabled control-look-input"
                                                   readonly="readonly">

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col">

                                <div class="container">

                                    <div class="row form-group">

                                        <div class="col-4">
                                            <label for="FormName"
                                                   class="col-form-label">Name</label>
                                        </div>

                                        <div class="col-8">
                                            <input [(ngModel)]="assessment.value.FormName"
                                                   [ngClass]="{'modelstate-invalid': !FormName.dirty && isInvalid('FormName')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="FormName"
                                                   #FormName="ngModel">
                                            <div class="control-look"></div>

                                            <div class="error-message"
                                                 *ngIf="!FormName.dirty && isInvalid('FormName')">
                                                <small class="error">
                                                    {{getError('FormName')}}
                                                </small>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row form-sections ml-0 mr-0 p-0">

                            <div class="col pl-0 form-sections-names">

                                <dx-tabs #sectionsTabs
                                         [items]="currentSectionsTabList"
                                         (onItemClick)="onItemClick_sections($event)"
                                         (onContentReady)="onContentReady_sections($event)"
                                         width="100%"
                                         showNavButtons="false">

                                    <div *dxTemplate="let section of 'item'">
                                        <div class="col section-name">{{section.SectionName}}</div>
                                    </div>

                                </dx-tabs>

                            </div>


                            <div class="form-sections-actions">

                                <dx-button (onClick)="onClick_open_sections_edit($event)"
                                           text=""
                                           [elementAttr]="{ class: 'sections-action' }"
                                           icon="assets/Content/Images/Icons/edit.png">
                                </dx-button>

                            </div>

                            <dx-popup *ngIf=" currentSections"
                                      (onHidden)=" currentSections = undefined"
                                      title="Edit Sections"
                                      [(visible)]="isVisible_sections_edit"
                                      container="#assessment"
                                      [animation]="{
                                          show: {
                                              type:'fadeIn',
                                              duration: 250,
                                              from: {
                                                  scale: 0.1,
                                                  opacity: 0
                                              }
                                          },
                                          hide: {
                                              type:'fadeOut',
                                              duration: 250,
                                              from: {
                                                  scale: 0.1,
                                                  opacity: 1
                                              }
                                          }
                                      }"
                                      hideOnOutsideClick="true"
                                      [dragEnabled]="false"
                                      shadingColor="rgba(85, 85, 85, 0.5)"
                                      maxHeight="500"
                                      maxWidth="450">

                                <div #sectionsForm
                                     class="container-fluid form-sections-edit">

                                    <div class="row p-4">
                                        <div class="col">

                                            <div class="container">

                                                <div class="row ml-0 mr-0 p-0">


                                                    <dx-list [dataSource]="currentSectionsList"
                                                             noDataText="Add Sections"
                                                             width="100%"
                                                             [itemDragging]="{ allowReordering: true }"
                                                             (onItemReordered)="onItemReordered_sections($event)"
                                                             [elementAttr]="{ id: 'form-sections-edit-names' }">

                                                        <div
                                                             *dxTemplate="let section of 'item'; let sectionIndex = index">

                                                            <div class="container">

                                                                <div class="row">

                                                                    <div class="col">

                                                                        <input [(ngModel)]="section.SectionName"
                                                                               type="text"
                                                                               class="form-custom-control control-look-input"
                                                                               name="SectionName-{{sectionIndex}}">

                                                                        <div class="control-look">
                                                                        </div>

                                                                    </div>

                                                                    <div
                                                                         class="col-2 align-self-center">
                                                                        <img *ngIf="section.Condition"
                                                                             style="opacity: 0.8;"
                                                                             src="assets/Content/Images/Icons/shown_field.png" />
                                                                    </div>

                                                                    <div
                                                                         class="col-3 p-0 text-center">

                                                                        <dx-button (onClick)="onClick_open_section_edit($event, sectionIndex)"
                                                                                   text=""
                                                                                   [elementAttr]="{ class: 'section-action mr-0' }"
                                                                                   icon="assets/Content/Images/Icons/edit.png">
                                                                        </dx-button>

                                                                        <dx-button (click)="onClick_delete_section($event, sectionIndex)"
                                                                                   text=""
                                                                                   [elementAttr]="{ class: 'section-action' }"
                                                                                   icon="assets/Content/Images/Icons/delete1.png">
                                                                        </dx-button>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </dx-list>


                                                </div>


                                                <div class="row form-fields-add pl-1 mt-4">

                                                    <div class="col">

                                                        <dx-button text=""
                                                                   (onClick)="onClick_add_section($event)"
                                                                   [elementAttr]="{ class: 'sections-action' }"
                                                                   icon="plus">
                                                        </dx-button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>



                                    <div class="row text-center fixed-bottom pb-4">

                                        <div class="col">
                                            <button (click)="onSave_sections()"
                                                    id="action-edit-form-sections-save"
                                                    [disabled]="!currentSections_modified">
                                                <img src="assets/Content/Images/Icons/save.png"
                                                     class="edit-button-image"
                                                     [ngClass]="{'disabled': !currentSections_modified}" />
                                                <span class="edit-button d-none d-xl-inline-block"
                                                      [ngClass]="{'disabled': !currentSections_modified}">Save</span>
                                            </button>
                                        </div>

                                        <div class="col">
                                            <button (click)="onCancel_sections_edit()"
                                                    id="action-edit-form-sections-cancel">
                                                <img src="assets/Content/Images/Icons/cancel.png"
                                                     class="edit-button-image" />
                                                <span
                                                      class="edit-button d-none d-xl-inline-block">Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </dx-popup>



                            <dx-popup *ngIf="currentSectionEdit"
                                      (onHidden)="currentSectionEdit = undefined"
                                      title="Edit Section Detail"
                                      [(visible)]="isVisible_section_edit"
                                      container="#assessment"
                                      [animation]="{
                                          show: {
                                              type:'fadeIn',
                                              duration: 250,
                                              from: {
                                                  scale: 0.1,
                                                  opacity: 0
                                              }
                                          },
                                          hide: {
                                              type:'fadeOut',
                                              duration: 250,
                                              from: {
                                                  scale: 0.1,
                                                  opacity: 1
                                              }
                                          }
                                      }"
                                      hideOnOutsideClick="true"
                                      [dragEnabled]="false"
                                      shadingColor="rgba(85, 85, 85, 0.5)"
                                      maxHeight="500"
                                      maxWidth="450">

                                <div class="container-fluid ">

                                    <div class="row p-4">
                                        <div class="col">


                                            <div class="row form-sections-edit-conditions p-2">
                                                <div class="col p-4">


                                                    <div class="container">


                                                        <div class="row">

                                                            <div class="col">
                                                                <img id="tooltip-conditions-info"
                                                                     src="assets/Content/Images/Icons/info2.png" />
                                                                <label for="condition"
                                                                       class="col-form-label">
                                                                    Condition to display this
                                                                    section
                                                                </label>

                                                            </div>
                                                        </div>

                                                        <div class="row form-group">


                                                            <div class="col">

                                                                <textarea [(ngModel)]="currentSectionEdit.Condition"
                                                                          type="text"
                                                                          class="form-custom-control control-look-input"
                                                                          name="condition"
                                                                          autosize
                                                                          [maxRows]="10"></textarea>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="row text-center fixed-bottom pb-4">

                                        <div class="col">
                                            <button (click)="onSave_section()"
                                                    id="action-edit-field-save"
                                                    [disabled]="!currentSection_modified">
                                                <img src="assets/Content/Images/Icons/save.png"
                                                     class="edit-button-image"
                                                     [ngClass]="{'disabled': !currentSection_modified}" />
                                                <span class="edit-button d-none d-xl-inline-block"
                                                      [ngClass]="{'disabled': !currentSection_modified}">Save</span>
                                            </button>
                                        </div>

                                        <div class="col">
                                            <button (click)="onCancel_section()"
                                                    id="action-edit-field-cancel">
                                                <img src="assets/Content/Images/Icons/cancel.png"
                                                     class="edit-button-image" />
                                                <span
                                                      class="edit-button d-none d-xl-inline-block">Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </dx-popup>


                        </div>

                        <ng-container
                                      *ngIf="currentSectionsTabList && currentSectionsTabList.length > 0">

                            <div class="row form-fields-title p-3">

                                <div class="col">
                                    <b> Title / Description /
                                        Question</b>
                                </div>
                                <div class="col-5">
                                    <b>Field Type</b>
                                </div>


                            </div>


                            <div class="row form-fields-content p-3">

                                <dx-list [items]="currentFieldsList"
                                         noDataText="Add Fields"
                                         width="100%"
                                         [itemDragging]="{ allowReordering: true }"
                                         (onItemReordered)="onItemReordered_fields($event)"
                                         [elementAttr]="{ id: 'form-fields-list' }">

                                    <div *dxTemplate="let field of 'item';let fieldIndex = index">

                                        <div class="container">

                                            <div class="row form-group">

                                                <div class="col"
                                                     [ngClass]="{ 'd-flex align-items-center' : isDivider(field) }">

                                                    <textarea *ngIf="!isDivider(field)"
                                                              [(ngModel)]="field.FieldName"
                                                              class="form-custom-control control-look-input"
                                                              name="FieldName-{{field.SequenceNo}}"
                                                              autosize
                                                              [maxRows]="10"></textarea>


                                                    <div *ngIf="isDivider(field)"
                                                         class="field-divider"></div>

                                                </div>


                                                <div class="col-1 align-self-center">
                                                    <img *ngIf="field.Condition && !isDivider(field)"
                                                         style="opacity: 0.8;"
                                                         src="assets/Content/Images/Icons/shown_field.png" />
                                                </div>


                                                <div class="col-3 align-self-center">

                                                    <pdi-dropdown [(ngModel)]="field.FieldType"
                                                            name="FieldType-{{field.SequenceNo}}" id="fieldTypeId{{field.id}}" [full]="true" >
                                                        <pdi-dropdown-option *ngFor="let ft of fieldTypeList" [key]="ft.id">
                                                            {{ft.name}}
                                                        </pdi-dropdown-option>
                                                    </pdi-dropdown>

                                                    <div class="control-look"></div>

                                                </div>

                                                <div class="col-2 align-self-center">

                                                    <dx-button (onClick)="onClick_open_field_edit($event, fieldIndex)"
                                                               text=""
                                                               [disabled]="isDivider(field)"
                                                               [elementAttr]="{ class: 'field-action mr-3' }"
                                                               icon="assets/Content/Images/Icons/edit.png">
                                                    </dx-button>

                                                    <dx-button (onClick)="onClick_delete_field($event, fieldIndex)"
                                                               text=""
                                                               [elementAttr]="{ class: 'field-action' }"
                                                               icon="assets/Content/Images/Icons/delete1.png">
                                                    </dx-button>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </dx-list>

                            </div>


                            <div class="row form-fields-add m-1">

                                <div class="col">

                                    <dx-button (onClick)="onClick_add_field($event)"
                                               text=""
                                               [elementAttr]="{ class: 'field-action mr-3' }"
                                               icon="plus">
                                    </dx-button>

                                </div>
                            </div>

                        </ng-container>

                    </div>


                    <dx-popup *ngIf="currentField"
                              (onHidden)="currentField = undefined; error_variableName = undefined"
                              title="Edit Field"
                              [elementAttr]="{ id: 'form-field-edit' }"
                              [(visible)]="isVisible_field_edit"
                              container="#assessment"
                              (onHidden)="currentFieldTab = 0"
                              [animation]="{
                                        show: {
                                            type:'fadeIn',
                                            duration: 250,
                                            from: {
                                                scale: 0.1,
                                                opacity: 0
                                            }
                                        },
                                        hide: {
                                            type:'fadeOut',
                                            duration: 250,
                                            from: {
                                                scale: 0.1,
                                                opacity: 1
                                            }
                                        }
                                    }"
                              hideOnOutsideClick="true"
                              [dragEnabled]="false"
                              shadingColor="rgba(85, 85, 85, 0.5)"
                              maxHeight="500"
                              maxWidth="450">

                        <div #optionsFormContainer
                             class="container-fluid">

                            <div class="row form-field-edit-tabs p-0">
                                <div class="col p-0">


                                    <dx-tabs #field_editTabs
                                             [dataSource]="[{Name: 'Values'}, {Name: 'Conditions'}]"
                                             (onItemClick)="onItemClick_field_edit_tabs($event)"
                                             [selectedIndex]="currentFieldTab">

                                        <div *dxTemplate="let field of 'item'">
                                            <div class="col">{{field.Name}}</div>
                                        </div>

                                    </dx-tabs>

                                </div>
                            </div>


                            <div *ngIf="currentFieldTab == 0"
                                 class="row form-field-edit-values p-2">
                                <div class="col p-4">

                                    <div class="container">

                                        <div *ngIf="hasFieldUnit()"
                                             class="row form-group p-2">

                                            <div class="col-4">
                                                <label for="Unit"
                                                       class="col-form-label">Unit</label>
                                            </div>

                                            <div class="col-8">

                                                <input [(ngModel)]="currentField.Unit"
                                                       type="text"
                                                       class="form-custom-control control-look-input"
                                                       name="Unit">

                                                <div class="control-look"></div>

                                            </div>
                                        </div>


                                        <div #optionsForm
                                             class="row field-edit-options p-4">
                                            <div class="container-fluid">


                                                <dx-list [dataSource]="currentOptionsList"
                                                         noDataText="Add Values"
                                                         width="100%"
                                                         [elementAttr]="{ id: 'form-field-edit-options-list' }">

                                                    <div
                                                         *dxTemplate="let option of 'item';let optionIndex = index">

                                                        <div class="container">

                                                            <div class="row">

                                                                <div class="col">

                                                                    <input [(ngModel)]="option.OptionName"
                                                                           type="text"
                                                                           class="form-custom-control control-look-input"
                                                                           name="OptionName-{{optionIndex}}">

                                                                    <div class="control-look"></div>

                                                                </div>

                                                                <div class="col-2 p-0 text-center">

                                                                    <dx-button (click)="onClick_delete_option($event, optionIndex)"
                                                                               text=""
                                                                               [elementAttr]="{ class: 'option-action' }"
                                                                               icon="assets/Content/Images/Icons/delete1.png">
                                                                    </dx-button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </dx-list>

                                                

                                            </div>
                                            <div>
                                                <dx-button (onClick)="onClick_add_option($event)"
                                                           text=""
                                                           [elementAttr]="{ class: 'option-action' }"
                                                           icon="plus">
                                                </dx-button>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div *ngIf="currentFieldTab == 1"
                                 class="row form-field-edit-conditions p-2">
                                <div class="col p-4">


                                    <div class="container">

                                        <div class="row form-group p-2">

                                            <div class="col-4">
                                                <label for="variableName"
                                                       class="col-form-label">Name</label>
                                            </div>

                                            <div class="col-8">

                                                <input [(ngModel)]="currentField.VariableName"
                                                       type="text"
                                                       class="form-custom-control control-look-input"
                                                       name="variableName">

                                                <div class="control-look"></div>


                                                <div class="error-message"
                                                     *ngIf="error_variableName">
                                                    <small class="error">
                                                        The variable name can start with a
                                                        letter or _, $; It cannot contain spaces.
                                                    </small>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col">
                                                <img id="tooltip-conditions-info"
                                                     src="assets/Content/Images/Icons/info2.png" />
                                                <label for="condition"
                                                       class="col-form-label">Condition to display
                                                    this field
                                                </label>
                                            </div>
                                        </div>

                                        <div class="row form-group">


                                            <div class="col">

                                                <textarea [(ngModel)]="currentField.Condition"
                                                          type="text"
                                                          class="form-custom-control control-look-input"
                                                          name="condition"
                                                          autosize
                                                          [maxRows]="10"></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div class="row text-center fixed-bottom pb-4">

                                <div class="col">
                                    <button (click)="onSave_field()"
                                            id="action-edit-field-save"
                                            [disabled]="!currentField_modified">
                                        <img src="assets/Content/Images/Icons/save.png"
                                             class="edit-button-image"
                                             [ngClass]="{'disabled': !currentField_modified}" />
                                        <span class="edit-button d-none d-xl-inline-block"
                                              [ngClass]="{'disabled': !currentField_modified}">Save</span>
                                    </button>
                                </div>

                                <div class="col">
                                    <button (click)="onCancel_field_edit()"
                                            id="action-edit-field-cancel">
                                        <img src="assets/Content/Images/Icons/cancel.png"
                                             class="edit-button-image" />
                                        <span
                                              class="edit-button d-none d-xl-inline-block">Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </dx-popup>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="assessment"></facnm-action-buttons>

        </div>

    </div>

</div>

<dx-popover target="#tooltip-conditions-info"
            container="#assessment"
            showEvent="dxhoverstart">
    <div *dxTemplate="let data of 'content'">

        <tooltip-conditions-info-assessment>
        </tooltip-conditions-info-assessment>

    </div>
</dx-popover>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>