import { HttpClient } from '@angular/common/http';
import {ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FleetManagementInterface, Fleet } from './fleet-management.model';
import { FleetManagementService } from './fleet-management.service';
import { dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
  selector: 'facnm-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.scss'],
  providers: [
    {
      provide: FleetManagementInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new FleetManagementService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class FleetManagementComponent implements OnInit {

  constructor(
    private FleetManagement$: FleetManagementInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  isAddForm = 0;
  entityName = 'Fleet Management';
  entityAddName = 'Fleet';
  entityData: Entity<Fleet>;
  newEntityData: Fleet;
  newEntity: any;
  VCollapse = false;
  DCollapse = false;
  collapseColor = 'green';

  ngOnInit(): void {
    this.actions();
    this.getList();
    this.app$.setScreenTitle(this.entityName);
    this.getNewEntityData();
  }
  actions() {
    this.FleetManagement$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ["Id"]
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.FleetManagement$.getList().subscribe(listWithCreated => {
      this.FleetManagement$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }
  getNewEntityData() {
    this.FleetManagement$.getEntity({ Id: 0 }).subscribe((res: any) => {
      this.newEntityData = res;
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.FleetManagement$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = {
      Id: this.entityData.value.Id,
    };
    this.FleetManagement$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.value.Id = 0;
    this.entityData.value.Name = "";
    this.entityData.value.AssignedDrivers = [];
    this.entityData.value.AssignedVehicles = [];
    this.entityData.created = true;
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    this.changeDetectorRef.detectChanges();
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Fleets Found";
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.FleetManagement$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.refreshGrid();
      this.isAddForm = 0;
      this.collapseColor = 'green';
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }

    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }
  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
