import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from '@app/app.service';
import { LoginService } from './login.service';
import { LoginSSOService } from '../login-sso/login-sso.service';
import { Router } from "@angular/router";
import { PdiLoaderService } from '@pdi/platform-angular-template';


@Component({
  selector: 'facnm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  entityName = "Login";
  error: string | undefined;
  loginForm = new FormGroup({
    username: new FormControl(null, (un) => !un.value ? { customError: true } : null),
    password: new FormControl(null, (pwd) => !pwd.value ? { customError: true } : null),
    company: new FormControl(null, (env) => !env.value ? { customError: true } : null)
  });

  constructor(private router$: Router, private app$: AppService,
    private loginService$: LoginService,
    private loginSSOService$: LoginSSOService,
    private el: ElementRef,
    private loadingService: PdiLoaderService
  ) { }
  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }
  get company() { return this.loginForm.get('company'); }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.app$.notifyScreenReady();
    this.app$.updateUserInfo({ isAuthenticated: false });
    this.app$.getAPIVersion();
    this.loginSSOService$.InitializeAuthService();
  }

  ngAfterViewInit(){
    this.el.nativeElement.querySelectorAll('input')[2].setAttribute('id', 'company');
  }

  onSubmit(loginForm) {
    this.error = "";
    this.loginForm.markAllAsTouched();
    if (loginForm.valid) {
      this.loadingService.openGlobalLoader();
      this.app$.notifyScreenBusy();
      this.loginService$.authentincate(loginForm.value).subscribe(
        (res: any) => {
          sessionStorage.setItem("Bearer", res.Token);
          this.app$.updateUserInfo({ isAuthenticated: true });
          this.app$.getUserDetails();
          this.app$.getAPIVersion();
          this.router$.navigate(['home']);
          this.loadingService.closeGlobalLoader();
        },
        (err) => {
          this.app$.notifyScreenReady();
          this.error = err.message;
          this.loadingService.closeGlobalLoader();
        }
      );
    }
  }

  submitSSOLogin(){
    this.app$.notifyScreenBusy();
    this.loadingService.openGlobalLoader();
    var redirectUrl = window.location.href.replace("login", "loginSSO");
    this.loginSSOService$.authService.authenticate(redirectUrl).then(
      (res: any) => {
        sessionStorage.setItem("SSOToken", res.accessToken);
        this.app$.redirectTo('loginSSO');
        this.loadingService.closeGlobalLoader();
      },
      (err) => {
        this.app$.notifyScreenReady();
        this.error = err.message;
        this.loadingService.closeGlobalLoader();
      }
    );
  }

  isSSOUrlLoaded() {
    if(LoginSSOService.ssoAuthenticationUrl) {
      return true;
    }
    return false;
  }
}
