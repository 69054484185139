<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">


    <div id="entity-errors"
         class="row m-1">
        <div class="col">
            <facnm-errors></facnm-errors>
        </div>
    </div>


    <div id="screen-serverConfigurations"
         class="row">

        <div class="col">

            <div className="server-configurations">

                <table class="table">

                    <thead>
                        <tr>
                            <td class="col-1 text-center"><strong>Config Id</strong></td>
                            <td class="col-4"><strong>Description</strong></td>
                            <td class="col-4"><strong>Default Config Value</strong></td>
                            <td class="col-3"></td>
                        </tr>
                    </thead>

                    <tbody [style.height]="formHeight">

                        <tr *ngFor="let config of serverConfigurations">

                            <td class="col-1 text-center align-middle">
                                <span class="config-id">

                                    {{ config.Id }}

                                </span>
                            </td>

                            <td class="col-4 align-middle">

                                {{ config.Description }}

                            </td>

                            <td class="col-4 align-middle">

                                <ng-container *ngIf="config.Options.length == 0"
                                              [ngSwitch]="config.Type">

                                    <ng-container *ngSwitchCase="serverConfigurationType.bool">

                                        <dx-check-box [(value)]="config.Value"
                                                      (onValueChanged)="onChangeConfiguration(config)"></dx-check-box>

                                    </ng-container>


                                    <ng-container *ngSwitchCase="serverConfigurationType.float || serverConfigurationType.int">

                                        <input [(ngModel)]="config.Value"
                                               (ngModelChange)="onChangeConfiguration(config)"
                                               [ngClass]="{'modelstate-invalid': config.invalid}"
                                               type="text"
                                               class="form-custom-control"
                                               name="Value"
                                               placeholder="Value">

                                    </ng-container>


                                    <ng-container *ngSwitchDefault>

                                        <input [(ngModel)]="config.Value"
                                               (ngModelChange)="onChangeConfiguration(config)"
                                               class="form-custom-control"
                                               type="text" />

                                    </ng-container>

                                </ng-container>

                                <ng-container *ngIf="config.Options.length > 0  ">

                                    <pdi-dropdown [full]="true" *ngIf="config.Type != 'flags' "  [(ngModel)]="config.Value"
                                            (valueSelected)="onChangeConfiguration(config)">
                                        <pdi-dropdown-option *ngFor="let o of config.Options"
                                            [key]="o.IdString">
                                            {{o.Name}}
                                        </pdi-dropdown-option>
                                    </pdi-dropdown>

                                    <dx-tag-box  class="dx-fieldset" *ngIf="config.Type == 'flags' "  [disabled]="(config.Options.length ==0)"
                                        [items]="AllFlags"
                                        [value]="SelectedFlags"
                                        [acceptCustomValue]="false"
                                        [inputAttr]="Options"
                                        (onValueChanged)="onSelectedFlagsChanged($event,config)"
                                        [showSelectionControls]="true"
                                        >
                            </dx-tag-box>


                                </ng-container>
                            </td>

                            <td class="col-3 align-middle">

                                <button type="button"
                                        class="btn-icon-action"
                                        (click)="saveConfiguration(config)"
                                        [disabled]="!config.modified">
                                    <img src="assets/Content/Images/Icons/save.png"
                                         class="edit-button-image"
                                         [ngClass]="{'disabled': !config.modified}" />
                                </button>

                                <button type="button"
                                        class="btn-icon-action"
                                        (click)="resetConfiguration(config)"
                                        [disabled]="!config.modified">
                                    <img src="assets/Content/Images/Icons/cancel.png"
                                         class="edit-button-image"
                                         [ngClass]="{'disabled': !config.modified}" />
                                </button>

                            </td>

                        </tr>

                    </tbody>

                </table>

            </div>

        </div>

    </div>

</div>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>