import { Injectable } from '@angular/core';
import { SafetyCheckInterface, safetyCheckGroup } from './safety-check.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SafetyCheckService implements SafetyCheckInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  getSafetyCheckTextGroups(): Observable<safetyCheckGroup[]> {
    return this._http.get<safetyCheckGroup[]>(e.endpoint.safetycheck + '/safetycheckgroup')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getSafetyCheckForCustomer(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforcustomer/' + arg.companyId + "/" + arg.customerTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getSafetyCheckForLogin(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforlogin/' + arg.companyId + "/" + arg.vehicleTypeId + "/" + arg.dataAreaId + "/" + arg.vehicleId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getSafetyCheckForLogout(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforlogout/' + arg.companyId + "/" + arg.vehicleTypeId + "/" + arg.dataAreaId + "/" + arg.vehicleId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  
  getSafetyGlobalMandatoryStates(): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/globalmandatorystates')
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getSafetyMandatoryStates(): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/mandatorystates')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getSafetyCheckForProduct(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforproduct/' + arg.companyId + "/" + arg.productTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getSafetyCheckForLoadingProduct(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforloadingproduct/' + arg.companyId + "/" + arg.productTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getSafetyCheckForVehicleInspection(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforvehicleinspection/' + arg.companyId + "/" + arg.vehicleTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getSafetyCheckForOthers(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/safetycheckforothers/' + arg.companyId + "/" + arg.groupTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewSafetyCheckForVehicleInspection(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/newsafetycheck/vehicleinspection/' + arg.companyId + "/" + arg.vehicleTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewSafetyCheckForOthers(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/newsafetycheck/others/' + arg.companyId + "/" + arg.groupTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getDataAreaList() {
        return this._http.get<any>(e.endpoint.products.dataArea + '/list/1')
        .pipe(catchError(this.errors$.handleNetworkError));
  }
  getDevicesList(arg) {
    return this._http.get<any>(e.endpoint.device + "/"+ arg.companyId)
    .pipe(catchError(this.errors$.handleNetworkError));
}

  getNewSafetyCheckForCustomer(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/newsafetycheck/customer/' + arg.companyId + "/" + arg.customerTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getNewSafetyCheckForLogin(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + "/newsafetycheck/login/" + arg.companyId + "/" + arg.vehicleTypeId + "/" + arg.dataAreaId + "/" + arg.vehicleId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getNewSafetyCheckForLogout(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/newsafetycheck/logout/' + arg.companyId + "/" + arg.vehicleTypeId + "/" + arg.dataAreaId + "/" + arg.vehicleId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewSafetyCheckForProduct(arg: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.safetycheck + '/newsafetycheck/product/' + arg.companyId + "/" + arg.productTypeId)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  saveSafetyCheckForCustomer(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforcustomer",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }
  
  saveSafetyCheckForLogin(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforlogin",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }
  saveSafetyCheckForLogout(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforlogout",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  saveSafetyCheckForProduct(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforproduct",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  saveSafetyCheckForLoadingProduct(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforloadingproduct",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  saveSafetyCheckForOthers(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforothers",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  saveSafetyCheckForVehicleInspection(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.safetycheck + "/savesafetycheckforvehicleinspection",
      data)
      .pipe(catchError(this.errors$.handleNetworkError));
  }

  deleteSafetyCheck(param: any): Observable<any> {
    return this._http.delete(e.endpoint.safetycheck + '/deletesafetycheck/' + param.companyId + "/" + param.textId + "/" + param.safetyCheckGroupId, {
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

}
