import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";

export class PrintText {
    Id: number;
    Text: string;

    References: PrintTextReference[];
}

export class PrintTextReference {

    ReferenceType: number;
    TextType: number;

    NumberOfLines: number;

    extID1: number;
    extID2: number;
    extID3: number;
    extID4: number;

    ClientId: number;
    ClientDeleted: boolean;

    constructor() {
        this.extID1 = 0;
        this.extID2 = 0;
        this.extID3 = 0;
        this.extID4 = 0;
        this.TextType = 1;
        this.NumberOfLines = 0;
    }
}

export enum PrintTextReferenceTypeEng {
    Product = 1,
    Company = 4,
    CustomerPrintType,
    CompanyVehicleTypeId,
    CompanyTemplateType,
    CustomerTaxGroup,
    SafetyCheck,
    ProductTextAtConfigurablePosition
}

@Injectable()
export abstract class PrintTextsInterface implements EntityInterface<PrintText> {

    create: (data: Entity<PrintText>) => Observable<any>;
    save: (data: Entity<PrintText>) => Observable<any>;
    delete: (data: any) => Observable<any>;

    getList: () => Observable<PrintText[]>;
    getEntity: (key: any) => Observable<PrintText>;

    ListRetrieved: Observable<PrintText[]>;
    notifyRetrieved: (data: PrintText[]) => void;

    getPrintTextReference: (printTextReference: PrintTextReference) => Observable<PrintTextReference>;

    getPrintTextTypes: () => Observable<Enum[]>;
    getPrintTextReferenceData: (printTextReference: PrintTextReference) => Observable<any>;

    getSafetySummaryTypes: () => Observable<any>;

}
