import { Injectable } from '@angular/core';
import { ProductBlendsInterface, ProductBlend } from './product-blends.model';
import { Entity } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class ProductBlendsService implements ProductBlendsInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    create(data: Entity<ProductBlend>): Observable<any> {
        return this._http.post<ProductBlend>(
            e.endpoint.products.blends,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }


    save(data: Entity<ProductBlend>): Observable<any> {

        return this._http.put<ProductBlend>(
            e.endpoint.products.blends,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }


    delete(data: any): Observable<any> {

        return this._http.request('delete',
            e.endpoint.products.blends,
            {
                body: data
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    private listRetrieved: BehaviorSubject<ProductBlend[]> = new BehaviorSubject<ProductBlend[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<ProductBlend[]> {

        return this._http.get<ProductBlend[]>(e.endpoint.products.blends)
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: ProductBlend[]): void {
        this.listRetrieved.next(data);
    }
    
    getEntity: (key: any) => Observable<ProductBlend>;
}
