import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";

export class TimeLog {
  Id: number;
  Name: string;
  Number: number;
  IsEditable: boolean;
  TimeLogId: number;
  TimeLogName: string;
  TimeLogType: number
  WorkType: number;
}

@Injectable()
export abstract class TimeLogInterface implements EntityInterface<TimeLog> {
  create: (data: Entity<TimeLog>) => Observable<any>;
  save: (data: Entity<TimeLog>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getList: () => Observable<TimeLog[]>;
  getEntity: (key: any) => Observable<TimeLog>;
  ListRetrieved: Observable<TimeLog[]>;
  notifyRetrieved: (data: TimeLog[]) => void;

  getNewEntity: () => Observable<TimeLog>;
  getTimeLogTypeList: () => Observable<any>;

}