<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">

    <div id="tax-rate"
         class="row h-100">

        <div class="col-6">

            <div id="entity-list"
                 class="h-100">

                <dx-data-grid #dataGrid
                              [dataSource]="taxRatesStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>

                    <dxi-column dataField="Id"
                                [width]="80"></dxi-column>
                    <dxi-column dataField="TaxGroup"
                                [width]="100"></dxi-column>
                    <dxi-column dataField="Rate"
                                [width]="80"></dxi-column>
                    <dxi-column dataField="Type"
                                [width]="120">
                        <dxo-lookup [dataSource]="taxRateTypes"
                                    valueExpr="id"
                                    displayExpr="name">
                        </dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="Description"></dxi-column>


                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="tax-rates.dataGrid"></dxo-state-storing>
                </dx-data-grid>

            </div>

        </div>

        <div id="entity-detail"
             class="col-6 pr-0">

            <div *ngIf="taxRate && taxRate.key">


                <form id="entity-form"
                      #taxRateForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">

                        <div id="entity-errors"
                             class="row">
                            <div class="col">

                                <facnm-errors></facnm-errors>

                            </div>
                        </div>

                        <div id="entity-fields"
                             class="row">

                            <div class="entity-fields-column col-lg-6 col-md-12">

                                <div class="container">


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Id"
                                                   class="col-form-label">Id</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="taxRate.value.Id"
                                                   [ngClass]="{
                                                       'modelstate-invalid': !Id.dirty && isInvalid('Id'),
                                                       'form-control-disabled': !taxRate.created
                                                    }"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="Id"
                                                   #Id="ngModel"
                                                   [readonly]="!taxRate.created">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!Id.dirty && isInvalid('Id')">
                                                <small class="error">
                                                    {{getError('Id')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="TaxGroup"
                                                   class="col-form-label">Tax Group</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="taxRate.value.TaxGroup"
                                                   [ngClass]="{
                                                        'modelstate-invalid': !TaxGroup.dirty && isInvalid('TaxGroup'),
                                                        'form-control-disabled': !taxRate.created
                                                    }"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="TaxGroup"
                                                   #TaxGroup="ngModel"
                                                   [readonly]="!taxRate.created">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!TaxGroup.dirty && isInvalid('TaxGroup')">
                                                <small class="error">
                                                    {{getError('TaxGroup')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Type"
                                                   class="col-form-label">Type</label>
                                        </div>
                                        <div class="col-8">
                                            <pdi-dropdown [full]="true" [(ngModel)]="taxRate.value.Type" #Type="ngModel" 
                                            [ngClass]="{'modelstate-invalid': !Type.dirty && isInvalid('Type'),'form-control-disabled': !taxRate.created }"
                                            name="Type" id="taxType" [isDisabled]="!taxRate.created">
                                                <pdi-dropdown-option *ngFor="let t of taxRateTypes" [key]="t.id">
                                                    {{t.name}}
                                                </pdi-dropdown-option>
                                            </pdi-dropdown>

                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!Type.dirty && isInvalid('Type')">
                                                <small class="error">
                                                    {{getError('Type')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="TaxClass"
                                                   class="col-form-label">Tax Class</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="taxRate.value.TaxClass"
                                                   [ngClass]="{
                                                        'modelstate-invalid': !TaxClass.dirty && isInvalid('TaxClass'),
                                                        'form-control-disabled': !taxRate.created
                                                    }"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="TaxClass"
                                                   #TaxClass="ngModel"
                                                   [readonly]="!taxRate.created">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!TaxClass.dirty && isInvalid('TaxClass')">
                                                <small class="error">
                                                    {{getError('TaxClass')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <div class="entity-fields-column col-lg-6 col-md-12">

                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="SequenceNo"
                                                   class="col-form-label">Sequence No.</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="taxRate.value.SequenceNo"
                                                   [ngClass]="{'modelstate-invalid': !SequenceNo.dirty && isInvalid('SequenceNo')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="SequenceNo"
                                                   #SequenceNo="ngModel">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!SequenceNo.dirty && isInvalid('SequenceNo')">
                                                <small class="error">
                                                    {{getError('SequenceNo')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Rate"
                                                   class="col-form-label">Rate</label>
                                        </div>
                                        <div class="col-8">
                                            <input [(ngModel)]="taxRate.value.Rate"
                                                   [ngClass]="{'modelstate-invalid': !Rate.dirty && isInvalid('Rate')}"
                                                   type="text"
                                                   class="form-custom-control control-look-input"
                                                   name="Rate"
                                                   #Rate="ngModel">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!Rate.dirty && isInvalid('Rate')">
                                                <small class="error">
                                                    {{getError('Rate')}}
                                                </small>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Description"
                                                   class="col-form-label">Description</label>
                                        </div>
                                        <div class="col-8">

                                            <textarea [(ngModel)]="taxRate.value.Description"
                                                      class="form-custom-control control-look-input"
                                                      name="Description"
                                                      autosize
                                                      [maxRows]="10"></textarea>

                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="taxRate"></facnm-action-buttons>

        </div>

    </div>

</div>


<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>