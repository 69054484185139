import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { ActionsService } from './actions.service';
import { AppService } from '@app/app.service';
import { Entity } from '@app/app.model';
import { ErrorsService } from '../errors/errors.service';


@Component({
    selector: 'facnm-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {

    @Input() modelEntity: Entity<any>;
    @Input() hideAdd: boolean;
    @Input() hideDelete: boolean;
    @Input() entityName: string;
    @Input() disableDelete: boolean = false;

    constructor(private actions$: ActionsService, private app$: AppService, private errors$: ErrorsService) { }

    ngDoCheck(): void {
        if (this.modelEntity) {
            this.modelEntity.modified = JSON.stringify(this.modelEntity.value) !== JSON.stringify(this.modelEntity.original);
        }
    }

    ngOnInit() {
    }


    create() {
        this.actions$.notifyCreating();
    }

    cancel() {
        this.actions$.notifyCanceling();
    }

    save() {
        this.actions$.notifySaving();
    }

    reset() {
        this.actions$.notifyResetting();
    }

    delete() {
        this.actions$.notifyDeleting();
    }

    addNewEntity() {
        this.actions$.notifyAdding();
    }
}
