import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { vehicleInterface, vehicle } from './vehicle.component.model';
import { vehicleService } from './vehicle.component.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {displayZeroValueText, dxSelectOptions, refreshElement} from '@app/common_helper';
import _ from 'lodash';

@Component({
  selector: 'facnm-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  providers: [
    {
      provide: vehicleInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new vehicleService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class VehicleComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  private arrguments = {};
  boxConfigList = [];
  vehicleBuildTypesAll = [];
  vehicleClasses = {};
  companyList = {};
  nonprintcompanyList = {};
  compartmentSetList = [];
  loadPointList = [];
  deviceTypeList = {};
  deviceConfigList = {};
  deviceStateList = {};
  dataAreaList = {};
  timezoneNameList = {};
  timezoneValueList = {};
  trailerStatesList = {};
  vehicleTypes = [];
  SpecialActions = {};
  vehicleData: ArrayStore;
  vehicle: Entity<vehicle>;
  entityName = 'Vehicle';
  testString = 'Test';
  vehicleDeleteConfirm = false;
  vehicleDeleteConfirmMsg = 'Are you sure you want to delete?';
  compartmentDeleteConfirm = false;
  compartmentDeleteConfirmMsg = 'Are you sure, you want to delete this compartment set?';
  collapseColor = 'green';
  truckTrailerLookup = [
    { IsTruck: 1, Text: 'Truck' },
    { IsTruck: 0, Text: 'Trailer' }
  ];
  hideVehicleIdcolumn: boolean = false;
  allMode: string;
  checkBoxesMode: string;
  isFilterOptionsOpen: boolean = true;
  filterOptions = {
    VehicleType: null, BuildType: null, ClassId: null, CarrierId: null, DeviceTypeId: null,
    DeviceConfigId: null, BoxConfigId: null, UseIgnition: null, DataAreaId: null, TimezoneName: null, State: null,
    VehicleColorHash: null, TankCapacity: null, CompartmentSetId: null, MotorSpeedLimit: null, CountrySpeedLimit: null,
    ResidentialSpeedLimit: null, HomeLatitude: null, HomeLongitude: null, HomeLoadlocationId: null
  };
  min: number = 1;
  max: number = 999;
  GICollapse = false;
  DSCollapse = false;
  TDCollapse = false;
  SLCollapse = false;
  HLCollapse = false;
  SSACollapse = false;
  isTruck = "false";
  isTrailer: boolean = false;
  isAddForm = 0;
  saveProgress: boolean;
  SelectedReceiptRange = [];
  SelectedReceiptRangeCopy = [];
  SelectedSpecialAction = {};
  multipleSelected = { count: 0, vehiclesList: [] };
  newvehicle: vehicle;
  VehicleTypesList = [];
  VehicleTypeSubmitted = false;
  saveCategoryProgress: boolean;
  deleteProCateItem: any;
  deleteProCateItemIndex: any;
  VehicleTypeDeleteConfirm = false;
  compartmentList = [];
  compartmentSetToDelete = {};
  selectedCompartmentSetId: any;
  compartmentSetSubmitted = false;
  initialReceiptRange = { CompanyId: null };
  vehicleBuildTypes = [];
  vehicleBuildTypeTruck = [];
  vehicleBuildTypeTrailer = [];
  isDeleteEnable = "false";
  searchFields = [];
  vehicleColumns: any;
  visibleReceiptNumberConflict: boolean = false;
  ConflictingDevices = [];
  visibleReceiptNumber: boolean = false;
  defaultCompartment = [{Id: null, Name:"Select Option"}];
  isCompartmentSetFormInvalid = false;
  isVehicleTypeFormInvalid = false;
  SetIDManually:boolean = false;
  vehicleInUse: boolean = false;
  attentionMsg = 'This vehicle is in use! Please ensure that any TransPac devices using this device id have been uninstalled, or moved to a different device id, before performing the deletion. It is recommended to switch vehicles and trailers to an inactive state instead of deleting them once they have been in use.'
  dxSelectOptions = new dxSelectOptions();

  constructor(
    private vehicle$: vehicleInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    public ngxSmartModalService: NgxSmartModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.searchFields = ['City', 'State'];
    vehicle$.ListRetrieved.subscribe(data => {
      if (data) {
        this.vehicleData = new ArrayStore({
          data: data,
          key: ['Id']
        });
        if (data.length == 0) {
          this.dataGrid.noDataText = 'No vehicle Found';
        }
        else {
          this.dataGrid.noDataText = '';
        }
      }
    });

    this.arrguments = {
      boxConfigs: this.vehicle$.getVehicleBoxConfig(),
      buildtypes: this.vehicle$.getVehicleBuildTypes(),
      vehicleClasses: this.vehicle$.getVehicleClasses(),
      companies: this.vehicle$.getCompanyList(),
      nonPrintCompanies: this.vehicle$.getNonPrintCompanyList(),
      compartmentSets: this.vehicle$.getCompartmentSetList(),
      loadPoints: this.vehicle$.getLoadPointList(),
      deviceTypes: this.vehicle$.getDeviceTypeList(),
      deviceConfigs: this.vehicle$.getDeviceConfigList(),
      deviceStates: this.vehicle$.getDeviceStateList(),
      dataAreas: this.vehicle$.getDataAreaList(),
      timezoneNames: this.vehicle$.getTimeZones(),
      timezoneValues: this.vehicle$.getTimeZoneValues(),
      trailerStates: this.vehicle$.getTrailerStates(),
      vehicleTypes: this.vehicle$.getVehicleTypes(),
      SpecialActions: this.vehicle$.getSpecialActions(),
      vehicles: this.vehicle$.getList(),
      newVehicle: this.vehicle$.getNewVehicle()
    };

    actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

    errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;
  @ViewChild('VehicleTypeForm') VehicleTypeForm: NgForm;
  @ViewChild('compartmentSetForm') compartmentSetForm: NgForm;

  ngOnInit(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'onClick';
    this.app$.setScreenTitle(this.entityName);
    forkJoin(
      this.arrguments
    ).subscribe(
      multipleData => {
        this.boxConfigList = multipleData['boxConfigs'];
        this.vehicleBuildTypesAll = multipleData['buildtypes'];

        this.vehicleBuildTypeTruck = this.vehicleBuildTypesAll.filter((vbt) => { return vbt.Id < 2 });
        this.vehicleBuildTypeTrailer = this.vehicleBuildTypesAll.filter((vbt) => { return vbt.Id > 1 });

        this.vehicleClasses = multipleData['vehicleClasses'];
        this.companyList = multipleData['companies'];
        this.nonprintcompanyList = multipleData['nonPrintCompanies'];
        this.compartmentSetList = multipleData['compartmentSets'];
        this.loadPointList = multipleData['loadPoints'];
        this.deviceTypeList = multipleData['deviceTypes'];
        this.deviceConfigList = multipleData['deviceConfigs'];
        this.deviceStateList = multipleData['deviceStates'];
        this.dataAreaList = multipleData['dataAreas'];
        this.timezoneNameList = multipleData['timezoneNames'];
        this.timezoneValueList = multipleData['timezoneValues'];
        this.trailerStatesList = multipleData['trailerStates'];
        this.vehicleTypes = multipleData['vehicleTypes'];
        this.SpecialActions = multipleData['SpecialActions'];
        this.vehicle$.notifyRetrieved(multipleData['vehicles']);
        this.app$.notifyScreenReady();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );


    this.vehicleColumns = [
      {
        caption: 'Icon', dataField: 'IsTruck', alignment: 'left', width: '100px',
        cellTemplate: function (cellElement, cellInfo) {
          if (cellInfo.data.IsTruck == true) {
            $("<span />")
              .attr("class", "icon icon-fulltruck")
              .attr("style", "color:" + cellInfo.data.ColorHash + ";font-size:2em;")
              .appendTo(cellElement);
          }
          else {
            $("<span />")
              .attr("class", "icon-trailer")
              .attr("style", "font-size:2em;")
              .appendTo(cellElement);
          }
        },
        lookup: { dataSource: this.truckTrailerLookup, valueExpr: 'IsTruck', displayExpr: 'Text' }
      },
      { caption: 'Vehicle Id', dataField: 'Id', visible: false, dataType: 'number' },
      {
        caption: 'Device Id - Name', dataField: 'DeviceId', cssClass: 'white-space-normal', width:'35%', dataType: 'string', alignment: 'left',
        cellTemplate: function (container, options) {
          var devicedetails = "";
          if (options.data.DeviceId != 0) devicedetails = devicedetails + options.data.DeviceId + " - ";
          if (options.data.DeviceName != null) devicedetails = devicedetails + options.data.DeviceName;
          $('<span />').text(devicedetails).append("</br>").appendTo(container);
        },
        calculateCellValue: function (data) {
          return data.DeviceId + " - " + data.DeviceName;
        },
        calculateFilterExpression: function (filterValue, selectedFilterOperation) {
          return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
      },
      { alignment: 'left', caption: 'Vehicle No', dataField: 'VehicleNumber', cssClass: 'VehicleNumber', dataType: 'string' },
      {
        alignment: 'left', caption: 'License Plate', dataField: 'LicensePlate', cssClass: 'LicensePlate', dataType: 'string', width: 150,
        cellTemplate: function (container, options) {
          $('<span />')
            .text(options.data.LicensePlate)
            .appendTo(container);
        }
      }
    ];
  }

  onContentReadyAssigned() {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No data";
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  GetVehicleList() {
    this.vehicle$.getList().subscribe(res => {
      this.vehicle$.notifyRetrieved(res);
      this.app$.notifyScreenReady();
    }, (errorNetwork) => {
      this.errors$.handleServerErrors(errorNetwork);
    });
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.vehicle = new Entity();
    this.vehicle.value = { ...this.newvehicle };
    this.vehicle.created = true;
    this.isTruck = "true";
    this.isAddForm = 1;
    this.collapseColor = 'yellow';
    this.vehicle.value.deviceDetailsVM = { Id: 0 };
    this.vehicle.value.CompartmentSet = null;
    this.initialReceiptRange.CompanyId = null;
    this.SelectedReceiptRange = [];
    this.TruckTrailerChange(true);
    this.changeDetectorRef.detectChanges();
  }

  private deleteConfirm() {
    var vehicleId = this.vehicle.value.Id;
    var deviceId = this.vehicle.value.deviceDetailsVM != null ? this.vehicle.value.deviceDetailsVM.Id : null;
    var param = {
      Id: vehicleId,
      DeviceId: deviceId
    };

    this.vehicle$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.refreshGrid();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
        this.GetVehicleList();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }
  private create(isSave) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    
    if (this.isTruck == 'true' && !_.isEqual(this.SelectedReceiptRange,this.SelectedReceiptRangeCopy) && this.vehicle.value.Id > 0) {
      this.visibleReceiptNumber = true;
      setTimeout(() => { this.app$.notifyScreenReady(); })
    }
    else
      this.saveVehicle(isSave);
  }

  saveVehicle(isSave){

    this.vehicle.value.IsTrailerGpsBox = this.isTrailer;

    if (this.multipleSelected.count > 1) {
      this.vehicle$.saveMultipleVehicles(this.vehicle, this.multipleSelected.vehiclesList).subscribe((res) => {
        this.app$.setScreenTitle(this.entityName);
        this.actions$.notifySaved();
        this.GetVehicleList();
        this.deselectvehicle();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
        (errorCreate) => {
          this.errors$.handleServerErrors(errorCreate);
        });
    }
    else {
      this.vehicle$.create(this.vehicle).subscribe(
        (okCreate) => {
          if (okCreate.ConflictingResult.length > 0) {
            this.app$.notifyScreenReady();
            this.ConflictingDevices = okCreate.ConflictingResult;
            this.visibleReceiptNumberConflict = true;
          }
          else {
            this.app$.setScreenTitle(this.entityName);
            isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
            this.GetVehicleList();
            this.visibleReceiptNumberConflict ? this.cancelPopup() : this.deselectvehicle();
            this.refreshGrid();
            this.app$.notifyScreenReady();
          }
        },
        (errorCreate) => {
          this.errors$.handleServerErrors(errorCreate);
        }
      );
    }
  }
  private reset() {
    this.vehicle.restore();
    if(!this.vehicle.value.deviceDetailsVM)
      this.vehicle.value.deviceDetailsVM = { Id:0 };
      
    this.SetIDManually = false;
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deselectvehicle();
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  TruckTrailerChange(boolvalue) {
    if (boolvalue) {
      this.isTruck = "true";
      this.isTrailer = false;
      this.vehicleBuildTypes = this.vehicleBuildTypeTruck;
      this.vehicle.value.BuildType > 1 ? this.vehicle.value.BuildType = null : "";
    }
    else if (!boolvalue) {
      this.isTruck = "false";
      this.vehicleBuildTypes = this.vehicleBuildTypeTrailer;
      if (this.vehicle.value.BuildType == null || this.vehicle.value.BuildType < 2) this.vehicle.value.BuildType = 2;
      if (this.vehicle.value.deviceDetailsVM?.Id > 0) this.isTrailer = true;
    }
  }

  resetfilterOptions() {
    this.filterOptions = {
      VehicleType: null, BuildType: null, ClassId: null, CarrierId: null, DeviceTypeId: null,
      DeviceConfigId: null, BoxConfigId: null, UseIgnition: null, DataAreaId: null, TimezoneName: null, State: null,
      VehicleColorHash: null, TankCapacity: null, CompartmentSetId: null, MotorSpeedLimit: null, CountrySpeedLimit: null,
      ResidentialSpeedLimit: null, HomeLatitude: null, HomeLongitude: null, HomeLoadlocationId: null
    };

    this.GetVehicleList();
  }

  filterVehicles() {
    this.vehicle$.getFilteredVehicleList(this.filterOptions).subscribe(res => {
      this.vehicle$.notifyRetrieved(res);
      this.app$.notifyScreenReady();
    }, (errorNetwork) => {
      this.errors$.handleServerErrors(errorNetwork);
    });
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onCellPrepared(e: { rowType: string; column: { dataField: string; }; data: { IsTruck: any, ColorHash: any, DeviceId: any, DeviceName: any, Address: any; }; cellElement: { innerHTML: string; }; }) {
    if (e.rowType === "data" && e.column.dataField === "IsTruck") {
      if (e.data.IsTruck)
        e.cellElement.innerHTML = '<span class="icon icon-fulltruck" style="color:' + e.data.ColorHash + ';font-size:2em;"></span>';
      else
        e.cellElement.innerHTML = '<span class="icon icon-trailer" style="font-size:2em;"></span>';


    }

    if (e.rowType === "data" && e.column.dataField === "DeviceId") {
      let devicedetails: string = "";
      if (e.data.DeviceId != 0) devicedetails = devicedetails + e.data.DeviceId + " - ";
      if (e.data.DeviceName != null) devicedetails = devicedetails + e.data.DeviceName;

      e.cellElement.innerHTML = '<span>' + devicedetails + '<br></span>';


    }
  }

  onSelectionChanged($clikedRow: any) {
    this.multipleSelected.vehiclesList = [];
    this.multipleSelected.count = $clikedRow.selectedRowsData.length;
    this.isDeleteEnable = "true";

    if ($clikedRow.selectedRowsData.length > 1) {
      this.multipleSelected.vehiclesList = cloneDeep($clikedRow.selectedRowsData);
      this.vehicle = new Entity();
      this.vehicle.value = { ...this.newvehicle };
      this.vehicle.key = -1;
      this.vehicle.value.deviceDetailsVM = { Id: 0 };
      this.isDeleteEnable = "false";
      this.changeDetectorRef.detectChanges();
    }
    else if (this.multipleSelected.count == 1) {
      if (!this.vehicle) {
        try {
          let keySaved = $clikedRow.selectedRowKeys[0];
          if (keySaved) {
            this.selectvehicle(keySaved);
          }
        }
        catch { }
        this.isDeleteEnable = "true";
      }
      else if (this.vehicle && this.vehicle.key != $clikedRow.selectedRowKeys[0]) {
        try {
          let keySaved = $clikedRow.selectedRowKeys[0];
          if (keySaved) {
            this.selectvehicle(keySaved);
          }
        }
        catch { }
      }
    }
    else {
      this.multipleSelected.count = 0;
      this.isDeleteEnable = "true";
    }

  }

  onRowClick($clikedRow: { key: any; }) {
    if (this.multipleSelected.count > 1) {
      return;
    }

    if (this.entityForm?.form.invalid)
      this.vehicle.key = undefined;
    this.app$.setScreenTitle(this.entityName);
    if (!this.vehicle){
      this.vehicle = new Entity();
      this.changeDetectorRef.detectChanges();
    }
    if (JSON.stringify(this.vehicle.key) === JSON.stringify($clikedRow.key)) {
      this.deselectvehicle();
    }
    else {
      this.selectvehicle($clikedRow.key);
    }
  }

  private deselectvehicle() {
    this.dataGrid.instance.deselectRows(this.vehicle.key);
    this.vehicle.key = undefined;
  }

  private selectvehicle(key: any) {
    this.actions$.notifyEntityBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';

    this.vehicle$.getVehicleDetails(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.vehicle = new Entity();
      this.vehicle.key = key;
      this.vehicle.value = cloneDeep(res);
      
      if (this.vehicle.value.deviceDetailsVM) {
        this.vehicleInUse = this.vehicle.value.deviceDetailsVM.State == 1;
        this.initialReceiptRange.CompanyId = this.vehicle.value.deviceDetailsVM.RangeVMs[0].CompanyId;
        this.SelectedReceiptRange = this.vehicle.value.deviceDetailsVM.RangeVMs[0];
        this.SelectedReceiptRangeCopy = cloneDeep(this.SelectedReceiptRange);
      }
      else {
        this.vehicle.value.deviceDetailsVM = {};
        this.initialReceiptRange.CompanyId = null;
        this.SelectedReceiptRange = [];
        this.SelectedReceiptRangeCopy = [];
      }

      if (this.vehicle.value.BuildType != null) {
        if (this.vehicle.value.BuildType < 2)
          this.TruckTrailerChange(true);
        else this.TruckTrailerChange(false);
      }
      else { // if null then it's a truck                   
        this.TruckTrailerChange(true);
      }
      this.changeDetectorRef.detectChanges();
      if(this.vehicle.value.deviceDetailsVM.CompanyId == 0){
        displayZeroValueText(this.nonprintcompanyList,'companyId','Id','Name');
      }
      if(this.vehicle.value.deviceDetailsVM.State == 0){
        displayZeroValueText(this.deviceStateList,'stateId','Id','Name');
      }
      if(this.vehicle.value.CarrierId == 0){
        displayZeroValueText(this.companyList,'carrierId','Id','Name');
      }
      if(this.vehicle.value.State == 0){
        displayZeroValueText(this.trailerStatesList,'trailerStateId','Id','Name');
      }
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  openModal() {
    this.VehicleTypeSubmitted = false;
    this.saveCategoryProgress = false;
    this.VehicleTypesList = cloneDeep(this.vehicleTypes);
    this.ngxSmartModalService.open('VehicleType');
  }


  addNewVehicleTypeRow() {
    var newidcount = -1;
    for (var i = 0; i <= this.vehicleTypes.length - 1; i++) {
      if (this.vehicleTypes[i].Id <= 0)
        newidcount--;
    }
    this.VehicleTypesList.push({ Id: newidcount, Name: "", Color: "", ColorHash: "" });
  }

  removeVehicleType(index) {
    this.VehicleTypesList.splice(index, 1);
  }


  checkDeleteVehicleType(item, index) {
    if (item.Id < 0) {
      this.removeVehicleType(index);
    }
    else {
      this.vehicle$.deleteVehicleType(item.Id).subscribe(
        (res) => {
          if (res) {
            this.removeVehicleType(index);
          } else {
            this.VehicleTypeDeleteConfirm = true;
            this.deleteProCateItem = item;
            this.deleteProCateItemIndex = index;
          }
        }, (error) => {
          this.actions$.notifyEntityCustomAction({type:'error', title: 'Error:', message: error.message });
        })
    }
  }

  closeVehicleType() {
    this.ngxSmartModalService.close('VehicleType');
  }

  saveVehicleType() {
    this.VehicleTypeSubmitted = true;
    if (!this.VehicleTypeForm.form.invalid) {
      this.isVehicleTypeFormInvalid = false;
      this.saveCategoryProgress = true;
      this.vehicle$.saveVehicleTypes(this.VehicleTypesList).subscribe((res) => {
        this.closeVehicleType();
        this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Vehicle type saved successfully' });
        this.vehicle$.getVehicleTypes().subscribe((VT) => {
          this.vehicleTypes = [];
          this.vehicleTypes = VT;
          refreshElement(this,'vehicleTypeLoading');
          this.vehicle.value.TypeId ? "" : this.vehicle.value.TypeId = 0;
        }, (errorCreate) => { this.errors$.handleServerErrors(errorCreate); });
      }, (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      })
    }
    else {
      this.isVehicleTypeFormInvalid = true;
    }
  }



  openCompartmentSetModal() {
    this.VehicleTypeSubmitted = false;
    this.saveCategoryProgress = false;
    this.ngxSmartModalService.open('compartmentSet');
    this.selectedCompartmentSet(0, this.compartmentSetList[0]);
  }

  selectedCompartmentSet(index, item) {
    this.selectedCompartmentSetId = index;
    this.compartmentList = item.Compartments;
  }

  closeCompartment() {
      this.compartmentList = [];
      let compartmentSetList = this.compartmentSetList.filter((cmp) => {
        cmp.Compartments = cmp.Compartments.filter((cm)=> { return cm.Id > 0});
        return cmp.Id > 0 
      })
      this.compartmentSetList = [];
      this.compartmentSetList = compartmentSetList;
      this.ngxSmartModalService.close('compartmentSet');
  }

  addNewCompartmentSet() {
    var newidcount = -1;
    for (var i = 0; i <= this.compartmentSetList.length - 1; i++) {
      if (this.compartmentSetList[i].Id <= 0)
        newidcount--;
    }
    let NewCompartmentSet = { Id: newidcount, Name: "", Compartments: [] }
    this.compartmentSetList.unshift(NewCompartmentSet);
    let compartmentSetListCopy = cloneDeep(this.compartmentSetList);
    this.compartmentSetList = [];
    this.compartmentSetList = compartmentSetListCopy;  
    this.compartmentList  = [];
  }

  deleteCompartmentSet(item, index) {
    if (item.Id <= 0) {
      this.compartmentSetList.splice(index, 1);
      this.compartmentList = [];
    }
    else if (item.Id > 0) {
      this.vehicle$.isCompartmentSetDeletable(item.Id).subscribe((data) => {
        if (data) { // if deletable then make a delete call
          this.vehicle$.deleteCompartmentSet(item.Id).subscribe((data) => {
            this.compartmentSetList.splice(index, 1);
            this.compartmentList = [];
          });
        }
        else { // else if not deletable then ask for confirmation.
          this.deleteProCateItem = item;
          this.deleteProCateItemIndex = index
          this.compartmentDeleteConfirm = true;
        }
      }, (errorCreate) => { this.errors$.handleServerErrors(errorCreate); });
    }
  }

  VehicleTypeDeleteClosed() {
    this.compartmentDeleteConfirm = false;
    this.deleteProCateItem = null;
    this.deleteProCateItemIndex = null;
  }

  VehicleTypeDeleteConfirmed() {
    this.compartmentDeleteConfirm = false;
    if (this.deleteProCateItem) {
      this.deleteCompartment(this.deleteProCateItem, this.deleteProCateItemIndex);
    }
  }

  deleteCompartment(item, index) {
    if (item.Id <= 0) {
      this.removeCompartment(index);
    }
    else{
      
      this.vehicle$.deleteCompartment(item.Id, item.SetId).subscribe((ok) => {
        this.removeCompartment(index);
      });
    }
  }

  removeCompartmentSet(index) {
    this.compartmentSetList.splice(index, 1);
  }

  removeCompartment(index) {
    this.compartmentList.splice(index, 1);
  }


  addNewCompartment() {
    var newidcount = -1;
    for (var i = 0; i <= this.compartmentList.length - 1; i++) {
      if (this.compartmentList[i].Id <= 0)
        newidcount--;
    }
    this.compartmentList.push({ Id: newidcount, Capacity: "" });
  }


  saveCompartments() {
    if (!this.compartmentSetForm.form.invalid) {
      this.isCompartmentSetFormInvalid = false;
      this.vehicle$.saveCompartmentSets(this.compartmentSetList).subscribe((res) => {
        this.vehicle$.getCompartmentSetList().subscribe((res)=>{
          this.compartmentSetList = [];
          this.compartmentSetList = res;
          this.ngxSmartModalService.close('compartmentSet');
        },(errorCreate) => { this.errors$.handleServerErrors(errorCreate); })
        this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Saved Compartment Sets successfully' });
      }, (errorCreate) => { this.errors$.handleServerErrors(errorCreate); });
    }
    else
      this.isCompartmentSetFormInvalid = true;
  }



  SubmitSpecialAction() {
    let vehicleList = this.multipleSelected.vehiclesList.length > 0 ? this.multipleSelected.vehiclesList : [this.vehicle.value.Id];
    this.vehicle$.saveSpecialAction(vehicleList, this.SelectedSpecialAction).subscribe(res => {
      this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Special Action implemented successfully' });
    }, (errorNetwork) => {
      this.errors$.handleCustomError("Special Action not implemented");
    });
  };

  UpdateReceiptRange(companyId) {
    if (this.vehicle.value.deviceDetailsVM != null) {
      if (companyId != null) {
        var result = this.vehicle.value.deviceDetailsVM.RangeVMs.filter(function (rvm) {
          return rvm.CompanyId == companyId;
        });
        this.SelectedReceiptRange = result[0];
        this.SelectedReceiptRangeCopy = cloneDeep(this.SelectedReceiptRange);
      }
      else if (this.initialReceiptRange != null) {
        var result = this.vehicle.value.deviceDetailsVM.RangeVMs.filter(function (rvm) {
          return rvm.CompanyId == this.initialReceiptRange.CompanyId;
        });
        this.SelectedReceiptRange = result[0];
        this.SelectedReceiptRangeCopy = cloneDeep(this.SelectedReceiptRange);
      }
    }
    else {
      this.SelectedReceiptRange = null;
      this.SelectedReceiptRangeCopy = null;
    }
  }

  receiptRangeChangingModal_onClick(sendReceiptRangeChange: boolean) {

    this.visibleReceiptNumber ? this.closePopup() : "";
    this.app$.notifyScreenBusy();
    if (sendReceiptRangeChange) {
      let vehicleList = this.multipleSelected.vehiclesList.length > 0 ? this.multipleSelected.vehiclesList : [this.vehicle.value.Id];

      
      this.vehicle$.saveSpecialAction(vehicleList, 1).subscribe((res) => {
        this.app$.exportVehicles(vehicleList).subscribe((res) => {
          this.saveVehicle(true);
          this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Special Action implemented successfully' });
        }, (errorCreate) => {
          this.errors$.handleCustomError("Vehicle Export failed");
          this.errors$.handleCustomError("Vehicle not updated");
        })
      }, (errorCreate) => {
        this.errors$.handleCustomError("Special Action not implemented");
        this.errors$.handleCustomError("Vehicle not updated");
      })

    }
    else {
      this.saveVehicle(true);
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  isNumberOnly(event) {
    return (event.charCode != 8 && event.charCode == 0 || (event.charCode == 46) || (event.charCode >= 48 && event.charCode <= 57))
  }


  cancelPopup() {
    this.vehicle.value.deviceDetailsVM.ignoreConflictingReceiptNumbers = false;
    this.visibleReceiptNumberConflict = false;
  }

  confirmPopup() {
    this.app$.notifyScreenBusy();
    this.vehicle.value.deviceDetailsVM.ignoreConflictingReceiptNumbers = true;
    this.saveVehicle(true);
  }

  loadPointDisplayExpr(item) {
    return item ? `${item.Name1} - ${item.Address}` : '';
  }

  closePopup(){
    this.visibleReceiptNumber = false;
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - 95);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

