<div #screen
     class="container-fluid h-100"
     (window:resize)="onResize($event)">
    <div id="screen-printText"
         class="row h-100">
        <div class="col-4">
            <div id="entity-list"
                 class="h-100">
                <dx-data-grid #dataGrid
                              [dataSource]="printTextsStore"
                              noDataText=""
                              (onInitialized)="onInitialized($event)"
                              (onSelectionChanged)="onSelectionChanged($event)"
                              (onRowClick)="onRowClick($event)"
                              [allowColumnResizing]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column dataField="Id"
                                [width]="80"></dxi-column>

                    <dxi-column dataField="Text"></dxi-column>


                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]"
                               [showPageSizeSelector]="true"
                               [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-state-storing [enabled]="true"
                                       type="localStorage"
                                       storageKey="print-text.dataGrid"></dxo-state-storing>
                </dx-data-grid>
            </div>
        </div>

        <div id="entity-detail"
             class="col pr-0">

            <div *ngIf="printText && printText.key">

                <form id="entity-form"
                      #printTextForm="ngForm"
                      [style.height]="formHeight">

                    <div class="container">
                        <div id="entity-errors"
                             class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>

                        <div id="entity-fields"
                             class="row">

                            <div class="entity-fields-column col-lg-6 col-md-12">
                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Id"
                                                   class="col-form-label">Id</label>
                                        </div>
                                        <div class="col-8">

                                            <input [(ngModel)]="printText.value.Id"
                                                   [ngClass]="{
                                                            'modelstate-invalid': !Id.dirty && isInvalid('Id')
                                                            }"
                                                   (keypress)="allowOnlyNumbers(event)"
                                                   type="number"
                                                   class="form-custom-control control-look-input"
                                                   name="Id"
                                                   #Id="ngModel">
                                            <div class="control-look"></div>
                                            <div class="error-message"
                                                 *ngIf="!Id.dirty && isInvalid('Id')">
                                                <small class="error">
                                                    {{getError('Id')}}
                                                </small>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>


                            <div class="entity-fields-column col-lg-6 col-md-12">
                                <div class="container">

                                    <div class="row form-group">
                                        <div class="col-4">
                                            <label for="Text"
                                                   class="col-form-label">Text</label>
                                        </div>
                                        <div class="col-8">

                                            <textarea #Text
                                                      [(ngModel)]="printText.value.Text"
                                                      class="form-custom-control control-look-input"
                                                      name="Text"
                                                      autosize
                                                      [maxRows]="10"></textarea>

                                            <div class="error-message"
                                                 *ngIf="!Text.dirty && isInvalid('Text')">
                                                <small class="error">
                                                    {{getError('Text')}}
                                                </small>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>



                            <div class="entity-fields-column-children col"
                                 (mouseout)="
                                            overRemoveReference = [];
                                            overDeleteReference = [];
                                            ">
                                <div class="container">

                                    <div *ngIf="!addingReference && printText.value.References && printText.value.References.length > 0" class="row form-group">
                                        <div class="col-12">
                                            <label class="col-form-label">
                                                References
                                            </label>
                                        </div>
                                        <div class="col-12">

                                            <div [ngClass]="{
                                                editGridDiv: !printText.created, 
                                                newGridDiv: printText.created
                                            }">
                                                <dx-data-grid #dataGridReferences
                                                              [dataSource]="printText.value.References"
                                                              keyExpr="ClientId"
                                                              (onRowClick)="onRowClick_References($event)"
                                                              (onRowPrepared)="onRowPrepared_References($event)"
                                                              noDataText=""
                                                              [allowColumnResizing]="true"
                                                              [(selectedRowKeys)]="selectedReference">
                                                    <dxo-load-panel>
                                                        <div *dxTemplate="let data of 'content'">
                                                            <pdi-loader size="medium"></pdi-loader>
                                                        </div>
                                                    </dxo-load-panel>

                                                    <dxi-column dataField="NumberOfLines"
                                                                [width]="160"></dxi-column>
                                                    <dxi-column dataField="ReferenceType">
                                                        <dxo-lookup [dataSource]="printTextReferenceTypes"
                                                                    valueExpr="id"
                                                                    displayExpr="name">
                                                        </dxo-lookup>
                                                    </dxi-column>
                                                    <dxi-column dataField="TextType"
                                                                [width]="100"></dxi-column>

                                                    <dxi-column caption=""
                                                                alignment="center"
                                                                cellTemplate="cellRemoveReference"
                                                                [width]="60"></dxi-column>
                                                    <div *dxTemplate="let cell of 'cellRemoveReference'">
                                                        <div *ngIf="cell.data.ClientDeleted != true">
                                                            <img (click)="click_RemoveReference(cell.data)"
                                                                 (mouseover)="overRemoveReference[cell.rowIndex] = true"
                                                                 (mouseout)="overRemoveReference = []"
                                                                 [ngStyle]="{opacity: !overRemoveReference[cell.rowIndex] ? '0.3': '1'}"
                                                                 width="25px"
                                                                 src="assets/Content/Images/Icons/delete1.png" />
                                                        </div>
                                                        <div *ngIf="cell.data.ClientDeleted == true">
                                                            <img (click)="click_RestoreReference(cell.data)"
                                                                 (mouseover)="overRemoveReference[cell.rowIndex] = true"
                                                                 (mouseout)="overRemoveReference = []"
                                                                 [ngStyle]="{opacity: !overRemoveReference[cell.rowIndex] ? '0.3': '1'}"
                                                                 width="25px"
                                                                 src="assets/Content/Images/Icons/undo.png" />
                                                        </div>
                                                    </div>

                                                    <dxo-selection [mode]="'single'"
                                                                   [allowSelectAll]="false">
                                                    </dxo-selection>


                                                    <dxo-filter-row [visible]="true">
                                                    </dxo-filter-row>

                                                    <dxo-scrolling showScrollbar="always"
                                                                   useNative="true">
                                                    </dxo-scrolling>

                                                    <dxo-sorting mode="single"> </dxo-sorting>

                                                    <dxo-paging [pageSize]="5"> </dxo-paging>
                                                    <dxo-pager [showNavigationButtons]="true">
                                                    </dxo-pager>

                                                </dx-data-grid>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="row actions-new-reference form-group pt-4">

                                        <div class="col"
                                             *ngIf="!printTextReference || (printTextReference && addingReference)">

                                            <button (click)="addPrintTextReference()"
                                                    id="action-add"
                                                    [disabled]="addingReference">
                                                <img src="assets/Content/Images/Icons/add.png"
                                                     class="edit-button-image"
                                                     [ngClass]="{
                                                         'edit-button-image-yellow': printText.created === true, 
                                                         'disabled': addingReference
                                                        }" />
                                                <span class="edit-button d-none d-xl-inline-block"
                                                      [ngClass]="{
                                                          'edit-button-yellow': printText.created === true,
                                                          'disabled': addingReference
                                                        }">Add Reference</span>
                                            </button>

                                        </div>

                                        <ng-container *ngIf="addingReference">

                                            <div class="col">

                                                <button (click)="savingPrintTextReference()"
                                                        id="action-save"
                                                        [disabled]="invalidPrintTextReference">
                                                    <img src="assets/Content/Images/Icons/save.png"
                                                         class="edit-button-image"
                                                         [ngClass]="{'edit-button-image-yellow': printText.created === true,
                                                         'disabled': invalidPrintTextReference}" />
                                                    <span class="edit-button d-none d-xl-inline-block"
                                                          [ngClass]="{'edit-button-yellow': printText.created === true,
                                                          'disabled': invalidPrintTextReference}">Save Reference</span>
                                                </button>

                                            </div>
                                            <div class="col">

                                                <button (click)="cancelPrintTextReference()"
                                                        id="action-cancel">
                                                    <img src="assets/Content/Images/Icons/cancel.png"
                                                         class="edit-button-image"
                                                         [ngClass]="{'edit-button-image-yellow': printText.created === true}" />
                                                    <span class="edit-button d-none d-xl-inline-block"
                                                          [ngClass]="{'edit-button-yellow': printText.created === true}">Cancel Reference</span>
                                                </button>

                                            </div>

                                        </ng-container>


                                    </div>

                                    <ng-container *ngIf="printTextReferenceTypeName">

                                        <div class="row entity-reference form-group">

                                            <div class="col-12">

                                                <facnm-print-text-reference [(printTextReference)]="printTextReference"
                                                                            [printTextCreated]="printText.created"
                                                                            (invalidChange)="onInvalidChange($event)"></facnm-print-text-reference>

                                            </div>

                                        </div>

                                    </ng-container>
                                </div>

                            </div>

                        </div>

                    </div>

                </form>

            </div>

            <facnm-action-buttons [modelEntity]="printText"></facnm-action-buttons>

        </div>

    </div>

</div>



<dx-popup title="Confirm Save"
          [(visible)]="savingConfirmVisible"
          [animation]="animation"
          (onShowing)="onConfirmSaveShowing($event)"
          hideOnOutsideClick="true"
          [dragEnabled]="false"
          shadingColor="shadingColor"
          maxHeight="250"
          maxWidth="320">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col">
                <div> Some of the print text references will be deleted on saving.</div>
                <div class="pt-2"> Are you sure you want to save?</div>
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="saveConfirm()"
                        id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Save</span>
                </button>
            </div>
            <div class="col">
                <button (click)="saveCancel()"
                        id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<dx-popup title="Confirm Overwrite"
          [(visible)]="savingReferenceConfirmOverwriteVisible"
          [animation]="animation"
          (onShowing)="onConfirmSaveShowing($event)"
          hideOnOutsideClick="true"
          [dragEnabled]="false"
          shadingColor="shadingColor"
          maxHeight="250"
          maxWidth="450">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div> The print text reference already exists in another text.</div>
                <div class="pt-2"> Are you sure you want to overwrite it?</div>
            </div>
        </div>
        <div class="row text-center fixed-bottom pb-4">
            <div class="col">
                <button (click)="savePrintTextReferenceConfirm()"
                        id="action-confirm-delete">
                    <img src="assets/Content/Images/Icons/save.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Save</span>
                </button>
            </div>
            <div class="col">
                <button (click)="savePrintTextReferenceCancel()"
                        id="action-confirm-cancel">
                    <img src="assets/Content/Images/Icons/cancel.png"
                         class="edit-button-image" />
                    <span class="edit-button d-none d-xl-inline-block">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</dx-popup>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>
<facnm-notifications [entityName]="entityName"></facnm-notifications>
<facnm-loading-screen-indicator></facnm-loading-screen-indicator>
<facnm-loading-entity-indicator></facnm-loading-entity-indicator>