import { Observable } from "rxjs";
import cloneDeep from 'lodash/cloneDeep';

export interface EntityInterface<T> {

    create: (data: Entity<T>) => Observable<any>;
    save: (data: Entity<T>) => Observable<any>;
    delete: (data: any) => Observable<any>;

    getList: (optionalKey) => Observable<T[]>;
    getEntity: (key) => Observable<T>;

    ListRetrieved: Observable<T[]>;

    notifyRetrieved: (data: T[]) => void;
}


export class Entity<T> {

    key: any = {};
    name: string;
    created: boolean;
    modified: boolean;

    original: T = {} as T;
    private entityValue: T = {} as T;

    constructor() {
        this.created = true;
    }

    set value(entityValue: T) {
        this.entityValue = entityValue;
        this.original = cloneDeep(entityValue);
        this.created = false;
    }

    get value(): T {
        return this.entityValue;
    }


    restore() {
        this.entityValue = cloneDeep(this.original);
        this.modified = false;
    }
}


export interface Enum {

    id: number;
    name: string;
}
