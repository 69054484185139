<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="pricegroup" class="row h-100">
        <div class="col-md-3 col-sm-3">
            <div id="entity-list" class="h-100">
                <dx-data-grid (onContentReady)="onContentReady($event)" #dataGrid [dataSource]="gridDataSource"
                    noDataText="" (onInitialized)="onInitialized($event)" (onRowClick)="onRowClick($event)"
                    [allowColumnResizing]="false" [columnAutoWidth]="false">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column alignment='center' caption="Price Group ID" dataField="Id"
                        dataType="number"></dxi-column>
                    <dxi-column alignment='center' caption="Products in the Group" dataField="NumberOfProducts" dataType="number"></dxi-column>
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true"
                        [visible]="true"></dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                    <dxo-remote-operations [paging]="true" [filtering]="true"></dxo-remote-operations>
                </dx-data-grid>
            </div>
        </div>

        <div id="entity-detail" class="col-md-9 pr-0">
            <div *ngIf="entityData && entityData.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight" style="overflow-x: hidden;">
                    <div class="pricegroup-Container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="top-buffer">
                            <div>
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-4 hidden-xs"> </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-4 control-label">
                                        <label for="Title" class="col-form-label">Price Group ID</label>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-4 pl-1">
                                        <input [(ngModel)]="entityData.value.Id" type="text"
                                            class="form-custom-control control-look-input" readonly="readonly"
                                            name="Id">
                                        <div class="control-look"></div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                            <div>
                                <div class="error-message mb-4"
                                    *ngIf="this.entityForm?.submitted && entityData.value.Products.length == 0 ">
                                    <small class="error">
                                        Add minimum one Product!
                                    </small>
                                </div>
                                <div class="error-message mb-4" *ngIf="this.priceGroupGridErrorMsg">
                                    <small class="error">
                                        {{priceGroupGridErrorMsg}}
                                    </small>
                                </div>
                                <div class="dataGridDiv mt-2" style="min-height:400px">
                                    <div class="pricegroup_items">Price Group Products</div>
                                    <dx-data-grid #dataGridPriceGroupItems class="no-data-min-height-sp"
                                        (onInitialized)="onInitialized($event)"
                                        (onContentReady)="onContentReadyPriceGroupItems($event)"
                                        (onRowInserting)="onPriceGroupItemsGridRowInserting($event)"
                                        (onRowInserted)="onPriceGroupItemsGridRowInserted($event)"
                                        [dataSource]="entityData.value.Products" noDataText="" [allowColumnResizing]="true"
                                        [columnAutoWidth]="true">
                                        <dxi-column width="30%" alignment='center' dataField="ProductId" caption="Product" 
                                            [lookup]="{ dataSource: productList, valueExpr: 'Id', displayExpr: 'ProductName'}"></dxi-column>
                                        <dxi-column width="15%" alignment='center' caption="Quantity" dataField="MinimumQty"
                                            dataType="number" cellTemplate="QuantityCell"></dxi-column>
                                            <div *dxTemplate="let qunatity of 'QuantityCell'">
                                               > {{qunatity.data.MinimumQty}} {{qunatity.data.QuantityUnit}}
                                            </div>
                                        <dxi-column width="15%" alignment='center' caption="Price" dataField="Price"
                                            dataType="number" cellTemplate="PriceCell"></dxi-column>
                                            <div *dxTemplate="let price of 'PriceCell'">
                                                 {{price.data.Price}} {{moneySign}} / {{price.data.PricePer}} {{price.data.QuantityUnit}}
                                            </div>
                                        <dxi-column width="15%" height="10%" alignment='center' caption="From" dataField="FromDate"
                                            dataType="date" format="dd/MM/yyyy" class="newclass"></dxi-column>
                                        <dxi-column width="15%" alignment='center' caption="Until" dataField="ToDate"
                                            dataType="date" format="dd/MM/yyyy">
                                        </dxi-column>
                                        <dxo-selection mode="single"> </dxo-selection>
                                        <dxo-filter-row [visible]="false"> </dxo-filter-row>
                                        <dxo-scrolling mode="standard" showScrollbar="onHover">
                                        </dxo-scrolling>
                                        <dxo-sorting mode="single"> </dxo-sorting>
                                        <dxo-paging [pageSize]="10"> </dxo-paging>
                                        <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true"
                                            mode="row" [texts]="{ addRow: '' }">
                                        </dxo-editing>
                                    </dx-data-grid>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [entityName]="entityNameNotification"
                [modelEntity]="entityData"></facnm-action-buttons>
        </div>

    </div>

</div>

<facnm-delete-dialog [entityName]="entityNameNotification"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityNameNotification"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>