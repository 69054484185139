import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdditionalFields, AdditionalFieldsInterface } from './additional-fields.model';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdditionalFieldsService implements AdditionalFieldsInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  private listRetrieved: BehaviorSubject<AdditionalFields[]> = new BehaviorSubject<AdditionalFields[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: AdditionalFields[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<AdditionalFields>) => Observable<any>;

  create(data: Entity<AdditionalFields>): Observable<any> {
    return this._http.put<AdditionalFields>(
      e.endpoint.fleet.additionalFields + "/additionalField",
      data.value).pipe(catchError(this.errors$.handleNetworkError));
  }

  getList(): Observable<AdditionalFields[]> {
    return this._http.get<AdditionalFields[]>(e.endpoint.fleet.additionalFields + '/list')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity(): Observable<AdditionalFields> {
    return this._http.get<AdditionalFields>(e.endpoint.fleet.additionalFields + '/new')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(key: any): Observable<any> {
    return this._http.delete(e.endpoint.fleet.additionalFields + "/" + key.FieldType + "/" + key.FieldLocation, {
      responseType: "text" 
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(param) {
    return this._http.get<AdditionalFields>(e.endpoint.fleet.additionalFields + '/' + param.FieldType + '/' + param.FieldLocation)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getDataTypeList() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/datatypes')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getFieldLocations() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/fieldtypes')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getViewModeTypes() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/viewmodetypes')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getTimeLogTypeList() {
    return this._http.get<Enum[]>(e.endpoint.fleet.timeLog)
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  getLoadLocationTypes() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/loadLocationTypes')
    .pipe(catchError(this.errors$.handleNetworkError));
  }

}


