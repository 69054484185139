import { Injectable } from '@angular/core';
import { ExportConfigurationInterface } from './export-configuration.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportConfigurationService implements ExportConfigurationInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  getConfigurationGroup(): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.fleet.configurationset)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
  getConfigurationValues(param: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.fleet.configurationset + "/" + param + '?noCache=' + new Date().getTime())
    .pipe(catchError(this.errors$.handleNetworkError));
  }

  exportConfigurationSetByid(param: any): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.fleet.configurationset + "/export/" + param)
    .pipe(catchError(this.errors$.handleNetworkError));
  }
}
