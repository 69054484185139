import { ExportConfigurationService } from './export-configuration.service';
import { Config, ExportConfigurationInterface } from './export-configuration.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, } from '@angular/core';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { displayZeroValueText } from '@app/common_helper';

@Component({
  selector: 'facnm-export-configuration',
  templateUrl: './export-configuration.component.html',
  styleUrls: ['./export-configuration.component.scss'],
  providers: [
    {
      provide: ExportConfigurationInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new ExportConfigurationService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class ExportConfigurationComponent implements OnInit {
  entityName = "Export TransPac Configuration";
  configValueTitle = "Default Config Value"
  configurationGroups: Config[];
  configurationValues: any[];
  groupSpecificConfigurationGroupNameId: any;
  isServerError: boolean;
  serverErrorMessage = "";

  constructor(
    private configuration$: ExportConfigurationInterface,
    private errors$: ErrorsService,
    private app$: AppService,
    private actions$: ActionsService,
  ) {
  }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.getConfiguration();
  }

  getConfiguration() {
    this.app$.notifyScreenBusy();
    this.configuration$.getConfigurationGroup().subscribe(res => {
      this.configurationGroups = res;
      this.app$.notifyScreenReady();
      this.groupSpecificConfigurationGroupNameId = 0;
      this.getConfigurationValues()
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  getConfigurationValues() {
    this.isServerError = false;
    var configId = this.groupSpecificConfigurationGroupNameId;
    this.configValueTitle = (configId != 0) ? "Group Config Value" : "Default Config Value";
    this.configurationValues = [];
     if(configId == 0){
      displayZeroValueText(this.configurationGroups,'groupSpecificConfigurationGroupNameId','Id','Name');
    }
    this.app$.notifyScreenBusy();
    this.configuration$.getConfigurationValues(configId).subscribe(res => {
      this.configurationValues = res['ConfigurationValues'];
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.handleServerErrors(errorGetList));
  }

  exportConfigurationValues() {
    this.isServerError = false;
    var configId = this.groupSpecificConfigurationGroupNameId;
    this.app$.notifyScreenBusy();
    this.configuration$.exportConfigurationSetByid(configId).subscribe(res => {
      this.exportJsonFile(res);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.handleServerErrors(errorGetList));
  };
  exportJsonFile(data) {
    var contentType = "application/json";
    try {
      var jsonString = JSON.stringify(data, null, 2);
      var blob = new Blob([jsonString], { type: contentType });
      var filename = "ConfigurationSet.json";
      var url = window.URL.createObjectURL(blob);
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', url);
      linkElement.setAttribute("download", filename);
      var clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      linkElement.dispatchEvent(clickEvent);
      this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Export successful.' });
      this.app$.notifyScreenReady();
    } catch (ex) {
      console.log(ex);
    }
  }
  handleServerErrors(errorGetList) {
    this.isServerError = true;
    this.serverErrorMessage = errorGetList.message
    this.app$.notifyScreenReady();
  }
  closeServerErrors() {
    this.isServerError = !this.isServerError;
  }

}
