import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity, Enum } from "@app/app.model";


export class PrintTemplate {

    Id: number;
    CompanyId: number;

    AliasId: number;
    AliasCompanyId: number;

    TemplateName: string;

    IsSyncedShadowLive: boolean;

    AnyShadowPrinterForms: boolean;
    AnyLivePrinterForms: boolean;

    Language: string;

    AllLanguages: string[];
    AllLanguagesOriginal: string[];

    InUse: boolean;

    PrinterForms: PrinterForm[];
    label:string;
    key: PrintTemplate;
}

export class PrinterForm {

    Language: string;
}

export class PrintTemplateEditor {

    cursor: { line: number, ch: number };
    options?: any;
    height?: any;

    constructor() {
        this.cursor = { line: 1, ch: 0 };
    }
}

export class PrintTemplateKeywords {

    PageParts: string[];
    FormatCommands: string[];
    Parsers: string[];
    TablesAndHashes: string[];
}



export class PrintTemplateTest {
    Id: number;
    DeviceId: number;
}

export class SaleTemplateTest extends PrintTemplateTest {
    SaleDate: Date | string;
    ReceiptNumber: number;
    CustomerName: string;
    TemplateTypeId: number;
    CustomerAddress: string;
}

export class LoadingTemplateTest extends PrintTemplateTest {

    TourNo: string;
    TourId: number;
    LoadLocation: string;
    DateFrom: Date | string;
    DateTo: Date | string;
}

export class SafetyCheckTemplateTest extends PrintTemplateTest {

    SessionId: number;
    Date: Date | string;
    DriverName: string;
    SafetyGroupId: number;

}

export class SaleProtocolTemplateTest extends PrintTemplateTest {

    DateStart: Date | string | null;
    DateEnd: Date | string | null;
    DriverName: string;
    TourNo: string;
    Date: Date | string;
    SessionId: number | null;
    TourId: number | null;
    IsSessionSale: boolean;
}

export class AssessmentTemplateTest extends PrintTemplateTest {

    SessionId: number | null;
    ResultId: number | null;
    FormName: string;
}

export class TransportDocumentTemplateTest extends PrintTemplateTest {

    DateFrom: Date | string | null;
    DateTo: Date | string | null;
}

export class TourPaperTemplateTest extends PrintTemplateTest {

    DateFrom: Date | string | null;
    DateTo: Date | string | null;
}

export class CollectiveSettlementTemplateTest extends PrintTemplateTest {

    DateFrom: Date | string | null;
    DateTo: Date | string | null;
}

export class CashBalanceTemplateTest extends PrintTemplateTest { }

export class CustomerDocumentTemplateTest extends SaleProtocolTemplateTest {

    CustomerId: number | null;
    CustomerPos: number | null;
}

export class DeliveryAuthorizationDocumentTemplateTest extends PrintTemplateTest {
    Id: number;
    SessionId: number;
    AuthorizationDate: Date | string;
    CustomerName: string;
    CustomerAddress: string;
}

export class PrintTemplateLog {
    LogDate: Date | string;
    IsLive: boolean;
    UserName: string;
    InsertsNo: number;
    UpdatesNo: number;
    DeletesNo: number;
}

export class PrintTemplateKeywordAttributes{
    Keyword: string;
    Bold: boolean;
    Italic: boolean;
    Underline: boolean;
    Value: boolean;
}

export class PrintTemplateAttributes{
    KeywordAttributes: PrintTemplateKeywordAttributes[];
    TopBorder: number;
    BottomBorder: number;
    PrintPaymentSlip: boolean;
}
export const linelenghtKeyword = 'lineLen';


@Injectable()
export abstract class PrintTemplatesInterface implements EntityInterface<PrintTemplate> {

    getList: (companyId: number) => Observable<PrintTemplate[]>;
    ListRetrieved: Observable<PrintTemplate[]>;
    notifyRetrieved: (data: PrintTemplate[]) => void;

    create: (data: Entity<PrintTemplate>) => Observable<any>;
    save: (data: Entity<PrintTemplate>) => Observable<any>;
    delete: (data: any) => Observable<any>;

    getEntity: (key: any) => Observable<PrintTemplate>;

    SyncPrinterForm: (entity: PrintTemplate, isLive: boolean) => Observable<any>;
    DownloadPrinterForm: (entity: PrintTemplate, isLive: boolean) => Observable<any>;
    UploadPrinterForm: (file: File, entity: PrintTemplate, attributes?: PrintTemplateAttributes) => Observable<any>;

    getAliases: () => Observable<PrintTemplate[]>;
    getKeywords: () => Observable<PrintTemplateKeywords>;
    getLineLength: () => Observable<number>;
    getTemplatesTypes: () => Observable<Enum[]>;

    getTestData: (templateTypeId: number, companyId: number, deviceId: number, dateFrom: Date, dateTo: Date) => Observable<any>;
    TestDataRetrieved: Observable<PrintTemplateTest[]>;
    notifyTestDataRetrieved: (data: PrintTemplateTest[]) => void;

    getLogData: (entity: PrintTemplate) => Observable<any>;
    LogDataRetrieved: Observable<PrintTemplateLog[]>;
    notifyLogDataRetrieved: (data: PrintTemplateLog[]) => void;

    getAttributeData: (entity: PrintTemplate) => Observable<any>;
    AttributeDataRetrieved: Observable<PrintTemplateAttributes[]>;
    notifyAttributeData: (data: PrintTemplateAttributes[]) => void;



    TestPrinterForm: (printerFormLines: string, templateTestEntity: any, deviceId: number, templateTypeId: number) => Observable<Blob>;
}
