<div class="container">

    <div class="row p-4">
        <div class="col text-center">

            <i>Conditions can be written in javascript</i>

        </div>
    </div>

    <div class="row p-4">
        <div class="col-7 examples">


            <table class="table table-bordered">

                <thead>
                    <tr>
                        <th scope="col">EXAMPLES - <i>show field when:</i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><i>selected tank_type is "other"</i></td>
                    </tr>
                    <tr>
                        <td>tank_type == "other"</td>
                    </tr>
                    <tr>
                        <td><i>tank_volume is larger than 1000</i></td>
                    </tr>
                    <tr>
                        <td>tank_volume > 1000</td>
                    </tr>
                    <tr>
                        <td><i>require_ladder is "YES" and ladder_fixed is "NO"</i></td>
                    </tr>
                    <tr>
                        <td>require_ladder == "YES" && ladder_fixed == "NO"</td>
                    </tr>
                    <tr>
                        <td><i>four_wheel_rtw is "YES" or six_wheel_rtw is "YES"</i></td>
                    </tr>
                    <tr>
                        <td>four_wheel_rtw == "YES" || six_wheel_rtw == "YES"</td>
                    </tr>
                </tbody>
            </table>


        </div>

        <div class="col legend">

            <table class="table table-bordered">

                <thead>
                    <tr>
                        <th scope="col">LEGEND</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="bg-info">
                            <span class="symbol">==</span>
                            equal to
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="symbol">!=</span> not equal</td>
                    </tr>
                    <tr>
                        <td class="bg-info">
                            <span class="symbol">> </span> greater than</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="symbol">
                                < </span>
                                  less
                                  than</td>
                                  </tr>
                                  <tr>
                        <td class="bg-info">
                            <span class="symbol">>=</span> greater than or equal to</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="symbol">
                                <=</span> less
                                    than
                                    or
                                    equal
                                    to</td>
                                    </tr>
                                    <tr>
                        <td class="bg-info">
                            <span class="symbol">&&</span> and</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="symbol">||</span> or</td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>
</div>