import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { ServerConfiguration, ServerConfigurationInterface, ServerConfigurationType } from './server-configuration.model';
import { NgForm } from '@angular/forms';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { AppService } from '@app/app.service';
import { ServerConfigurationService } from './server-configuration.service';
import { HttpClient } from '@angular/common/http';

import cloneDeep from 'lodash/cloneDeep';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'facnm-server-configuration',
    templateUrl: './server-configuration.component.html',
    styleUrls: ['./server-configuration.component.scss'],
    providers: [
        {
            provide: ServerConfigurationInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new ServerConfigurationService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class ServerConfigurationComponent implements OnInit, AfterViewInit {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Server Configuration';
    AllFlags=[]; 
    SelectedFlags:[];
    serverConfigurations: ServerConfiguration[];
    serverConfigurationsOriginal: ServerConfiguration[];

    serverConfigurationType = ServerConfigurationType;

    @ViewChild('serverConfigurationForm') serverConfigurationForm: NgForm;

    errorsHeight: number = 0;


    constructor(private serverConfiguration$: ServerConfigurationInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            errors ? this.errorsHeight = 50 : this.errorsHeight = 0;
            this.resizeScreenContent();
        });

    }

    ngOnInit() {
        this.AllFlags=[];
        this.SelectedFlags=[];
        this.app$.setScreenTitle(this.entityName);
        forkJoin(

            this.serverConfiguration$.getList()

        ).pipe(map(multipleData => {

            if (multipleData) {

                let configs = multipleData[0];

                configs.forEach(c => {

                    if (c.Type == ServerConfigurationType.bool) {

                        c.Value = c.Value == '1' ? true : false;
                    }
                });
            }

            return multipleData;

        })).subscribe(multipleDataMapped => {

            this.serverConfigurationsOriginal = multipleDataMapped[0];
            this.serverConfigurations = cloneDeep(this.serverConfigurationsOriginal);
            this.setSelectedFlags(this.SelectedFlags,this.AllFlags,this.serverConfigurations);

            this.app$.notifyScreenReady();

            this.resizeScreenContent();

        }, (errorNetwork) => {

            this.errors$.handleServerErrors(errorNetwork);
        });
    }

    onChangeConfiguration(config: ServerConfiguration) {

        config.invalid = false;
        config.modified = this.serverConfigurationsOriginal.find(c => c.Id == config.Id).Value != config.Value;
    }

    saveConfiguration(config: ServerConfiguration) {

        this.app$.notifyScreenBusy();

        this.errors$.clearErrors();

        switch (config.Type) {
            case ServerConfigurationType.bool:
                config.Value = config.Value ? '1' : '0';
                break;
        }

        this.serverConfiguration$.save(config).subscribe(() => {

            this.serverConfigurationsOriginal.find(c => c.Id == config.Id).Value = config.Value;
            config.modified = false;

            this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Saved Configuration ' + config.Id + ' - ' + config.Description });

            this.app$.notifyScreenReady();

        }, (errorNetwork) => {

            config.invalid = true;
            this.errors$.handleServerErrors(errorNetwork);
            
        }, () => {

            if (config.Type == ServerConfigurationType.bool) {

                config.Value = config.Value == '1' ? true : false;
            }
        });
    }

    resetConfiguration(config: ServerConfiguration) {

        config.Value = this.serverConfigurationsOriginal.find(c => c.Id == config.Id).Value;
        config.modified = false;
        config.invalid = false;

        this.errors$.clearErrors();
        this.SelectedFlags=[];
        this.setSelectedFlags(this.SelectedFlags,this.AllFlags,this.serverConfigurationsOriginal);
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onSelectedFlagsChanged(e,Config) {
        Config.ListValue =[];
        Config.Value="";
        e.value.forEach(function (evalue,ekey) {
            Config.ListValue.push(Config.Options.find(a=>a.Name == evalue).Id);
        });
        Config.Value= Config.ListValue.reduce((acc, cur) => acc + Number(cur), 0);
        this.onChangeConfiguration(Config);       
    }    
    setSelectedFlags(SelectedFlags,AllFlags,serverConfigurations):void{
        serverConfigurations.forEach (function (value,key) {           
            if(value.Type =="flags")
            {
                value.Options.forEach (function (innnervalue,innerkey) {
                    AllFlags.indexOf(innnervalue.Name) === -1 ? AllFlags.push(innnervalue.Name) : "";                
                    value.FlagsValues.forEach(function (value,key) {
                        if(value == innnervalue.Id)
                        {
                            SelectedFlags.push(innnervalue.Name); 
                        }
                    });
                });
            }
          });

    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 100 - this.errorsHeight + 'px';
        this.changeDetectorRef.detectChanges();
    }
}
