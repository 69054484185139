import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { User } from "./header.model";
import { Router } from "@angular/router";

@Component({
  selector: 'facnm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() pagetitle;
  user: User;
  enableSpin: boolean;
  showAlert: boolean;
  exportClicked: boolean;
  notify: object[] = [];
  exportImg = 'assets/Content/svg-icons/action-bar-icons/export.svg';
  folder = 'assets/Content/svg-icons/action-bar-icons/';
  exportSuccess = this.folder + 'exported.svg';
  exportIdle = this.folder + 'export.svg';
  exportFail = this.folder + 'export_error.svg';
  exportProcess = this.folder + 'exporting.svg';


  constructor(private app$: AppService, private router: Router,) { }

  ngOnInit(): void {
    this.initializeUserData();
    this.app$.loginUserInfo.subscribe((res: any) => {
      this.user.username = res.Username;
      this.user.company = res.Company;
      this.user.isAdmin = res.IsAdmin;
      this.user.isSysAdmin = res.IsSysAdmin;
      this.user.isStrappingChartEnabled = res.IsStrappingChartEnabled;
      this.user.isAuthenticated = res.isAuthenticated;
    });
    this.verifyAuthentication();
    this.app$.getAPIVersion();
  }
  initializeUserData() {
    this.user = {
      username: "",
      company: "",
      isAdmin: false,
      isSysAdmin: false,
      isAuthenticated: false,
      isStrappingChartEnabled: false
    };
  }
  verifyAuthentication() {
    var bearerToken = sessionStorage.getItem("Bearer");
    if (bearerToken != null) {
      this.user.isAuthenticated = true;
      this.app$.getUserDetails();
    }
  }
  gotoPage(page) {
    this.router.navigate([page]);
  }
  onClickExport(type) {
    this.exportImg = this.exportProcess;
    this.enableSpin = true;
    this.app$.exportData(type.toLowerCase()).subscribe((res) => {
      this.exportClicked = true;
      this.enableSpin = false;
      this.exportImg = this.exportSuccess;
      this.notify.push({ type: 'success', title: 'Success!', body: 'Export of ' + type + ' is succesful' });
    }, (err) => {
      this.exportClicked = true;
      this.enableSpin = false;
      if (err.status == 401) {
        this.logout();
      }
      else {
        this.exportImg = this.exportFail;
        this.notify.push({ type: 'danger', title: 'Error!', body: 'Export of ' + type + ' failed' });
      }
    })
  }
  showNotification() {
    if (this.exportClicked) {
      this.showAlert = true;
      this.exportClicked = false;
      this.exportImg = this.exportIdle;
      setTimeout(() => {
        this.notify = [];
        this.showAlert = false;
      }, 5000)
    }
  }
  logout() {
    sessionStorage.clear();
    this.initializeUserData();
    this.app$.getAPIVersion();
    window.location.reload();
  }
}
