import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DxDataGridModule, DxTooltipModule, DxLoadPanelModule, DxToastModule, DxPopupModule, DxTagBoxModule, DxSwitchModule, DxTabsModule, DxDateBoxModule, DxDropDownBoxModule, DxNumberBoxModule, DxCheckBoxModule, DxPopoverModule } from 'devextreme-angular';

import { SharedModule } from '@app/_shared/shared.module';

import { PrintTemplatesComponent } from './print-templates/print-templates.component';
import { PrintTemplatesEditComponent } from './print-templates/print-templates-edit.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';

import { AutosizeModule } from 'ngx-autosize';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { PrintTextsComponent } from './print-text/print-text.component';
import { PrintTextReferenceComponent } from './print-text/print-text-reference.component';
import { PrintTextsService } from './print-text/print-text.service';
import { ServerConfigurationComponent } from './server-configuration/server-configuration.component';
import { ModulesComponent } from './modules/modules.component';
import { UsersComponent } from './users/users.component';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule,MAT_DATE_LOCALE } from '@angular/material/core';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { CompanyComponent } from './company/company.component';
import { PaymentTermComponent } from './payment-term/payment-term.component';
import { FleetManagementComponent } from './fleet-management/fleet-management.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { PdiDatePickerModule } from '@pdi/platform-angular-template';
@NgModule({
    declarations: [PrintTemplatesComponent, TaxRatesComponent, PrintTemplatesEditComponent, PrintTextsComponent, PrintTextReferenceComponent, ServerConfigurationComponent, ModulesComponent, UsersComponent, AuditLogComponent, CompanyComponent, PaymentTermComponent, FleetManagementComponent, BankAccountComponent],
    providers: [PrintTextsService],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        DxDataGridModule,
        DxTooltipModule,
        DxLoadPanelModule,
        DxToastModule,
        DxPopupModule,
        DxTagBoxModule,
        DxSwitchModule,
        DxTabsModule,
        DxDateBoxModule,
        DxDropDownBoxModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxPopoverModule,
        AutosizeModule,
        CodemirrorModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        PdiDatePickerModule
    ],
    exports: [MatRadioModule]
})
export class SettingsModule { }
