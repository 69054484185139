import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceMapInterface, DeviceMap } from './device-mapping.model';
import { DeviceMappingService } from './device-mapping.service';
import { dxDataGridHeightAdjustment, makePDIDropDownOptions, dxSelectOptions } from '@app/common_helper';

@Component({
  selector: 'facnm-device-mapping',
  templateUrl: './device-mapping.component.html',
  styleUrls: ['./device-mapping.component.scss'],
  providers: [
    {
      provide: DeviceMapInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new DeviceMappingService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class DeviceMappingComponent implements OnInit {

  constructor(
    private deviceMap$: DeviceMapInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
   @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;
  dxSelectOptions = new dxSelectOptions();
  private unsubscribe$ = new Subject<void>();
  gridData: ArrayStore;
  isAddForm = 0;
  GICollapse = false;
  collapseColor = 'green';
  entityName = 'Device Product Mapping';
  entityNameNotification = 'Device Product';
  entityData: Entity<DeviceMap>;
  newEntityData: DeviceMap;
  newEntity: any;
  ProductList: any[];
  DeviceList: any[];
  deviceMappingColumnConfig: any;

  ngOnInit(): void {
    this.actions();
    this.getList();
    this.app$.setScreenTitle(this.entityName);
    forkJoin({
      ProductList: this.deviceMap$.getProductList(),
      DeviceList: this.deviceMap$.getDeviceList(),
      newEntityData: this.deviceMap$.getNewEntity()
    }).subscribe(
      res => {
        this.ProductList = res['ProductList'];
        this.DeviceList = res['DeviceList'];
        this.newEntityData=res['newEntityData'];
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );

    this.deviceMappingColumnConfig = [
      { caption: 'Product Name on Register', dataField: 'ProductNameOnDevice', dataType: 'string',alignment: 'left' },
      { caption: 'Product Id on Register', dataField: 'ProductNameNumber', dataType: 'string',alignment: 'left' },
      { caption: 'Product Unit', dataField: 'UnitOfProduct', dataType: 'string',alignment: 'center' },
      {
        caption: 'Device Id - Name', dataField: 'DeviceId', cssClass: 'DeviceId', dataType: 'string', alignment: 'center',
        cellTemplate: function (container, options) {
          var devicedetails = "";
          if (options.data.DeviceId != 0) devicedetails = devicedetails + options.data.DeviceId + " - ";
          if (options.data.DeviceName != null) devicedetails = devicedetails + options.data.DeviceName;
          $('<span />').text(devicedetails).append("</br>").appendTo(container);
        },
        calculateCellValue: function (data) {
          return data.DeviceId + " - " + data.DeviceName;
        },
        calculateFilterExpression: function (filterValue, selectedFilterOperation) {
          return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
      },
      {
        caption: 'Product Number - Name', dataField: 'ProductId', cssClass: 'ProductId', dataType: 'string', alignment: 'center',
        cellTemplate: function (container, options) {
          var devicedetails = "";
          if (options.data.ProductId != 0) devicedetails = devicedetails + options.data.ProductId + " - ";
          if (options.data.ProductName != null) devicedetails = devicedetails + options.data.ProductName;
          $('<span />').text(devicedetails).append("</br>").appendTo(container);
        },
        calculateCellValue: function (data) {
          return data.ProductId + " - " + data.ProductName;
        },
        calculateFilterExpression: function (filterValue, selectedFilterOperation) {
          return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
      }
      
    ];

  }

    actions() {
    this.deviceMap$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridData = new ArrayStore({
          data: data,
          key: ["DeviceId", "ProductId", "ProductNameNumber"]
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }


  getList() {
    this.deviceMap$.getList().subscribe(listWithCreated => {
      this.deviceMap$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }
    onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.dataField === "DeviceIdName") {
      e.cellElement.innerHTML = e.data.DeviceId + ' - ' + e.data.DeviceName;
    } else if (e.rowType === "data" && e.column.dataField === "ProductIdName") {
      e.cellElement.innerHTML = e.data.ProductId + ' - ' + e.data.ProductName;
    }
  }
  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }

    this.deviceMap$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = {
      DeviceId: this.entityData.value.DeviceId,
      ProductId: this.entityData.value.ProductId,
      ProductNameNumber: this.entityData.value.ProductNameNumber,
    };
    this.deviceMap$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.app$.setScreenTitle('New ' + this.entityName);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.isAddForm = 1;
    this.collapseColor="yellow";
    this.changeDetectorRef.detectChanges();
  }

  onContentReadyGroup(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Device Product Mapping Found";
  }


  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.deviceMap$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.changeDetectorRef.detectChanges();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }
    collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  deviceDisplayExpr(item) {
    return item ? `${item.Id} - ${item.Name}` : '';
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
