import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { ImportConfigurationService } from './import-configuration.service';
import { ImportConfigurationInterface } from './import-configuration.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'facnm-import-configuration',
  templateUrl: './import-configuration.component.html',
  styleUrls: ['./import-configuration.component.scss'],
  providers: [
    {
      provide: ImportConfigurationInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new ImportConfigurationService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class ImportConfigurationComponent implements OnInit {
  entityName = "Import TransPac Configuration";
  compareConfigurationSet: any;
  importedConfigurationSet: any[];
  fileString: any;
  imageUploading: any[] = [];
  file: any;
  isDropZoneActive = false;
  showConfigModal: boolean;
  modalConfigurationValue: any;
  notValidFile: boolean;

  constructor(
    private configuration$: ImportConfigurationInterface,
    private errors$: ErrorsService,
    private app$: AppService,
    private actions$: ActionsService
  ) { }

  ngOnInit(): void {
    this.app$.setScreenTitle(this.entityName);
    this.app$.notifyScreenReady();
  }

  onDropZoneEnter(e:any) {
    if (e.dropZoneElement.id === 'dropzone-external') { this.isDropZoneActive = true; }
  }

  onDropZoneLeave(e:any) {
    if (e.dropZoneElement.id === 'dropzone-external') { this.isDropZoneActive = false; }
  }

  onPreview() {
    this.app$.notifyScreenBusy();
    this.fileString = null;
    this.notValidFile = false;
    this.importedConfigurationSet = [];
    this.file = this.imageUploading[0];
    let blob = this.file.slice(0, this.file.size, 'application/json');
    let fr = new FileReader();
    fr.onload = (e: any) => {
      this.fileString = e.target.result;
      try {
        this.importedConfigurationSet = JSON.parse(this.fileString);
      } catch (e) {
        this.notValidFile = true;
      }
      this.app$.notifyScreenReady();
    }
    fr.readAsText(blob);
  }

  uploadFile() {
    this.app$.notifyScreenBusy();
    this.configuration$.uploadConfigurationSet(this.importedConfigurationSet).subscribe(res => {
      this.compareConfigurationSet = res;
      this.app$.notifyScreenReady();
      this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'File upload successful.' });
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  importConfigurations() {
    
    if (this.compareConfigurationSet.CurrentDBVersion != this.compareConfigurationSet.ImportedDBVersion) {
      this.actions$.notifyEntityCustomAction({ type: 'warning', title: 'Error!', message: 'Cannot Import - Different DB version detected.' });
      return;
    }
    else if (this.compareConfigurationSet.ConfigurationSetType == 'Group' && this.compareConfigurationSet.ImportedDBGroupName == '') {
      this.actions$.notifyEntityCustomAction({ type: 'warning', title: 'Error!', message: 'Cannot Import - Imported Configs Group name required.' });
      return;
    }

    this.app$.notifyScreenBusy();
    this.configuration$.importConfigurationSet(this.compareConfigurationSet).subscribe((res) => {
      this.app$.notifyScreenReady();
      this.actions$.notifyEntityCustomAction({ title: 'Success!', message: 'Configurations import successful.' });
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  viewComplexConfigValue(configurationValue: any, XmlSchema: any) {
    configurationValue.XmlSchema = cloneDeep(XmlSchema);
    this.modalConfigurationValue = configurationValue;
    this.showConfigModal = true;
  }

  popupClose() {
    this.showConfigModal = false;
  }

  cancel() {
    this.fileString = null;
    this.file = null;
    this.importedConfigurationSet = [];
    this.compareConfigurationSet = null;
    this.isDropZoneActive = false;
  }

}
