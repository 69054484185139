import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { areArraysEqual, checkPattern } from '@app/common_helper';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'facnm-config-field',
  templateUrl: './config-field.component.html',
  styleUrls: ['./config-field.component.scss']
})
export class ConfigFieldComponent implements OnInit {
  inputAccessKey: any;


  constructor() { }
  @Input() configPageType = 1;
  @Input() configurationValue;
  @Input() configType = 1;
  @Input() disabled = false;
  @Input() showGroupCount = false;
  @Input() showDelete = false;
  @Input() showAction = false;
  @Output() deleteConfig: EventEmitter<any> = new EventEmitter();
  @Input() selectedDeviceDriver: any;
  @Input() selectedGroupNameId: any;
  @Output() refreshComplexConfig: EventEmitter<any> = new EventEmitter();
  showConfigModal = false;

  accessKey = 'DefaultValue';
  flagsValueKey = 'DefaultFlagsValue';
  booleanValueKey = 'DefaultBooleanValue';
  xmlKey = 'DefaultXmlSchema';
  accessKeys = { 1: 'DefaultValue', 2: 'DeviceValue', 3: 'DriverValue', 4: 'GroupValue' };
  flagsValueKeys = { 1: 'DefaultFlagsValue', 2: 'DeviceFlagsValue', 3: 'DriverFlagsValue', 4: 'GroupFlagsValue' };
  booleanValueKeys = { 1: 'DefaultBooleanValue', 2: 'DeviceBooleanValue', 3: 'DriverBooleanValue', 4: 'GroupBooleanValue' };
  xmlKeys = { 1: 'DefaultXmlSchema', 2: 'DeviceXmlSchema', 3: 'DriverXmlSchema', 4: 'GroupXmlSchema' };

  ngOnInit(): void {
    this.inputAccessKey = this.configurationValue.Type + this.configurationValue.Id;
    this.accessKey = this.accessKeys[this.configType];
    this.flagsValueKey = this.flagsValueKeys[this.configType];
    this.booleanValueKey = this.booleanValueKeys[this.configType];
    this.xmlKey = this.xmlKeys[this.configType];
    if (this.configurationValue.Type == 'int' || this.configurationValue.Type == 'float') {
      this.checkPattern();
    }
  }

  preDeleteConfigurationValue() {
    this.deleteConfig.emit();
  }

  viewEditComplexConfigValue() {
    //view edit complex config value
    this.configurationValue.deviceId = null;
    this.configurationValue.driverId = null;
    if (this.configType == 2) {
      this.configurationValue.deviceId = this.selectedDeviceDriver;
      this.configurationValue.XmlSchema = cloneDeep(this.configurationValue.DeviceXmlSchema);
    }
    else if (this.configType == 3) {
      this.configurationValue.driverId = this.selectedDeviceDriver;
      this.configurationValue.XmlSchema = cloneDeep(this.configurationValue.DriverXmlSchema);
    }
    else if (this.configType == 4) {
      this.configurationValue.GroupId = this.selectedGroupNameId;
      this.configurationValue.XmlSchema = cloneDeep(this.configurationValue.GroupXmlSchema);
    }
    else {
      this.configurationValue.XmlSchema = cloneDeep(this.configurationValue.DefaultXmlSchema);
    }
    this.showConfigModal = true;
  }

  disableEnableDeleteButton() {
    switch (this.configurationValue.Type) {
      case "xml":
        return this.configurationValue.OriginalXmlSchema == null;
      case "bool":
        return this.configurationValue.OriginalBooleanValue == null;
      case "flags":
        return this.configurationValue.OriginalFlagsValue == null;
      case "color":
      case "dastr":
      case "float":
      case "int":
      case "str":
        return this.configurationValue.OriginalValue == null;
      default:
        return false;
    }
  }

  checkChanges() {
    switch (this.configurationValue.Type) {
      case "xml":
        return this.configurationValue[this.xmlKey] != this.configurationValue.OriginalXmlSchema;
      case "bool":
        return this.configurationValue[this.booleanValueKey] != this.configurationValue.OriginalBooleanValue;
      case "flags":
        let arr1 = this.configurationValue[this.flagsValueKey] || [];
        let arr2 = this.configurationValue.OriginalFlagsValue || [];
        return !areArraysEqual(arr1, arr2);
      case "color":
      case "dastr":
      case "float":
      case "int":
      case "str":
        return this.configurationValue[this.accessKey] != this.configurationValue.OriginalValue;
      default:
        return false;
    }
  }

  isChanged() {
    if (!this.disabled)
      this.configurationValue.IsChanged = this.checkChanges();
  }

  checkPattern() {
    this.isChanged();
    let val = this.configurationValue[this.accessKey];
    this.configurationValue.invalid = !checkPattern(this.configurationValue.Type, val);
  }

  setBoolValue() {
    if (this.configurationValue.BooleanValue == true)
      this.configurationValue.UIValue = "1";
    else this.configurationValue.UIValue = "0";
    this.isChanged();
  }

  popupClose() {
    this.showConfigModal = false;
    if (!this.disabled) {
      this.refreshComplexConfig.emit();
    }
  }

}
