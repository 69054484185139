import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { ErrorsService } from '@app/_shared/errors/errors.service';

import { PrintTextsInterface, PrintText, PrintTextReference } from './print-text.model';
import { Entity, Enum } from '@app/app.model';

@Injectable()
export class PrintTextsService implements PrintTextsInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    create(data: Entity<PrintText>): Observable<any> {
        return this._http.post<PrintText>(
            e.endpoint.settings.printTexts,
            data.value,
            ).pipe(catchError(this.errors$.handleNetworkError));
    }

    save(data: Entity<PrintText>): Observable<any> {
        return this._http.put<PrintText>(
            e.endpoint.settings.printTexts,
            data.value).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(data: any): Observable<any> {
        return this._http.request('delete',
            e.endpoint.settings.printTexts,
            {
                body: data
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    private listRetrieved: BehaviorSubject<PrintText[]> = new BehaviorSubject<PrintText[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<PrintText[]> {
        return this._http.get<PrintText[]>(e.endpoint.settings.printTexts)
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: PrintText[]): void {
        this.listRetrieved.next(data);
    }

    getEntity(key: any): Observable<PrintText> {

        return this._http.get<PrintText>(e.endpoint.settings.printTexts + '/' + key.Id)
        .pipe(catchError(this.errors$.handleNetworkError));
    }


    getPrintTextReference(printTextReference: PrintTextReference): Observable<PrintTextReference> {

        return this._http.post<PrintTextReference>(
            e.endpoint.settings.printTexts + '/print-text-reference', 
            printTextReference).pipe(catchError(this.errors$.handleNetworkError));
    }

    getPrintTextTypes(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.settings.printTexts + '/print-text-reference-types')
        .pipe(catchError(this.errors$.handleNetworkError));
    }


    getPrintTextReferenceData(printTextReference: PrintTextReference): Observable<any> {

        return this._http.post<any>(
            e.endpoint.settings.printTexts + '/print-text-reference-data',
            printTextReference).pipe(catchError(this.errors$.handleNetworkError));
    }

    
    getSafetySummaryTypes(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.settings.printTexts + '/safety-summary-types').pipe(catchError(this.errors$.handleNetworkError));
    }

}
