<ngx-smart-modal #modal identifier="modal" [customClass]="'modal-custom config-modal'" (onClose)="onPopupClose()"
    [closable]="true" [dismissable]="false" [escapable]="false">

    <div class="modal-header" [ngClass]="{'add-new':isNew}">
        <div class="modal-title model-header-label">{{modalHeading}}</div>
    </div>
    <div id="modal-body" class="modal-body modal-body-container p-0" [ngClass]="{'multi-form':!configurationValue.XmlSchema.isSingle}">
        <div [ngClass]="{'error-div':!configurationValue.XmlSchema.isSingle}" *ngIf="serverErrors">
            <pdi-page-notification  type="alert" text="<strong> Error! </strong> {{serverErrors.message}} {{serverErrors.details}}">
            </pdi-page-notification>
        </div>
        <div *ngIf="!configurationValue.XmlSchema.isSingle">
            <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 group-sider-bar">
                <button id="action-new" [disabled]="readonly" class="w-100" type="button" (click)="addNewRegister()">
                    <img [ngClass]="{'buttonImageDisabled':readonly}" src="assets/Content/Images/Icons/add.png"
                        class="add-button-image edit-button-image-yellow" />
                    <span [ngClass]="{'buttonDisabled':readonly}"
                        class="add-button hidden-md hidden-sm hidden-xs">{{labelConfig.addButtonLabel}}</span>
                </button>
                <div class="list-group">
                    <button type="button" class="list-group-item"
                        [ngClass]="{active: $index == multiFormSettings.selectedFormIndex}"
                        *ngFor="let form of  multiFormSettings.stagingComplexConfig; let $index = index"
                        (click)="multiFormSettings.selectedFormIndex = $index;isNewGroup()">
                        {{labelConfig.listLabel +($index+1)}}
                    </button>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-md-9 col-sm-9 multi-form-detail">
                <facnm-modal-config-field [readonly]="readonly" [configId]="configurationValue.Id"
                    [isSingle]="configurationValue.XmlSchema.isSingle"
                    [form]="multiFormSettings.stagingComplexConfig[multiFormSettings.selectedFormIndex]"></facnm-modal-config-field>
            </div>
        </div>
        <div *ngIf="configurationValue.XmlSchema.isSingle">
            <div *ngFor="let form of configurationValue.XmlSchema.fieldSequence">
                <facnm-modal-config-field [readonly]="readonly" [configId]="configurationValue.Id"
                    [isSingle]="configurationValue.XmlSchema.isSingle" [form]="form"></facnm-modal-config-field>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div *ngIf="configurationValue.XmlSchema.isSingle" class="col-md-3 text-center">
            <button type="button" class="edit-button-green" (click)="onPopupCancel()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>
        <div class="col-md-3  text-center" *ngIf="!readonly || (!configurationValue.XmlSchema.isSingle && readonly)">
            <button [disabled]="readonly" type="submit" id="action-save" class="edit-button-green" (click)="onPopupSave()">
                <img src="assets/Content/Images/Icons/save.png" [ngClass]="{'buttonImageDisabled':readonly}"
                    class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs" [ngClass]="{'buttonDisabled':readonly}">Save</span>
            </button>
        </div>
        <div *ngIf="!configurationValue.XmlSchema.isSingle && !multiFormSettings.stagingComplexConfig.length" class="col-md-9"></div>
        <div *ngIf="!configurationValue.XmlSchema.isSingle && multiFormSettings.stagingComplexConfig.length && !isNew && !readonly" class="col-md-3 text-center">
            <button type="button" class="edit-button-green" (click)="deleteConfig()">
                <img src="assets/Content/Images/Icons/cancel.png" class="edit-button-image mr-2" />
                <span class="edit-button hidden-xs">Delete</span>
            </button>
        </div>
        <div *ngIf="!configurationValue.XmlSchema.isSingle && multiFormSettings.stagingComplexConfig.length && !isNew && !readonly" class="col-md-3  text-center">
            <button type="button" id="action-copy" (click)="copyConfigValue()">
                <img src="assets/Content/Images/Icons/copy.png" class="edit-button-image" />
                <span class="edit-button hidden-md hidden-sm hidden-xs">Copy</span>
            </button>
        </div>

        <div *ngIf="!configurationValue.XmlSchema.isSingle && multiFormSettings.stagingComplexConfig.length && !readonly"
            [ngClass]="{'col-md-5':isNew,'col-md-3':!isNew}" class="col-md-3  text-center">
            <button type="button" id="action-paste" (click)="pasteConfigValue()" [disabled]="!multiFormSettings.copiedForm">
                <img src="assets/Content/Images/Icons/paste.png" class="edit-button-image"
                    [ngClass]="{'buttonImageDisabled':!multiFormSettings.copiedForm}" />
                <span class="edit-button hidden-md hidden-sm hidden-xs"
                    [ngClass]="{'buttonDisabled':!multiFormSettings.copiedForm}">Paste</span>
            </button>
        </div>

        <div *ngIf="!configurationValue.XmlSchema.isSingle && (isNew || readonly)"
            [ngClass]="{'col-md-9':readonly,'col-md-4':isNew}" class="text-center">
            <button type="button" class="edit-button-green" (click)="onPopupCancel()">
                <img src="assets/Content/Images/Icons/cancel.png" [ngClass]="{'edit-button-image-yellow':isNew}"
                    class="edit-button-image mr-2" />
                <span [ngClass]="{'edit-button-yellow':isNew}" class="edit-button hidden-xs">Cancel</span>
            </button>
        </div>

    </div>
</ngx-smart-modal>