
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { AppService } from '@app/app.service';
import { Entity, Enum } from '@app/app.model';

import { TaxRatesInterface, TaxRate } from './tax-rates.model';
import { TaxRatesService } from './tax-rates.service';

import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { displayZeroValueText, dxDataGridHeightAdjustment } from '@app/common_helper';

@Component({
    selector: 'facnm-tax-rates',
    templateUrl: './tax-rates.component.html',
    styleUrls: ['./tax-rates.component.scss'],
    providers: [
        {
            provide: TaxRatesInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new TaxRatesService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class TaxRatesComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Tax Rate';

    taxRate: Entity<TaxRate>;
    taxRatesStore: ArrayStore;
    taxRateTypes: Enum[];

    @ViewChild('taxRateForm') taxRateForm: NgForm;
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;


    constructor(private taxRates$: TaxRatesInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService) {


        taxRates$.ListRetrieved.subscribe(data => {

            if (data) {

                this.taxRatesStore = new ArrayStore({
                    data: data,
                    key: ['Id', 'TaxGroup', 'Type', 'TaxClass']
                });
                if (data.length == 0) {
                    this.dataGrid.noDataText = 'No tax rates';
                }
                else {
                    this.dataGrid.noDataText = '';
                }
            }
        });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });
    }


    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(

            this.taxRates$.getTaxRatesTypes()

        ).subscribe(
            multipleData => {

                this.taxRateTypes = multipleData[0];

                this.taxRates$.getList().subscribe(data => {

                    this.taxRates$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList))
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }


    onSelectionChanged($clikedRow) {

        if (!this.taxRate)
            try {
                let keySaved = JSON.parse(localStorage.getItem('tax-rates.dataGrid')).selectedRowKeys[0];
                if (keySaved) {
                    this.selectTaxRate(keySaved);
                }
            }
            catch{ }
    }


    onRowClick($clikedRow) {

        this.app$.setScreenTitle(this.entityName);

        if (!this.taxRate)
            this.taxRate = new Entity();

        if (JSON.stringify(this.taxRate.key) === JSON.stringify($clikedRow.key)) {
            this.deselectTaxRate();
        }
        else {
            this.selectTaxRate($clikedRow.key);
        }
    }


    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    private addNewEntity() {

        this.app$.setScreenTitle('New ' + this.entityName);

        this.taxRate = new Entity();
        this.taxRate.created = true;
    }


    private reset() {

        this.taxRate.restore();
    }


    private create() {

        this.handleFormSubmit();

        this.taxRates$.create(this.taxRate).subscribe(

            (okCreate) => {

                this.app$.setScreenTitle(this.entityName);
                this.actions$.notifyCreated();

                this.deselectTaxRate();

                this.taxRates$.getList().subscribe(data => {

                    this.taxRates$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }


    private save() {

        this.handleFormSubmit();

        this.taxRates$.save(this.taxRate).subscribe(
            (okSave) => {

                this.actions$.notifySaved();

                this.deselectTaxRate();

                this.taxRates$.getList().subscribe(data => {

                    this.taxRates$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

            },

            (errorSave) => {
                this.errors$.handleServerErrors(errorSave);
            }
        );
    }


    private deleteConfirm() {

        this.taxRates$.delete(this.taxRate.key).subscribe(

            (okDelete) => {

                this.actions$.notifyDeleted();

                this.deselectTaxRate();

                this.taxRates$.getList().subscribe(data => {

                    this.taxRates$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorDelete) => {
                this.errors$.handleServerErrors(errorDelete);
            }
        );
    }


    private cancel() {

        this.app$.setScreenTitle(this.entityName);

        this.deselectTaxRate();
    }


    private selectTaxRate(key: any) {

        this.actions$.notifyEntityBusy();

        this.taxRates$.getEntity(key).pipe(
            map(entity => {
                return entity;
            }),
        ).subscribe(

            entityMapped => {
                this.taxRate = new Entity();
                this.taxRate.value = entityMapped;
                this.taxRate.key = key;
                if(this.taxRate.value.Type == 0){
                    displayZeroValueText(this.taxRateTypes,'taxType','id','name');
                }                    
                this.app$.notifyScreenReady()
            },

            (errorGetEntity) => this.errors$.handleServerErrors(errorGetEntity)
        );
    }

    private deselectTaxRate() {
        this.dataGrid.instance.deselectRows(this.taxRate.key);
        this.taxRate.key = undefined;
    }


    private handleFormSubmit() {
        this.taxRateForm.form.markAsPristine();
        this.taxRateForm.form.markAsUntouched();
        this.taxRateForm.form.updateValueAndValidity();
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onInitialized(e) {
        this.resizeScreenContent();
    }
    ngAfterViewInit() {
        this.resizeScreenContent();
    }
    onResize(e): void {
        this.resizeScreenContent();
    }
    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }
}