import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '@app/app.service';

@Component({
  selector: 'facnm-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(private app$: AppService) {
    this.animation = app$.getAnimationSettings();
    this.shadingColor = app$.getShadingColor();
  }

  animation: any;
  shadingColor: any;
  visible = true;

  @Input() message: string;
  @Output() confirmed = new EventEmitter<string>();
  @Output() canceled = new EventEmitter<string>();

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }
  confirm() {
    this.confirmed.emit();
  }
  cancel() {
    this.canceled.emit();
  }

}
