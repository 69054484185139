import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { vehicle, vehicleInterface } from './vehicle.component.model';

@Injectable({
    providedIn: 'root'
})
export class vehicleService implements vehicleInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    private listRetrieved: BehaviorSubject<vehicle[]> = new BehaviorSubject<vehicle[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();

    notifyRetrieved(data: vehicle[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<vehicle>) => Observable<any>;

    create(data: Entity<vehicle>): Observable<any> {
        return this._http.put<vehicle>(
            e.endpoint.fleet.vehicle,
            data.value)
            .pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(): Observable<vehicle[]> {
        return this._http.get<vehicle[]>(e.endpoint.fleet.vehicle)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(param: any): Observable<any> {
        return this._http.delete(e.endpoint.fleet.vehicle + "/" + param.Id + "/" + param.DeviceId, {
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getEntity(param) {
        return this._http.get<vehicle>(e.endpoint.fleet.vehicle + '/' + param.Id)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleBuildTypes() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/buildtypes')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleBoxConfig() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/boxconfig')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleClasses() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/vehicleclasses')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getNewVehicle() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/newvehicle')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getCompanyList() {
        return this._http.get<any>(e.endpoint.company + '/dropdown/1')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getNonPrintCompanyList() {
        return this._http.get<any>(e.endpoint.settings.nonPrintCompanyDropdown + '1')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getCompartmentSetList() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/compartmentsets')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getLoadPointList() {
        return this._http.get<any>(e.endpoint.loadPoint)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getDeviceTypeList() {
        return this._http.get<any>(e.endpoint.device + '/devicetypes')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getDeviceConfigList() {
        return this._http.get<any>(e.endpoint.device + '/deviceconfig')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getDeviceStateList() {
        return this._http.get<any>(e.endpoint.device + '/devicestate')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getDataAreaList() {
        return this._http.get<any>(e.endpoint.products.dataArea + '/list/0')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getTimeZones() {
        return this._http.get<any>(e.endpoint.masterData + '/timezones')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getTimeZoneValues() {
        return this._http.get<any>(e.endpoint.masterData + '/timezonesvalues')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getTrailerStates() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/trailerstate')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getVehicleTypes() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/vehicletypes/0')
        .pipe(catchError(this.errors$.handleNetworkError));
    }
    getSpecialActions() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/specialactions')
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleDetails(param) {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/' + param.Id)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    getFilteredVehicleList(param) {
        return this._http.put<any>(e.endpoint.fleet.vehicle + '/filter', param)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    saveSpecialAction(vehiclelist, specialaction) {
        var param = { Vehicles: vehiclelist, SpecialActionId: specialaction };
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/specialactions", param)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteVehicleType(param) {
        return this._http.delete(e.endpoint.fleet.vehicle + '/vehicletypes/' + param, {
            responseType: "text" 
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    saveVehicleTypes(vehicleTypes){
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/vehicletypes",vehicleTypes)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    isCompartmentSetDeletable(param){
        return this._http.get<any>(e.endpoint.fleet.vehicle + "/compartmentsets/deletable/" + param)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteCompartmentSet(param){
        return this._http.delete(e.endpoint.fleet.vehicle + "/compartmentsets/" + param, {
            responseType: "text"
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    saveCompartmentSets(param){
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/compartmentsets", param)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    saveMultipleVehicles(vehicle, vehiclelist){
        var data = { Vehicles: vehiclelist, Vehicle: vehicle };
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/multiplevehicles", data)
        .pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteCompartment(Id, setId){
        return this._http.delete(e.endpoint.fleet.vehicle + "/compartmentsets/compartment/"  + setId + "/" + Id, {
            responseType: "text" 
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
}
