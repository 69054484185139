import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, DoCheck, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Entity, Enum } from '@app/app.model';

import { NgForm } from '@angular/forms';
import { DxDataGridComponent, DxTabsComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { forkJoin, Subject } from 'rxjs';

import { AssessmentInterface, Assessment, SectionField, FormSection, FieldOption } from './assessment.model';
import { AssessmentService } from './assessment.service';

import { AppService } from '@app/app.service';

import { map, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import maxBy from 'lodash/maxBy';
import isEmpty from 'lodash/isEmpty';

import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { dxDataGridHeightAdjustment, displayZeroValueText } from '@app/common_helper';

@Component({
    selector: 'facnm-assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss'],
    providers: [
        {
            provide: AssessmentInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new AssessmentService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        }
    ]
})
export class AssessmentComponent implements OnInit, DoCheck, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    entityName = 'Assessment';

    assessment: Entity<Assessment>;
    assessmentsStore: ArrayStore;

    currentSectionsTabList: FormSection[];
    currentSectionsList: FormSection[];
    currentFieldsList: SectionField[];
    currentOptionsList: FieldOption[];

    @ViewChild('entityForm')
    entityForm: NgForm;
    @ViewChild('entityForm', { read: ElementRef }) entityFormElement: ElementRef;
    @ViewChild('sectionsForm', { read: ElementRef }) sectionsFormElement: ElementRef;
    @ViewChild('optionsForm', { read: ElementRef }) optionsFormElement: ElementRef;
    @ViewChild('optionsFormContainer', { read: ElementRef }) optionsFormContainer: ElementRef;

    currentSections: FormSection[];
    currentSections_modified: boolean;
    currentSectionTab: number = 0;
    currentSectionEdit: FormSection;
    currentSection_modified: boolean;
    currentSectionIndex: number = 0;
    currentFieldIndex: number;
    currentField: SectionField;
    currentField_modified: boolean;
    currentFieldTab: number = 0;

    @ViewChild('sectionsTabs') sectionsTabs: DxTabsComponent;

    fieldTypeList: Enum[];


    isVisible_sections_edit: boolean;
    isVisible_section_edit: boolean;
    isVisible_field_edit: boolean;

    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
    error_variableName: boolean;


    constructor(private assessments$: AssessmentInterface, private actions$: ActionsService, private errors$: ErrorsService, private app$: AppService, private changeDetectorRef: ChangeDetectorRef) {


        assessments$.ListRetrieved.subscribe(data => {

            if (data) {

                this.assessmentsStore = new ArrayStore({
                    data: data,
                    key: ['FormId']
                });

                if (data.length == 0) {
                    this.dataGrid.noDataText = 'No Forms';
                }
                else {
                    this.dataGrid.noDataText = '';
                }
            }
        });

        actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
        actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create());
        actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.save());
        actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
        actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
        actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());

        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {

            if (errors) {

                let entityForm = document.getElementById('entity-form');

                if (entityForm) {
                    entityForm.scrollTop = 0;
                }
            }
        });
    }



    ngDoCheck() {

        if (this.assessment) {

            if (this.currentSections) {
                this.currentSections_modified = JSON.stringify(this.currentSectionsList) !== JSON.stringify(this.assessment.value.FormSections);
            }

            if (this.currentSectionEdit) {
                this.currentSection_modified = JSON.stringify(this.currentSectionEdit.Condition) !== JSON.stringify(this.currentSectionsList[this.currentSectionIndex].Condition);
            }

            if (this.currentField) {
                this.currentField_modified = JSON.stringify(this.currentField) !== JSON.stringify(this.assessment.value.FormSections[this.currentSectionTab].SectionFields[this.currentFieldIndex]);
            }
        }
    }


    ngOnInit() {

        this.app$.setScreenTitle(this.entityName);

        forkJoin(

            this.assessments$.getFieldTypeList(),

        ).subscribe(

            multipleData => {

                this.fieldTypeList = multipleData[0];

                this.assessments$.getList().subscribe(assessmentsData => {
                    this.assessments$.notifyRetrieved(assessmentsData);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );
    }


    onSelectionChanged($clikedRow) {

        if (!this.assessment)
            try {
                let keySaved = JSON.parse(localStorage.getItem('assessment.dataGrid')).selectedRowKeys[0];

                if (keySaved) {
                    this.selectAssessment(keySaved);
                }
            }
            catch{ }
    }

    onRowClick($clikedRow) {

        this.app$.setScreenTitle(this.entityName);

        if (!this.assessment)
            this.assessment = new Entity();

        if (JSON.stringify(this.assessment.key) === JSON.stringify($clikedRow.key)) {
            this.deselectAssessment();
        }
        else {
            this.selectAssessment($clikedRow.key);
        }
    }


    onContentReady_sections(e) {

        e.component.selectItem(0);

        if (this.assessment.value.FormSections && this.assessment.value.FormSections.length > 0)
            {
                this.currentFieldsList = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(f => !f.deleted);
                this.changeDetectorRef.detectChanges();  
                var dividerFields= this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(i=>i.FieldType ==0)
                if(dividerFields.length>0)
                    {
                    dividerFields.forEach(d => {
                        let fieldTypeId ='fieldTypeId'+d.id;
                        displayZeroValueText(this.fieldTypeList,fieldTypeId,'id','name');
                    });
                    }
            }                  
    }

    onItemClick_sections(e) {

        this.currentSectionTab = e.itemIndex;
        this.currentFieldsList = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(f => !f.deleted);
    }


    onItemReordered_sections(e) {
        this.currentSections.splice(e.toIndex, 0, this.currentSections.splice(e.fromIndex, 1)[0]);
        this.currentSections.forEach((s, i) => {
            s.SequenceNo = i + 1;
        });
    }

    onClick_open_sections_edit(e) {

        this.isVisible_sections_edit = true;

        this.currentSections = cloneDeep(this.assessment.value.FormSections);

        this.currentSectionsList = this.currentSections.filter(s => !s.deleted);

        setTimeout(() => {
            this.sectionsFormElement.nativeElement.style.height = this.sectionsFormElement.nativeElement.parentElement.clientHeight - 75 + 'px';
        });
    }

    onClick_add_section() {

        let section = new FormSection();

        section.SequenceNo = 1;

        let sectionPrev: FormSection = maxBy(this.currentSectionsList, 'SequenceNo');
        if (sectionPrev) {
            section.SequenceNo = sectionPrev.SequenceNo + 1;
        }
        section.id = -1;
        section.SectionName = '';
        section.formID = this.assessment.value.FormId;

        this.currentSectionsList.push(section);
        this.currentSections.push(section);
        setTimeout(() => {
            this.sectionsFormElement.nativeElement.scrollTop = this.sectionsFormElement.nativeElement.scrollHeight;
        });
    }

    onClick_delete_section(e, si) {

        let sDeleting = this.currentSectionsList[si];

        if (sDeleting.id == -1) {
            this.currentSectionsList.splice(si, 1);
        }

        else {

            let sOrig = this.currentSections.find(so => sDeleting.id == so.id && sDeleting.formID == so.formID);

            sOrig.deleted = true;

            sOrig.SectionFields.forEach(f => {

                f.deleted = true;

                f.FieldOptions.forEach(fo => {
                    fo.deleted = true;
                });
            });

            this.currentSectionsList = this.currentSections.filter(s => !s.deleted);
        }
    }


    onCancel_sections_edit() {

        this.isVisible_sections_edit = false;
    }



    onClick_open_section_edit(e, si) {

        this.isVisible_section_edit = true;

        this.currentSectionIndex = si;

        this.currentSectionEdit = cloneDeep(this.currentSectionsList[this.currentSectionIndex]);
    }


    onSave_section() {

        this.isVisible_section_edit = false;

        this.currentSectionsList[this.currentSectionIndex] = this.currentSectionEdit;

        this.currentSection_modified = false;
    }

    onCancel_section() {

        this.isVisible_section_edit = false;
    }


    onSave_sections() {

        this.isVisible_sections_edit = false;

        this.currentSectionsList.forEach(sSaving => {

            let siOrig = this.currentSections.findIndex(so => sSaving.id == so.id && sSaving.formID == so.formID && sSaving.SequenceNo == so.SequenceNo);

            if (siOrig < 0) {
                this.currentSections.push(sSaving);
            }
            else {
                this.currentSections[siOrig] = cloneDeep(sSaving);
            }

        });

        this.assessment.value.FormSections = cloneDeep(this.currentSections);

        this.currentSectionTab = 0;
        this.currentSectionsTabList = this.assessment.value.FormSections.filter(s => !s.deleted);
    }



    onItemReordered_fields(e) {
        this.assessment.value.FormSections[this.currentSectionTab].SectionFields.splice(e.toIndex, 0, this.assessment.value.FormSections[this.currentSectionTab].SectionFields.splice(e.fromIndex, 1)[0]);        
        this.assessment.value.FormSections[this.currentSectionTab].SectionFields.forEach((s, i) => {
            s.SequenceNo = i + 1;
        });

        this.currentFieldsList = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(f => !f.deleted);
        this.changeDetectorRef.detectChanges();
    }


    onClick_add_field(e) {

        let newField = new SectionField();

        newField.SequenceNo = 1;
        newField.FieldType = this.fieldTypeList.find((v: Enum) => v.name == 'Small Text').id;

        let fieldPrev: SectionField = maxBy(this.assessment.value.FormSections[this.currentSectionTab].SectionFields, 'SequenceNo');
        if (fieldPrev) {
            newField.SequenceNo = fieldPrev.SequenceNo + 1;
        }
        newField.id = -1;
        newField.formID = this.assessment.value.FormId;
        newField.sectionID = this.assessment.value.FormSections[this.currentSectionTab].id;

        this.currentFieldsList.push(newField);

        this.assessment.value.FormSections[this.currentSectionTab].SectionFields.push(newField);

        setTimeout(() => this.entityFormElement.nativeElement.scrollTop = this.entityFormElement.nativeElement.scrollHeight);

    }

    onClick_delete_field(e, fi) {

        let fDeleting = this.currentFieldsList[fi];

        if (fDeleting.id == -1) {
            this.currentFieldsList.splice(fi, 1);
        }

        else {

            let fOrig = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.find(fo => fDeleting.id == fo.id && fDeleting.formID == fo.formID && fDeleting.sectionID == fo.sectionID);

            fOrig.deleted = true;

            fOrig.FieldOptions.forEach(fo => {
                fo.deleted = true;
            });

            this.currentFieldsList = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(f => !f.deleted);
        }
    }

    onClick_open_field_edit(e, i) {

        this.isVisible_field_edit = true;
        this.currentFieldIndex = i;

        this.currentField = cloneDeep(this.currentFieldsList[this.currentFieldIndex]);

        this.currentOptionsList = this.currentField.FieldOptions.filter(o => !o.deleted);

        setTimeout(() => {
            this.optionsFormElement.nativeElement.style.height = this.optionsFormContainer.nativeElement.parentElement.clientHeight - 200 + 'px';
        });
    }

    onSave_field() {

        this.error_variableName = false;

        let validVariableNameJs = new RegExp('^[a-zA-Z_$][0-9a-zA-Z_$]*$');
        if (validVariableNameJs.test(this.currentField.VariableName)) {
            this.isVisible_field_edit = false;

            this.currentField.FieldOptions = this.currentField.FieldOptions.filter(o => !isEmpty(o) && o.OptionName != null);

            let fOrigIndex = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.findIndex(fo => this.currentField.id == fo.id && this.currentField.formID == fo.formID && this.currentField.sectionID == fo.sectionID);

            this.assessment.value.FormSections[this.currentSectionTab].SectionFields[fOrigIndex] = this.currentField;
            this.currentField_modified = false;

            this.currentFieldsList = this.assessment.value.FormSections[this.currentSectionTab].SectionFields.filter(f => !f.deleted);
        }

        else {
            this.error_variableName = true;
        }
    }

    onCancel_field_edit() {

        this.isVisible_field_edit = false;
    }



    onItemClick_field_edit_tabs(e) {

        this.currentFieldTab = e.itemIndex;
    }


    onClick_add_option(e) {

        let newOption = new FieldOption();
        newOption.id = -1;
        this.currentField.FieldOptions.push(newOption);

        this.currentOptionsList = this.currentField.FieldOptions.filter(o => !o.deleted);

        setTimeout(() => this.optionsFormElement.nativeElement.scrollTop = this.optionsFormElement.nativeElement.scrollHeight);
    }

    onClick_delete_option(e, oi) {

        let oDeleting = this.currentOptionsList[oi];

        if (oDeleting.id == -1) {
            this.currentOptionsList.splice(oi, 1);
        }

        else {

            let oOrig = this.currentField.FieldOptions.find(oo => oDeleting.id == oo.id && oDeleting.formID == oo.formID && oDeleting.sectionID == oo.sectionID && oDeleting.fieldID == oo.fieldID);

            oOrig.deleted = true;

            this.currentOptionsList = this.currentField.FieldOptions.filter(o => !o.deleted);
        }
    }


    isDivider(field: SectionField) {

        let divider = this.fieldTypeList.filter((v: Enum) => ['Divider'].includes(v.name)).map((f => f.id));

        return divider.includes(field.FieldType);
    }

    hasFieldUnit() {

        let fieldTypesWithUnit = this.fieldTypeList.filter((v: Enum) => ['Small Text', 'Large Text', 'Number', 'Text Only'].includes(v.name)).map((f => f.id));

        return fieldTypesWithUnit.includes(this.currentField.FieldType);
    }


    isInvalid(field) { return this.errors$.isInvalid(field); }

    getError(field) { return this.errors$.getError(field); }


    private addNewEntity() {

        this.app$.setScreenTitle('New ' + this.entityName);

        this.assessment = new Entity();

        let section = new FormSection();
        section.SectionName = 'section';
        section.SequenceNo = 1;
        this.assessment.value.FormSections = [];
        this.assessment.value.FormSections.push(section);

        this.assessment.original = cloneDeep(this.assessment.value);
        this.changeDetectorRef.detectChanges();

        this.currentSectionTab = 0;
        this.currentSectionsTabList = this.assessment.value.FormSections.filter(s => !s.deleted);

        this.assessment.created = true;
    }


    private reset() {

        this.assessment.restore();

        this.currentSectionTab = 0;
        this.currentSectionsTabList = this.assessment.value.FormSections.filter(s => !s.deleted);
    }

    create() {

        this.handleFormSubmit();

        this.assessments$.create(this.assessment).subscribe(

            (okCreate) => {

                this.app$.setScreenTitle(this.entityName);
                this.actions$.notifyCreated();

                this.deselectAssessment();

                this.assessments$.getList().subscribe(listWithCreated => {

                    this.assessments$.notifyRetrieved(listWithCreated)
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorCreate) => {
                this.errors$.handleServerErrors(errorCreate);
            }
        );
    }



    private save() {

        this.handleFormSubmit();

        this.assessments$.save(this.assessment).subscribe(

            (okSave) => {

                this.actions$.notifySaved();

                this.deselectAssessment();

                this.assessments$.getList().subscribe(listWithSaved => {

                    this.assessments$.notifyRetrieved(listWithSaved);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));

            },

            (errorSave) => {
                this.errors$.handleServerErrors(errorSave);
            }
        );
    }


    private deleteConfirm() {

        this.assessments$.delete(this.assessment.key).subscribe(

            (okDelete) => {

                this.actions$.notifyDeleted();

                this.deselectAssessment();

                this.assessments$.getList().subscribe(data => {

                    this.assessments$.notifyRetrieved(data);
                    this.app$.notifyScreenReady();

                }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
            },

            (errorDelete) => {
                this.errors$.handleServerErrors(errorDelete);
            }
        );
    }


    private cancel() {

        this.app$.setScreenTitle(this.entityName);

        this.deselectAssessment();
    }


    private selectAssessment(key: any) {

        this.assessments$.getEntity(key).pipe(
            map(entity => {
                entity.FormSections = entity.FormSections.sort((a, b) => a.SequenceNo - b.SequenceNo);
                entity.FormSections.forEach(s => s.SectionFields = s.SectionFields.sort((a, b) => a.SequenceNo - b.SequenceNo));
                return entity;
            }),
        ).subscribe(
            entitySortedBySequenceNo => {

                this.assessment = new Entity();
                this.assessment.value = entitySortedBySequenceNo;
                this.assessment.key = key;
                this.changeDetectorRef.detectChanges();

                this.currentSectionTab = 0;
                this.currentSectionsTabList = this.assessment.value.FormSections.filter(s => !s.deleted);

                this.app$.notifyScreenReady()
            },

            (errorGetEntity) => this.errors$.handleServerErrors(errorGetEntity)
        );
    }


    private deselectAssessment() {

        this.dataGrid.instance.deselectRows(this.assessment.key);
        this.assessment.key = undefined;
    }


    private handleFormSubmit() {
        this.entityForm.form.markAsPristine();
        this.entityForm.form.markAsUntouched();
        this.entityForm.form.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onInitialized(e) {
        this.resizeScreenContent();
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }

    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        this.formHeight = h - 80 + 'px';
        this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
    }
}
