import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxDataGridModule, DxTooltipModule, DxToastModule, DxLoadPanelModule, DxPopupModule, DxDropDownBoxModule } from 'devextreme-angular';

import { FormsModule } from '@angular/forms';

import { ProductBlendsComponent } from './product-blends/product-blends.component';
import { ProductTransformationComponent } from './product-transformation/product-transformation.component';
import { ProductsGridComponent } from './products-grid/products-grid.component';
import { SharedModule } from '@app/_shared/shared.module';
import { ProductComponent } from './product/product.component';

import { NgxSmartModalModule,NgxSmartModalService } from 'ngx-smart-modal';
import { DataAreaComponent } from './data-area/data-area.component';
import { DeviceMappingComponent } from './device-mapping/device-mapping.component';
import { DeviceGroupComponent } from './device-group/device-group.component';
import { FactorComponent } from './factor/factor.component';
import { ProductGroupComponent } from './product-group/product-group.component';
import { PriceGroupComponent } from './price-group/price-group.component';

@NgModule({
    declarations: [
        ProductsGridComponent,
        ProductTransformationComponent,
        ProductBlendsComponent,
        ProductComponent,
        DataAreaComponent,
        DeviceMappingComponent,
        DeviceGroupComponent,
        FactorComponent,
        ProductGroupComponent,
        FactorComponent,
        PriceGroupComponent
    ],
    providers: [ NgxSmartModalService ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        DxDataGridModule,
        DxTooltipModule,
        DxLoadPanelModule,
        DxToastModule,
        DxPopupModule,
        DxDropDownBoxModule,
        NgxSmartModalModule.forRoot()
    ],
    exports: []
})
export class ProductsModule { }
