<div #screen class="container-fluid h-100" (window:resize)="onResize($event)">

    <div id="driver-management" class="row h-100">
        <div class="col">
            <div id="entity-list" class="h-100">
                <dx-data-grid #dataGrid [dataSource]="driverData" noDataText="" (onInitialized)="onInitialized($event)"
                    (onSelectionChanged)="onSelectionChanged($event)" (onRowClick)="onRowClick($event)"
                    (onCellPrepared)="onCellPrepared($event)" [allowColumnResizing]="true" [columnAutoWidth]="true">
                    <dxo-load-panel>
                        <div *dxTemplate="let data of 'content'">
                            <pdi-loader size="medium"></pdi-loader>
                        </div>
                    </dxo-load-panel>
                    <dxi-column caption="Icon" dataField="Deleted">
                        <dxo-lookup [dataSource]="ActiveInactiveDriverLookup" valueExpr="Deleted" 
                        displayExpr="Text">
                        </dxo-lookup>
                    </dxi-column>
                    <dxi-column caption="Driver ID" dataField="Id" dataType="string"></dxi-column>
                    <dxi-column caption="Driver Number" dataField="Number" dataType="string"></dxi-column>
                    <dxi-column caption="Driver Name" dataField="Name" dataType="string"></dxi-column>
                    <dxi-column caption="Valid Until" dataField="ValidDate" dataType="date" format="dd/MM/yyyy">
                    </dxi-column>
                   
                    <dxo-selection mode="single"> </dxo-selection>
                    <dxo-filter-row [visible]="true"> </dxo-filter-row>
                    <dxo-scrolling showScrollbar="always"> </dxo-scrolling>
                    <dxo-sorting mode="single"> </dxo-sorting>
                    <dxo-pager [allowedPageSizes]="[10, 20, 30]" [showPageSizeSelector]="true" [visible]="true">
                    </dxo-pager>
                    <dxo-paging [pageSize]="20"> </dxo-paging>
                </dx-data-grid>
            </div>
        </div>
        <div id="entity-detail" class="col pr-0">
            <div *ngIf="driverManagement && driverManagement.key">
                <form id="entity-form" #entityForm="ngForm" [style.height]="formHeight">
                    <div class="container">
                        <div id="entity-errors" class="row">
                            <div class="col">
                                <facnm-errors></facnm-errors>
                            </div>
                        </div>
                        <div class="collapsible-header-container top-buffer" data-toggle="collapse" (click)="collapse()"
                            aria-expanded="false" aria-controls="Driver">
                            <div class="collapsible-header-left collapsible-header-green">
                                <div class="collapsible-header-icon collapsible-header-icon-green-up"></div>
                                <div class="text-center">
                                    <span class="collapsible-header-content">General Settings</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="Driver" class="row" [hidden]="data_toggle">
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-6 mt-1">
                                            <pdi-radio group="activeOrInActive" [(ngModel)]="driverManagement.value.Deleted" name="user" 
                                            [checked]="true" [label]="'Active'" [value]="false"></pdi-radio></div>
                                            <div class="col-6 mt-1">
                                            <pdi-radio group="activeOrInActive" [(ngModel)]="driverManagement.value.Deleted" name="user" 
                                            [label]="'InActive'" [value]="true"></pdi-radio></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="ValidDate" class="col-form-label">Valid until</label>
                                            </div>
                                            <div class="col-8">
                                                <pdi-date-picker label="" [(ngModel)]="driverManagement.value.ValidDate"
                                                required placeholder="Select a date..." name="ValidDate" #ValidDate="ngModel" [format]="dateFormat">
                                                </pdi-date-picker>
                                                    <div class="error-message"
                                                    *ngIf="!ValidDate.dirty && isInvalid('ValidDate')">
                                                    <small class="error">
                                                        {{getError('ValidDate')}}
                                                    </small>
                                                    </div>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="DriverId" class="col-form-label">Driver ID</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.Id" type="text"
                                                    class="form-custom-control form-control-disabled control-look-input"
                                                    name="DriverId" #DriverId="ngModel" readonly="readonly">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="Language" class="col-form-label">Language</label>
                                            </div>
                                            <div class="col-8">
                                                <dx-select-box name="Language"
                                                    [(ngModel)]="driverManagement.value.Language" [dataSource]="languageList"
                                                    displayExpr="Name" valueExpr="Code" [searchExpr]="['Code','Name']"
                                                    [searchMode]="dxSelectOptions.searchMode"
                                                    [searchTimeout]="dxSelectOptions.searchTimeout"
                                                    [minSearchLength]="dxSelectOptions.minSearchLength"
                                                    [searchEnabled]="true" placeholder="" itemTemplate="item">
                                                    <div *dxTemplate="let data of 'item'">
                                                        <div>{{data.Name}}</div>
                                                        <div>{{data.Code}}</div>
                                                    </div>
                                                </dx-select-box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="DriverNo" class="col-form-label">Driver No.</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.Number" type="text"
                                                    class="form-custom-control control-look-input" name="DriverNo"
                                                    #DriverNo="ngModel">
                                                <div class="control-look"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="Barcode" class="col-form-label">Barcode</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.Barcode" type="text"
                                                    class="form-custom-control control-look-input" name="Barcode"
                                                    #Barcode="ngModel">
                                                <div class="control-look"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="Name" class="col-form-label">Driver Name</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.Name"
                                                    [ngClass]="{'modelstate-invalid': !Name.dirty && isInvalid('Name')}"
                                                    type="text" class="form-custom-control control-look-input"
                                                    name="Name" #Name="ngModel">
                                                <div class="control-look"></div>
                                                <div class="error-message" *ngIf="!Name.dirty && isInvalid('Name')">
                                                    <small class="error">
                                                        {{getError('Name')}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="iButtonId" class="col-form-label">iButton No.</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.iButtonId" type="text"
                                                    class="form-custom-control control-look-input" name="iButtonId"
                                                    #iButtonId="ngModel">
                                                <div class="control-look"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="Password" class="col-form-label">Password</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.Password" type="text"
                                                    class="form-custom-control control-look-input" name="Password"
                                                    #Password="ngModel">
                                                <div class="control-look"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="CanbusDriverId" class="col-form-label">Canbus Driver
                                                    No.</label>
                                            </div>
                                            <div class="col-8">
                                                <input [(ngModel)]="driverManagement.value.CanbusDriverId" type="text"
                                                    class="form-custom-control control-look-input" name="CanbusDriverId"
                                                    #CanbusDriverId="ngModel">
                                                <div class="control-look"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-4">
                                                <label for="CanbusDriverId" class="col-form-label">Roles</label>
                                            </div>
                                            <div class="col-8" style="margin-top: -10px;">
                                                <pdi-dropdown [multiselect]="true" name="role" placeholder="(Role)" [multiselectMode]="'checkbox'"
                                                 [(ngModel)]="driverManagement.value.Roles">
                                                    <pdi-dropdown-option *ngFor="let t of roleList" [key]="t.id">
                                                        {{ t.name }}</pdi-dropdown-option>
                                                    </pdi-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <facnm-action-buttons [modelEntity]="driverManagement" [hideDelete]="true"></facnm-action-buttons>
        </div>
    </div>
</div>

<facnm-delete-dialog [entityName]="entityName"></facnm-delete-dialog>

<facnm-notifications [entityName]="entityName"></facnm-notifications>

<facnm-loading-screen-indicator></facnm-loading-screen-indicator>

<facnm-loading-entity-indicator></facnm-loading-entity-indicator>