import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class AppService {

    constructor(private _http: HttpClient, private router$: Router,) {

    }
    private APIVersion: Subject<void> = new Subject();
    APIVersionDetails = this.APIVersion.asObservable();
    private loginUser: BehaviorSubject<{}> = new BehaviorSubject({});
    loginUserInfo = this.loginUser.asObservable();
    private PageTitle: Subject<string> = new Subject();
    PageTitleValue = this.PageTitle.asObservable();
    dateFormat: string = this.getDateFormat(navigator.language); 
   
    updateUserInfo(data) {
        this.loginUser.next(data);
    }
    updateAPIVersion(data) {
        this.APIVersion.next(data);
    }
    getLoginUserInfo() {
        return this._http.get(environment.endpoint.userLoginInfo);
    }
    getModulesStatus() {
        return this._http.get(environment.endpoint.modulesStatus);
    }
    exportData(type) {
        return this._http.get(environment.endpoint.exportURL + '/' + type);
    }
    exportVehicles(deviceIds) {
        return this._http.put(environment.endpoint.exportURL + '/device', deviceIds);
    }
    getAPIVersion() {
        return this._http.get(environment.endpoint.apiversion).subscribe(res => {
            this.updateAPIVersion(res);
        }, (err => {
            this.updateAPIVersion({ ApiFileVersion: "no API connection", ApiFileInfoVersion: "", DbVersion: 0, connectionFailed: true });
        }));
    }

    getUserDetails() {
        var bearerToken = sessionStorage.getItem("Bearer");
        if (bearerToken != null) {
            forkJoin([this.getLoginUserInfo(), this.getModulesStatus()]).subscribe(res => {
                let userData: object = res[0];
                let moduleStatus: object = res[1];
                let loginUserInfo = { ...userData, ...moduleStatus, ...{ isAuthenticated: true } }
                this.updateUserInfo(loginUserInfo);
            });
        }
        return;
    }
    setScreenTitle(title: string): any {
        this.PageTitle.next(title);
    }
    getScreenTitle() {
        return this.PageTitle;
    }
    redirectToLogin(): any {
        this.router$.navigate(['login']);
    }
    redirectTo(page): any {
        this.router$.navigate([page]);
    }

    private screenReady: Subject<void> = new Subject();
    ScreenReady = this.screenReady.asObservable();
    notifyScreenReady() {
        this.screenReady.next();
    }

    private screenBusy: Subject<void> = new Subject();
    ScreenBusy = this.screenBusy.asObservable();
    notifyScreenBusy() {
        this.screenBusy.next();
    }


    getAnimationSettings(): any {

        return {
            show: {
                type: 'fadeIn',
                duration: 250,
                from: {
                    scale: 0.1,
                    opacity: 0
                }
            },
            hide: {
                type: 'fadeOut',
                duration: 250,
                from: {
                    scale: 0.1,
                    opacity: 1
                }
            }
        };
    }


    getShadingColor(): any {
        return 'rgba(85, 85, 85, 0.5)';
    }


    allowOnlyNumbers(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }


    getImageType(Image: string): string {

        switch (Image.charAt(0)) {
            case '/': return 'jpg';
            case 'i': return 'png';
            case 'R': return 'gif';
            case 'Q': return 'bmp';
            case 'U': return 'webP';
            default: return undefined;
        }
    }
    getDateFormat(locale: string) {
        const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat(locale, options);
        const parts = formatter.formatToParts();
        const formatString = parts.map(part => {
          switch (part.type) {
            case 'day': return 'dd';
            case 'month': return 'MM';
            case 'year': return 'yyyy';
            default: return part.value;
          }
        }).join('');
    
        return (typeof formatString != 'undefined' && formatString) ? formatString : 'MM/dd/yyyy';
      };
}
