import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityInterface, Entity } from "@app/app.model";


export class ProductBlend {
    
    BlendId: number;
    BlendName: string;  
     
    ProductId: number; 
    BlendProductsId: number[];
}



@Injectable()
export abstract class ProductBlendsInterface implements EntityInterface<ProductBlend> {

    create: (data: Entity<ProductBlend>) => Observable<any>;

    save: (data: Entity<ProductBlend>) => Observable<any>;

    delete: (data: any) => Observable<any>;

    getList: () => Observable<ProductBlend[]>;
    getEntity: (key: any) => Observable<ProductBlend>;

    ListRetrieved: Observable<ProductBlend[]>;

    notifyRetrieved: (data: ProductBlend[]) => void;


}